import React from 'react';
import { Alert } from 'foundation';
import formatDollars from 'utils/format-dollars';
import { formatSizeString } from 'components/utils';
import { DatabaseSize, Tier } from 'entities/database';

type Props = {
  size: DatabaseSize;
  tier: Tier;
};

export default ({ size, tier }: Props) => {
  const memory = formatSizeString(size.memory);
  const storage = formatSizeString(size.storage);
  const parsedCostPerHour = parseFloat(size.cost_per_hour);
  const parsedCostPerMonth = parsedCostPerHour * 730;
  const costPerHour = Number.isNaN(parsedCostPerHour)
    ? '-'
    : `${formatDollars(parsedCostPerHour)}/hour`;
  const costPerMonth = Number.isNaN(parsedCostPerMonth)
    ? '-'
    : `${formatDollars(parsedCostPerMonth)}`;

  const hidePricingInfo = tier === Tier.AURA_DSE; // Dont show pricing info for Enterprise Tier

  return (
    <>
      <Alert icon type="info" data-testid="estimate-info">
        <>
          Given the size of your graph and the algorithms you want to use we recommend using the
          following instance: Memory: <b>{memory}</b>; CPU: <b>{size.cpu}</b>; Storage:{' '}
          <b>{storage}</b>{' '}
          {!hidePricingInfo && (
            <>
              ; Price: <b data-testid="estimate-pricing">{costPerHour}</b>. Continuous running for 1
              month will cost {costPerMonth} based on a 730 hour average month.
            </>
          )}
        </>
      </Alert>
    </>
  );
};
