export const profileInformationDefaults: ProfileInformationFormData = {
  firstName: '',
  lastName: '',
  jobRole: undefined,
  jobRoleOther: '',
  primaryUseCase: undefined,
  primaryUseCaseOther: '',
  companyName: '',
  country: undefined,
  subdivision: undefined,
};

export interface ProfileInformationFormData {
  firstName: string;
  lastName: string;
  jobRole?: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  companyName: string;
  country?: string;
  subdivision?: string;
}
