import SnapshotsResource from 'remote/resources/snapshots';
import { DatabasesStore } from 'state';
import logger from 'logger';
import Actions from 'actions';

const restoreSnapshot = (dbId: string, snapshotTimestamp: string) =>
  SnapshotsResource.restore(dbId, snapshotTimestamp)
    .then(updatedDatabase => {
      DatabasesStore.upsert(dbId, updatedDatabase);
      Actions.navigate.push({ hash: '#' });
    })
    .catch(error => {
      logger.error('Failed to restore snapshot due to error:', error);
    });

export default restoreSnapshot;
