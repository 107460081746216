import globals from 'browser/globals';
import location from 'browser/location';

export interface UploadError {
  // This can either be a regular error message or the
  // raw content of a response in XML or JSON.
  // Definitely not for display in the UI.
  message?: string;
  // HTTP response status if applicable
  status?: number;
  // Error code from AWS/Azure/GCP, if applicable
  // It could also be an ad hoc error code set in
  // the frontend logic
  reason?: string;
}

export interface UploadHandlers {
  onError(error: UploadError): void;
  onUploadComplete(xhr: XMLHttpRequest): void;
  onLoadStart(file: File): void;
  setProgress(event: ProgressEvent): void;
}

export interface OnSuccess {
  (): void;
}

export const xhrUpload = (
  url: string,
  file: File,
  handlers: UploadHandlers,
  onSuccess: OnSuccess
) => {
  const xhr = new globals.XMLHttpRequest();

  xhr.onload = (event: ProgressEvent) => {
    const response = event.currentTarget as XMLHttpRequest;

    if (response.status >= 200 && response.status < 300) {
      handlers.onUploadComplete(xhr);
      onSuccess();
    } else {
      handlers.onError(getErrorDetails(response));
    }
  };

  xhr.ontimeout = (event: ProgressEvent) => {
    const response = event.currentTarget as XMLHttpRequest;
    const error: UploadError = {
      message: `Request timed out after ${response.timeout}ms`,
      reason: 'xhr-timeout',
    };
    handlers.onError(error);
  };

  xhr.onerror = () => {
    const error: UploadError = {
      message: 'Network/XHR error',
      reason: 'xhr-error',
    };
    handlers.onError(error);
  };

  xhr.upload.onloadstart = () => {
    handlers.onLoadStart(file);
  };
  xhr.upload.onprogress = handlers.setProgress;
  xhr.open('PUT', url);

  // So previously we would only send dump files which doesn't have a content-type resulting in the
  // content-type being present but undefined. When we also start to accepting .tar files this creates
  // the issue of the signed-url generated by db-manager not conforming to the request sent since
  // db-manager don't take the content-type header into account.
  xhr.setRequestHeader('Content-Type', '');
  xhr.setRequestHeader('Access-Control-Allow-Origin', `https://${location.hostname}`);

  // Azure storage requires this header
  if (/blob\.core\.windows\.net/.test(url)) {
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
  }

  xhr.send(file);
};

const getErrorDetails = (response: XMLHttpRequest): UploadError => {
  const status: number = response.status;
  const responseJson: any | null = maybeParseJson(response.responseText);
  const gcpErrorCode: string | null = responseJson?.error?.errors
    ?.map(_ => _?.reason)
    ?.find(reason => reason);

  // AWS and Azure both use the same XML error response format
  const responseXml: Document | null = response.responseXML;
  const xmlErrorCode: string | null = responseXml?.querySelector('Error Code')?.textContent;

  return {
    status,
    message: response.responseText || 'Unknown/blank error response',
    reason: gcpErrorCode || xmlErrorCode,
  };
};

const maybeParseJson = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};
