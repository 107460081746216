import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';
import Icon from 'components/ui/icons';

interface CloudProviderCardProps {
  'data-testid': string;
  providerIcon: 'gcp' | 'aws' | 'aws-dark' | 'azure';
  selected: boolean;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  'aria-label': string;
}

export const CloudProviderCard = ({
  providerIcon,
  selected,
  onSelect,
  'data-testid': dataTestid,
  'aria-label': ariaLabel,
}: CloudProviderCardProps) => {
  const classes = classNames('console-cloud-provider-card tw-border tw-h-16 tw-w-36', {
    'tw-border-palette-neutral-border-strong': !selected,
    'tw-border-palette-primary-border-strong': selected,
  });
  return (
    <button
      data-testid={dataTestid}
      className={classes}
      onClick={onSelect}
      type="button"
      tabIndex={0}
      onMouseDown={e => e.preventDefault()}
      aria-label={ariaLabel}
      aria-checked={selected}
      role="radio"
    >
      <Icon name={providerIcon} width="45" height="36" />
    </button>
  );
};
