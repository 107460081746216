import React from 'react';
import { Menu } from '@neo4j-ndl/react';
import './menu.css';

export const MenuItem = React.forwardRef(function MenuItem(
  props: React.ComponentProps<typeof Menu.Item>,
  ref
) {
  return (
    <Menu.Item
      {...props}
      className={`console-menu-item ${props.className ? props.className : ''}`}
      ref={ref}
    />
  );
});
