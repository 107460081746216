import { saveAs } from 'file-saver';
import LogsResource from 'remote/resources/logs';
import { LogFile, LogStatus, RemoteLog } from 'types/log';
import { formatISO } from 'date-fns';

const fetchLogs = async (dbid: string): Promise<RemoteLog[]> => {
  const { data } = await LogsResource.listLogs(dbid);
  return data;
};

const requestLog = async (
  dbid: string,
  start: string,
  end: string,
  files: LogFile[]
): Promise<RemoteLog> => {
  const { log_id: logId } = await LogsResource.requestLog(dbid, start, end, files);
  return {
    log_id: logId,
    dbid,
    start,
    end,
    status: LogStatus.IN_PROGRESS,
    files,
    requested_at: formatISO(new Date()),
  };
};

const downloadLog = async (dbid: string, logId: string, filename: string): Promise<void> => {
  const { url } = await LogsResource.getDownloadLink(dbid, logId);
  // The filename given here doesn't take - limitations of the saveAs
  // library, which is falling back to a virtual anchor element to
  // avoid being blocked by CORS.
  saveAs(url, filename);
};

export default { fetchLogs, requestLog, downloadLog };
