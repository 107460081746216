import React from 'react';
import classNames from 'classnames';
import { DataGrid, useDataGridContext } from 'foundation';
import './sticky-actions-data-grid.css';

const StickyActionsBodyCell = ({ cell, components, isStickyActionCell }) => (
  <components.BodyCell
    key={cell.id}
    cell={cell}
    innerProps={{
      className: classNames('!tw-bg-transparent', {
        '!tw-pl-0 tw-flex tw-flex-grow tw-justify-end tw-w-full': isStickyActionCell,
      }),
    }}
  />
);

export const StickyActionsBodyRowCells = ({ row }) => {
  const { components } = useDataGridContext();

  return row.getVisibleCells().map(cell => {
    const isStickyActionCell = cell.column.columnDef?.meta?.isStickyAction;
    return (
      components.BodyCell &&
      (!isStickyActionCell ? (
        <StickyActionsBodyCell
          cell={cell}
          key={`sticky-actions-cell-${cell.id}`}
          components={components}
          isStickyActionCell={false}
        />
      ) : (
        <div
          className="sticky-action-cell tw-sticky tw-right-0 tw-flex"
          key={`sticky-actions-wrapper-cell-${cell.id}`}
          style={{
            minWidth: cell.column.columnDef?.size,
          }}
        >
          <StickyActionsBodyCell
            cell={cell}
            key={`sticky-actions-cell-${cell.id}`}
            components={components}
            isStickyActionCell={true}
          />
        </div>
      ))
    );
  });
};

const StickyActionsBodyRow = ({ row }) => (
  <div
    className="sticky-action-row tw-flex tw-flex-row tw-justify-between tw-border-t tw-min-h-[56px]"
    key={`sticky-actions-row-${row.id}`}
    role="row"
  >
    <StickyActionsBodyRowCells row={row} />
  </div>
);

export const StickyActionsDataGrid = ({
  tableInstance,
  isResizable = false,
  clean = true,
  ...props
}) => (
  <DataGrid
    {...props}
    tableInstance={tableInstance}
    isResizable={isResizable || false}
    styling={{ headerStyle: clean ? 'clean' : 'filled' }}
    components={{
      ...props.components,
      BodyRow: StickyActionsBodyRow,
    }}
  />
);
