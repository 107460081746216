import { CloudProvider } from 'entities/database';

export const capitalizeCloudProvider = (csp: CloudProvider) => {
  if (csp === CloudProvider.AWS) {
    return 'AWS';
  } else if (csp === CloudProvider.GCP) {
    return 'GCP';
  } else {
    return 'Azure';
  }
};
