import ApiClient from 'remote/api-client';
import { Tier } from 'entities/database';

const estimate = async (
  nodeCount: number,
  relationshipCount: number,
  algorithmCategories: string[],
  tier: Tier
) => {
  return ApiClient.post('/estimations')
    .issue({
      AlgorithmCategories: algorithmCategories,
      NodeCount: nodeCount,
      RelationshipCount: relationshipCount,
      Tier: tier,
    })
    .ignoreServerErrors()
    .promise();
};

export default {
  estimate,
};
