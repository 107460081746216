import React, { useEffect, useState } from 'react';
import { User } from 'types/user';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { Alert, Button, Dialog, Form, FormSelect } from 'foundation';
import { useQuery } from 'react-query';
import { listByUserId } from 'remote/resources/tenants';
import UsersResource from 'remote/resources/users';

const getErrorMessage = (err: Error): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }
  return `Error: ${err.reason || 'unknown'}: ${err.responseMessage}`;
};

interface Props {
  user: User;
  open: boolean;
  onClose: () => any;
}

export const UpdateDefaultTenantModal = ({ user, open, onClose }: Props) => {
  const [defaultTenant, setDefaultTenant] = useState(user.DefaultNamespace);
  const [error, setError] = useState<string>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDefaultTenantChange = item => {
    setDefaultTenant(item.value);
  };

  const handleSubmit = async () => {
    if (!defaultTenant || defaultTenant === user.DefaultNamespace) return;

    setError(null);
    setLoading(true);

    try {
      const response: User = await UsersResource.updateDefaultTenant(user.UserId, defaultTenant);
      setDefaultTenant(response.DefaultNamespace);
      setSuccess(true);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDefaultTenant(user.DefaultNamespace);
    setError('');
    setSuccess(false);
  }, [open]);

  const query = useQuery(['namespaces', user.UserId], () => listByUserId(user.UserId), {
    enabled: open,
  });
  let options = [];
  if (query.isFetched) {
    options = query.data.map(tenant => ({
      label: `${tenant.name} (${tenant.id !== tenant.name ? tenant.id + ', ' : ' '}${
        tenant.planType
      }) ${user.DefaultNamespace === tenant.id ? '*Current*' : ''}`,
      key: tenant.id,
      value: tenant.id,
    }));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header data-testid="update-tenant-modal-header">Change default tenant</Dialog.Header>
      <Dialog.Content data-testid="update-tenant-modal-content">
        <Dialog.Description>
          This will change the user&apos;s default project and might limit their access to the old
          one.
        </Dialog.Description>
        <Form onSubmit={handleSubmit}>
          <FormSelect
            label="Project"
            data-testid="update-tenant-select"
            options={options}
            value={defaultTenant}
            onChange={handleDefaultTenantChange}
          />
        </Form>

        {error && (
          <Alert type="danger" data-testid="update-tenant-error" className="tw-mt-2">
            {error}
          </Alert>
        )}
        {query.error && (
          <Alert type="danger" data-testid="update-tenant-error" className="tw-mt-2">
            Failed to fetch user project: {String(query.error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="update-tenant-success" className="tw-mt-2">
            User was successfully migrated to {defaultTenant}.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} fill="outlined" color="neutral">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          loading={loading}
          disabled={defaultTenant === user.DefaultNamespace || loading || success}
          data-testid="update-tenant-button"
        >
          Change project
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
