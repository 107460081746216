import React, { useEffect, useState } from 'react';
import { useSession } from 'store/hooks';
import { LoadingSpinner, Alert, Page } from 'foundation';
import './marketplace.css';
import Actions from 'actions';
import track, { useTracking } from 'react-tracking';
import { friendlyCloudProviderNameMap } from 'entities/database';

const MarketplaceRegistrationPage = () => {
  const tracking = useTracking();
  const session = useSession();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, message: <></> });
  const [registrationDetails, setRegistrationDetails] = useState(null);

  useEffect(() => {
    const registerOrder = async () => {
      // Fetch this once on page load, then store in state
      // Don't keep trying to get it from local storage or query params
      // That will be cleared on success or error at the end of the registration
      // process, and fetching it again will result in getting null.
      // However, we still need the details for error messages, hence we keep it in
      // state.
      const details = Actions.marketplace.getMarketplaceRegistrationLocalStorage(session.email);
      if (details === null) {
        throw Error('Missing required registration details');
      }
      setRegistrationDetails(details);

      Actions.marketplace
        .registerOrder({
          CustomerId: details.customerId,
          ProductId: details.productId,
          Marketplace: details.cloudProvider,
        })
        .then(result => {
          Actions.marketplace.removeMarketplaceRegistrationLocalStorage(session.email);
          const url = new URL(location.href);
          url.searchParams.delete('aws_product_id');
          url.searchParams.delete('aws_customer_id');
          url.searchParams.delete('azure_product_id');
          url.searchParams.delete('azure_customer_id');
          url.searchParams.delete('marketplace-registration');
          url.searchParams.set('tenant', result.namespace_id);
          url.hash = '';
          location.replace(url);
        })
        .catch(e => {
          setLoading(false);
          if (e.response?.status === 404) {
            setError({
              isError: true,
              message: <p className="tw-mt-4">Failed to find marketplace order.</p>,
            });
          } else if (e.response?.status === 409) {
            setError({
              isError: true,
              message: (
                <p className="tw-mt-4">
                  To gain access to this project, request an invitation from the administrator.
                  Access is controlled via the Neo4j Aura console.
                </p>
              ),
            });
          } else {
            setError({
              isError: true,
              message: (
                <p className="tw-mt-4">
                  An error has occurred and we are working to fix the problem.
                </p>
              ),
            });
          }
          Actions.marketplace.removeMarketplaceRegistrationLocalStorage(session.email);
        });
    };
    registerOrder();
  }, []);

  return (
    <Page className="tw-h-full">
      <div className="console-marketplace-content-container">
        {loading && !error.isError && registrationDetails && (
          <>
            <div className="console-marketplace-loading-container">
              <LoadingSpinner size="large"></LoadingSpinner>
            </div>
            <Alert data-testid="marketplace-verify-order" className="tw-mb-6">
              Verifying {friendlyCloudProviderNameMap[registrationDetails.cloudProvider]}{' '}
              marketplace order details.
            </Alert>
          </>
        )}
        {error.isError && registrationDetails && (
          <Alert data-testid="marketplace-verify-order-error" className="tw-mb-6" type="danger">
            {error.message}
            <p className="tw-mt-4">
              If you need immediate help from our customer service team about an ongoing order,
              please{' '}
              <a
                onClick={() => {
                  tracking.trackEvent({
                    action: 'marketplace_support_link',
                    properties: { event_label: 'clicked' },
                  });
                }}
                href="https://support.neo4j.com/s/"
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </a>{' '}
              and include the following Customer ID: {registrationDetails.customerId} in your
              ticket.
            </p>
          </Alert>
        )}
      </div>
    </Page>
  );
};

export default track()(MarketplaceRegistrationPage);
