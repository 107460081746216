import ApiClient from 'remote/api-client';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

const NO_DATA = 'NoMetricDataAvailable';
const OFFLINE = 'NoMetricDataOffline';
const SERVER_ERROR = 'NoMetricsDueToServerError';

const fetch = (dbId: string, metricsPeriod: string) =>
  callApi(dbId, metricsPeriod).catch(err => ({ error: getErrorConstant(err) }));

let pollIntervalId;

const poll = (interval: number, dbId: string, metricsPeriod: string, callback: Function) => {
  if (pollIntervalId) {
    throw new Error('Duplicate polling request: poll operation already in progress');
  }

  pollIntervalId = setInterval(
    () =>
      callApi(dbId, metricsPeriod)
        .then(callback)
        .catch(err => callback({ error: getErrorConstant(err) })),
    interval
  );
};

const callApi = (dbId: string, period: string) =>
  ApiClient.get(`/databases/${dbId}/charts`)
    .query({ period })
    .ignore(404);

const resetPolling = () => {
  if (pollIntervalId) {
    clearInterval(pollIntervalId);
    pollIntervalId = null;
  }
};

const getErrorConstant = (error: ApiClientRequestError) => {
  if (error && error.message === 'Failed to fetch') {
    return OFFLINE;
  }

  const status = error && error.response && error.response.status;

  switch (status) {
    case 404:
      return NO_DATA;
    default:
      return SERVER_ERROR;
  }
};

export default {
  NO_DATA,
  OFFLINE,
  SERVER_ERROR,
  fetch,
  poll,
  resetPolling,
};
