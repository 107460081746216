import { SessionState } from 'state/session-store';
import { covers } from 'store/hooks/permissions';
import { Action } from 'types/user';
import { Database, DatabaseStatus } from 'entities/database';

export const isCDCEnrichmentModeEnabled = (session: SessionState, database: Database): boolean => {
  const { tenant } = session;

  // There's no guarantee that tenant is successfully loaded
  if (!tenant) return false;
  return (
    (tenant.capabilities?.cdc_enrichment_mode &&
      database.Capabilities?.cdc_enrichment_mode?.enabled) ??
    false
  );
};

export const canEditCDCEnrichmentMode = (session: SessionState, database: Database): boolean => {
  const isEnabled = isCDCEnrichmentModeEnabled(session, database);
  return (
    (isEnabled &&
      database.AvailableActions?.cdc_enrichment_mode?.enabled &&
      !database.IsBeingCloned &&
      !isChangingCDCEnrichmentMode(database) &&
      isAllowedEditCDCEnrichmentMode(session, database)) ??
    false
  );
};

export const isAllowedEditCDCEnrichmentMode = (session: SessionState, database: Database) => {
  const { permissions, tenant } = session;

  return covers(permissions, Action.UPDATE, `namespaces/${tenant.id}/databases/${database.DbId}`);
};

export const isChangingCDCEnrichmentMode = (database: Database) => {
  return (
    database.DatabaseStatus === DatabaseStatus.UPDATING &&
    database.DesiredSettings.CDCEnrichmentMode &&
    database.AppliedSettings.CDCEnrichmentMode &&
    database.DesiredSettings.CDCEnrichmentMode !== database.AppliedSettings.CDCEnrichmentMode
  );
};
