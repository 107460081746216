import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { SessionStore } from 'state';
import globals from 'browser/globals';
import location from 'browser/location';
import localStorage from 'browser/localStorage';
import logger from 'logger';
import { Tenant, TenantType } from 'entities/tenant';

export type Neo4jMixpanel = OverridedMixpanel & { aura_live: any; neo4j_web: any };

// The version number is the first character of the third group : [VERSION_NUMBER][0-9A-F]{3}
// Check for version 4 and 5.
const regexpUUID = /[0-9A-F]{8}-[0-9A-F]{4}-[45][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi;

const databaseDetailsRegex = /\/#databases\/([^/]+)\/detail/;
const databaseCreatedRegex = /\/#databases\/([^/]+)\/created/;

const mixPanelIdKey = 'neo.mixpanel';
const env = process.env.NODE_ENV;
const mixpanelDisabled = process.env.MIXPANEL_DISABLED && env !== 'production';
const mixpanelConfig =
  env !== 'production'
    ? {
        loaded: mx => mx.set_config({ debug: true }),
      }
    : {};

if ((env === 'development' || env === 'production') && !mixpanelDisabled) {
  mixpanel.init(process.env.MIXPANEL_TOKEN, mixpanelConfig, 'aura_live');

  const projectId =
    env === 'production' ? '4bfb2414ab973c741b6f067bf06d5575' : 'ef1696f0a9c88563894dcba2019a9bef';
  mixpanel.init(projectId, mixpanelConfig, 'neo4j_web');
}

const replaceUUIDS = (pagePath: string) => {
  let result = '' + pagePath;

  for (const match of pagePath.matchAll(regexpUUID)) {
    result = result.replace(match[0], '<UUID>');
  }

  return result;
};

const trackPath = (pagePath: string) => {
  try {
    // UA-XXXXX-XX tracker ID needs to match that of index.html
    globals.window.gtag('config', 'UA-1192232-34', {
      page_path: pagePath,
    });

    const dbIdExtracted =
      databaseCreatedRegex.exec(pagePath) || databaseDetailsRegex.exec(pagePath);
    const reducedPathByID = (dbIdExtracted && dbIdExtracted.length > 1
      ? pagePath.replace(`${dbIdExtracted[1]}/`, '')
      : pagePath
    ).replace(/^(\/#|\/)+/g, '');

    const reducedPath = replaceUUIDS(reducedPathByID);

    trackEvent(`NAVIGATE_TO_${reducedPath.toUpperCase()}`, {
      application: 'WEBAPP',
      'reduced-path': reducedPath,
      'page-path': pagePath,
    });
  } catch (e) {
    logger.error(e);
  }
};

const determineCloudProvider = (tenant: Tenant) => {
  if (!tenant) return null;
  if (tenant.tenantType === TenantType.PERSONAL) return null;
  const cloudProviders = Object.keys(tenant.providerConfigs);
  return cloudProviders[0];
};

const trackEvent = (event, properties: any = {}) => {
  try {
    const { email, name, sub: auth0SubjectId, planType, product, tenant } = SessionStore.state;
    const props = {
      ...properties,
      consoleType: planType,
      tenantId: tenant?.id,
      tenantType: tenant?.tenantType,
      cloudProvider: determineCloudProvider(tenant),
      product,
      ...(['NAVIGATE_TO_DATABASES/CREATE', 'NAVIGATE_TO_CREATE-DATABASE/PROFESSIONAL'].includes(
        event
      )
        ? {
            proTrial: tenant.availableActions.create_pro_trial.enabled
              ? 'proTrial_included'
              : 'proTrial_excluded',
          }
        : {}),
    };
    const auraEvent = `AURA_${event}`;

    // Assume the mixpanel properties have been initialized
    const neo4jMixpanel = mixpanel as Neo4jMixpanel;

    if (!mixpanelDisabled) {
      handleMixpanelIdentity({ email, name, auth0SubjectId });

      neo4jMixpanel.neo4j_web.track(auraEvent, props);
      neo4jMixpanel.aura_live.track(auraEvent, props);
    }

    globals.window.analytics.track(event, { properties: props });
    globals.window.gtag('event', auraEvent, {
      event_category: 'aura',
      ...props,
    });
  } catch (e) {
    logger.error(e);
  }
};

const hasMixPanelIDInQuery = query => query && query.includes('mpid=');

const checkMixPanelUser = () => {
  const query = location.search;

  if (hasMixPanelIDInQuery(query)) {
    const params = new globals.URLSearchParams(query.substring(1));
    const mpid = params.get('mpid');
    if (mpid) {
      localStorage.setItem(mixPanelIdKey, mpid);
    }
  }
};

const removeMixpanelUserId = () => localStorage.removeItem(mixPanelIdKey);

const handleMixpanelIdentity = ({ email, name, auth0SubjectId }) => {
  if (mixpanelDisabled) {
    return;
  }

  if (!auth0SubjectId) {
    // do not track when auth0SubjectId is falsy'
    return;
  }

  const userIdFromLocalStorage = localStorage.getItem(mixPanelIdKey);

  const userProfile = {
    $email: email,
    name,
  };

  // Assume the mixpanel properties have been initialized
  const neo4jMixpanel = mixpanel as Neo4jMixpanel;

  // Aura Mixpanel Project
  neo4jMixpanel.aura_live.people.set(userProfile);
  neo4jMixpanel.aura_live.identify(auth0SubjectId);
  globals.window.analytics.identify(auth0SubjectId, {
    name,
    email,
  });

  // Neo Universal Mixpanel Project
  neo4jMixpanel.neo4j_web.people.set(userProfile);

  if (userIdFromLocalStorage) {
    neo4jMixpanel.neo4j_web.alias(userIdFromLocalStorage);
    globals.window.analytics.alias(userIdFromLocalStorage);
    removeMixpanelUserId();
  }
  neo4jMixpanel.neo4j_web.identify(auth0SubjectId);
  globals.window.analytics.identify(auth0SubjectId);
};

export default {
  trackEvent,
  trackPath,
  checkMixPanelUser,
  handleMixpanelIdentity,
};
