import React, { useState } from 'react';
import { Dialog, Button, Alert, Form } from 'foundation';
import BillingAccountsResource from 'remote/resources/billing-accounts';
import { BillingAccountFormData } from './types';
import { BillingAccount } from 'types/billing-account';
import BillingAccountFormFields from './billing-account-form-fields';

export interface CreateBillingAccountModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(ba: BillingAccount): void;
  organizationId: string;
}

const defaultValues = (organizationId): BillingAccountFormData => ({
  organizationId: organizationId,
  name: '',
});

export const CreateBillingAccountModal = ({
  open,
  closeModal,
  saveAndClose,
  organizationId,
}: CreateBillingAccountModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [billingAccount, setBillingAccount] = useState<BillingAccountFormData>({
    ...defaultValues(organizationId),
  });

  const handleClose = () => {
    setBillingAccount({ ...defaultValues(organizationId) });
    setErrorMessage(null);
    closeModal();
  };

  const handleSubmit = async () => {
    setErrorMessage(null);

    setLoading(true);
    try {
      const createdBillingAccount = await BillingAccountsResource.create(
        billingAccount.organizationId,
        billingAccount.name
      );
      setLoading(false);
      saveAndClose(createdBillingAccount);
    } catch (err) {
      setErrorMessage(err.toString());
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (data: BillingAccountFormData) => {
    setBillingAccount(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'create-billing-account-modal' }}
    >
      <Dialog.Header>Create new Billing Acount</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">
          Create a new Billing Account for this organization. You can link a project to the new
          Billing Account later.
        </div>
      </Dialog.Description>
      <Dialog.Content data-testid="create-billing-account-content">
        <Form onSubmit={handleSubmit} id="create-billing-account-form">
          <BillingAccountFormFields
            billingAccount={billingAccount}
            onChange={handleChange}
            organizationId={organizationId}
          />
        </Form>
        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="create-billing-account-error-message"
            description={`There was an error creating the Billing Account: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          disabled={loading}
          onClick={handleClose}
          data-testid="discard-create-billing-account-button"
        >
          Discard
        </Button>
        <Button
          loading={loading}
          type="submit"
          form="create-billing-account-form"
          data-testid="create-billing-account-button"
        >
          Create
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default CreateBillingAccountModal;
