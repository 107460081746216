import ApiClient from 'remote/api-client';

const create = async (email: string): Promise<any> =>
  ApiClient.post('/authorized-users')
    .issue({ Email: email })
    .promise();

export default {
  create,
};
