import React from 'react';

const ErrorPage = () => (
  <div className="tw-m-6">
    <h1>An error occurred</h1>
    <p>Try reloading the page, and if the problem persists please contact support.</p>
  </div>
);

export default ErrorPage;
