export interface CustomerResponse {
  address?: Address;
  service_address?: Address;
  company_name?: string;
  billing_name?: string;
  email?: string;
}

export interface Customer {
  address?: BillingAddress;
  serviceAddress?: BillingAddress;
  companyName?: string;
  billingName?: string;
  email?: string;
}

export interface CustomerCardResponse {
  Last4: string;
  Brand: string;
  ExpMonth: string;
  ExpYear: string;
  Name: string;
  Address: Address;
}

export interface CustomerCardDetails {
  last4: string;
  brand: string;
  expMonth: string;
  expYear: string;
  name: string;
  address: BillingAddress;
}

export interface Address {
  line1: string;
  city: string;
  postal_code: string;
  state: string;
  country: string;
}

export interface BillingAddress {
  address_line1: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
}

export const transformAddressToBillingAddress = (address: Address): BillingAddress => ({
  address_line1: address?.line1,
  address_city: address.city,
  address_zip: address.postal_code,
  address_state: address?.state,
  address_country: address.country,
});

export const transformBillingAddressToAddress = (billingAddress: BillingAddress): Address => ({
  city: billingAddress.address_city,
  line1: billingAddress.address_line1,
  postal_code: billingAddress.address_zip,
  state: billingAddress.address_state,
  country: billingAddress.address_country,
});

export const isSameAddress = (address: BillingAddress, serviceAddress: BillingAddress) =>
  JSON.stringify(address) === JSON.stringify(serviceAddress);
