import Auth0Authentication from './auth0-universal-login-auth';
import FakeAuthentication from './fake-auth';

type AuthenticationClient = FakeAuthentication | Auth0Authentication;

const getAuthClient = (): AuthenticationClient => {
  if (process.env.INSTANT_FAKE_AUTH) {
    return new FakeAuthentication(0);
  } else if (process.env.FAKE_AUTH) {
    return new FakeAuthentication(500);
  }

  return new Auth0Authentication();
};

const authenticationClient = getAuthClient();

export default authenticationClient;
