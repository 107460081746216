import React, { useMemo, useState } from 'react';
import { Database, Region, Tier } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { useDefaultErrorHandler } from 'remote/error-handler';
import {
  AzureEndpointConnectionState,
  TrafficConfig,
  TrafficEnablement,
} from 'types/traffic-configs';
import TrafficConfigResources from 'remote/resources/traffic-configs';
import { validateYup, Validation } from 'utils/validation';
import * as yup from 'yup';
import { productFriendlyName } from 'types/product';
import {
  Alert,
  Button,
  Dialog,
  FormInput,
  FormSelect,
  LoadingSpinner,
  TextLink,
} from 'components/foundation';
import { useNotify } from 'state/notifications';
import Icon from 'components/ui/icons';
import { CopyInput } from 'components/application/copy-input';
import {
  ConfirmCheckbox,
  ConnectionRequestAcceptor,
  PrivateConnection,
  VpnMessage,
} from './shared';
import { getRegionsForTier } from 'utils/tiers-and-regions';
import { useTracking } from 'react-tracking';

interface FormData {
  tier?: Tier;
  region?: string;
  subscriptionIds: string[];
  privateTraffic: TrafficEnablement;
  publicTraffic: TrafficEnablement;
  endpointIds: string[];
  endpointIdField: string;
}

const schema = yup.object({
  tier: yup
    .string()
    .oneOf(Object.values(Tier))
    .required()
    .label('Product'),
  region: yup
    .string()
    .required()
    .label('Region'),
  subscriptionIds: yup
    .array()
    .min(1)
    .required()
    .of(
      yup
        .string()
        .required()
        .uuid()
        .label('Azure Subscription ID')
    ),
  privateTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  publicTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  endpointIds: yup
    .array()
    .required()
    .of(
      yup
        .string()
        .min(1)
        .label('Azure Endpoint ID')
    ),
});

const validate = (data: FormData): Validation<FormData> => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  subscriptionIds: trafficConfig ? trafficConfig?.azureProperties?.subscriptionIds : [],
  endpointIds: trafficConfig ? trafficConfig.azureProperties.endpointIds : [],
  endpointIdField: '',
  privateTraffic: trafficConfig?.privateTraffic ?? TrafficEnablement.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TrafficEnablement.ENABLED,
});

const getConnectionStateLabelColor = (state: AzureEndpointConnectionState) => {
  if (state === AzureEndpointConnectionState.APPROVED) {
    return 'success';
  }

  if (state === AzureEndpointConnectionState.REJECTED) {
    return 'danger';
  }

  return 'default';
};

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const AzureTrafficConfigDialog = ({
  tenant,
  trafficConfig,
  title,
  onClose,
  onSuccess,
  databases,
  // Only needed in create mode
  // Used to prepopulate the props
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: {
  tenant: Tenant;
  trafficConfig?: TrafficConfig;
  title: string;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<Tier, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  databases: Database[];
}) => {
  const notify = useNotify();
  const providerConfigs = tenant.providerConfigs;
  const editMode = !!trafficConfig;
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const defaultErrorHandler = useDefaultErrorHandler();
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [enableError, setEnableError] = useState('');
  const tracking = useTracking();
  const [endpointConnectionMessage, setEndpointConnectionMessage] = useState<{
    message: React.ReactNode;
    messageType: 'warning' | 'danger' | 'info' | 'success';
  }>(null);
  const affectedDatabases = useMemo(() => {
    let dbs: Database[] = [];
    if (data.region && data.tier && data.publicTraffic === TrafficEnablement.DISABLED) {
      dbs = databases.filter(db => db.Region === data.region && db.Tier === data.tier);
    }
    return dbs;
  }, [data.publicTraffic]);

  const handleClose = () => {
    onClose();
  };

  const handleFinishLater = () => {
    tracking.trackEvent({
      action: 'new_network_access_finish_later',
      properties: { event_label: 'clicked' },
    });
    onClose();
  };

  const handleTierChange = option => {
    tracking.trackEvent({
      action: 'new_network_access_product_select',
      properties: { event_label: 'selected', tier: option.value },
    });
    setData(prev => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = option => {
    const newRegion = option.value;
    tracking.trackEvent({
      action: 'new_network_access_region_select',
      properties: { event_label: 'selected', region: newRegion },
    });
    setData(prev => {
      const existingTrafficConfig = existingTrafficConfigs.find(
        c => c.tier === prev.tier && c.region === newRegion
      );
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingEndpointIds = existingTrafficConfig?.azureProperties?.endpointIds ?? [];
      const existingSubscriptionIds = existingTrafficConfig?.azureProperties?.subscriptionIds ?? [];
      return {
        ...prev,
        region: newRegion,
        endpointIds: existingEndpointIds,
        subscriptionIds: existingSubscriptionIds,
      };
    });
  };

  const handleSubscriptionIdChange = (e, index) => {
    const newValue = e.target.value;
    setData(prev => {
      prev.subscriptionIds[index] = newValue;
      const newSubscriptionIds = [...prev.subscriptionIds];
      return { ...prev, subscriptionIds: newSubscriptionIds };
    });
  };
  const handleDeleteSubscriptionId = index => {
    setData(prev => ({
      ...prev,
      subscriptionIds: data.subscriptionIds.filter((_, i) => i !== index),
    }));
  };
  const handleAddSubscriptionId = () => {
    setData(prev => ({ ...prev, subscriptionIds: [...prev.subscriptionIds, ''] }));
  };

  const handleEndpointIdFieldChange = e => {
    setEndpointConnectionMessage(null);
    const newValue = e.target.value;
    setData(prev => {
      return { ...prev, endpointIdField: newValue };
    });
  };
  const handleAddEndpointId = () => {
    setData(prev => {
      const endpointConnection = trafficConfig.status.azureStatus.privateEndpointConnections.find(
        conn => conn.privateEndpointId === prev.endpointIdField
      );

      if (!endpointConnection) {
        setEndpointConnectionMessage({
          message:
            'Endpoint connection request could not be found. Please follow the instructions on the previous step to create an endpoint connection request, and/or check that your endpoint id is correct.',
          messageType: 'danger',
        });
        return prev;
      }

      if (prev.endpointIds.includes(endpointConnection.privateEndpointId)) {
        setEndpointConnectionMessage({
          message: (
            <>
              The endpoint connection accept request for{' '}
              <b>{endpointConnection.privateEndpointId}</b> has already been sent. The setup can
              take a couple of minutes.
            </>
          ),
          messageType: 'info',
        });
        return prev;
      }

      setEndpointConnectionMessage({
        message: (
          <>
            The endpoint connection accept request for <b>{endpointConnection.privateEndpointId}</b>{' '}
            has been sent. The setup can take a couple of minutes.
          </>
        ),
        messageType: 'success',
      });

      const newData = {
        ...prev,
        endpointIds: [...prev.endpointIds, prev.endpointIdField],
        endpointIdField: '',
      };

      tracking.trackEvent({
        action: 'new_network_access_connection_accept_request',
        properties: { event_label: 'added' },
      });

      handleSubmitWithData(newData, { next: false });

      return newData;
    });
  };

  const handleConfirmChange = e => {
    tracking.trackEvent({
      action: 'vpn_statement_acknowledgement',
      properties: { event_label: 'checked' },
    });
    setConfirm(e.target.checked);
  };

  const handleTestGuidesClick = () => {
    tracking.trackEvent({ action: 'link_to_connection_test_guides' });
  };

  const handlePublicTrafficDisabledChange = e => {
    const newValue = !e.target.checked;
    tracking.trackEvent({
      action: newValue ? 'enable_public_traffic' : 'disable_public_traffic',
      properties: { event_label: 'checked' },
    });
    setData(prev => ({
      ...prev,
      publicTraffic: newValue ? TrafficEnablement.ENABLED : TrafficEnablement.DISABLED,
    }));
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 4));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleSubmit = async (e, close = false) => {
    tracking.trackEvent({
      action: 'new_network_access_enable_private_link',
      properties: { event_label: 'clicked' },
    });
    handleSubmitWithData(data, { close });
  };

  const handleSubmitWithData = async (toSubmit: FormData, { close = false, next = true } = {}) => {
    const errors = validate(toSubmit);
    if (errors) {
      setValidation(errors);
      return;
    }
    setEnableError('');
    setValidation({});
    setLoading(true);

    try {
      const response = await TrafficConfigResources.update(
        tenant.id,
        toSubmit.tier,
        toSubmit.region,
        {
          privateTraffic: toSubmit.privateTraffic,
          publicTraffic: toSubmit.publicTraffic,
          azureProperties: {
            endpointIds: toSubmit.endpointIds,
            subscriptionIds: toSubmit.subscriptionIds,
          },
        }
      );
      if (next) handleNextStep();
      if (onSuccess) onSuccess(response);
      if (close) handleClose();
    } catch (err) {
      if (err.response.status === 422) {
        setEnableError(err.response.responseMessage);
      } else if (err.response.status <= 499 && err.response.status >= 400) {
        notify.error('Failed to update network security configuration');
      } else {
        defaultErrorHandler(err);
      }
    }

    setLoading(false);
  };

  const handleSubmitAndClose = e => handleSubmit(e, true);

  const trackCopyEndpoint = () => {
    tracking.trackEvent({ action: 'copy_azure_endpoint_service_name' });
  };

  const tiers = [...new Set(existingTrafficConfigs.map(config => config.tier))];
  let regions: Region[] | null = null;
  let regionOptions = [];
  if (data.tier) {
    regions = getRegionsForTier(providerConfigs, data.tier);
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs
      .filter(tc => tc.tier === data.tier)
      .map(tc => tc.region);
    regions = regions.filter(
      r => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name)
    );
    regionOptions = regions.map(r => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }

  const tierOptions = tiers
    .filter(t => [Tier.ENTERPRISE, Tier.AURA_DSE].includes(t)) // Shouldn't happen in practice, but my devenv is whack
    .map(tier => {
      const product = getProductFromTier(tier);
      return {
        key: tier,
        value: tier,
        label: productFriendlyName(product),
      };
    });

  const privateLinkServiceName = trafficConfig?.status?.azureStatus?.privateLinkServiceName;
  const endpointConnections = trafficConfig?.status?.azureStatus?.privateEndpointConnections ?? [];
  const configCreating = !trafficConfig || !privateLinkServiceName;

  // Always render at least one subscription id field
  const subscriptionIds = data.subscriptionIds.length > 0 ? data.subscriptionIds : [''];

  return (
    <Dialog open onClose={handleClose}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
        <p>Step {step} of 4</p>
        {step === 1 && (
          <>
            <FormSelect
              label="Product"
              options={tierOptions}
              value={data.tier}
              onChange={handleTierChange}
              disabled={editMode}
              errorText={validation.tier?.message}
              data-testid="azure-dialog-select-product"
            />
            {regions && (
              <>
                {regionOptions.length === 0 && (
                  <Alert
                    type="warning"
                    title="All regions configured"
                    description={`All ${productFriendlyName(
                      getProductFromTier(data.tier)
                    )} regions have been configured.`}
                  />
                )}
                {regionOptions.length > 0 && (
                  <FormSelect
                    label="Region"
                    helpText="Azure PrivateLink applies to all instances in the region."
                    options={regionOptions}
                    value={data.region ?? ''}
                    onChange={handleRegionChange}
                    disabled={editMode}
                    errorText={validation.region?.message}
                    data-testid="azure-dialog-select-region"
                  />
                )}
              </>
            )}
            {data.region && (
              <>
                {subscriptionIds.map((subscriptionId, index) => {
                  // Disable the first N items where N is the number of items already existing
                  // in the project ids config
                  const disabled =
                    (trafficConfig?.azureProperties?.subscriptionIds ?? []).length > index;
                  const validationKey = `subscriptionIds[${index}]`;
                  const validationItem = validation[validationKey];
                  return (
                    <FormInput
                      fluid
                      key={index}
                      aria-label="Target Azure Subscription ID"
                      label={index === 0 ? "Target Azure Subscription ID's" : ''}
                      placeholder="Enter a Azure Subscription ID..."
                      value={subscriptionId}
                      onChange={v => handleSubscriptionIdChange(v, index)}
                      errorText={validationItem?.message}
                      disabled={disabled}
                      rightIcon={
                        index > 0 &&
                        !disabled && (
                          <Icon
                            name="TrashIconOutline"
                            onClick={() => handleDeleteSubscriptionId(index)}
                            data-testid={`delete-subscription-id-button-${index + 1}`}
                          />
                        )
                      }
                      data-testid="azure-dialog-subscription-id"
                    />
                  );
                })}
                <div>
                  <Button
                    fill="outlined"
                    onClick={handleAddSubscriptionId}
                    iconName="PlusIconOutline"
                    data-testid="azure-dialog-add-subscription-id"
                  >
                    Add subscription ID
                  </Button>
                </div>
                {enableError && <Alert description={enableError} type="danger" />}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            {configCreating && (
              <Alert
                title={
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="PrivateLink is being configured..."
              />
            )}
            {!configCreating && (
              <>
                <Alert icon type="success" title="Accepted" />
                <CopyInput
                  id="copy-azure-privatelink-service-name"
                  label="PrivateLink Service Name"
                  value={privateLinkServiceName}
                  readOnly
                  onCopy={trackCopyEndpoint}
                />
              </>
            )}

            <div className="tw-mt-2">
              <h6>
                <span className="tw-font-normal">Create Private Endpoint</span>
              </h6>
              <ol className="console-network-instructions tw-list-decimal tw-mt-3 tw-ml-8">
                <li>
                  Log in to the{' '}
                  <TextLink href="https://portal.azure.com/" externalLink>
                    Azure Portal
                  </TextLink>
                  .
                </li>
                <li>
                  Search for <i>Private endpoints</i> in the global search bar, and click on the{' '}
                  <b>Private endpoints</b> item in the dropdown.
                </li>

                <li>
                  With <b>Private endpoints</b> selected in the side navigation, click{' '}
                  <b>+ Create</b>.
                </li>

                <li>
                  Set <b>Subscription</b> to the subscription you entered on the previous step for{' '}
                  <b>Target Subscription ID</b>.
                </li>

                <li>
                  Set <b>Resource group</b> to an appropriate resource group, or create a new one.
                </li>

                <li>
                  Set <b>Name</b> and <b>Network Interface Name</b> to appropriate names.
                </li>
                <li>
                  Set <b>Region</b> to the same region as the virtual network you want to connect to
                  your Neo4j instances from.
                </li>
                <li>
                  Click <b>Next</b>.
                </li>
                <li>
                  Set <b>Connection Method</b> to{' '}
                  <b>Connect to an Azure resource by resource ID or alias</b>.
                </li>

                {configCreating && (
                  <li>
                    Once PrivateLink is done configuring, set <b>Resource ID or alias</b> to the
                    PrivateLink Service Name.
                  </li>
                )}
                {!configCreating && (
                  <li>
                    Set <b>Resource ID or alias</b> to <i>{privateLinkServiceName}</i>.
                  </li>
                )}

                <li>
                  Click <b>Next</b>.
                </li>
                <li>
                  Set <b>Virtual Network</b> and <b>Subnet</b> to the virtual network and subnet you
                  want to connect to your instances from.
                </li>
                <li>
                  Click <b>Next</b>.
                </li>

                <li>
                  Leave the DNS tab as the defaults and click <b>Next</b>.
                </li>
                <li>
                  After the deployment is complete, go back to the <b>Private endpoints</b> page.
                </li>
                <li>
                  Save the <b>Private IP</b> of your newly created endpoint for later.
                </li>
              </ol>
              <div className="n-body-medium tw-mt-4">
                *{' '}
                <TextLink
                  href="https://learn.microsoft.com/en-us/azure/private-link/create-private-endpoint-portal"
                  externalLink
                >
                  <i>Azure Private Link documentation</i>
                </TextLink>
              </div>
            </div>
          </>
        )}
        {step === 3 && (
          <div className="tw-flex tw-flex-col tw-gap-8">
            <ConnectionRequestAcceptor
              value={data.endpointIdField}
              onChange={handleEndpointIdFieldChange}
              onAccept={handleAddEndpointId}
              inputErrorText={validation?.endpointIdField?.message}
              acceptMessage={endpointConnectionMessage}
              loading={loading}
              connectionRequests={endpointConnections.map(c => ({
                id: c.privateEndpointId,
                state: c.state,
              }))}
              stateColorMapper={getConnectionStateLabelColor}
            />
            <div>
              <h6>
                <span className="tw-font-normal">Enable Private DNS in Azure console</span>
              </h6>
              <ol className="console-network-instructions tw-list-decimal tw-mt-3 tw-ml-8">
                <li>
                  Log in to the{' '}
                  <TextLink href="https://portal.azure.com/" externalLink>
                    Azure Portal
                  </TextLink>
                  .
                </li>
                <li>
                  Search for <i>Private DNS zones</i> in the global search bar, and click on the{' '}
                  <b>Private DNS zones</b> item in the dropdown.
                </li>
                <li>
                  Create a Private DNS Zone:
                  <ul className="tw-list-[lower-alpha] tw-ml-6">
                    <li>
                      Click <b>+ Create</b>.
                    </li>
                    <li>
                      Set <b>Subscription</b> to the same subscription you used for the Private
                      Endpoint.
                    </li>
                    <li>
                      Set <b>Resource group</b> to the same resource group you used for the Private
                      Endpoint.
                    </li>
                    <li>
                      Set <b>Name</b> to <i>{trafficConfig?.status?.dnsDomain}</i>.
                    </li>
                    <li>
                      Click <b>Next</b>.
                    </li>
                    <li>
                      Click <b>Review Create</b>.
                    </li>
                    <li>
                      Click <b>Create</b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Once the deployment is complete, click <b>Go to resource</b>.
                </li>

                <li>
                  Create a Private DNS record set:
                  <ul className="tw-list-[lower-alpha] tw-ml-6">
                    <li>
                      Click <b>+ Record set</b>.
                    </li>
                    <li>
                      Set <b>Name</b> to &quot;*&quot; (an asterisk).
                    </li>
                    <li>
                      Set <b>Type</b> to <i>A</i>.
                    </li>
                    <li>
                      Set <b>IP Address</b> to the Private IP of the Private endpoint you saved
                      earlier.
                    </li>
                    <li>
                      Click <b>OK</b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Select <b>Virtual network links</b> from the side navigation.
                </li>
                <li>
                  Link the Private Endpoint to your virtual network:
                  <ul className="tw-list-[lower-alpha] tw-ml-6">
                    <li>
                      Click <b>+ Add</b>.
                    </li>
                    <li>
                      Set <b>Name</b> to an appropriate name.
                    </li>

                    <li>
                      Set <b>Subscription</b> to the same subscription you used for the Private
                      Endpoint.
                    </li>
                    <li>
                      Set <b>Virtual network</b> to the virtual network you want to connect to your
                      Neo4j instances from.
                    </li>
                    <li>
                      Click <b>OK</b>.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="tw-mt-4">
              The configuration should now be complete. Once you have an Aura instance created you
              should be able to test the connection using{' '}
              <TextLink
                href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                externalLink
                onClick={handleTestGuidesClick}
              >
                this guide.
              </TextLink>
            </div>
            <div className="n-body-medium tw-mt-4">
              *{' '}
              <TextLink
                href="https://learn.microsoft.com/en-us/azure/dns/private-dns-getstarted-portal"
                externalLink
              >
                <i>Azure Private Link documentation</i>
              </TextLink>
            </div>
          </div>
        )}
        {step === 4 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status?.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedDatabases={affectedDatabases}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_azure_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="tw-justify-between">
        <Button onClick={handleFinishLater} fill="outlined">
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={handleSubmit}
            fill="filled"
            loading={loading}
            disabled={!data.region || !data.tier}
            data-testid="azure-dialog-enable-privatelink"
          >
            Enable PrivateLink
          </Button>
        )}
        {step === 1 && editMode && (
          <Button
            onClick={handleSubmit}
            loading={loading}
            fill="filled"
            data-testid="azure-dialog-next"
          >
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              disabled={!privateLinkServiceName}
              data-testid="azure-dialog-next"
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button onClick={handleNextStep} fill="filled" data-testid="azure-dialog-next">
              Next
            </Button>
          </div>
        )}
        {step === 4 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              loading={loading}
              disabled={!confirm}
              data-testid="azure-dialog-save"
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};
