import React from 'react';
import TotalBillingUsage from 'application/usage/report';
import { useSession } from 'store';

export default () => {
  const session = useSession();
  return (
    <div className="tw-p-8 tw-bg-palette-neutral-bg-weak tw-h-auto tw-w-full tw-rounded-tr-lg tw-rounded-b-lg">
      {<TotalBillingUsage tenant={session.tenant} />}
    </div>
  );
};
