import { useEffect, useState, useCallback } from 'react';
import RemoteDatabases from 'remote/resources/databases';
import RemoteSnapshots from 'remote/resources/snapshots';
import { useSession } from 'store';
import { useInterval } from 'utils/hooks';
import { triggerTrackingEvents } from 'actions/database-tracking-actions';
import { DatabasesStore, SnapshotsStore } from 'state';
import { IgnoreErrors } from 'remote/api-client';

interface RequestState<T> {
  loading: boolean;
  error: Error | null;
  data?: T;
}

export const useDatabasesRequest = ({ ignore }: { ignore?: IgnoreErrors } = {}) => {
  const [state, setState] = useState<RequestState<never>>({ loading: true, error: null });

  const session = useSession();

  useEffect(() => {
    RemoteDatabases.list(session.currentTenant, ignore)
      .then(dbs => {
        triggerTrackingEvents(DatabasesStore.state.databases || [], dbs);
        DatabasesStore.refresh(dbs);
        setState(prev => ({ ...prev, loading: false }));
      })
      .catch(e => setState(prev => ({ ...prev, error: e, loading: false })));
  }, []);

  return state;
};

export const useDatabasesPoll = ({
  delay = 10000 /* 10 seconds */,
  ignore,
}: {
  delay?: number;
  ignore?: IgnoreErrors;
} = {}) => {
  const session = useSession();

  const refreshDbs = useCallback(() => {
    RemoteDatabases.list(session.currentTenant, ignore)
      .then(dbs => {
        triggerTrackingEvents(DatabasesStore.state.databases || [], dbs);
        DatabasesStore.refresh(dbs);
        return dbs;
      })
      .catch(() => {
        // Silence failures to background refresh
      });
  }, [session.currentTenant]);

  useInterval(refreshDbs, delay);
};

export const useSnapshotsRequest = (dbId: string, { ignore }: { ignore?: IgnoreErrors } = {}) => {
  const [state, setState] = useState<RequestState<never>>({ loading: true, error: null });

  useEffect(() => {
    RemoteSnapshots.list(dbId, ignore)
      .then(snaps => {
        SnapshotsStore.refresh(dbId, snaps);
        setState(prev => ({ ...prev, loading: false }));
      })
      .catch(e => setState(prev => ({ ...prev, loading: false, error: e })));
  }, [dbId]);

  return state;
};

export const useDatabaseRequest = (dbId: string, { ignore }: { ignore?: IgnoreErrors } = {}) => {
  const [state, setState] = useState<RequestState<never>>({ loading: true, error: null });

  useEffect(() => {
    RemoteDatabases.get(dbId, ignore)
      .then(db => {
        const oldDb = DatabasesStore.findById(dbId);
        DatabasesStore.upsert(db.DbId, db);
        triggerTrackingEvents(oldDb ? [oldDb] : [], [db]);
        setState(prev => ({ ...prev, loading: false }));
      })
      .catch(e => setState(prev => ({ ...prev, loading: false, error: e })));
  }, [dbId]);

  return state;
};

export const useDatabasePoll = (
  dbId: string,
  {
    delay = 10000 /* 10 seconds */,
    ignore,
  }: {
    delay?: number;
    ignore?: IgnoreErrors;
  } = {}
) => {
  const refreshDb = useCallback(() => {
    RemoteDatabases.get(dbId, ignore)
      .then(db => {
        const oldDb = DatabasesStore.findById(dbId);
        DatabasesStore.upsert(db.DbId, db);
        triggerTrackingEvents(oldDb ? [oldDb] : [], [db]);
      })
      .catch(() => {
        // Silence failures to background refresh
      });
  }, [dbId]);

  useInterval(refreshDb, delay);
};

export const useSnapshotsPoll = (
  dbId: string,
  {
    delay = 10000 /* 10 seconds */,
    ignore,
  }: {
    delay?: number;
    ignore?: IgnoreErrors;
  } = {}
) => {
  const refreshSnaps = useCallback(() => {
    RemoteSnapshots.list(dbId, ignore)
      .then(snaps => {
        SnapshotsStore.refresh(dbId, snaps);
      })
      .catch(() => {
        // Silence failures to background refresh
      });
  }, [dbId]);

  useInterval(refreshSnaps, delay);
};
