import { Page } from 'foundation/page';
import { Link } from 'foundation';
import React from 'react';

const ApprovalRequiredPage = () => (
  <Page>
    <h1>Unauthorised Access</h1>
    <p>Thanks for your interest in Neo4j Aura!</p>
    <p>This system is restricted to authorised users only.</p>
    <p>
      Please make sure you{' '}
      <Link href="#" className="is-primary-text">
        login
      </Link>{' '}
      with the email address you used during sign up.
    </p>
    <p>
      Still not working and you believe you should have access?{' '}
      <a className="is-primary-text" href="mailto://cloud-support@neo4j.com">
        Send us an email
      </a>
      , and we&#39;ll help out.
    </p>
  </Page>
);

export default ApprovalRequiredPage;
