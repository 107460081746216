import { InternalSsoConfig, InternalSsoConfigResourceData } from 'types/sso-config';
import { transformSsoConfig } from './sso-configs';
import ApiClient from 'remote/api-client';
import { useQuery, UseQueryResult } from 'react-query';

export const transformInternalSsoConfig = (response: any): InternalSsoConfig => {
  const data = transformSsoConfig(response);
  return {
    ...data,
    auth0ApplicationId: response.Auth0ApplicationId,
    auth0ConnectionId: response.Auth0ConnectionId,
  };
};

const getBySsoConfigId = async (ssoConfigId: string): Promise<InternalSsoConfig> =>
  ApiClient.get(`/sso-configs/${ssoConfigId}`)
    .promise()
    .then(transformInternalSsoConfig);

const create = (
  organizationId: string,
  ssoConfig: InternalSsoConfigResourceData
): Promise<InternalSsoConfig> => {
  return ApiClient.post(`/internal/organizations/${organizationId}/sso-configs`)
    .issue({
      DisplayName: ssoConfig.displayName,
      IdpType: ssoConfig.idpType,
      Channel: ssoConfig.channel,
      ClientId: ssoConfig.clientID,
      ClientSecret: ssoConfig.clientSecret,
      EmailDomains: ssoConfig.emailDomains,
      OrganizationLoginMethod: ssoConfig.organizationLoginMethod,
      DiscoveryUri: ssoConfig.discoveryURI || null,
      Issuer: ssoConfig.issuer || null,
      AuthorizationEndpoint: ssoConfig.authorizationEndpoint || null,
      TokenEndpoint: ssoConfig.tokenEndpoint || null,
      JwksUri: ssoConfig.jwksURI || null,
      RoleMapping: ssoConfig.roleMapping || null,
    })
    .promise()
    .then(transformInternalSsoConfig);
};

const update = async (
  ssoConfigId: string,
  ssoConfig: InternalSsoConfigResourceData
): Promise<InternalSsoConfig> =>
  ApiClient.put(`/internal/organizations/${ssoConfig.organizationId}/sso-configs/${ssoConfigId}`)
    .issue({
      DisplayName: ssoConfig.displayName,
      IdpType: ssoConfig.idpType,
      Channel: ssoConfig.channel,
      ClientId: ssoConfig.clientID,
      ClientSecret: ssoConfig.clientSecret,
      EmailDomains: ssoConfig.emailDomains,
      OrganizationLoginMethod: ssoConfig.organizationLoginMethod,
      DiscoveryUri: ssoConfig.discoveryURI || null,
      Issuer: ssoConfig.issuer || null,
      AuthorizationEndpoint: ssoConfig.authorizationEndpoint || null,
      TokenEndpoint: ssoConfig.tokenEndpoint || null,
      JwksUri: ssoConfig.jwksURI || null,
      RoleMapping: ssoConfig.roleMapping || null,
    })
    .ignore(422)
    .promise()
    .then(transformInternalSsoConfig);

export const useInternalSsoConfigQuery = (
  ssoConfigId: string
): UseQueryResult<InternalSsoConfig> => {
  const query = async () => {
    const databases = await getBySsoConfigId(ssoConfigId);
    return databases;
  };

  return useQuery(`sso-config-${ssoConfigId}`, query, {
    enabled: !!ssoConfigId,
    refetchInterval: 10000,
  });
};

export default {
  create,
  update,
  getBySsoConfigId,
};
