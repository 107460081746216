import { SsoConfig } from 'types/sso-config';

export enum OrgPageSuffix {
  USER_MANAGEMENT = 'user-management',
  SSO_CONFIG = 'sso-config',
  SUMMARY = 'summary',
}

export const OrgPageSuffixTitles: Record<OrgPageSuffix, string> = {
  [OrgPageSuffix.USER_MANAGEMENT]: 'User Management',
  [OrgPageSuffix.SSO_CONFIG]: 'SSO Configurations',
  [OrgPageSuffix.SUMMARY]: 'Summary',
};

export enum SsoConfigFormAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface LinkedTenant {
  tenantId: string;
  tenantName: string;
}

export interface EnrichedSsoConfig extends SsoConfig {
  clientSecret?: string;
  tenantLoginMethod?: boolean;
  originalLinkedTenants?: LinkedTenant[];
  linkedTenants?: LinkedTenant[];
}

export type SsoConfigFormData = Omit<
  EnrichedSsoConfig,
  'channel' | 'emailDomains' | 'ssoConfigId'
> & {
  /**
   * Indicates that the client secret field needs to be populated.
   * Should be set to true in these scenarios:
   * - Creating backchannel config
   * - Updating config and channel is switched to backchannel
   *
   * We do not need the secret when updating a backchannel config as
   * the secret is already set
   */
  needsSecret?: boolean;
};
