import { Database } from 'entities/database';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { CustomEndpoint } from 'types/custom-endpoints';
import { validateYup, Validation } from 'utils/validation';
import customEndpointResources from 'remote/resources/custom-endpoints';
import logger from 'logger';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { customEndpointURI } from 'entities/custom-endpoints';
import { Alert, FormSelect, Button, Dialog, TextInput, Tip } from 'components/foundation';
import * as yup from 'yup';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { formatOptionLabel } from '../shared';

type UpdateCustomEndpointData = {
  dbId: string;
};
const schema = yup.object({
  dbId: yup
    .string()
    .required()
    .label('Instance'),
});

const validate = (data: UpdateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  databases: Database[];
  database?: Database;
  endpoint: CustomEndpoint;
};

export const ConfigureCustomEndpointDialog = ({
  onClose,
  database,
  databases,
  endpoint,
}: Props) => {
  const [data, setData] = useState<UpdateCustomEndpointData>({ dbId: database?.DbId });
  const [validationError, setValidationError] = useState<Validation<
    UpdateCustomEndpointData
  > | null>(null);
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();

  const linkCustomEndpointAction = useMemo(() => {
    const selectedDatabase = databases.find(db => db.DbId === data.dbId);
    return selectedDatabase?.AvailableActions.link_custom_endpoint;
  }, [databases, data.dbId]);
  const linkCustomEndpointActionMessage =
    !linkCustomEndpointAction || linkCustomEndpointAction.enabled || data.dbId === database?.DbId
      ? undefined
      : linkCustomEndpointAction.message;

  const handleSelectDatabase = (dbId?: string) => {
    setValidationError(null);
    setData({ dbId: dbId ?? '' });
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    const validation = validate(data);
    if (validation) {
      setValidationError(validation);
      return;
    }
    setValidationError(null);

    customEndpointResources
      .update(data.dbId, endpoint.id)
      .then(() => {
        onClose();
      })
      .catch((e: ApiClientRequestError) => {
        logger.error('Failed to update custom endpoint', e);
        defaultErrorHandler(e);
      })
      .finally(() => setLoading(false));
  };

  const options = databases.map(item => ({
    key: item.DbId,
    label: item.Name,
    value: item.DbId,
    description: item.DbId,
    isDisabled: !item.Capabilities.assign_custom_endpoint.enabled,
    disabledReason: !item.Capabilities.assign_custom_endpoint.enabled
      ? item.Capabilities.assign_custom_endpoint.message
      : undefined,
  }));

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Configure custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-4">
            <TextInput label="Custom endpoint" value={customEndpointURI(endpoint)} readOnly fluid />
            <FormSelect
              label="Assign to instance"
              errorText={validationError?.dbId?.message ?? linkCustomEndpointActionMessage}
              value={options.find(item => item.key === data.dbId)}
              options={options}
              onChange={obj => handleSelectDatabase(obj?.value)}
              formatOptionLabel={formatOptionLabel}
              menuPosition="fixed"
              data-testid="select-instance"
            />
          </div>
          <Alert
            icon
            type="info"
            description="It will take a short moment for this custom endpoint to be ready for use again."
          />
          <Alert
            icon
            type="warning"
            description={
              <span>
                You will not be able to configure this custom endpoint for the next 3 hours.
                {database
                  ? ' Although, you will be able to transfer this custom endpoint back to the currently assigned instance.'
                  : ''}
              </span>
            }
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={onClose} data-testid="cancel-button">
            Cancel
          </Button>
          <Tip isDisabled={!database || database.DbId !== data.dbId}>
            <Tip.Trigger>
              <Button
                color="primary"
                type="submit"
                loading={loading}
                disabled={
                  (linkCustomEndpointAction && !linkCustomEndpointAction.enabled) ||
                  (database && database.DbId === data.dbId)
                }
                data-testid="confirm-button"
              >
                Confirm
              </Button>
            </Tip.Trigger>
            <Tip.Content isPortaled={false} style={{ width: 200, position: 'fixed' }}>
              <b>{database?.Name}</b> is already assigned to this endpoint.
            </Tip.Content>
          </Tip>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
