import { useEffect, useState } from 'react';
import DatabaseMetricsResource from 'remote/resources/db-metrics';

export const POLLING_INTERVAL = 15 * 1000;

const useMetrics = (dbId: string, timeWindow: string) => {
  const [metrics, setMetrics] = useState({});
  // Set loading to true initially so that the no data message does not briefly display
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    DatabaseMetricsResource.fetch(dbId, timeWindow).then(newMetrics => {
      // The below state updates will not be batched, because they are part of an async callback
      // The state cannot be moved to a single object, because it causes the loading value to get reset to true on rerender
      // Async state updates will be automatically batched once we have updated to React 18+
      setMetrics(newMetrics);
      setLoading(false);
    });
    DatabaseMetricsResource.resetPolling();
    DatabaseMetricsResource.poll(POLLING_INTERVAL, dbId, timeWindow, setMetrics);
  }, [dbId, timeWindow]);

  useEffect(() => {
    return () => {
      DatabaseMetricsResource.resetPolling();
    };
  }, []);

  return { metrics, loading };
};

export default useMetrics;
