import formatDollars from 'utils/format-dollars';
import { DataGrid, DataGridComponents, createColumnHelper, useDefaultTable } from 'foundation';
import React, { useMemo } from 'react';
import { TransformedBreakdownItem } from 'types/usage';
import { tierDisplayName } from 'entities/tenant';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useSession } from 'store';
import { capitalize } from './utils';

const columnHelper = createColumnHelper<TransformedBreakdownItem>();

export const ReportsTable = ({
  breakdown,
  totalBreakdownCount,
  isLoading,
  searchFilter,
  setSearchFilter,
  pagination,
  setPagination,
  setSorting,
  sorting,
}) => {
  const session = useSession();
  const { capabilities } = session.tenant;
  const hasConsumptionReportingEnabled = capabilities?.consumption_reporting;

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => 'Instance',
        cell: cx => {
          return <div className="tw-truncate">{cx.getValue()}</div>;
        },
      }),
      columnHelper.accessor('dbid', {
        header: () => 'ID',
      }),
      columnHelper.accessor('tier', {
        header: () => 'Type',
        cell: cx => tierDisplayName(session.tenant, cx.getValue()) ?? '',
      }),
      columnHelper.accessor('billingCategory', {
        header: () => 'Billing category',
        cell: cx => capitalize(cx.getValue()),
      }),
      columnHelper.accessor('endTime', {
        header: () => 'Billing status',
        cell: cx => {
          const value = cx.getValue();
          let time = 'Ongoing';
          if (value) {
            const endTime = parseISO(value);
            time = `Ended (${format(utcToZonedTime(endTime, 'UTC'), 'yyyy-MM-dd HH:mm')} UTC)`;
          }

          return <div className="tw-truncate">{time}</div>;
        },
      }),
      columnHelper.accessor('consumptionUnits', {
        header: () => 'Usage (GB-hour(s))',
      }),
      columnHelper.accessor('unitCostInDollars', {
        header: () => 'Unit price (Per GB-hour)',
        cell: cx => formatDollars(cx.getValue(), 10),
      }),
      columnHelper.accessor('cost', {
        header: () => (hasConsumptionReportingEnabled ? 'Credits consumed' : 'Amount'),
        cell: cx => (hasConsumptionReportingEnabled ? cx.getValue() : formatDollars(cx.getValue())),
        enableResizing: false,
      }),
    ],
    [session.planType]
  );
  const table = useDefaultTable({
    data: breakdown,
    columns,
    initialState: {
      columnVisibility: {
        unitCostInDollars: !hasConsumptionReportingEnabled,
      },
    },
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableSorting: !isLoading,
    state: {
      globalFilter: searchFilter,
      pagination,
      sorting,
    },
    onGlobalFilterChange: setSearchFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount:
      totalBreakdownCount % pagination.pageSize === 0
        ? totalBreakdownCount / pagination.pageSize
        : Math.ceil(totalBreakdownCount / pagination.pageSize),
  });
  return (
    <DataGrid
      isResizable={true}
      isLoading={isLoading}
      tableInstance={table}
      styling={{
        headerStyle: 'clean',
      }}
      components={{
        Body: props => (
          <DataGridComponents.Body
            {...props}
            innerProps={{
              style: {
                borderTop: '1px solid rgb(var(--theme-palette-neutral-border-weak))',
              },
            }}
          />
        ),
        BodyRow: props => (
          <DataGridComponents.BodyRow
            {...props}
            innerProps={{
              style: {
                minHeight: '56px',
              },
            }}
          />
        ),
      }}
    />
  );
};
