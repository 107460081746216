import React from 'react';
import { FormSelect } from 'components/foundation';
import { useOrganizationTenantsSummariesQuery } from 'remote/resources/tenants';
import { LinkedTenant } from '../types';

export interface SsoConfigFormFieldsProps {
  linkedTenants: string[];
  organizationId: string;
  setLinkedTenants: (tenants: LinkedTenant[]) => void;
  errorText: string;
}

export const TenantMultiSelect = ({
  linkedTenants,
  organizationId,
  setLinkedTenants,
  errorText,
  ...props
}: SsoConfigFormFieldsProps) => {
  const organizationListTenantsQuery = useOrganizationTenantsSummariesQuery(organizationId);
  const tenantsOptions = !organizationListTenantsQuery.isLoading
    ? organizationListTenantsQuery.data.map(tenant => ({
        label: tenant.name,
        key: tenant.id,
        value: tenant.id,
      }))
    : [];
  const handleTenantsChange = selectedOptions => {
    const selectedValues = selectedOptions.map(e => ({ tenantId: e.value, tenantName: e.label }));
    setLinkedTenants(selectedValues);
  };
  return (
    <FormSelect
      data-testid="tenant-multi-select"
      isMulti={true}
      onChange={handleTenantsChange}
      searchable={false}
      loading={organizationListTenantsQuery.isLoading}
      value={linkedTenants}
      label="Projects"
      options={tenantsOptions}
      errorText={errorText}
      {...props}
    />
  );
};
