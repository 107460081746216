import getClient, { ApiClientRequestBuilderInterface } from './api-client';
import { xhrUpload } from './upload-client';

export { IgnoreErrors } from './api-client';

// This exists to make TS happy when mocking api-client
// as the mock exports this so users can mock the .then and .catch
// functions on the api calls
export const mockClient: ApiClientRequestBuilderInterface = undefined;

export default {
  get: (url: string) => getClient().get(url),
  post: (url: string) => getClient().post(url),
  put: (url: string) => getClient().put(url),
  patch: (url: string) => getClient().patch(url),
  delete: (url: string) => getClient().delete(url),
  options: (url: string) => getClient().options(url),
  xhrUpload,
};
