import { getDefaultSelfServeDatabaseName } from 'components/utils';
import { CloudProvider, Tier } from 'entities/database';
import { getCloudProvider, availableRegionsForTier, ProviderConfigs } from 'entities/tenant';
import { getDefaultVersion } from 'utils/neo4j-versions';
import { validateYup } from 'utils/validation';
import { CreateDatabaseFormData } from './form-data';
import { DatabaseNameInput } from './form-essentials';
import * as yup from 'yup';

const getFreeRegion = (providerConfigs: ProviderConfigs) => {
  const regionsToSkip = [];
  const availableRegions = availableRegionsForTier(providerConfigs, Tier.FREE);
  const filteredRegions = availableRegions.filter(r => !regionsToSkip.includes(r.name));

  if (filteredRegions.length > 0) {
    return filteredRegions[0].name;
  }

  // Return whatever we have
  return availableRegions[0]?.name;
};

export const freeDefaults = (
  providerConfigs: ProviderConfigs,
  name?: string
): CreateDatabaseFormData => {
  const cloudProvider = getCloudProvider(providerConfigs);
  return {
    name: name || getDefaultSelfServeDatabaseName(),
    region: getFreeRegion(providerConfigs),
    version: getDefaultVersion(providerConfigs, cloudProvider, Tier.FREE),
    tier: Tier.FREE,
    size: undefined,
    confirmed: undefined,
    cloudProvider,
    vectorOptimized: false,
  };
};

export const freeSchema = yup.object({
  tier: yup.string().required(),
  name: DatabaseNameInput.yupValidator.label('Name'),
  region: yup
    .string()
    .required()
    .label('Region'),
  version: yup.string().label('Version'),
  size: yup.object(),
  confirmed: yup.bool(),
  vectorOptimized: yup.bool(),
});

export const validateFree = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(freeSchema, data, onlyRequired || false);
};

export const mteDefaults = (): CreateDatabaseFormData => {
  const tier = Tier.MTE;
  return {
    tier,
    name: getDefaultSelfServeDatabaseName(),
    version: undefined,
    region: undefined,
    size: undefined,
    confirmed: undefined,
    cloudProvider: CloudProvider.GCP,
    vectorOptimized: false,
  };
};

const mteSchema = yup.object({
  cloudProvider: yup
    .string()
    .required()
    .label('Cloud Provider'),
  tier: yup.string().required(),
  name: DatabaseNameInput.yupValidator.label('Name'),
  region: yup
    .string()
    .required()
    .label('Region'),
  version: yup
    .string()
    .required()
    .label('Version'),
  size: yup.object({ memory: yup.string().required() }).required(),
  vectorOptimized: yup.bool(),
  confirmed: yup
    .bool()
    .required('You must check that your understand the costs of creating a database')
    .isTrue('You must check that your understand the costs of creating a database'),
});

export const validateMte = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(mteSchema, data, onlyRequired || false);
};
