import { Tier } from 'entities/database';
import { useCallback } from 'react';
import { selectLocation } from 'state/navigation';
import { selectNotificationState } from 'state/notifications';
import {
  selectBillingRequiredFactory,
  selectDatabaseState,
  selectNavigationState,
  selectSession,
  selectSnapshotsState,
  useSelector,
} from '../selectors';

export * from './permissions';

export const useNotificationState = () => useSelector(selectNotificationState);
export const useNavigationState = () => useSelector(selectNavigationState);
export const useSnapshotsState = () => useSelector(selectSnapshotsState);
export const useDatabaseState = () => useSelector(selectDatabaseState);
export const useSession = () => useSelector(selectSession);
export const useNeedsBilling = (tier?: Tier) => {
  const selector = useCallback(selectBillingRequiredFactory(tier), [tier]);
  return useSelector(selector);
};

export const useLocation = () => useSelector(selectLocation);
