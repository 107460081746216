import ApiClient from 'remote/api-client';
import { Role, RoleName } from 'types/user';

export const listUserRoles = (userId): Promise<Array<Role>> =>
  ApiClient.get(`/users/${userId}/roles`)
    .ignoreAllErrors()
    .promise();
export const deleteUserRole = (userId, roleName: RoleName) =>
  ApiClient.delete(`/users/${userId}/roles/${roleName}`)
    .ignoreAllErrors()
    .promise();
export const createUserRole = (userId, roleName: RoleName) =>
  ApiClient.post(`/users/${userId}/roles`)
    .issue({ Name: roleName })
    .ignoreAllErrors()
    .promise();

export const deleteRole = (roleId: string) =>
  ApiClient.delete(`/roles/${roleId}`)
    .ignoreAllErrors()
    .promise();

export default {
  listUserRoles,
  deleteUserRole,
  createUserRole,
  deleteRole,
};
