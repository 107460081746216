import { useEffect } from 'react';
import Actions from 'actions';

const Login = () => {
  useEffect(() => Actions.auth.login(), []);

  return null;
};

export default Login;
