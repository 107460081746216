import Actions from 'actions';
import { getErrorMessage } from './error-messages';
import logger from 'logger';
import { ErrorReasons } from './error-reason-codes';
import { ApiClientRequestError, NetworkError } from 'remote/api-client/api-client-error';
import { useNotify } from 'state/notifications';
import { SessionStore } from 'state';
import { useCallback } from 'react';
import { UpdatableNavigationLocation } from 'actions/navigation-actions';

type SupportedErrors = ApiClientRequestError | NetworkError;

/**
 * Returns an error that will handle all 400+ errors.
 *
 * The returned handler function returns a promise so when
 * handling unknown errors you should return the response of the
 * error handler in `.catch` handlers, or for async/await you
 * should await the handler.
 *
 * Example:
 *  const handler = useDefaultErrorHandler()
 *
 *  const handleSubmit = () => {
 *    request().then(onSuccess).catch(e => {
 *      if (e.property === 'something-custom') {
 *        // Put component specific error handling here
 *      } else {
 *        return handler(e)
 *      }
 *    })
 *  }
 *
 * Example (async/await):
 *  const handler = useDefaultErrorHandler()
 *
 *  const handleSubmit = async () => {
 *    try {
 *      await request()
 *      onSuccess()
 *    } catch (e) {
 *      if (e.property === 'something-custom') {
 *        // Put component specific error handling here
 *      } else {
 *        await handler(e)
 *      }
 *    })
 *   }
 *  }
 */
export const useDefaultErrorHandler = (propagateErr: boolean = false) => {
  const notify = useNotify();
  const handler = useCallback(
    (e: SupportedErrors): Promise<unknown> => {
      const navigate = Actions.navigate.push;
      return handleError(e, notify, navigate, propagateErr);
    },
    [notify, propagateErr]
  );

  return handler;
};

export const handleError = (
  e: SupportedErrors,
  notify: Notify,
  navigate: Navigate,
  propagateErr: boolean
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const redirected = handler(e, notify, navigate);
    // If we redirect we never want to reject and pass the promise back
    // to the next handler, as we want to instead have the chain wait
    // for forever, since the page will refresh
    if (propagateErr && !redirected) {
      reject(e); // Continue to the next error handler in chain
    }
  });
};

const handler = (e: SupportedErrors, notify: Notify, navigate: Navigate): boolean => {
  if (e instanceof ApiClientRequestError) {
    return handleApiError(e, notify, navigate);
  } else if (e instanceof NetworkError) {
    return handleNetError(e, notify);
  }

  logger.error('Unable to handle error', e);
  return false;
};

type Notify = ReturnType<typeof useNotify>;
type Navigate = (location: UpdatableNavigationLocation) => void;

export const handleNetError = (error: Error, notify: Notify): boolean => {
  logger.error('Network issue.', error);

  notify.error('Unable to contact our servers. Please check your internet connection.', {
    expiresAfter: null,
  });

  return false;
};

export const handleApiError = (
  e: ApiClientRequestError,
  notify: Notify,
  navigate: Navigate
): boolean => {
  const { status } = e.response;
  const { reason } = e;

  if (status < 400) return false;

  if (status === 401) return handleAuthenticationFailure(e, status, reason, notify, navigate);
  if (status === 403) return handleAuthorizationFailure(status, reason, notify, navigate);
  if (status === 404) return false;

  const friendlyMessage = getErrorMessage(status, reason);
  notify.error(friendlyMessage);
  return false;
};

const handleAuthenticationFailure = (
  e: ApiClientRequestError,
  status: number,
  reason: string,
  notify: Notify,
  navigate: Navigate
): boolean => {
  switch (reason) {
    case ErrorReasons.EMAIL_UNVERIFIED:
      Actions.auth.emailVerificationRequired();
      navigate('email-verification-required');
      return true;

    case ErrorReasons.EMAIL_IN_USE:
      navigate({ hash: 'logout', search: { returnTo: 'login-error/email-in-use' } });
      return true;

    case ErrorReasons.ACCOUNT_LINK_REQUIRED:
      SessionStore.setAccountToLink({
        email: e.extra.email,
        connection: e.extra.connection,
      });
      return true;

    case ErrorReasons.INVALID_TOKEN:
    case ErrorReasons.USER_LOGGED_OUT:
      void Actions.auth.loginRedirect(location);
      return true;
    default:
      logger.warn(`Unhandled error reason ${reason}. Logging user out.`);
      navigate({ hash: 'logout' });
      return true;
  }
};

const handleAuthorizationFailure = (
  status: number,
  reason: string,
  notify: Notify,
  navigate: Navigate
): boolean => {
  switch (reason) {
    case ErrorReasons.ACCOUNT_TERMINATED:
      navigate({ hash: 'logout', search: { returnTo: 'account-terminated' } });
      return true;

    case ErrorReasons.UNAUTHORIZED:
    default:
      notify.error("You don't have permission to perform this action");
      return false;
  }
};
