import { useSession } from 'store';
import { Permission, Action } from 'types/user';

const checkPermission = (permission: Permission, action: Action, resource: string) => {
  if (permission.Action !== action) {
    return false;
  }

  const permissionNodes = permission.Resource.split('/');
  const requestNodes = resource.split('/');

  if (requestNodes.length > permissionNodes.length) {
    return false;
  }

  for (let i = 0; i < permissionNodes.length; i++) {
    if (permissionNodes[i] !== '*' && permissionNodes[i] !== requestNodes[i]) {
      return false;
    }
  }

  return true;
};

export const covers = (permissions: Permission[], action: Action, resource: string) => {
  return permissions.some((permission: Permission) =>
    checkPermission(permission, action, resource)
  );
};

const allowFor = (permissions: Permission[]) => (action: Action, resource: string) => {
  return covers(permissions, action, resource);
};

const denyFor = (permissions: Permission[]) => (action: Action, resource: string) => {
  return !covers(permissions, action, resource);
};

export const usePermissions = () => {
  const { permissions } = useSession();

  return {
    allow: allowFor(permissions),
    deny: denyFor(permissions),
  };
};
