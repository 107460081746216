import React, { useEffect, useState } from 'react';
import Actions from 'actions';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import './mfa.css';
import { Alert, Button, Form, Link } from 'foundation';

interface Props {
  tenantId: string;
  mfaRequired: boolean;
}

const getErrorContent = (err: ApiClientRequestError, tenantId: string): JSX.Element => {
  switch (err.reason) {
    case 'tenant-not-found':
    case 'namespace-not-found':
      return (
        <Alert type="danger" data-testid="mfa-error-message" className="tw-mt-2">
          <div>
            The project <code>{tenantId}</code> does not exist.
          </div>
          <div className="tw-mt-4">
            You may create it by going to the{' '}
            <Link href="#admin/projects/create" target="_blank" rel="noopener noreferrer">
              Create Project Form
            </Link>
            .
          </div>
        </Alert>
      );
    case 'unauthorized':
      return (
        <Alert type="danger" data-testid="mfa-error-message" className="tw-mt-2">
          You do not have permission to enable MFA on project <code>{tenantId}</code>.
        </Alert>
      );
    case 'rate-limit-exceeded':
      return (
        <Alert type="danger" data-testid="mfa-error-message" className="tw-mt-2">
          Auth0 Management API rate limit exceeded, please try at a later time.
          <code>{tenantId}</code>.
        </Alert>
      );
    default:
      return (
        <Alert type="danger" data-testid="mfa-error-message" className="tw-mt-2">
          Something unexpected went wrong. Please try again or contact the Aura Console team for
          assistance.
        </Alert>
      );
  }
};

const MFAForm = ({ tenantId, mfaRequired }: Props) => {
  const [responseError, setResponseError] = useState(null);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [mfaRequiredState, setMfaRequiredState] = useState(mfaRequired);

  const reset = () => {
    if (responseError) setResponseError(null);
  };

  useEffect(() => {
    reset();
  }, [tenantId]);

  const handleSubmit = async () => {
    try {
      await Actions.projects.setMFA(tenantId, !mfaRequiredState);
      setSubmittedForm(true);
      setMfaRequiredState(!mfaRequiredState);
      reset();
    } catch (err) {
      setResponseError(getErrorContent(err, tenantId));
    }
  };

  return (
    <Form className="tw-pt-8" onSubmit={handleSubmit}>
      <Button data-testid="mfa-button" type="submit" color="primary">
        {mfaRequiredState ? 'Disable MFA' : 'Enable MFA'}
      </Button>
      {submittedForm && (
        <Alert type="success" data-testid="mfa-success-message" className="tw-mt-2">
          MFA was {mfaRequiredState ? 'enabled' : 'disabled'} for project {tenantId}.
        </Alert>
      )}
      {responseError}
    </Form>
  );
};

export default MFAForm;
