import React, { ReactChild, ReactNode } from 'react';
import { Dropdown, TextInput } from '@neo4j-ndl/react';

// Form component

type FormProps = {
  onSubmit: (ev: any) => void;
  className?: string;
  children: ReactChild[] | ReactChild;
  id?: string;
  'data-testid'?: string;
};

export const Form = ({ onSubmit, ...rest }: FormProps) => {
  const submit = event => {
    event.preventDefault();
    onSubmit(event);
  };

  return <form onSubmit={submit} {...rest} />;
};

interface FormInputWithoutAriaLabelProps extends React.ComponentProps<typeof TextInput> {
  label?: ReactNode;
  ['aria-label']: string;
}

interface FormInputWithAriaLableProps extends React.ComponentProps<typeof TextInput> {
  label: ReactNode;
  ['aria-label']?: string;
}
// FormInput component

type FormInputProps = FormInputWithAriaLableProps | FormInputWithoutAriaLabelProps;

export const FormInput = (props: FormInputProps) => <TextInput {...props} />;

// FormSelect component

export interface SelectOption<T extends any = string> {
  key: string;
  label: string;
  value: T;
}

type DropdownProps = React.ComponentProps<typeof Dropdown>;
type DropdownSelectProps = DropdownProps['selectProps'];

type ReusedDropdownProps = Pick<
  DropdownSelectProps,
  'onChange' | 'defaultValue' | 'isClearable' | 'options'
> &
  Pick<DropdownProps, 'label' | 'errorText' | 'fluid' | 'aria-label'>;

type FormSelectPropsBase<T> = ReusedDropdownProps & {
  'data-testid'?: string;
  options: SelectOption<T>[];
  searchable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  formatOptionLabel?: any;
  closeMenuOnSelect?: boolean;
  styles?: any;
  helpText?: string;
  menuPosition?: 'fixed' | 'absolute';
};

interface FormSelectMultiProps<T> {
  isMulti: true;
  value: T[] | SelectOption<T>[];
  onChange: (value: SelectOption<T>[]) => void;
}

interface FormSelectSingleProps<T> {
  isMulti?: false | null | undefined;
  value: T | SelectOption<T>;
  onChange: (value: SelectOption<T>) => void;
}

type ValueProps<T> = FormSelectMultiProps<T> | FormSelectSingleProps<T>;

export type FormSelectProps<T> = FormSelectPropsBase<T> & ValueProps<T> & { className?: string };

const mapValueToOption = <T extends any = any>(
  value: any,
  options: SelectOption<T>[]
): SelectOption<T> => {
  if (
    typeof value === 'object' &&
    'value' in value &&
    options.map(o => o.value).includes(value.value)
  ) {
    return options.find(o => o.value === value.value);
  }

  return options.find(o => o.value === value);
};

const mapValuesToOptions = <T extends any = any>(
  value: any[],
  options: SelectOption<T>[]
): SelectOption<T>[] => {
  return value.map(v => mapValueToOption(v, options));
};

export const FormSelect = <T extends any = string>({
  'aria-label': ariaLabel,
  className,
  ...props
}: FormSelectProps<T>) => {
  let value = null;
  if (props.value) {
    if (props.isMulti) {
      value = mapValuesToOptions(props.value, props.options);
    } else {
      value = mapValueToOption(props.value, props.options);
    }
  }

  return (
    <Dropdown
      aria-label={ariaLabel}
      size="medium"
      type="select"
      label={props.label}
      data-testid={props['data-testid']}
      errorText={props.errorText}
      helpText={props.helpText}
      selectProps={{
        placeholder: props.placeholder,
        isDisabled: props.disabled,
        isLoading: props.loading,
        onChange: props.onChange,
        defaultValue: props.defaultValue || [],
        isClearable: props.isClearable || false,
        isMulti: props.isMulti || false,
        isSearchable: props.searchable,
        options: props.options,
        value,
        formatOptionLabel: props.formatOptionLabel,
        closeMenuOnSelect: props.closeMenuOnSelect,
        styles: props.styles,
        className,
        components: {},
        menuPosition: props.menuPosition,
      }}
    />
  );
};
