import DatabasesResource from 'remote/resources/databases';

interface TransferProps {
  targetDbid: string;
  targetEmail?: string;
  targetUserId?: string;
}

const transferDatabase = (data: TransferProps) => {
  return DatabasesResource.transfer(data);
};

export default transferDatabase;
