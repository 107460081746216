import { TrafficConfig } from 'types/traffic-configs';

export const isSameTrafficConfig = (
  trafficConfigA: TrafficConfig,
  trafficConfigB: TrafficConfig
) => {
  return (
    trafficConfigA.cloudProvider === trafficConfigB.cloudProvider &&
    trafficConfigA.tier === trafficConfigB.tier &&
    trafficConfigA.region === trafficConfigB.region
  );
};
