import { ErrorReasons } from './error-reason-codes';

// N.B. some error messages can also be found in default-error-handler.js
// We should probably move them all here for consistency.
const messages = {
  alreadyRegistered:
    'It looks like you are already registered. Please log in using the same method you used when you first registered.',
  cardNotFound: 'Unable to retrieve saved card details',
  cardNotUpdated: 'A problem occurred while saving your card details',
  databaseIsBeingCloned: 'Actions cannot be performed on a database while it is being cloned.',
  experiencingProblems:
    'Our system is experiencing problems right now. Try again later or contact support if the problem persists.',
  freeQuotaExceeded: "You've reached the maximum number of free instances allowed.",
  inputError: 'There seems to be a problem with your input. Please correct and retry.',
  operationsLimitReached:
    'This region is temporarily at capacity, please try again in a few minutes, or use a different region.',
  quotaExceeded: "You've reached the maximum number of instances allowed.",
  regionAtCapacity:
    'Region currently at capacity. Please try creating the database in a different region.',
  sessionExpired: 'Your login session has expired. Please sign-in again.',
  unavailable: 'Our service is currently unavailable. We are working to resolve the problem.',
};

const reasonCodeMapping = {
  [ErrorReasons.DATABASE_BEING_CLONED]: messages.databaseIsBeingCloned,
  [ErrorReasons.OPERATIONS_LIMIT_REACHED]: messages.operationsLimitReached,
  [ErrorReasons.REGION_AT_CAPACITY]: messages.regionAtCapacity,
  [ErrorReasons.QUOTA_EXCEEDED]: messages.quotaExceeded,
};

const defaultMessage = (httpStatus: number): string => {
  switch (httpStatus) {
    case 400:
    case 422:
      return messages.inputError;
    case 404:
      return;
    case 502:
    case 503:
    case 504:
      return messages.unavailable;
    case 507:
      return messages.regionAtCapacity;
    case 500:
    default:
      return messages.experiencingProblems;
  }
};

export const getErrorMessage = (httpStatus: number, reasonCode: string): string => {
  if (reasonCode === ErrorReasons.QUOTA_EXCEEDED && httpStatus === 402) {
    return messages.freeQuotaExceeded;
  }
  return reasonCodeMapping[reasonCode] || defaultMessage(httpStatus);
};

export default messages;
