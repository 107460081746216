import React, { useEffect, useState } from 'react';
import { RoleName, Role, User, isGlobalRole } from 'types/user';
import LoadError from 'application/load-error';
import { Dialog, FormSelect } from 'foundation';
import {
  createUserRole,
  deleteRole as deleteRoleById,
  listUserRoles,
} from 'remote/resources/roles';
import { useNotify } from 'state/notifications';
import logger from 'logger';
import { useDefaultErrorHandler } from 'remote/error-handler';

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

interface RoleSelectOption {
  key: RoleName;
  value: RoleName;
  label: RoleName;
}

const ROLE_OPTIONS: RoleSelectOption[] = Object.values(RoleName).map(role => ({
  key: role,
  value: role,
  label: role,
}));

const GLOBAL_ROLE_OPTIONS: RoleSelectOption[] = ROLE_OPTIONS.filter(role => isGlobalRole(role.key));

export const UserRolesModal = ({ open, onClose, user }: Props) => {
  const defaultErrorHandler = useDefaultErrorHandler();
  const notify = useNotify();
  const [data, setData] = useState<{ loading: boolean; error: any; roles: Role[] }>({
    roles: [],
    loading: true,
    error: null,
  });
  const { loading, error, roles } = data;

  const refreshRoles = () => {
    setData(prev => ({ ...prev, loading: true, error: null }));
    listUserRoles(user.UserId)
      .then(newRoles => {
        const currentRoles = newRoles.filter(role => isGlobalRole(role.Name));
        setData({ error: null, roles: currentRoles, loading: false });
      })
      .catch(e => {
        setData({ error: e, roles: [], loading: false });
      });
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    refreshRoles();
  }, [open]);

  const deleteRole = (roleName: RoleName) => {
    setData(prev => ({ ...prev, loading: true, error: null }));
    const roleToDelete = roles.find(role => role.Name === roleName);

    deleteRoleById(roleToDelete.RoleId)
      .then(() => {
        refreshRoles();
      })
      .catch(e => {
        if (e.response?.status === 409) {
          notify.error(
            `Failed to delete role ${roleName} on user ${user.Email}. User doesn't have role`
          );
          setData(prev => ({ ...prev, loading: false, error: e }));
          refreshRoles();
        } else {
          logger.error(e);
          defaultErrorHandler(e);
          setData(prev => ({ ...prev, loading: false, error: e }));
        }
      });
  };

  const createRole = (roleName: RoleName) => {
    setData(prev => ({ ...prev, loading: true, error: null }));

    createUserRole(user.UserId, roleName)
      .then(() => {
        refreshRoles();
      })
      .catch(e => {
        if (e.response?.status === 409) {
          notify.error(
            `Failed to create role ${roleName} on user ${user.Email}. User already has the role`
          );
          setData(prev => ({ ...prev, loading: false, error: e }));
          refreshRoles();
        } else {
          logger.error(e);
          defaultErrorHandler(e);
          setData(prev => ({ ...prev, loading: false, error: e }));
        }
      });
  };

  const handleRoleChange = (selectedOptions: RoleSelectOption[]) => {
    const selectedValues = selectedOptions.map(e => e.value);
    const currentRoleNames = roles.map(r => r.Name);

    const deletedRoles = currentRoleNames.filter(role => !selectedValues.includes(role));
    const createdRoles = selectedValues.filter(val => !currentRoleNames.includes(val));
    deletedRoles.forEach(deleteRole);
    createdRoles.forEach(createRole);
  };
  return (
    <Dialog open={open} onClose={onClose} size="large" modalProps={{ style: { minHeight: 500 } }}>
      <Dialog.Header>{user.Email} Roles</Dialog.Header>
      <Dialog.Content>
        <form data-testid="role-selector-form">
          <FormSelect
            label="Roles"
            data-testid="role-selector"
            onChange={handleRoleChange}
            disabled={loading}
            isMulti={true}
            isClearable={false}
            options={GLOBAL_ROLE_OPTIONS}
            value={roles.map(role => role.Name)}
          />
        </form>
        {error && <LoadError />}
      </Dialog.Content>
    </Dialog>
  );
};
