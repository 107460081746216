import React, { useCallback, useEffect, useState } from 'react';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { FormInput, Button, Dialog, Alert, Form, FormSelect, LoadingSpinner } from 'foundation';
import { RoleName, getTenantRoleOptions } from 'types/user';
import InviteActions from 'actions/invite-actions';
import NamespacesResource from 'remote/resources/namespaces';
import { Tenant } from 'entities/tenant';
import { debounce } from 'utils/javascript';

const getErrorMessage = (err: Error): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  return `Error: ${err.reason || 'unknown'}: ${err.responseMessage}`;
};

interface Props {
  email: string;
  onClose: () => any;
}

export const InviteUserToEnterpriseTenantModal = ({ email, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState(RoleName.TENANT_MEMBER);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [isFetchingTenant, setIsFetchingTenant] = useState(false);
  const [error, setError] = useState('');
  const [invite, setInvite] = useState('');
  const fetchTenant = async (name: string) => {
    if (name) {
      setIsFetchingTenant(true);
      const id = await searchTenant(name);
      if (id) {
        try {
          const t = await NamespacesResource.get(id);
          setTenant(t);
        } catch (err) {
          setError(getErrorMessage(err));
        }
      } else {
        setTenant(null);
      }
      setIsFetchingTenant(false);
    } else {
      setTenant(null);
    }
  };

  const func = useCallback(
    debounce(async name => {
      await fetchTenant(name);
    }, 500),
    []
  );

  const handleTenantChange = ({ target: { value } }) => {
    setTenantName(value);
    func(value);
  };

  const searchTenant = async (name: string) => {
    try {
      const searchResult = await NamespacesResource.search(name);

      const tenantMatches = searchResult.tenants;

      if (tenantMatches.length === 0) {
        setError(`Project not found: ${name}`);
        return null;
      }

      const exactMatch = tenantMatches.filter(t => name === t.internalName);

      if (exactMatch.length === 0) {
        const suggestedTenantId = tenantMatches[0].id;
        const suggestedInternalName = tenantMatches[0].internalName;
        setError(
          `Project not found: ${name}. ` +
            `Did you mean ${suggestedInternalName} (${suggestedTenantId})?`
        );
        return null;
      }
      // This shouldn't happen
      if (exactMatch.length > 1) {
        const duplicateIds = exactMatch.map(t => t.id).join(', ');
        setError(
          `The internal name ${name} matched more than one result, ` +
            'this is not supposed to happen! ' +
            `The duplicate project IDs found were: ${duplicateIds}`
        );
        return null;
      }

      return exactMatch[0].id;
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!tenantName) return;

    setLoading(true);
    setError(null);

    try {
      await InviteActions.inviteUserToTenant(tenant.id, email, [selectedRoleName]);
      setInvite(tenantName);
      setTenantName('');
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tenantName) {
      setInvite('');
      setError('');
      setInvite('');
    }
  }, [tenantName]);

  useEffect(() => {
    setTenantName('');
    setSelectedRoleName(RoleName.TENANT_MEMBER);
    setError('');
    setInvite('');
  }, [open]);

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header data-testid="invite-user-to-enterprise-modal-header">Invite</Dialog.Header>
      <Dialog.Content data-testid="invite-user-to-enterprise-modal-content">
        <Form onSubmit={handleSubmit}>
          <FormInput
            fluid
            value={tenantName}
            label="Project Name"
            onChange={handleTenantChange}
            placeholder=""
            data-testid="invite-user-to-enterprise-input"
          />
          {tenant && !isFetchingTenant && (
            <div className="tw-mt-2">
              <FormSelect
                label="Role"
                searchable={false}
                value={selectedRoleName}
                options={getTenantRoleOptions(tenant)}
                onChange={role => {
                  setSelectedRoleName(role.value);
                }}
              />
            </div>
          )}
          {isFetchingTenant && (
            <div className="tw-flex tw-justify-center tw-items-center tw-mt-2">
              <LoadingSpinner size="medium" />
            </div>
          )}

          {error && (
            <Alert type="danger" data-testid="invite-user-to-enterprise-error" className="tw-mt-2">
              {error}
            </Alert>
          )}
          {invite && (
            <Alert
              type="success"
              data-testid="invite-user-to-enterprise-success"
              className="tw-mt-2"
            >
              User was successfully invited to {invite}.
            </Alert>
          )}
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} fill="outlined" color="neutral">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          type="submit"
          loading={loading}
          disabled={tenantName === '' || !!invite || !tenant}
          data-testid="invite-user-to-enterprise-button"
        >
          Invite
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
