import { Alert, Form, FormInput, FormSelect } from 'components/foundation';
import {
  ProfileInformationFormData,
  profileInformationDefaults,
} from './form-profile-information-data';
import React, { useState } from 'react';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';
import {
  isUnspecifiedJobRole,
  JobRole,
  jobRoleOptions,
  requiresSubdivision,
  transformProfileInformationFormData,
  UseCase,
  useCaseOptions,
  getSubdivisionOptions,
} from './helpers';
import countryList from '../../billing-form/country-list';
import { Button } from '@neo4j-ndl/react';
import './form-profile-information.css';
import { addTrialCustomerInfo } from 'remote/resources/organizations';
import { useSession } from 'store';

export const schema = yup.object({
  firstName: yup
    .string()
    .required()
    .label('First (Given) name')
    .min(1)
    .max(50),
  lastName: yup
    .string()
    .required()
    .label('Last (Family) name')
    .min(1)
    .max(50),
  jobRole: yup
    .string()
    .required()
    .label('Role'),
  jobRoleOther: yup
    .string()
    .label('Role')
    .when('jobRole', {
      is: JobRole.OtherBusiness,
      then: yup
        .string()
        .required()
        .min(1)
        .max(100),
    })
    .when('jobRole', {
      is: JobRole.OtherTechnical,
      then: yup
        .string()
        .required()
        .min(1)
        .max(100),
    }),
  primaryUseCase: yup
    .string()
    .optional()
    .label('Primary use case'),
  primaryUseCaseOther: yup
    .string()
    .optional()
    .label('Primary use case')
    .when('primaryUseCase', {
      is: UseCase.Other,
      then: yup
        .string()
        .min(1)
        .max(100)
        .required(),
    }),
  companyName: yup
    .string()
    .label('Company/organization name')
    .required()
    .min(1)
    .max(200),
  country: yup
    .string()
    .label('Primary business location')
    .required(),
  subdivision: yup
    .string()
    .when('country', {
      is: 'US',
      then: yup
        .string()
        .required()
        .label('US state'),
    })
    .when('country', {
      is: 'CA',
      then: yup
        .string()
        .required()
        .label('Canadian province or territory'),
    }),
});

export const validate = (
  data: ProfileInformationFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(schema, data, onlyRequired || false);
};

interface Props {
  data: ProfileInformationFormData;
  onChange: (data: ProfileInformationFormData) => any;
  onCancel: () => any;
  onSubmit: () => any;
  setLoading: (boolean) => any;
  loading: boolean;
}

export const ProfileInformationForm = ({
  data,
  onChange,
  onCancel,
  onSubmit,
  setLoading,
  loading,
}: Props) => {
  const [validationErrors, setValidationErrors] = useState(null);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(null);
  const session = useSession();

  function resetValidationError(property: string) {
    if (!validationErrors || !Object.keys(validationErrors).includes(property)) {
      return;
    }

    if (validationErrors[property].error) {
      let errors = { ...validationErrors };
      delete errors[property];
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
      setValidationErrors(errors);
    }
  }

  const handleFirstNameChange = ({ target: { value } }) => {
    resetValidationError('firstName');
    onChange({ ...data, firstName: value });
  };
  const handleLastNameChange = ({ target: { value } }) => {
    resetValidationError('lastName');
    onChange({ ...data, lastName: value });
  };
  const handleJobRoleChange = ({ value }) => {
    if (value !== data.jobRole) {
      resetValidationError('jobRole');
      resetValidationError('jobRoleOther');
      onChange({ ...data, jobRole: value, jobRoleOther: profileInformationDefaults.jobRoleOther });
    }
  };
  const handleJobRoleOtherChange = ({ target: { value } }) => {
    resetValidationError('jobRoleOther');
    onChange({ ...data, jobRoleOther: value });
  };
  const handlePrimaryUseCaseChange = e => {
    const value = e === null ? profileInformationDefaults.primaryUseCase : e.value;
    if (value !== data.primaryUseCase) {
      resetValidationError('primaryUseCase');
      resetValidationError('primaryUseCaseOther');
      onChange({
        ...data,
        primaryUseCase: value,
        primaryUseCaseOther: profileInformationDefaults.primaryUseCaseOther,
      });
    }
  };
  const handlePrimaryUseCaseOtherChange = ({ target: { value } }) => {
    resetValidationError('primaryUseCaseOther');
    onChange({ ...data, primaryUseCaseOther: value });
  };
  const handleCompanyNameChange = ({ target: { value } }) => {
    resetValidationError('companyName');
    onChange({ ...data, companyName: value });
  };
  const handleCountryChange = ({ value }) => {
    if (value !== data.country) {
      resetValidationError('country');
      resetValidationError('subdivision');
      onChange({ ...data, country: value, subdivision: profileInformationDefaults.subdivision });
    }
  };
  const handleSubdivisionChange = ({ value }) => {
    resetValidationError('subdivision');
    onChange({ ...data, subdivision: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSubmitErrorMessage(null);

    const errors = validate(data);

    if (errors) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    addTrialCustomerInfo(session.tenant.organizationId, transformProfileInformationFormData(data))
      .then(() => {
        onSubmit();
      })
      .catch(e => {
        setSubmitErrorMessage(`An error occured: ${e.reason || e.message || 'unknown error'}`);
        setLoading(false);
      });
  };

  return (
    <>
      <h5 className="tw-mb-6">Set up your account</h5>
      <Form onSubmit={handleSubmit} className="tw-mt-4">
        <div className="form-profile-information profile-information">
          <div className="first-name">
            <FormInput
              fluid
              label="First (Given) name"
              value={data.firstName || ''}
              onChange={handleFirstNameChange}
              errorText={validationErrors?.firstName?.message}
            />
          </div>
          <div className="last-name">
            <FormInput
              fluid
              label="Last (Family) name"
              value={data.lastName || ''}
              onChange={handleLastNameChange}
              errorText={validationErrors?.lastName?.message}
            />
          </div>
          <div className="role">
            <FormSelect
              fluid
              label="What is your role?"
              placeholder="Select a role..."
              options={jobRoleOptions}
              value={data.jobRole}
              onChange={e => {
                handleJobRoleChange(e);
              }}
              errorText={validationErrors?.jobRole?.message}
              data-testid="select-job-role"
            />
          </div>
          {isUnspecifiedJobRole(data.jobRole) && (
            <div className="role-other">
              <FormInput
                fluid
                label="Role (other)"
                value={data.jobRoleOther || ''}
                onChange={handleJobRoleOtherChange}
                errorText={validationErrors?.jobRoleOther?.message}
              />
            </div>
          )}
          <div className="primary-use-case">
            <FormSelect
              fluid
              label="What is your primary use case?"
              placeholder="Select a primary use case..."
              options={useCaseOptions}
              value={data.primaryUseCase}
              onChange={handlePrimaryUseCaseChange}
              errorText={validationErrors?.primaryUseCase?.message}
              data-testid="select-primary-use-case"
              isClearable
            />
          </div>
          {data.primaryUseCase === UseCase.Other && (
            <div className="primary-use-case-other">
              <FormInput
                fluid
                label="Primary use case (other)"
                value={data.primaryUseCaseOther || ''}
                onChange={handlePrimaryUseCaseOtherChange}
                errorText={validationErrors?.primaryUseCaseOther?.message}
              />
            </div>
          )}
          <div className="company-name">
            <FormInput
              fluid
              label="What is your company or organization name?"
              value={data.companyName || ''}
              onChange={handleCompanyNameChange}
              errorText={validationErrors?.companyName?.message}
              data-testid="input-company-name"
            />
          </div>
          <div className="country">
            <FormSelect
              fluid
              label="Primary business location"
              placeholder="Select a country..."
              options={countryList}
              value={data.country}
              onChange={handleCountryChange}
              errorText={validationErrors?.country?.message}
              data-testid="select-primary-business-location"
            />
          </div>
          {requiresSubdivision(data.country) && (
            <div className="sub-division">
              <FormSelect
                fluid
                label={`Primary business location (${
                  data.country === 'US' ? 'US state' : 'Canadian province or territory'
                })`}
                options={getSubdivisionOptions(data.country)}
                value={data.subdivision}
                onChange={handleSubdivisionChange}
                errorText={validationErrors?.subdivision?.message}
                data-testid="input-sub-division"
              />
            </div>
          )}
          {submitErrorMessage && (
            <div className="error">
              <Alert data-testid="submit-error-banner" type="danger">
                {submitErrorMessage}
              </Alert>
            </div>
          )}
          <div className="actions tw-flex tw-justify-end tw-my-8 tw-gap-4">
            <Button
              onClick={onCancel}
              color="neutral"
              fill="outlined"
              disabled={loading}
              data-testid="cancel-profile-information"
            >
              Back
            </Button>
            <Button
              type="submit"
              disabled={validationErrors !== null}
              loading={loading}
              data-testid="submit-profile-information"
            >
              Create
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
