import { DatabaseStatus } from 'entities/database';

type DbSettingValue = string | number | undefined;

export const valueExists = (value: DbSettingValue) => value !== undefined && value !== null;

const transitionValue = (
  appliedValue: DbSettingValue,
  desiredValue: DbSettingValue,
  dbStatus: DatabaseStatus
) => {
  if (
    valueExists(desiredValue) &&
    [
      DatabaseStatus.CREATING,
      DatabaseStatus.PAUSING,
      DatabaseStatus.PAUSED,
      DatabaseStatus.RESUMING,
      DatabaseStatus.DESTROYING,
    ].includes(dbStatus)
  ) {
    return `${desiredValue}`;
  }

  if (valueExists(appliedValue) && valueExists(desiredValue) && appliedValue !== desiredValue) {
    return `${appliedValue} → ${desiredValue}`;
  }

  if (valueExists(appliedValue)) {
    return `${appliedValue}`;
  }

  return null;
};

export default transitionValue;
