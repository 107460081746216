import React, { useState } from 'react';

import Actions from 'actions';
import track, { useTracking } from 'react-tracking';
import { InfoMessage } from './messages';
import { setUpHandlers, Status } from './upload-handlers';
import { ImportComponent } from './import-component';
import { Database, DatabaseStatus } from 'entities/database';

interface Props {
  database: Database;
  isTesting?: boolean;
  initialState?: Partial<State>;
}

interface State {
  status: Status;
  fileMetadata: { name: string; size: number };
  message?: string;
  errorCode?: string;
  progress: number;
}

const Import = ({ database, isTesting = false, initialState = {} }: Props) => {
  const {
    status: initialStatus,
    fileMetadata: initialFileMetadata,
    message: initialMessage,
    errorCode: initialErrorCode,
    progress: initialProgress,
  } = initialState;

  const tracking = useTracking();
  const [status, setStatus] = useState<Status>(initialStatus || Status.IDLE);
  const [fileMetadata, setFileMetadata] = useState<{ name: string; size: number }>(
    initialFileMetadata
  );
  const [progress, setProgress] = useState<number>(initialProgress);
  const [message, setMessage] = useState<string>(initialMessage);
  const [errorCode, setErrorCode] = useState<string | null>(initialErrorCode);

  const reduceImportState = (props: Partial<State>) => {
    if (props.progress === undefined || props.progress === 100) {
      tracking.trackEvent({
        action: 'import_state_change',
        properties: {
          message: props.message,
          status: props.status,
          filesize: fileMetadata && fileMetadata.size,
          ...(database.EncryptionKey && {
            encryptionKeyRef: database.EncryptionKey.encryptionKeyRef,
          }),
        },
      });
    }
    if (props.status) {
      setStatus(props.status);
    }
    if (props.fileMetadata) {
      setFileMetadata(props.fileMetadata);
    }
    if (props.progress) {
      setProgress(props.progress);
    }
    if (props.message) {
      setMessage(props.message);
    }
    if (props.errorCode) {
      setErrorCode(props.errorCode);
    }
  };

  const handleSubmit = (file: File) => {
    Actions.databases.uploadDatabaseDump(database.DbId, file, setUpHandlers(reduceImportState));
  };

  if (database.DatabaseStatus === DatabaseStatus.LOADING_DATA) {
    return (
      <InfoMessage
        message="Import feature will be available again once current data loading completes"
        header="Loading in progress"
      />
    );
  }

  return (
    <ImportComponent
      status={status}
      fileMetadata={fileMetadata}
      progress={progress}
      onSubmit={handleSubmit}
      database={database}
      isTesting={isTesting}
      message={message}
      errorCode={errorCode}
    />
  );
};

export default track({ view: 'import_upload' })(React.memo(Import));
