import React, { useState } from 'react';
import { StickyActionsDataGrid, createColumnHelper, useDefaultTable, Link } from 'foundation';
import { InternalTenant } from 'entities/tenant';
import TenantsActionsMenu from './tenants-table-actions';

interface Props {
  tenants: InternalTenant[];
  className?: string;
}

const columnHelper = createColumnHelper<InternalTenant>();

export const TenantsTable = ({ tenants }: Props) => {
  const [tenantsData, setTenantsData] = useState(tenants);

  const saveIsolationIds = (rowIndex: number, ids: string[]) => {
    const newData = [...tenantsData];
    const newTenant: InternalTenant = { ...newData[rowIndex], isolationIds: ids };
    newData[rowIndex] = newTenant;
    setTenantsData(newData);
  };

  const columns = [
    columnHelper.accessor('internalName', {
      header: () => 'Internal Name',
      cell: cx => {
        const tenant: InternalTenant = cx.row.original;
        return (
          <Link href={`#admin/projects/${tenant.id}/users`}>
            <code>{tenant.internalName}</code>
          </Link>
        );
      },
    }),
    columnHelper.accessor('friendlyName', {
      header: () => 'Friendly Name',
    }),
    columnHelper.accessor('planType', {
      header: () => 'Plan Type',
    }),
    columnHelper.accessor('id', {
      header: () => 'Project UUID',
    }),
    columnHelper.accessor('isolationIds', {
      header: () => 'Isolation IDs',
      cell: cx => {
        const isolationIds: string[] = cx.getValue();

        return (
          <div style={{ display: 'block' }}>
            {isolationIds?.map((id, index) => (
              <div key={index} className={index > 0 ? 'tw-mt-4' : ''}>
                <Link
                  href={`https:///${window.location.host.replace(
                    'console',
                    'sre'
                  )}/orchestras?isolation_units=${id}`}
                >
                  <code>{id}</code>
                </Link>
              </div>
            ))}
          </div>
        );
      },
    }),
    columnHelper.accessor(ns => ns, {
      id: 'actions',
      header: () => '',
      cell: cx => (
        <TenantsActionsMenu
          tenant={cx.getValue()}
          saveIsolationIds={saveIsolationIds.bind(null, cx.row.index)}
        />
      ),
      size: 60,
      minSize: 60,
      meta: {
        isStickyAction: true,
      },
    }),
  ];

  const table = useDefaultTable({
    data: tenantsData,
    columns: columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    enableSorting: false,
    columnResizeMode: 'onChange',
  });

  return (
    <div className="tw-mt-6 tw-w-full">
      <StickyActionsDataGrid tableInstance={table} />
    </div>
  );
};
