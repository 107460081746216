import globals from 'browser/globals';
import createAuth0Client from '@auth0/auth0-spa-js';
import location from 'browser/location';
import logger from 'logger';
import localStorage from 'browser/localStorage';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js/dist/typings/global';

export const authKnownUserKey = 'auth.previousLogin';

export interface AuthenticateOptions {
  /**
   * Auth0 organization id
   */
  organization?: string;

  /**
   * Auth0 organization invitation id
   * If provided so should organization
   */
  invitation?: string;

  redirect_uri?: string;
}

const rememberPreviousLogin = () => {
  localStorage.setItem(authKnownUserKey, 'true');
};

// This check is taken from the Auth0 quckstart documentation here:
// https://auth0.com/docs/quickstart/spa/vanillajs/01-login#log-in-to-the-application
const isAuthenticationRedirectFromAuth0 = () => {
  const query = location.search;
  return query.includes('code=') && query.includes('state=');
};

const fetchTokenProfileAndTrackingIdForAuthenticatedUser = async auth0 => {
  const token = await auth0.getTokenSilently();
  const profile = await auth0.getUser();
  const trackingId = await auth0.options.tracking_id;
  return { token, profile, trackingId };
};

const handleRedirectFromAuth0 = async auth0 => {
  await auth0.handleRedirectCallback();
};

export const getAuthenticateWithAuth0Client = (onAuthenticating, onAuthenticated) => async (
  auth0: Auth0Client,
  partnerAccount,
  options: AuthenticateOptions = {}
) => {
  const isAuthenticated = await auth0.isAuthenticated();
  if (!isAuthenticationRedirectFromAuth0() && !isAuthenticated && !options.organization) {
    await auth0.loginWithRedirect(options);
  } else if (!isAuthenticationRedirectFromAuth0() && options.organization) {
    // Org logins can be used even when already authenticated

    // Must have screen_hint signin for org login
    await auth0.loginWithRedirect({ ...options, screen_hint: 'signin' });
  } else {
    if (isAuthenticationRedirectFromAuth0()) {
      if (onAuthenticating) {
        onAuthenticating();
      }
      await handleRedirectFromAuth0(auth0);
    }
    const { token, profile } = await fetchTokenProfileAndTrackingIdForAuthenticatedUser(auth0);
    rememberPreviousLogin();
    if (onAuthenticated) {
      onAuthenticated(null, { accessToken: token, profile, partnerAccount });
    }
  }
};

export default class Auth0Authentication {
  onAuthenticating;
  onAuthenticated;

  audience: string;
  auth0ClientID: string;
  auth0Domain: string;
  partnerAccount: { userId: string; projectId: string };
  auth0: Auth0Client;
  connection?: string;

  constructor() {
    // The 'audience' is a way to make it impossible for a token intended
    // for one API to be intercepted and used for another; however, defining
    // new audiences in Auth0 is a bit cumbersome, so we just have two - one
    // for prod environments and one for everything else.
    const isProduction =
      location.host === 'console.neo4j.io' ||
      location.host === 'console-classic.neo4j.io' ||
      location.host === 'console-staging.neo4j.io' ||
      location.host === 'console-classic-staging.neo4j.io';

    this.audience = isProduction ? 'https://console.neo4j.io' : 'https://nonprod.neo4j.io';

    if (isProduction) {
      this.auth0ClientID = 'WSLs6047kOjpUSW83gDZ4JyYhIk5zYTo';
    } else {
      this.auth0ClientID = 'e9oyRzV9LV0BSOXZlhnrVz19h0LE86cT';
    }
    this.auth0Domain = isProduction ? 'login.neo4j.com' : 'account-dev.neo4j.com';

    if (location.search) {
      const params = new globals.URLSearchParams(location.search.substring(1));
      if (params.get('user_id') && params.get('project_id')) {
        this.partnerAccount = {
          userId: params.get('user_id'),
          projectId: params.get('project_id'),
        };
      }
      if (params.get('sso_id')) {
        this.connection = params.get('sso_id');
      }
    }
  }

  fetchAnonymousId() {
    let anonymousId;
    try {
      anonymousId = globals.window.analytics.user().anonymousId();
    } catch (error) {
      logger.warn('Analytics blocked');
    }
    return anonymousId;
  }

  async ensureAuth0client(): Promise<Auth0Client> {
    const urlParams = location.search && new globals.URLSearchParams(location.search.substring(1));
    const urlBasedAuthScreen = typeof urlParams === 'object' && urlParams.get('action');
    let configOptions = {};

    // By default Auth0 shows login screen, so we don't need to pass any params for it.
    // We only need to send { screen_hint: 'signup' } if we want to show signup screen by default

    // By the URL params have an approprite `action` value, then it should supersede the localStroage based previousLogin check
    if (urlBasedAuthScreen && ['signup', 'signin'].indexOf(urlBasedAuthScreen) > -1) {
      configOptions = {
        ...(urlBasedAuthScreen === 'signup' ? { screen_hint: 'signup' } : {}),
      };
    } else {
      const hasPreviousLogin = localStorage.getItem(authKnownUserKey);
      configOptions = {
        ...(hasPreviousLogin ? {} : { screen_hint: 'signup' }),
      };
    }

    if (!this.auth0) {
      let authConfig: Auth0ClientOptions = {
        ...configOptions,
        domain: this.auth0Domain,
        client_id: this.auth0ClientID,
        redirect_uri: location.href,
        prompt: 'select_account',
        audience: this.audience,
        scope: 'openid profile email',
        tracking_id: this.fetchAnonymousId(),
      };

      if (this.partnerAccount) {
        authConfig = {
          ...authConfig,
          login_hint: this.partnerAccount.userId,
          connection: 'google-oauth2',
        };
      } else if (this.connection) {
        authConfig = {
          ...authConfig,
          connection: this.connection,
        };
      }
      this.auth0 = await createAuth0Client(authConfig);
    }

    return this.auth0;
  }

  getTokenForConnectionAndEmail = async (connection: string, toEmail: string) => {
    const a0: Auth0Client = await createAuth0Client({
      domain: this.auth0Domain,
      client_id: this.auth0ClientID,
      audience: this.audience,
      redirect_uri: location.href,
      authorizeTimeoutInSeconds: 300,
    });

    await a0.loginWithPopup({
      max_age: 0,
      scope: 'openid email profile',
      connection,
      login_hint: toEmail,
    });

    return a0.getTokenSilently();
  };

  async authenticate(options: AuthenticateOptions = {}) {
    const auth0 = await this.ensureAuth0client();
    getAuthenticateWithAuth0Client(this.onAuthenticating, this.onAuthenticated)(
      auth0,
      this.partnerAccount,
      options
    );
  }

  async logout(returnTo) {
    const auth0 = await this.ensureAuth0client();
    auth0.logout({ returnTo });
  }

  async loginRedirect(returnTo?: string) {
    const auth0 = await this.ensureAuth0client();
    auth0.loginWithRedirect({ returnTo });
  }
}
