import React, { ChangeEvent } from 'react';
import { FormInput } from 'foundation';
import { BillingAccountFormData } from './types';

export interface CreateBillingAccountFormFieldsProps {
  billingAccount: BillingAccountFormData;
  onChange(data: BillingAccountFormData): void;
  organizationId?: string;
}

const BillingAccountConfigFormFields = ({
  billingAccount,
  onChange,
}: CreateBillingAccountFormFieldsProps) => {
  const updateData = (param: string, value: any) => onChange({ ...billingAccount, [param]: value });

  const handleNameChange = ({ target: { value: name } }: ChangeEvent<HTMLInputElement>) => {
    updateData('name', name);
  };
  return (
    <>
      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Name"
          aria-label="Nme"
          data-testid="sso-config-display-name-input"
          value={billingAccount.name}
          onChange={handleNameChange}
        />
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Organization ID"
          aria-label="Organization id"
          data-testid="billing-account-client-id-input"
          value={billingAccount.organizationId}
          disabled={true}
        />
      </div>
    </>
  );
};

export default BillingAccountConfigFormFields;
