import React, { useContext } from 'react';
import {
  SessionStore,
  DatabasesStore,
  NavigationStore,
  NotificationsStore,
  SnapshotsStore,
} from 'state';
import { NavigationState } from 'state/navigation';
import { NotificationState } from 'state/notifications';
import { SessionState } from 'state/session-store';
import { SnapshotState } from 'state/snapshots-store';
import { DatabasesState } from 'state/databases-store';
import globals from 'browser/globals';

export const subStores = {
  session: SessionStore,
  databases: DatabasesStore,
  navigation: NavigationStore,
  notifications: NotificationsStore,
  snapshots: SnapshotsStore,
};

// Expose store data on window for debug purposes
// in dev deployments
if (process.env.NODE_ENV === 'development') {
  (globals.window as any)._stores = subStores;
}

export const createStore = () => {
  return new Store();
};

export type State = {
  session: SessionState;
  databases: DatabasesState;
  snapshots: SnapshotState;
  navigation: NavigationState;
  notifications: NotificationState;
} & Record<string, any>;

type SubscriberCallback = (state: State) => void;

class Store {
  private subscribers: SubscriberCallback[] = [];
  private state: State;

  constructor() {
    this.initializeState();

    this.startListening();
  }

  getState() {
    return this.state;
  }

  reset() {
    this.stopListening();
    this.resetState();
    this.startListening();
  }

  resetState() {
    Object.values(subStores).forEach(subStore => subStore.resetState());

    this.initializeState();
  }

  subscribe(callback: (state: State) => void) {
    // Shift to keep the subscriber list in
    // component hierarchy order [parent -> child]
    //
    // Its important to shift as then child components
    // will have their callback called after their parents
    // which helps avoid odd behavior from a child subscription
    // triggering a rerender when the parent might not even want
    // that component rendered anymore, but the parents
    // subscriber hasn't been notified yet

    this.subscribers.push(callback);
  }

  /**
   * Replace the subscriber and keep the list order to preserve
   * the [child -> parent] component hierarchy order of the subscriber list
   */
  replace(oldCallback: (state: State) => void, newCallback: (state: State) => void) {
    const index = this.subscribers.findIndex(subscriber => subscriber === oldCallback);
    if (index === -1) {
      throw new Error('Failed to replace state subscriber. Could not find old subscriber');
    }

    this.subscribers[index] = newCallback;
  }

  unsubscribe(callback: (state: State) => void) {
    this.subscribers = this.subscribers.filter(subscriber => subscriber !== callback);
  }

  stopListening() {
    Object.values(subStores).forEach(subStore => {
      subStore.stopListening();
    });
  }

  startListening() {
    Object.entries(subStores).forEach(([key, subStore]) => {
      if (subStore?.listen) {
        subStore.listen(newSubState => this.updateState(key, newSubState));
      }
    });
  }

  private updateState(key: string, subState: Record<string, any>) {
    this.state = { ...this.state, [key]: subState };

    const subscribers = [...this.subscribers];

    // Subscribers can be removed while we are calling them.
    // For example: If the subscriber1 updates state that unmounts the component
    // that controls subscriber2, then after we call subscriber1, we
    // will try to call subscriber2 which will try to update an unmounted components
    // state, throwing a harmless but annoying "Cannot update state on unmounted component".
    // To avoid this, we check that the subscriber still exists in the
    // subscriber list before we call it.
    for (const subscriber of subscribers) {
      if (this.subscribers.findIndex(sub => sub === subscriber) !== -1) {
        subscriber(this.state);
      }
    }
  }

  private initializeState() {
    this.state = Object.entries(subStores).reduce((acc, [key, subStore]) => {
      if (subStore) {
        return { ...acc, [key]: subStore.state };
      }
      return acc;
    }, {}) as State;
  }
}

export const store = createStore();

export const StoreContext = React.createContext(store);

export const useStore = () => useContext(StoreContext);
