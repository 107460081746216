import { NavigationStore } from 'state';
import { hrefFromLocation, locationFromUrl, NavigationLocation } from 'state/navigation';
import { parse, stringify } from 'query-string';
import globals from 'browser/globals';

export interface UpdatableNavigationLocation
  extends Omit<Partial<NavigationLocation>, 'search' | 'origin'> {
  search?: Record<string, any> | URLSearchParams | string;
}

interface UpdateOptions {
  /**
   * Whether or not to replace the history or push the history record
   */
  replace?: boolean;

  /**
   * Whether or not to sync the session state product to the url.
   * This should only be used in the very rare cases where the session
   * product has not been instantiated/inferred yet. Otherwise we
   * always want to sync
   */
  syncProduct?: boolean;
}

type PushReplaceOptions = Omit<UpdateOptions, 'replace'>;

export const searchToObject = (
  search: Record<string, any> | URLSearchParams | string
): Record<string, any> => {
  if (typeof search === 'string') {
    return parse(search);
  }

  if (search instanceof URLSearchParams) {
    const result = {};
    for (const [key, value] of search.entries()) {
      result[key] = value;
    }
    return result;
  }

  return search;
};

const searchToString = (search: Record<string, any> | URLSearchParams | string): string => {
  const searchString = stringify(searchToObject(search));
  if (!searchString) {
    return '';
  }

  return `?${searchString}`;
};

const cleanLocationData = (data: NavigationLocation): NavigationLocation => {
  // URL normalizes url pieces when you assign to it
  const url = new URL(data.origin);
  url.hash = cleanHash(data.hash);
  url.pathname = data.pathname;
  url.search = data.search;

  return {
    hash: url.hash,
    origin: url.origin,
    pathname: url.pathname,
    search: url.search,
  };
};

const cleanHash = (hashValue: string) => {
  let hash = hashValue.trim();
  if (!hash) {
    return '';
  }

  if (hash.startsWith('/#')) {
    hash = hash.slice(1);
  }

  if (hash.startsWith('#/')) {
    hash = `#${hash.slice(2)}`;
  }

  return hash;
};

export const watch = handler => window.addEventListener('popstate', handler);
export const stopWatching = handler => window.removeEventListener('popstate', handler);

const updateLocation = (data: string | UpdatableNavigationLocation, { replace = false }) => {
  let newLocation: NavigationLocation;
  if (typeof data === 'string') {
    newLocation = locationFromUrl(data);
  } else {
    newLocation = locationFromObject(data);
  }

  newLocation = cleanLocationData(newLocation);
  // Update Url and History
  const newHref = hrefFromLocation(newLocation);
  if (replace) {
    globals.window.history.replaceState(null, '', newHref);
  } else {
    globals.window.history.pushState(null, '', newHref);
  }

  // Update internal location tracking
  NavigationStore.setLocation(newLocation);
};

const locationFromObject = (data: UpdatableNavigationLocation) => {
  const existingLocation = NavigationStore.state.location;
  let search = existingLocation.search;

  if ('search' in data) {
    search = searchToString(data.search);
  }

  return { ...existingLocation, ...data, search };
};

export const push = (data: string | UpdatableNavigationLocation, options?: PushReplaceOptions) =>
  updateLocation(data, { ...options, replace: false });
export const replace = (data: string | UpdatableNavigationLocation, options?: PushReplaceOptions) =>
  updateLocation(data, { ...options, replace: true });
export const back = () => globals.window.history.back();
