import globals from './globals';

export interface LocalStorage {
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  getItem: (key: string) => string;
}

const localStorage: LocalStorage = {
  setItem(key, value) {
    globals.window.localStorage?.setItem(key, value);
  },
  removeItem(key) {
    globals.window.localStorage?.removeItem(key);
  },
  getItem(key) {
    return globals.window.localStorage?.getItem(key);
  },
};

export default localStorage;
