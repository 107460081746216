import ApiClient from 'remote/api-client';
import { OauthClient } from 'types/oauth';

export const create = (name: string) => {
  return ApiClient.post('/oauth')
    .issue({ Name: name })
    .promise();
};

export const list = () => {
  return ApiClient.get('/oauth').promise();
};

export const deleteClient = (clientId: string) => {
  return ApiClient.delete(`/oauth/${clientId}`).promise();
};

export const findByClientID = (clientId: string): Promise<OauthClient> => {
  return ApiClient.get(`/oauth/${clientId}`).promise();
};
