import { Button } from 'foundation/button';
import { Alert } from 'foundation/alert';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { Dialog } from 'foundation/dialog';
import { Database } from 'entities/database';
import Actions from 'actions';

interface ModalProps {
  database: Database;
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

const ClearDatabaseModal = ({ database, open, onSuccess, onClose }: ModalProps) => {
  const tracking = useTracking();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = async () => {
    tracking.trackEvent({ action: 'clear_db' });

    setLoading(true);

    try {
      await Actions.databases.clearDatabase(database.DbId);
      onSuccess();
    } catch (e) {
      setError(e.responseMessage);
    }

    setLoading(false);
  };

  const handleCancelClick = () => !loading && onClose();

  return (
    <Dialog open={open} modalProps={{ 'data-testid': 'clear-db-modal' }} onClose={onClose}>
      {error && <Alert type="danger" description={error} />}
      <Dialog.Header>Reset to blank</Dialog.Header>
      <Dialog.Content>
        <Alert
          title="Existing data will be erased"
          description="Resetting into a blank state will erase all data, so please be certain. If you want to keep the current data please take a snapshot and export it."
          className="tw-mb-6"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          fill="outlined"
          color="neutral"
          onClick={handleCancelClick}
          disabled={loading}
          className="tw-mr-2"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={loading} data-testid="clear-db-confirm">
          Reset
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ClearDatabaseModal;
