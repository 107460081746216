import { Tip, Typography } from 'components/foundation';
import React from 'react';

export const formatOptionLabel = (
  {
    label,
    description,
    isDisabled = false,
    disabledReason = 'This feature is not enabled for this instance',
  }: { label: string; description: string; isDisabled?: boolean; disabledReason?: string },
  { context }: { context: string }
) => {
  if (context === 'value') {
    return <div className="tw-flex tw-flex-row tw-whitespace-break-spaces">{label}</div>;
  } else if (context === 'menu') {
    return (
      <div className="tw-flex tw-grow tw-flex-col tw-justify-between">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Tip isDisabled={!isDisabled} type="tooltip">
            {/* type defaults to 'submit' -- form submits when pressed  (as of @neo4j-ndl/react@3.0.24) */}
            <Tip.Trigger type="button">
              <span className="tw-w-1/2">{label}</span>
            </Tip.Trigger>
            <Tip.Content className="tw-w-[300px] tw-fixed tw-z-50" isPortaled={false}>
              {disabledReason}
            </Tip.Content>
          </Tip>
        </div>
        <Typography variant="body-small" className="tw-text-neutral-text-weaker tw-break-words">
          {description ? description : ''}
        </Typography>
      </div>
    );
  }
  return null;
};
