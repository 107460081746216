import EstimationsResource from 'remote/resources/estimations';
import { SizeEstimate } from 'types/size-estimate';
import { Tier } from 'entities/database';

const estimateDatabaseSize = async (
  nodeCount: number,
  relationshipCount: number,
  algorithmCategories: string[],
  tier: Tier
): Promise<SizeEstimate> => {
  const response = await EstimationsResource.estimate(
    nodeCount,
    relationshipCount,
    algorithmCategories,
    tier
  );

  return response.recommendation;
};

export default { estimateDatabaseSize };
