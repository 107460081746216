export { DataGrid, DataGridComponents, useDataGridContext } from '@neo4j-ndl/react';
export {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  FilterFn,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  TableOptions,
  getCoreRowModel,
  getPaginationRowModel,
  RowData,
} from '@tanstack/react-table';
export * from './sticky-actions-data-grid';

export const globalFilterFn: FilterFn<any> = (row, columnId, filterValue: string) => {
  const search = filterValue.toLowerCase();

  let value = row.getValue(columnId) as string;
  if (typeof value === 'number') value = String(value);

  return value?.toLowerCase().includes(search);
};

// This is to include the "isStickyAction" meta data on the column.
// https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module '@tanstack/react-table' {
  // This needs to be here for eslint to be happy, if we remove the generic vars ColumnMeta is not happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    isStickyAction?: boolean;
  }
}

export const useDefaultTable = <T extends unknown>({
  data,
  columns,
  ...rest
}: Omit<TableOptions<T>, 'getCoreRowModel'>): ReturnType<typeof useReactTable> =>
  useReactTable({
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: globalFilterFn,
    columnResizeMode: 'onChange',
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...rest,
  });
