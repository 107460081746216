import { SyntheticEvent } from 'react';
import track, { useTracking } from 'react-tracking';
import { isNeoAppsAccessable, Database } from 'entities/database';
import { SessionState } from 'state/session-store';
import { useSession } from 'store';

export interface OpenWithBloomChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  href: string;
}
export interface OpenWithBloomProps {
  database: Database;
  children: (args: OpenWithBloomChildArgs) => JSX.Element;
}

const buildHostedBloomURL = (database: Database, session: SessionState) => {
  if (!database.BloomUrl) {
    return null;
  }

  const url = new URL(database.BloomUrl);
  const { sub: auth0SubjectId } = session;
  url.searchParams.set('ntid', auth0SubjectId);
  return url.toString();
};

export const OpenWithBloom = track()(({ database, children }: OpenWithBloomProps) => {
  const tracking = useTracking();
  const session = useSession();

  const handleBloomClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    // @Todo: remove the `open_db` event after AuraDS GA and Appcues triggers has been refactored
    tracking.trackEvent({
      action: 'open_db',
      properties: { event_label: 'neo4j-bloom' },
    });

    tracking.trackEvent({
      action: 'open_db_bloom',
    });
  };

  const bloomUrl = buildHostedBloomURL(database, session);

  return children({
    onClick: handleBloomClick,
    href: bloomUrl,
    disabled: !bloomUrl || !isNeoAppsAccessable(database),
  });
});
