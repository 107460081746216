import React, { SyntheticEvent, useCallback, useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { Database, Tier, hasActiveProTrial, isResumable } from 'entities/database';
import { ResumeDatabaseModal } from './modal';
import { DatabasesStore } from 'state';
import errorMessages from 'remote/error-handler/error-messages';
import logger from 'logger';
import DatabasesResource from 'remote/resources/databases';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';

export interface ResumeDatabaseChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  loading: boolean;
  allowResume: boolean;
}
export interface ResumeDatabaseProps {
  database: Database;
  suspended: boolean;
  children: (args: ResumeDatabaseChildArgs) => JSX.Element;
}

const ResumeDatabase = ({ database, suspended, children }: ResumeDatabaseProps) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowResume = allow(
    Action.RESUME,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const { trackEvent } = useTracking();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);

  const shouldShowModal = () => database.Tier !== Tier.FREE && !hasActiveProTrial(database);

  const resumeDatabase = async () => {
    trackEvent({ action: 'resume_db', properties: { dbid: database.DbId } });
    setLoading(true);
    setError(null);

    try {
      const updatedDatabase = await DatabasesResource.resume(database.DbId);

      logger.debug(`Database ${database.DbId} successfully scheduled for resuming`);

      DatabasesStore.upsert(database.DbId, updatedDatabase);
      setLoading(false);
      setShowModal(false);
    } catch (error) {
      logger.debug('Failed to resume DB due to error:', error);
      setError(errorMessages.experiencingProblems);
      setLoading(false);
    }
  };

  const onResumeClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (shouldShowModal()) {
      setShowModal(!showModal);
      return;
    }

    resumeDatabase();
  };

  const handleModalConfirmClick = useCallback(() => {
    resumeDatabase();
  }, [database.DbId]);

  const handleModalCancelClick = useCallback(() => {
    setLoading(false);
    setError(null);
    setShowModal(false);
  }, []);

  return (
    <>
      {children({
        onClick: onResumeClick,
        disabled: !isResumable(database, suspended) || loading || !allowResume,
        loading,
        allowResume,
      })}
      {showModal && (
        <ResumeDatabaseModal
          database={database}
          open={showModal}
          loading={loading}
          onConfirmClick={handleModalConfirmClick}
          onCancelClick={handleModalCancelClick}
          error={error}
          errorHeader="Unable to resume database"
        />
      )}
    </>
  );
};

export default track()(ResumeDatabase);
