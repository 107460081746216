import React from 'react';
import { DatabaseSizePicker, DatabaseSizePickerProps } from 'application/db-sizes';
import Icon from 'ui/icons';
import track, { useTracking } from 'react-tracking';
import { Tip } from 'foundation';

interface FormSizePickerProps extends DatabaseSizePickerProps {
  hideTitle?: boolean;
}

const FormSizePicker = ({ hideTitle, ...props }: FormSizePickerProps) => {
  const tracking = useTracking();

  return (
    <div data-testid="create-form-size-picker">
      {!hideTitle && (
        <h4 className="tw-mb-4 tw-mr-2 tw-inline-block tw-align-top tw-text-center">
          Instance size
          <Tip allowedPlacements={['right', 'bottom']}>
            <Tip.Trigger>
              <span className="tw-inline">
                <Icon
                  name="QuestionMarkCircleIconOutline"
                  type="solid"
                  className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1"
                  aria-label="Size information"
                  onMouseEnter={() =>
                    tracking.trackEvent({
                      action: 'database_size_tooltip',
                      properties: { event_label: 'hover' },
                    })
                  }
                />
              </span>
            </Tip.Trigger>
            <Tip.Content className="tw-max-w-sm" isPortaled={false}>
              <p>
                If you are migrating or cloning a database to Aura, choose a similar size to the
                machine you are running today.
              </p>
              <p className="tw-mt-2">
                Your database size can be increased without impact to availability after database
                creation, should your workload require it.
              </p>
            </Tip.Content>
          </Tip>
        </h4>
      )}

      <DatabaseSizePicker {...props} />
    </div>
  );
};

export default track()(FormSizePicker);
