import React from 'react';
import { Dialog } from 'foundation/dialog';
import LoadingSpinner from 'foundation/loading-spinner';

const LoadingModal = ({ open }) => {
  const handleClose = () => null; // Unclosable

  return (
    <Dialog size="small" open={open} onClose={handleClose} disableCloseButton>
      <Dialog.Content>
        <LoadingSpinner size="large" expand />
      </Dialog.Content>
    </Dialog>
  );
};

export default LoadingModal;
