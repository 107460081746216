import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';

import Actions from 'actions';
import { IconButton } from 'foundation';
import RestoreSnapshotModal from './modal';
import { Database as Db } from 'entities/database';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import { getHasCDCEnrichmentMode } from 'application/edit-cdc-enrichment-mode/helpers';

export interface RestoreSnapshotProps {
  snapshotId: string;
  database: Db;
  timestamp: string;
  profile: string;
  showModal?: boolean;
}

const RestoreSnapshot = ({
  snapshotId,
  database,
  timestamp,
  profile,
  showModal,
}: RestoreSnapshotProps) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowRestoreSnapshot = allow(
    Action.OVERWRITE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const tracking = useTracking();
  const hasCDCEnrichmentMode = getHasCDCEnrichmentMode(database);

  const [showModalDialog, setShowModalDialog] = useState(showModal);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const showModalAction = (e: any) => {
    e.preventDefault();
    setShowModalDialog(true);
    setActive(true);
  };

  const restoreSnapshot = () => {
    tracking.trackEvent({
      action: 'restore_db',
      properties: { event_label: 'clicked', time_stamp: timestamp, profile: profile },
    });

    setLoading(true);
    Actions.databases
      .restoreSnapshot(database.DbId, snapshotId)
      .then(() => cancelModal())
      .catch(() => cancelModal());
  };

  const cancelModal = () => {
    setLoading(false);
    setShowModalDialog(false);
    setActive(false);
  };

  return (
    <>
      <PermissionTip hasPermission={allowRestoreSnapshot}>
        <IconButton
          disabled={!database.AvailableActions.restore.enabled || !allowRestoreSnapshot}
          iconName="ArrowUturnLeftIconOutline"
          aria-label="Restore from snapshot"
          title="Restore from snapshot"
          data-testid="restore"
          clean
          onClick={e => showModalAction(e)}
          active={active}
        />
      </PermissionTip>
      {allowRestoreSnapshot && (
        <RestoreSnapshotModal
          open={showModalDialog}
          DbId={database.DbId}
          DbName={database.Name}
          Timestamp={timestamp}
          hasCDCEnrichmentMode={hasCDCEnrichmentMode}
          onConfirm={() => restoreSnapshot()}
          onCancel={() => cancelModal()}
          loading={loading}
        />
      )}
    </>
  );
};

export default track()(RestoreSnapshot);
