import React from 'react';
import TotalBillingUsage from 'application/usage/report';
import { Tenant } from 'entities/tenant';

interface Props {
  tenant: Tenant;
  className?: string;
}

export const UsagePane = ({ tenant, className }: Props) => {
  return (
    <div className={className}>
      <TotalBillingUsage tenant={tenant} />
    </div>
  );
};
