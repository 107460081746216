import React, { useState, useEffect } from 'react';
import DatabasesResource from 'remote/resources/databases';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { Alert, LoadingSpinner } from 'foundation';
import { DatabasesTable } from './databases-table';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { Database } from 'entities/database';

interface Props {
  tenantId: string;
}

export const ViewTenantDatabases = ({ tenantId }: Props) => {
  const permission = usePermissions();
  const canListDbs = permission.allow(Action.READ, 'namespaces/*/databases');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [databases, setDatabases] = useState<Database[]>([]);

  const fetchDatabases = async () => {
    try {
      setDatabases(await DatabasesResource.list(tenantId));
    } catch (err) {
      setError(getErrorMessage(err, tenantId));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (canListDbs) {
      fetchDatabases();
    }
  }, [tenantId, canListDbs]);

  if (!canListDbs) {
    return (
      <Alert type="danger" data-testid="view-tenant-dbs-error">
        Access Denied
      </Alert>
    );
  }
  if (loading) {
    return <LoadingSpinner size="large" expand />;
  }
  return (
    <>
      {error ? (
        <Alert type="danger" data-testid="view-tenant-dbs-error">
          {error}
        </Alert>
      ) : (
        <DatabasesTable databases={databases} />
      )}
    </>
  );
};

const getErrorMessage = (err, tenantId: string): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case 'tenant-not-found':
    case 'namespace-not-found':
    case 'project-not-found':
      return `Project "${tenantId}" does not exist`;
    default:
      return `Error: ${err.reason || 'unknown'}: ${err.responseMessage}`;
  }
};
