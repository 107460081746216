import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { deepEquals } from 'state/utils';
import { store } from 'store';
import { Notification, selectNotifications } from 'state/notifications';
import './notifications-toast.css';
import { ToastNotification } from './types';
import { Toast } from './toast';

const NOTIFICATION_COUNT = 4;
const FADE_ANIMATION_TIME = 500;

enum ToastAction {
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
}

interface Action extends Record<string, any> {
  type: ToastAction;
  notifications: ToastNotification[];
}

interface State {
  notifications: ToastNotification[];
}

const initialState: State = {
  notifications: [],
};

const reducer = (state: State = initialState, action: Action) => {
  const { type } = action;
  if (type === ToastAction.ADD) {
    return { ...state, notifications: [...state.notifications, ...action.notifications] };
  }

  if (type === ToastAction.DELETE) {
    const deletedNotificationIds = action.notifications.map(o => o.id);
    const newNotifications = state.notifications.filter(
      o => !deletedNotificationIds.includes(o.id)
    );
    return { ...state, notifications: newNotifications };
  }

  if (type === ToastAction.UPDATE) {
    const newNotifications = [...state.notifications];

    for (const note of action.notifications) {
      const i = newNotifications.findIndex(o => o.id === note.id);
      if (i === -1) continue;
      newNotifications[i] = note;
    }

    return { ...state, notifications: newNotifications };
  }

  return state;
};

const useToasts = () => {
  const [state, setState] = useState<State>(() => ({
    ...initialState,
    notifications: selectNotifications(store.getState()).map(o => ({ ...o, mounted: true })),
  }));
  const stateRef = useRef(state);
  stateRef.current = state;

  const dispatch = useCallback((action: Action) => {
    const newState = reducer(stateRef.current, action);
    if (newState === stateRef.current) {
      return;
    }

    // We need to sync state back to the ref now so that if two
    // back to back actions are dispatched the second one is not
    // operating on stale state.
    stateRef.current = newState;

    setState(newState);
  }, []);

  const process = useCallback((newNotifications: Notification[]) => {
    const currentNotifications = stateRef.current.notifications;
    const currentNotificationsIds = currentNotifications.map(o => o.id);
    const newNotificationsIds = newNotifications.map(o => o.id);

    if (deepEquals(newNotificationsIds, currentNotificationsIds)) {
      return;
    }

    const removedNotifications = currentNotifications.filter(
      o => !newNotificationsIds.includes(o.id)
    );

    if (removedNotifications.length > 0) {
      const unmountedNotifications = removedNotifications.map(o => ({ ...o, mounted: false }));
      dispatch({
        type: ToastAction.UPDATE,
        notifications: unmountedNotifications,
      });

      setTimeout(() => {
        dispatch({ type: ToastAction.DELETE, notifications: unmountedNotifications });
      }, FADE_ANIMATION_TIME);
    }

    const removedNotificationsIds = removedNotifications.map(o => o.id);
    const unremovedNotifications = newNotifications.filter(
      o => !removedNotificationsIds.includes(o.id)
    );
    const addedNotifications = unremovedNotifications.filter(
      o => !currentNotificationsIds.includes(o.id)
    );

    if (addedNotifications.length > 0) {
      dispatch({
        type: ToastAction.ADD,
        notifications: addedNotifications.map(o => ({ ...o, mounted: true })),
      });
    }
  }, []);

  useEffect(() => {
    store.subscribe(storeState => {
      const newNotifications = selectNotifications(storeState);

      if (!deepEquals(newNotifications, stateRef.current.notifications)) {
        process(newNotifications);
      }
    });
  }, [process]);

  const notifications = useMemo(() => state.notifications.slice(0, NOTIFICATION_COUNT), [
    state.notifications,
  ]);

  return notifications;
};

const NotificationsToast = memo(() => {
  const toasts = useToasts();

  return (
    <div className="console-toast">
      {toasts.map(notification => (
        <Toast notification={notification} key={notification.id} />
      ))}
    </div>
  );
});

export default NotificationsToast;
