import { TenantNotification } from 'entities/tenant';
import hash from 'object-hash';
import { useSession } from 'store';
import localStorage from 'browser/localStorage';

export const DISMISSED_TENANT_NOTIFICAIONS_KEY = 'dismissedTenantNotifications';

interface DismissedTenantNotifications {
  [key: string]: number;
}

const now = () => new Date().getTime();

const filterExpired = (
  dismissedTenantNotifications: DismissedTenantNotifications
): DismissedTenantNotifications =>
  Object.entries(dismissedTenantNotifications).reduce((filteredNotifications, [key, expiresAt]) => {
    if (expiresAt > now()) {
      filteredNotifications[key] = expiresAt;
    }
    return filteredNotifications;
  }, {});

const setDismissedTenantNotifications = (
  dismissedTenantNotifications: DismissedTenantNotifications
) => {
  localStorage.setItem(
    DISMISSED_TENANT_NOTIFICAIONS_KEY,
    JSON.stringify(dismissedTenantNotifications)
  );
};

const getDismissedTenantNotifications = (): DismissedTenantNotifications => {
  const dismissedTenantNotificationsStr =
    localStorage.getItem(DISMISSED_TENANT_NOTIFICAIONS_KEY) || '{}';
  const dismissedTenantNotifications = JSON.parse(dismissedTenantNotificationsStr);
  return filterExpired(dismissedTenantNotifications);
};

// We are unable to use useLocalStorage because we need to read back the latest value before writing
export const dismissTenantNotification = (notification: TenantNotification) => {
  const notificationKey = hash(notification);
  const dismissedTenantNotifications = getDismissedTenantNotifications();
  dismissedTenantNotifications[notificationKey] = now() + notification.dismissalPeriod;
  setDismissedTenantNotifications(dismissedTenantNotifications);
};

export const useTenantNotifications = (): TenantNotification[] => {
  const { tenant } = useSession();
  const tenantNotifications = tenant?.notifications || [];
  const dismissedTenantNotifications = getDismissedTenantNotifications();

  const notificationsToDisplay = tenantNotifications.filter(
    notification => !(hash(notification) in dismissedTenantNotifications)
  );

  return notificationsToDisplay;
};
