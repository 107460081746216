import React, { useState, useRef, forwardRef, RefObject, MouseEventHandler } from 'react';
import track from 'react-tracking';
import Actions from 'actions';
import Button from 'foundation/button';
import { PartnerMessage } from 'application/partner/partner';
import { FallbackAvatar } from './fallback-avatar';
import { Alert, ImageWrapper, Link, Popover, TextLink } from 'foundation';
import Icon from 'ui/icons';
import { SessionState } from 'state/session-store';
import { TenantType, PlanType } from 'entities/tenant';
import classnames from 'classnames';
import './profile.css';
import { formatUpxDomain } from 'components/utils';

const gcpLogo = require('ui/png/gcp-cloud.png');

interface ProfileSectionImageProps {
  session: SessionState;
  isN4GCP: boolean;
}

const ProfileSectionImage = ({ session, isN4GCP }: ProfileSectionImageProps) => {
  const [hover, setHover] = useState(false);

  const props = {
    className: 'profile-image tw-w-10 tw-h-10 lg:tw-mr-0 tw-mr-4',
    'data-testid': 'profile-section-image',
  };

  if (isN4GCP) {
    Object.assign(props, {
      onMouseEnter: () => {
        setHover(true);
      },
      onMouseLeave: () => {
        setHover(false);
      },
      'data-testid': 'partner-profile',
    });
  }

  return (
    <ImageWrapper
      src={isN4GCP && !hover ? gcpLogo : session.picture}
      Fallback={() => <FallbackAvatar session={session} {...props} />}
      {...props}
    />
  );
};

interface WorkspaceSwitchProps {
  session: SessionState;
  onClick: MouseEventHandler;
}

const WorkspaceSwitch = ({ session, onClick }: WorkspaceSwitchProps) => {
  const title = `You are using the ${
    session.workspaceEnabled === false ? 'classic' : 'new'
  } experience.`;

  const text = session.workspaceEnabled ? (
    <>
      Feel free to send us your{' '}
      <TextLink href="https://aura.feedback.neo4j.com/workspace" externalLink>
        feedback
      </TextLink>{' '}
      to let us know how we can improve!
    </>
  ) : (
    'Enable the Workspace beta to experience our user tools in a single app.'
  );

  const links = (
    <>
      <a
        className="workspace-switch-link"
        data-testid="workspace-switch-link"
        href="#settings/preferences"
        onClick={onClick}
      >
        Change preference
      </a>
      {session.workspaceEnabled && (
        <TextLink
          className="workspace-switch-link"
          href="https://neo4j.com/product/workspace/"
          externalLink
        >
          Learn more
        </TextLink>
      )}
    </>
  );

  return (
    <Alert
      title={title}
      type="info"
      icon
      className="profile-popup-alert tw-mt-5"
      data-testid="workspace-switch-section"
    >
      <p className="n-body-medium">{text}</p>
      <div className="tw-flex tw-justify-start tw-gap-4 tw-mt-4">{links}</div>
    </Alert>
  );
};

interface ProfileSectionContainerProps {
  children: React.ReactNode;
  [key: string]: any;
}

const ProfileSectionContainer = forwardRef(
  ({ children, ...rest }: ProfileSectionContainerProps, ref: RefObject<HTMLDivElement>) => (
    <div data-testid="profile-section" className="tw-flex tw-cursor-pointer" ref={ref} {...rest}>
      {children}
    </div>
  )
);

interface UserProfileProps {
  session: SessionState;
  className?: string;
}

const UserProfile = ({ session, className }: UserProfileProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAnchorClick = () => {
    if (!isOpen) setIsOpen(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!isOpen && event.key === 'Enter') setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAccountClick = () => {
    handleClose();
  };

  const handleLogoutClick = () => {
    Actions.navigate.push({ hash: 'logout' });
  };

  const handleWorkspaceSwitchClick = () => {
    handleClose();
  };

  const ref = useRef(null);

  const classes = classnames(className, 'tw-cursor-pointer');

  // When loading the application, there is a window where `loggedIn` is true
  // but tenant has not yet loaded, hence null guard
  const isN4GCP = session.tenant?.tenantType === TenantType.N4GCP;

  const showUpxPreview = session.tenant;
  const upxDomain = formatUpxDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);

  return (
    <>
      <ProfileSectionContainer
        onClick={handleAnchorClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        ref={ref}
        className={classes}
      >
        <ProfileSectionImage session={session} isN4GCP={isN4GCP} />
        <div className="lg:tw-flex tw-flex-col tw-justify-center tw-px-2 tw-hidden">
          <span className="tw-text-sm">{session.name}</span>
          {session.name !== session.email && (
            <span className="tw-text-xs tw-text-palette-neutral-text-weaker">{session.email}</span>
          )}
        </div>
        <div className="tw-self-center lg:tw-inline tw-hidden">
          <Icon
            name="ChevronDownIconOutline"
            className="tw-w-4 tw-text-palette-neutral-text-weaker lg:tw-inline tw-hidden"
            aria-label="Open account information"
            style={{ minWidth: 13 }}
          />
        </div>
      </ProfileSectionContainer>

      <Popover
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={ref.current}
        className="tw-p-6"
        id="profile-popup"
        anchorPortal={false}
      >
        <div
          data-testid="profile-userdetails"
          className="tw-max-w-md tw-flex tw-flex-col"
          id="profile-content"
        >
          {isN4GCP && (
            <PartnerMessage googleProjectId={session.tenant.googleProjectId} className="tw-mb-5" />
          )}
          <div className="tw-flex tw-gap-4 tw-items-center">
            <ImageWrapper
              src={session.picture}
              Fallback={() => <FallbackAvatar session={session} id="profile-popup-image" />}
              id="profile-popup-image"
              className="profile-image"
            />
            <div
              className="tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden"
              data-testid="account-details"
            >
              <h6
                title={session.name}
                className="tw-font-bold tw-whitespace-nowrap tw-overflow-hidden"
                id="profile-name"
              >
                {session.name}
              </h6>
              {session.name !== session.email && (
                <div
                  data-testid="account-email"
                  title={session.email}
                  className="tw-text-sm tw-font-semibold tw-whitespace-nowrap tw-overflow-hidden"
                  id="profile-email"
                >
                  {session.email}
                </div>
              )}
            </div>
          </div>
          <div className="tw-flex tw-justify-end tw-items-center tw-mt-6">
            {session.planType === PlanType.SELF_SERVE && (
              <Link
                href="#terms"
                onClick={handleClose}
                className="tw-mr-4 tw-text-sm"
                id="terms-link"
              >
                Terms of Service
              </Link>
            )}
            <Button
              data-testid="account-page"
              onClick={handleAccountClick}
              fill="outlined"
              color="neutral"
              size="small"
              as={Link}
              href="/#account"
            >
              Account details
            </Button>
            <Button
              onClick={handleLogoutClick}
              color="neutral"
              fill="outlined"
              size="small"
              className="tw-ml-2"
              iconName="ArrowRightOnRectangleIconOutline"
              title=""
            >
              Log out
            </Button>
          </div>
          {showUpxPreview && (
            <Alert
              className="profile-popup-alert tw-mt-5"
              title="We are upgrading your experience"
              description="Try upcoming features in a unified interface. The existing operational and security capabilities will be added soon."
              icon={true}
              actions={[
                {
                  href: `https://${upxDomain}/projects/${session.tenant.id}/instances`,
                  label: 'Preview now',
                },
              ]}
            />
          )}
          {session.planType !== PlanType.SELF_SERVE && !showUpxPreview && (
            <WorkspaceSwitch session={session} onClick={handleWorkspaceSwitchClick} />
          )}
        </div>
      </Popover>
    </>
  );
};

interface ProfileProps {
  session: SessionState;
  className?: string;
}

const Profile = ({ session, className }: ProfileProps) => (
  <>
    {session.loggedIn ? (
      <UserProfile session={session} className={className} />
    ) : (
      <ProfileSectionContainer className={className}>
        <div className="tw-align-middle tw-text-palette-neutral-text-inverse tw-mr-4 tw-select-none">
          <Button data-testid="profile-login" href="/">
            Login/Register
          </Button>
        </div>
      </ProfileSectionContainer>
    )}
  </>
);

export default track()(Profile);
