import React, { SyntheticEvent } from 'react';
import { isNeoAppsAccessable, Database, Tier } from 'entities/database';
import { useTracking } from 'react-tracking';
import { Tip } from 'foundation';
import { useSession } from 'store';
import { SessionState } from 'state/session-store';

export const buildWorkspaceUrl = (database: Database, session: SessionState): string | null => {
  if (!database.WorkspaceUrl) {
    return null;
  }

  const url = new URL(database.WorkspaceUrl);

  url.searchParams.set('instanceName', database.Name);

  const { sub: auth0SubjectId } = session;
  url.searchParams.set('ntid', auth0SubjectId);
  if ([Tier.AURA_DSE, Tier.ENTERPRISE].includes(database.Tier)) {
    url.searchParams.set('acceptTerms', 'true');
  }

  return url.toString();
};

export interface OpenWithWorkspaceChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  href: string;
}

export interface OpenWithWorkspaceProps {
  database: Database;
  children: (args: OpenWithWorkspaceChildArgs) => JSX.Element;
}

export const OpenWithWorkspace = ({ database, children }: OpenWithWorkspaceProps) => {
  const tracking = useTracking();
  const session = useSession();

  const workspaceUrl = buildWorkspaceUrl(database, session);

  const handleWorkspaceClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    tracking.trackEvent({
      action: 'open_db_workspace',
    });
  };

  const disabled = !workspaceUrl || !isNeoAppsAccessable(database);

  const alteredChildren = children({
    onClick: handleWorkspaceClick,
    href: workspaceUrl,
    disabled: disabled,
  });

  return (
    <Tip>
      <Tip.Trigger>
        <span>{alteredChildren}</span>
      </Tip.Trigger>
      {!disabled && <Tip.Content>Import, Explore and Query in Neo4j Workspace</Tip.Content>}
    </Tip>
  );
};
