import React, { useState } from 'react';
import { Alert, Button, Checkbox, Dialog, Radio, TextInput } from 'components/foundation';
import {
  CloudwatchDestination,
  Destination,
  DestinationType,
  EditLogForwardingPayload,
  LogAnalyticsDestination,
  LogForwarding,
  LogType,
  StackdriverDestination,
} from 'remote/resources/log-forwarding';

interface Props<T extends Destination> {
  onEdit: (payload: EditLogForwardingPayload) => any;
  onClose: () => any;
  open: boolean;
  loading: boolean;
  error: Error | null;
  logForwarding: LogForwarding<T>;
}

export const EditStackdriverConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<StackdriverDestination>) => {
  const destination = logForwarding.destination;
  const [name, setName] = useState(logForwarding.name);
  const [gcpProjectId, setGcpProjectId] = useState(destination.gcpProjectId);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleGcpProjectIdChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setGcpProjectId(e.target.value.trim());

  const handleEdit = () => {
    const payload: EditLogForwardingPayload = {
      destination: {
        type: DestinationType.STACKDRIVER,
        gcpProjectId,
      },
      logType: LogType.SECURITY,
      name,
    };
    onEdit(payload);
  };

  const isValidPayload = !!gcpProjectId && !!name.trim();

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>Edit forwarding process</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        {error && <Alert type="danger">{String(error)}</Alert>}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          onBlur={trimName}
          placeholder="Process name"
          fluid
          data-testid="log-forwarding-name-input"
        />
        <div>
          <p className="tw-mb-2">Logs to forward:</p>
          <Radio checked readOnly label="Security logs" />
        </div>
        <TextInput
          label="Target GCP Project ID"
          value={gcpProjectId}
          onChange={handleGcpProjectIdChange}
          placeholder="your-project-id"
          fluid
          data-testid="log-forwarding-gcp-project-id-input"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!isValidPayload}
          onClick={handleEdit}
          loading={loading}
          data-testid="log-forwarding-edit"
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const EditLogAnalyticsConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<LogAnalyticsDestination>) => {
  const destination = logForwarding.destination;

  const [name, setName] = useState(logForwarding.name);
  const [editDestination, setEditDestination] = useState(false);
  const [azureWorkspaceId, setAzureWorkspaceId] = useState(destination.customerId);
  const [azureSharedKey, setAzureSharedKey] = useState('');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleAzureWorkspaceIdChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureWorkspaceId(e.target.value.trim());

  const handleAzureSharedKeyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureSharedKey(e.target.value.trim());

  const handleEdit = () => {
    const payload: EditLogForwardingPayload = {
      ...(editDestination && {
        destination: {
          type: DestinationType.LOG_ANALYTICS,
          customerId: azureWorkspaceId,
          sharedKey: azureSharedKey,
        },
      }),
      logType: LogType.SECURITY,
      name,
    };
    onEdit(payload);
  };

  const isValidPayload =
    !!name.trim() && editDestination ? !!azureWorkspaceId && !!azureSharedKey : true;
  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>Edit forwarding process</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        {error && <Alert type="danger">{String(error)}</Alert>}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          onBlur={trimName}
          placeholder="Process name"
          fluid
          data-testid="log-forwarding-name-input"
        />
        <div>
          <p className="tw-mb-2">Logs to forward:</p>
          <Radio checked readOnly label="Security logs" />
        </div>
        <Checkbox
          checked={editDestination}
          onChange={e => setEditDestination(e.target.checked)}
          label="Edit Workspace ID"
          data-testid="log-forwarding-azure-edit-target"
        />
        {editDestination && (
          <>
            <TextInput
              label="Workspace ID"
              value={azureWorkspaceId}
              onChange={handleAzureWorkspaceIdChange}
              placeholder="your-project-id"
              fluid
              data-testid="log-forwarding-azure-workspace-id-input"
            />
            <TextInput
              label="Primary Key"
              value={azureSharedKey}
              onChange={handleAzureSharedKeyChange}
              placeholder="your-primary-key"
              fluid
              data-testid="log-forwarding-azure-shared-key-input"
            />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!isValidPayload}
          onClick={handleEdit}
          loading={loading}
          data-testid="log-forwarding-edit"
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const EditCloudwatchConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<CloudwatchDestination>) => {
  const [name, setName] = useState(logForwarding.name);
  const [destination, setDestination] = useState(logForwarding.destination);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleGroupNameChange = ({ target: { value } }) =>
    setDestination(oldValue => ({ ...oldValue, logGroupName: value }));

  const handleStreamNameChange = ({ target: { value } }) =>
    setDestination(oldValue => ({ ...oldValue, logStreamName: value }));

  const handleAwsRegionChange = ({ target: { value } }) =>
    setDestination(oldValue => ({ ...oldValue, region: value }));

  const handleRetentionDaysChange = ({ target: { value } }) =>
    setDestination(oldValue => ({
      ...oldValue,
      logRetentionDays: value ? parseInt(value ?? '1') : 1,
    }));

  const handleLogArnChange = ({ target: { value } }) =>
    setDestination(oldValue => ({ ...oldValue, logArn: value }));

  const handleEdit = () => {
    const payload: EditLogForwardingPayload = {
      destination,
      logType: LogType.SECURITY,
      name,
    };
    onEdit(payload);
  };

  const isValidPayload =
    !!name.trim() &&
    !!destination.logArn &&
    !!destination.logGroupName &&
    !!destination.logStreamName &&
    destination.logRetentionDays > 0 &&
    !!destination.region;

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>Edit forwarding process</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        {error && <Alert type="danger">{String(error)}</Alert>}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          onBlur={trimName}
          placeholder="Process name"
          fluid
          data-testid="log-forwarding-name-input"
        />
        <div>
          <p className="tw-mb-2">Logs to forward:</p>
          <Radio checked readOnly label="Security logs" />
        </div>
        <TextInput
          label="Aws ARN"
          value={destination.logArn}
          onChange={handleLogArnChange}
          fluid
          data-testid="log-forwarding-aws-role-arn-input"
        />
        <TextInput
          label="Group name"
          value={destination.logGroupName}
          onChange={handleGroupNameChange}
          fluid
          data-testid="log-forwarding-aws-group-name-input"
        />
        <TextInput
          label="Stream name"
          value={destination.logStreamName}
          onChange={handleStreamNameChange}
          fluid
          data-testid="log-forwarding-aws-stream-name-input"
        />
        <TextInput
          label="Retention days"
          value={destination.logRetentionDays}
          onChange={handleRetentionDaysChange}
          type="number"
          errorText={
            destination.logRetentionDays <= 0 ? 'Retention days must be larger than 0' : ''
          }
          min={1}
          fluid
          data-testid="log-forwarding-aws-retention-days-input"
        />
        <TextInput
          label="AWS region"
          value={destination.region}
          onChange={handleAwsRegionChange}
          fluid
          data-testid="log-forwarding-aws-region-input"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!isValidPayload}
          onClick={handleEdit}
          loading={loading}
          data-testid="log-forwarding-edit"
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
