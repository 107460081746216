export default function formatDollars(
  dollarAmount: number | string,
  maximumDecimalPlaces: number = 5
): string {
  const number = (typeof dollarAmount === 'string' ? parseFloat(dollarAmount) : dollarAmount) || 0;
  const formatted = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumDecimalPlaces,
  });
  // Strip unnecessary zero cents ".00"
  return formatted.replace(/\.00$/, '');
}
