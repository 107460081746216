import React, { ComponentType, useEffect, useState } from 'react';

interface Props {
  src: string;
  Fallback: ComponentType;
  [key: string]: any;
}

export const ImageWrapper = ({ src, Fallback, ...rest }: Props) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  return error ? <Fallback /> : <img onError={() => setError(true)} src={src} {...rest} />;
};
