import { Channel, IdpType, SsoConfig } from 'types/sso-config';
import { validateYup, Validation } from 'utils/validation';
import * as yup from 'yup';
import { SsoConfigFormData } from './types';

const uriErrorMessage =
  'Either a Discovery URI or a combination of Issuer, Authorization Endpoint, Token Endpoint and JWKS URI is required.';

const ssoConfigSchema = yup.object().shape({
  displayName: yup
    .string()
    .required()
    .min(3)
    .max(128)
    .label('Display name'),
  idpType: yup
    .string()
    .oneOf(Object.values(IdpType))
    .required()
    .label('IDP Type'),
  channel: yup
    .string()
    .oneOf(Object.values(Channel))
    .required()
    .label('Channel'),
  discoveryURI: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function(value) {
      const { issuer, authorizationEndpoint, tokenEndpoint, jwksURI } = this.parent;
      return value || (issuer && authorizationEndpoint && tokenEndpoint && jwksURI);
    })
    .label('Discovery URI'),
  issuer: yup
    .string()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function(value) {
      const { discoveryURI } = this.parent;
      return discoveryURI || value;
    })
    .label('Issuer'),
  authorizationEndpoint: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function(value) {
      const { discoveryURI } = this.parent;
      return discoveryURI || value;
    })
    .label('Authorization Endpoint'),
  tokenEndpoint: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-tokenEndpoint', uriErrorMessage, function(value) {
      const { discoveryURI } = this.parent;
      return discoveryURI || value;
    })
    .label('Token Endpoint'),
  jwksURI: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-jwksURI', uriErrorMessage, function(value) {
      const { discoveryURI } = this.parent;
      return discoveryURI || value;
    })
    .label('JWKS URI'),
  clientID: yup
    .string()
    .required()
    .label('Client ID'),
  needsSecret: yup.boolean(),
  clientSecret: yup.string().when('needsSecret', {
    is: true,
    then: yup
      .string()
      .required()
      .label('Client Secret'),
  }),
  emailDomains: yup.array().label('Email Domains'),
  roleMapping: yup
    .string()
    .nullable()
    .label('Role Mapping'),
});

const validateSsoConfig = (data: SsoConfigFormData): Validation<SsoConfig> => {
  return validateYup(ssoConfigSchema, data);
};

export { ssoConfigSchema, validateSsoConfig };
