import ApiClient, { IgnoreErrors } from 'remote/api-client';
import Actions from 'actions';
import { NotificationLevel } from 'state/notifications';
import { Database } from 'entities/database';

const list = (dbId: string, ignore?: IgnoreErrors) =>
  ApiClient.get(`/databases/${dbId}/snapshots?status=ALL`)
    .ignoreErrors(ignore)
    .promise();

const getUrl = (dbId: string, snapshotId: string) =>
  ApiClient.get(`/databases/${dbId}/snapshots/${snapshotId}/url`).promise();

const create = (dbId: string) => ApiClient.post(`/databases/${dbId}/snapshots`).promise();

const restore = (dbId: string, snapshotId: string): Promise<Database> =>
  ApiClient.post(`/databases/${dbId}/restore`)
    .issue({ SnapshotId: snapshotId })
    .catch(err => {
      const errStatus = err && err.response ? err.response.status : undefined;
      if (errStatus === 404) {
        Actions.notifications.show({
          level: NotificationLevel.ERROR,
          message:
            "Our system is experiencing problems right now and we're " +
            'unable to restore from the snapshot you requested. Please try ' +
            'again later or contact support if the problem persists.',
        });
      } else {
        throw err;
      }
    });

export default {
  list,
  create,
  restore,
  getUrl,
};
