import Actions from 'actions';
import { Alert, Button, Form, FormInput, LoadingSpinner } from 'components/foundation';
import Neo4jLogo from 'ui/icons/aura-colored.svg';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'store';
import { parse } from 'query-string';
import OrganizationResources from 'remote/resources/organizations';

export const OrganizationLookupPage = () => {
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const queryParams = parse(search);

  const authError = queryParams.error_description as string;
  const queryId = queryParams.org_id;

  let initialId = '';
  if (queryId && (typeof queryId === 'string' || queryId instanceof String)) {
    initialId = queryId as string;
  }

  // Can be a uuid or an sso id (auth0 org id)
  const [orgId, setOrgId] = useState(initialId);
  const [lookupError, setLookupError] = useState<string | null>(null);
  const handleOrgIdChange = e => {
    setOrgId(e.target.value);
  };

  const handleSubmit = async () => {
    setLookupError(null);
    let ssoId = orgId;
    if (!orgId.startsWith('org_')) {
      try {
        const result = await OrganizationResources.getSsoId(orgId);
        ssoId = result.ssoId;
      } catch (e) {
        if (e.response.status === 404) {
          setLookupError(`Organization ${orgId} cannot be found or is missing an SSO ID`);
        } else {
          setLookupError(
            `An error occurred when trying to lookup SSO ID for this organization: ${e}`
          );
        }

        setLoading(false);
        return;
      }
    }

    Actions.auth.login({
      organization: ssoId,
      redirect_uri: location.origin,
    });
  };

  useEffect(() => {
    if (!authError && initialId && queryParams.autosubmit === 'true') {
      handleSubmit();
    } else {
      // Hide loading when not autosubmitting
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingSpinner expand size="large" />;
  }

  const error = authError || lookupError;

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-bg-palette-neutral-bg-weak tw-rounded-md tw-mt-12 tw-p-8 tw-flex tw-flex-col tw-gap-4 tw-max-w-[500px] tw-flex-1 tw-drop-shadow-md">
        <Neo4jLogo />
        <h5>Login with organization SSO</h5>
        <Form onSubmit={handleSubmit}>
          <FormInput
            value={orgId}
            onChange={handleOrgIdChange}
            placeholder="Enter Organization ID"
            label="Organization ID"
            fluid
            data-testid="organization-id-input"
          />
        </Form>
        {error && <Alert type="danger" title="An error occurred" description={error} />}
        <div className="tw-flex tw-justify-end tw-gap-2">
          <Button as="a" href="/" fill="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} data-testid="organization-submit-button" disabled={!orgId}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
