import { useNotify } from 'state/notifications';
import { NotificationLevel } from 'state/notifications/types';
import { getActionsForNotification } from './tenant-notification-actions';
import { useTenantNotifications, dismissTenantNotification } from './dismissed-notifications-store';
import { TenantNotificationDate } from 'entities/tenant';
import { format } from 'date-fns';

const substituteDates = (str: string, dates: TenantNotificationDate[]): string =>
  dates.reduce(
    (acc, { name, format: f, timestamp }) =>
      acc.replace(`{{${name}}}`, format(new Date(timestamp), f)),
    str
  );

export interface TenantNotificationsProps {
  openExtendTrial: () => void;
}

const TenantNotifiations = ({ openExtendTrial }: TenantNotificationsProps) => {
  const notify = useNotify();
  const tenantNotifications = useTenantNotifications();

  for (const notification of tenantNotifications) {
    const level = notification.level in notify ? notification.level : NotificationLevel.INFO;
    notify[level](substituteDates(notification.message, notification.dates), {
      title: substituteDates(notification.title, notification.dates),
      expiresAfter: null,
      onClose: () => dismissTenantNotification(notification),
      actions: getActionsForNotification(notification, { openExtendTrial }),
    });
  }

  return null;
};

export default TenantNotifiations;
