import React from 'react';
import Icon, { IconNames, IconProps } from 'ui/icons';
import { Button as NDLButton, ButtonProps as NDLProps, LoadingSpinner } from '@neo4j-ndl/react';
import cx from 'classnames';
import './button.css';

export interface ButtonProps extends Omit<NDLProps, 'children'> {
  children?: NDLProps['children'];
  iconName?: IconNames;
  iconProps?: Omit<IconProps, 'name'>;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size,
      loading,
      disabled,
      className = '',
      iconName = null,
      iconProps = {},
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const { className: iconClassName = '', ...iconRest } = iconProps;

    const iconClasses = cx('button-icon', {
      [iconClassName]: !!iconClassName,
      disabled,
      'tw-mr-1': !!children,
    });

    const interactable = !disabled && !loading;

    // Icon click will propagate and skip the button
    // on click handler when disabled, so we need
    // to swallow them here
    const disabledIconClick = e => {
      e.stopPropagation();
      e.preventDefault();
    };

    const icon = iconName ? (
      <Icon
        role="button"
        name={iconName}
        className={iconClasses}
        width="14"
        height="14"
        {...iconRest}
        {...(!interactable && { onClick: disabledIconClick })}
      />
    ) : null;

    const classes = cx('console-button', className);
    const loaderClasses = cx('console-button-loader', { 'tw-mr-1': !!children });

    return (
      <NDLButton {...rest} size={size} className={classes} ref={ref} disabled={!interactable}>
        {!loading && icon}
        {loading && <LoadingSpinner size="small" className={loaderClasses} />}
        {/*
          Button children must have span, otherwise errors
          occur when users use google translate as it modifies the DOM

          https://stackoverflow.com/questions/50784302/uncaught-notfounderror-failed-to-execute-insertbefore-on-node
          https://github.com/facebook/react/issues/11538#issuecomment-390386520
          https://neo4j-inc.sentry.io/issues/4201856474/
        */}
        <span>{children}</span>
      </NDLButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
