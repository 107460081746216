import ApiClient from 'remote/api-client';

const get = () =>
  ApiClient.get('/user-details')
    .ignore(403)
    .promise();

const update = (newValue: {
  AcceptedTermsAndConditions?: string;
  WorkspaceEnabled?: boolean;
  MarketingPreference?: boolean;
}) => ApiClient.patch('/user-details').issue(newValue);

export default { get, update };
