import React, { useMemo } from 'react';
import {
  Label,
  StatusIndicator,
  StatusType,
  createColumnHelper,
  useDefaultTable,
  StickyActionsDataGrid,
  LoadingSpinner,
} from 'foundation';
import { Snapshot, SnapshotStatus } from 'types/snapshot';
import { SessionState } from 'state/session-store';
import { Database } from 'entities/database';
import { format, isValid, parseISO } from 'date-fns';
import RestoreSnapshot from 'components/application/restore-snapshot';
import { SnapshotActions } from './snapshot-actions';
import { filterAndSortSnapshots } from './snapshot-preparation';

type SnapshotsTableProps = {
  database: Database;
  snapshots?: Snapshot[];
  onCreateFromBackupClick: (snapshotId: string) => any;
  session: SessionState;
};

const humanize = (word: string) => {
  switch (word) {
    case 'InProgress':
      return 'In Progress';
    case 'AdHoc':
      return 'On Demand';
    default:
      return word;
  }
};

const formatTimestamp = (timestamp, timeFormat) => {
  const ts = parseISO(timestamp);
  if (isValid(ts)) {
    const date = format(ts, timeFormat);
    return date;
  }
  return null;
};

const statusToStatusType = (status: string): StatusType => {
  switch (status) {
    case 'In Progress':
    case 'Pending':
      return 'unknown';
    case 'Failed':
      return 'danger';
    default:
      return 'success';
  }
};

const helper = createColumnHelper<Snapshot>();

const SnapshotsTable = ({
  database,
  snapshots,
  onCreateFromBackupClick,
  session,
}: SnapshotsTableProps) => {
  const sortedSnapshots = useMemo(() => filterAndSortSnapshots(snapshots ?? []), [
    snapshots,
    database,
  ]);
  const columns = useMemo(
    () => [
      helper.accessor('Timestamp', {
        id: 'Date',
        header: () => 'Date',
        cell: cx => {
          const { Status } = cx.row.original;
          return Status === SnapshotStatus.PENDING ? (
            <LoadingSpinner size="small" />
          ) : (
            formatTimestamp(cx.getValue(), 'do MMM')
          );
        },
      }),
      helper.accessor('Timestamp', {
        id: 'Time',
        header: () => 'Time',
        cell: cx => {
          const { Status } = cx.row.original;
          return Status === SnapshotStatus.PENDING ? (
            <LoadingSpinner size="small" />
          ) : (
            formatTimestamp(cx.getValue(), 'HH:mm zzz')
          );
        },
      }),
      helper.accessor('Status', {
        id: 'Status',
        cell: cx => {
          const status = humanize(cx.getValue());
          let statusType = statusToStatusType(status);
          return (
            <div className="n-label">
              <StatusIndicator type={statusType} />
              {status}
            </div>
          );
        },
      }),
      helper.accessor('Profile', {
        id: 'Type',
        header: () => 'Type',
        cell: cx => {
          return <Label fill="outlined">{humanize(cx.getValue())}</Label>;
        },
      }),
      helper.display({
        id: 'snapshot-actions',
        cell: cx => {
          const { Status: status, Timestamp, SnapshotId, Type, Exportable } = cx.row.original;
          const renderOptions =
            Exportable && status !== SnapshotStatus.IN_PROGRESS && status !== SnapshotStatus.FAILED;
          const fileName = `${database.DbId}-${formatTimestamp(Timestamp, 'ddMMM-HH:mmx')}.dump`;

          switch (status) {
            case SnapshotStatus.PENDING:
            case SnapshotStatus.IN_PROGRESS:
              return <LoadingSpinner size="small" className="tw-mr-3" />;
            case SnapshotStatus.FAILED:
              return null;
            default:
              return (
                <div data-testid={SnapshotId}>
                  {renderOptions && (
                    <SnapshotActions
                      snapshotId={SnapshotId}
                      planType={session.planType}
                      onCreateFromBackupClick={onCreateFromBackupClick}
                      fileName={fileName}
                      database={database}
                    />
                  )}
                  {status === SnapshotStatus.COMPLETED && (
                    <RestoreSnapshot
                      snapshotId={SnapshotId}
                      profile={Type}
                      database={database}
                      timestamp={Timestamp}
                    />
                  )}
                </div>
              );
          }
        },
        meta: {
          isStickyAction: true,
        },
        size: 110,
      }),
    ],
    []
  );

  const snapShotsTable = useDefaultTable({
    data: sortedSnapshots,
    columns,
  });

  const hasSnapshots = sortedSnapshots.length > 0;
  const emptySnapshotsMessage = (
    <div>
      <p>No snapshots available.</p>
    </div>
  );

  return (
    <div>
      {hasSnapshots ? (
        <StickyActionsDataGrid tableInstance={snapShotsTable} />
      ) : (
        emptySnapshotsMessage
      )}
    </div>
  );
};

export default SnapshotsTable;
