import { Alert, Dialog, Link } from 'foundation';
import React, { useState } from 'react';
import StripeForm from 'application/billing-form';
import { useSession } from 'store';
import OrganizationResources from 'remote/resources/organizations';

export interface ExtendTrialModalProps {
  open: boolean;
  onClose: () => void;
}

const ExtendTrialModal = ({ open, onClose }: ExtendTrialModalProps) => {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const session = useSession();

  const handleSuccess = async () => {
    try {
      await OrganizationResources.extendProTrial(session.tenant.organizationId);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 2000);
    } catch {
      setErrorMessage('There was a problem extending your trial. Please contact support.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} size="large">
      <div className="tw-mx-auto console-w-fit">
        <div className="tw-w-full tw-mx-auto tw-my-0 console-max-w-medium">
          <StripeForm
            onSuccess={handleSuccess}
            onCancel={onClose}
            session={session}
            submitButtonText="Extend Trial"
            cancelButtonText="Cancel"
            formTitle="Extend trial"
            subHeaderComponent={
              <p>Add your credit card info to extend the trial for an additional 7 days.</p>
            }
            subFormComponent={<ExtendTrialSubHeader />}
            isTrialExtension
          />
          {success && (
            <Alert
              closeable
              onClose={() => setSuccess(null)}
              type="success"
              title="Success"
              description="Trial extended"
              className="tw-mt-2"
              data-testid="trial-extension-success"
            />
          )}
          {errorMessage && (
            <Alert
              closeable
              onClose={() => {
                setSuccess(null);
                setErrorMessage(null);
              }}
              type="danger"
              title="Error"
              description={errorMessage}
              className="tw-mt-2"
              data-testid="trial-extension-error"
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export const ExtendTrialSubHeader = () => {
  return (
    <div>
      <Alert type="info" icon id="extend-trial-alert" className="tw-my-5">
        After your trial extension, the instance will be charged according to our{' '}
        <Link href={'https://neo4j.com/pricing/'}>pricing</Link>.
      </Alert>
    </div>
  );
};

export default ExtendTrialModal;
