import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';

import Actions from 'actions';

import { Button } from 'foundation';
import { usePermissions, useSession } from 'store/hooks';
import { Invite } from 'types/invite';
import { Action, TenantUser, RoleName, getTenantRoleOptions } from 'types/user';
import UsersTable from './users';
import EditUserModal from './modals/edit-user-modal';
import DeleteUserModal from './modals/delete-user-modal';
import InviteUserModal from './modals/invite-user-modal';
import InviteRevokeModal from './modals/invite-revoke-modal';
import { PermissionTip } from 'application/permission-tip';

import './user-management.css';
import location from 'browser/location';
import userDetailsActions from 'actions/user-details-actions';
import { useNotify } from 'state/notifications';
import { Tier } from 'entities/database';
import { Tenant, tierDisplayName } from 'entities/tenant';

interface UserManagementProps {
  tenant: string;
  invites: Invite[];
  users: TenantUser[];
  onRefetchData: () => void;
}

export const UserManagement = ({
  tenant: tenantId,
  invites,
  users,
  onRefetchData,
}: UserManagementProps) => {
  const { allow } = usePermissions();
  const tracking = useTracking();
  const notify = useNotify();
  const session = useSession();

  const [editedUser, setEditedUser] = useState(null);
  const [editInProgress, setEditInProgress] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState(null);

  const [userBeingDeleted, setUserBeingDeleted] = useState(null);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [deletionErrorMessage, setDeletionErrorMessage] = useState(null);

  const [inviteBeingRevoked, setInviteBeingRevoked] = useState(null);
  const [revokeInviteError, setRevokeErrorMessage] = useState(null);
  const [revokeInProgress, setRevokeInProgress] = useState(false);

  const [inviteUserOpen, setInviteUserOpen] = useState(false);
  const [inviteUserError, setInviteUserError] = useState(null);
  const [inviteInProgress, setInviteInProgress] = useState(false);
  const roleOptions = getTenantRoleOptions(session.tenant);

  const handleUserRoleChange = async (user: TenantUser, newRoleName: RoleName) => {
    setEditInProgress(true);
    setEditErrorMessage(null);

    try {
      await Actions.namespaces.changeMemberRole(user, newRoleName);

      tracking.trackEvent({
        action: 'edit_tenant_member',
        properties: { newRole: newRoleName },
      });
      setEditedUser(null);
      if (user.UserId === session.userId) {
        // We refetch the user details in order to update the
        // users permission in the tenant. This will trigger a re-render of
        // the parent component rendering the component without invites.
        userDetailsActions
          .refetch()
          .then(() => onRefetchData())
          .catch(() => {
            notify.error('Failed to refetch permissions. Please reload the page');
          });
      } else {
        onRefetchData();
      }
    } catch (ex) {
      if (ex.response.status === 403) {
        setEditErrorMessage('You do not have permission to perform that action.');
      } else if (ex.reason === 'last-admin-leaving-tenant') {
        // This will only apply to the last user trying to remove themselves as admin.
        setEditErrorMessage(
          'A project must have at least one Admin. You cannot remove yourself as an Admin until you have assigned another user as an Admin.'
        );
      } else {
        setEditErrorMessage(ex.responseMessage);
      }
    }
    setEditInProgress(false);
  };

  const handleRemoveUserFromTenant = (user: TenantUser) => {
    setDeletionInProgress(true);
    setDeletionErrorMessage(null);
    Actions.namespaces
      .removeTenantMember(user)
      .then(() => {
        tracking.trackEvent({
          action: 'remove_tenant_member',
        });
        if (user.UserId === session.userId) {
          location.reload();
        } else {
          setUserBeingDeleted(null);
          onRefetchData();
        }
      })
      .catch(ex => {
        if (ex.response.status === 403) {
          setDeletionErrorMessage('You do not have permission to perform that action.');
        } else if (ex.reason === 'last-admin-leaving-tenant') {
          // This will only apply to the last user trying to leave the tenant.
          setDeletionErrorMessage(
            'A project must have at least one Admin. You cannot remove yourself as an Admin until you have assigned another user as an Admin.'
          );
        } else if (ex.reason === 'personal-namespace-cannot-be-removed') {
          setDeletionErrorMessage('This user cannot be removed from their primary project.');
        } else {
          setDeletionErrorMessage(ex.responseMessage);
        }
      })
      .finally(() => {
        setDeletionInProgress(false);
      });
  };

  const handleInviteUser = (tenant: Tenant, email: string, roleName: RoleName) => {
    setInviteInProgress(true);
    setInviteUserError(null);
    Actions.invites
      .inviteUserToTenant(tenant.id, email, [roleName])
      .then(() => {
        tracking.trackEvent({
          action: 'invite_tenant_member',
          properties: { role: roleName },
        });
        setInviteUserOpen(false);
        onRefetchData();
      })
      .catch(ex => {
        if (ex.response?.status === 403) {
          setInviteUserError('You do not have permission to perform that action.');
        } else if (ex.reason === 'duplicate-invite') {
          setInviteUserError(`An invite for ${email} already exists.`);
        } else if (ex.reason === 'user-already-in-namespace') {
          setInviteUserError(`User ${email} is already a member of this project.`);
        } else if (ex.reason === 'downgrade-invite') {
          setInviteUserError(
            `User ${email} has an existing Aura ${tierDisplayName(
              tenant,
              Tier.ENTERPRISE
            )} account. Contact support to transfer accounts between ${tierDisplayName(
              tenant,
              Tier.ENTERPRISE
            )} and Professional consoles.`
          );
        } else {
          setInviteUserError(ex.responseMessage);
        }
      })
      .finally(() => {
        setInviteInProgress(false);
      });
  };

  const handleRevokeInvite = (invite: Invite) => {
    setRevokeInProgress(true);
    setRevokeErrorMessage(null);
    Actions.invites
      .revokeInvite(invite.InviteId)
      .then(() => {
        tracking.trackEvent({
          action: 'revoke_pending_invite',
        });
        setInviteBeingRevoked(null);
        onRefetchData();
      })
      .catch(ex => {
        if (ex.response.status === 403) {
          setRevokeErrorMessage('You do not have permission to perform that action.');
        } else {
          setRevokeErrorMessage(ex.responseMessage);
        }
      })
      .finally(() => {
        setRevokeInProgress(false);
      });
  };

  const allowInvite = allow(Action.CREATE, `namespaces/${tenantId}/invites`);
  return (
    <div className="user-management-wrapper">
      <section>
        <h5>User Management</h5>
        <div>
          <PermissionTip hasPermission={allowInvite}>
            <Button disabled={!allowInvite} onClick={() => setInviteUserOpen(true)}>
              Invite user
            </Button>
          </PermissionTip>
        </div>
      </section>
      <UsersTable
        invitesList={invites}
        usersList={users}
        onEditUserClick={member => setEditedUser(member)}
        onDeleteUserClick={member => setUserBeingDeleted(member)}
        onRevokeInviteClick={member => setInviteBeingRevoked(member)}
      />
      {editedUser && (
        <EditUserModal
          open={editedUser !== null}
          user={editedUser}
          onClose={() => {
            setEditedUser(null);
            setEditErrorMessage(null);
            setEditInProgress(false);
          }}
          onEdit={handleUserRoleChange}
          editInProgress={editInProgress}
          errorMessage={editErrorMessage}
          roleOptions={roleOptions}
        />
      )}
      {userBeingDeleted && (
        <DeleteUserModal
          open={userBeingDeleted !== null}
          user={userBeingDeleted}
          onClose={() => {
            setUserBeingDeleted(null);
            setDeletionErrorMessage(null);
            setDeletionInProgress(false);
          }}
          onDelete={handleRemoveUserFromTenant}
          deleteInProgress={deletionInProgress}
          errorMessage={deletionErrorMessage}
        />
      )}
      <InviteUserModal
        open={inviteUserOpen}
        onClose={() => {
          setInviteUserOpen(false);
          setInviteUserError(null);
          setInviteInProgress(false);
        }}
        onInvite={handleInviteUser}
        inviteInProgress={inviteInProgress}
        errorMessage={inviteUserError}
      />
      {inviteBeingRevoked && (
        <InviteRevokeModal
          open={inviteBeingRevoked !== null}
          invite={inviteBeingRevoked}
          onClose={() => {
            setInviteBeingRevoked(null);
            setRevokeErrorMessage(null);
            setRevokeInProgress(false);
          }}
          onRevoke={handleRevokeInvite}
          revokeInProgress={revokeInProgress}
          errorMessage={revokeInviteError}
        />
      )}
    </div>
  );
};

export default track()(UserManagement);
