import { SyntheticEvent } from 'react';
import track, { useTracking } from 'react-tracking';
import { isNeoAppsAccessable, Database, Tier } from 'entities/database';

import { SessionState } from 'state/session-store';
import { useSession } from 'store';

const buildHostedBrowserURL = (database: Database, session: SessionState) => {
  const dbHost = new URL(database.BrowserUrl).host;
  const { sub: auth0SubjectId } = session;
  const url = new URL('https://browser.neo4j.io/');
  url.searchParams.set('connectURL', `neo4j+s://neo4j@${dbHost}/`);
  url.searchParams.set('ntid', auth0SubjectId);
  return url;
};

const hostedBrowserEnabledMap: Record<Tier, boolean> = {
  [Tier.FREE]: true,
  [Tier.PROFESSIONAL]: true,
  [Tier.MTE]: true,
  [Tier.GDS]: true,
  [Tier.ENTERPRISE]: false,
  [Tier.AURA_DSE]: false,
};

export const buildBrowserUrl = (database: Database, session: SessionState): string | null => {
  if (!database.BrowserUrl) {
    return null;
  }

  const url = hostedBrowserEnabledMap[database.Tier]
    ? buildHostedBrowserURL(database, session)
    : new URL(database.BrowserUrl);

  return url.toString();
};

export interface OpenWithBrowserChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  href: string;
}

export interface OpenWithBrowserProps {
  database: Database;
  children: (args: OpenWithBrowserChildArgs) => JSX.Element;
}

export const OpenWithBrowser = track()(({ database, children }: OpenWithBrowserProps) => {
  const tracking = useTracking();
  const session = useSession();

  const handleBrowserClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    // @Todo: remove the `open_db` event after AuraDS GA and Appcues triggers has been refactored
    tracking.trackEvent({
      action: 'open_db',
      properties: { event_label: 'neo4j-browser' },
    });

    tracking.trackEvent({
      action: 'open_db_browser',
    });
  };

  const browserUrl = buildBrowserUrl(database, session);

  return children({
    onClick: handleBrowserClick,
    href: browserUrl,
    disabled: !browserUrl || !isNeoAppsAccessable(database),
  });
});
