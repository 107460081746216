import React, { useState } from 'react';
import UsersResource from 'remote/resources/users';
import { Button, Alert, Dialog } from 'foundation';
import { User } from 'types/user';

interface Props {
  user: User;
  open: boolean;
  onClose: () => any;
}

export const TerminateUserModal = ({ user, open, onClose }: Props) => {
  const [error, setError] = useState<Error>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      if (user.Terminated) {
        await UsersResource.unterminateUser(user.UserId);
      } else {
        await UsersResource.terminateUser(user.UserId);
      }
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header data-testid="terminate-user-auth-header">Are you sure?</Dialog.Header>
      <Dialog.Content data-testid="terminate-user-auth-content">
        <Dialog.Description>
          This will {user.Terminated ? 'unterminate' : 'terminate'} the user.
        </Dialog.Description>
        {error && (
          <Alert type="danger" className="tw-mt-2">
            {String(error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="terminate-user-auth-success" className="tw-mt-2">
            User was succesfully {user.Terminated ? 'unterminated' : 'terminated'}.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          data-testid="terminate-user-auth-cancel"
          onClick={onClose}
          fill="outlined"
          color="neutral"
        >
          Cancel
        </Button>

        <Button
          data-testid="terminate-user-button"
          color={user.Terminated ? 'primary' : 'danger'}
          onClick={handleSubmit}
          disabled={loading || success}
        >
          {user.Terminated ? 'Unterminate user' : 'Terminate user'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
