import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import InternalSsoConfigResource from 'remote/resources/internal-sso-configs';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import Actions from 'actions';

const LookupSsoConfig = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleFindConfigById = async () => {
    if (!query) return;
    setLoading(true);
    setError(null);

    try {
      const config = await InternalSsoConfigResource.getBySsoConfigId(query.trim().toLowerCase());

      Actions.navigate.push({ hash: `#admin/sso-configs/${config.ssoConfigId}` });
    } catch (err) {
      if (err instanceof ApiClientRequestError && err.response.status === 404) {
        setError(`No SSO Config found with name: ${query}`);
      } else {
        setError(String(err));
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleFindConfigById}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          placeholder="SSO ID"
          aria-label="SSO config id"
          data-testid="lookup-sso-config-input"
          name="sso-id"
        />
        <Button
          className="tw-mt-4"
          type="submit"
          color="primary"
          loading={loading}
          disabled={!query || loading}
          data-testid="lookup-sso-config-button"
        >
          Find by ID
        </Button>
        {error && (
          <Alert type="danger" data-testid="lookup-sso-config-error-message" className="tw-mt-2">
            {error}
          </Alert>
        )}
      </Form>
    </>
  );
};

export default LookupSsoConfig;
