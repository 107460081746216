import amex from './amex.png';
import diners from './dinersclub.png';
import discover from './discover.png';
import jcb from './jcb.png';
import mastercard from './mastercard.png';
import unknown from './unknown.png';
import unionpay from './unionpay.png';
import visa from './visa.png';
import React from 'react';
import cn from 'classnames';

const getBrand = (brand: string) => {
  /* Supported brands from https://stripe.com/docs/api/cards/object#card_object-brand */

  let brandName = brand
    .toLowerCase()
    .split(' ')
    .join('-');

  let logo;

  switch (brandName) {
    case 'american-express':
      logo = amex;
      break;
    case 'diners-club':
      logo = diners;
      break;
    case 'discover':
      logo = discover;
      break;
    case 'jcb':
      logo = jcb;
      break;
    case 'mastercard':
      logo = mastercard;
      break;
    case 'unionpay':
      logo = unionpay;
      break;
    case 'visa':
      logo = visa;
      break;
    default:
      logo = unknown;
      brandName = 'unknown';
      break;
  }

  return {
    logo,
    brandName,
  };
};

const CardBrand = ({ brand = 'unknown', disabled = false }) => {
  const { logo, brandName } = getBrand(brand);
  return (
    <div
      className={cn('tw-align-middle', { 'tw-opacity-30': disabled })}
      style={{ height: '1.3em', width: '2em' }}
      data-testid={`card-${brandName}`}
    >
      <img className="tw-w-full tw-h-full" src={logo} />
    </div>
  );
};

export default CardBrand;
