import React from 'react';
import classnames from 'classnames';
import './list.css';

interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  bulleted?: boolean;
  ordered?: boolean;
}

export const List = ({ children, ordered, bulleted, className, ...rest }: ListProps) => {
  const classes = classnames(className, 'console-list', {
    'console-list-bulleted': bulleted,
    'console-list-ordered': ordered,
  });
  const Component = ordered ? 'ol' : 'ul';

  return (
    <Component {...rest} className={classes}>
      {children}
    </Component>
  );
};

const ListItem = ({ children, ...rest }) => {
  return <li {...rest}>{children}</li>;
};

const ListDescription = ({ children, className = '', ...rest }) => {
  const classes = classnames(className, 'console-list-description');
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

List.Item = ListItem;
List.Description = ListDescription;
