import { Page } from 'foundation/page';
import { Link } from 'foundation';
import React from 'react';

const LoginErrorPage = ({ errorCode }) => (
  <Page>
    <h1>Login Failed</h1>

    {suggestedWorkarounds(errorCode)}

    <p>
      If this doesn&apos;t solve your problem, then please{' '}
      <a href="https://support.neo4j.com/s/" target="_blank" rel="noopener noreferrer">
        contact our support team
      </a>
      .
    </p>
  </Page>
);

const suggestedWorkarounds = errorCode => {
  switch (errorCode) {
    case 'expired':
      return [
        <p key="1">
          Your session timed out. Please <Link href="/">log in</Link> again.
        </p>,
        <p key="2">
          If your session is timing out unreasonably quickly, please check that your system clock is
          accurate.
        </p>,
      ];

    case 'clock-sync':
      return [
        <p key="1">
          We could not log you in for security reasons because your system clock settings appear to
          be incorrect.
        </p>,
        <p key="2">
          Please synchronise your clock to an accurate time source (such as NTP) and{' '}
          <Link href="/">log in</Link> again.
        </p>,
      ];

    case 'reauthentication-required':
      return <p>Your session timed out. Please log in again.</p>;

    case 'email-in-use':
      return (
        <p>
          It looks like you are already registered. Please log in using the same method you used
          when you first registered.
        </p>
      );

    case 'partner-enterprise-session':
      return (
        <p>
          It looks like you have accessed the console using a partner enterprise portal. Please
          login using your regular credentials
        </p>
      );

    default:
      return [
        <p key="1">
          A problem occurred while logging in. Please try <Link href="/">logging in</Link> again.
        </p>,
        <p key="2">If the problem persists, try clearing the browser cache.</p>,
      ];
  }
};

export default LoginErrorPage;
