import { Button, CodeBlock, Dialog } from '@neo4j-ndl/react';
import globals from 'browser/globals';
import Icon from 'components/ui/icons';
import React, { useState } from 'react';
import { EncryptionKey } from 'types/encryption-keys';
import { Tip } from 'foundation';

interface AwsInstructionsProps {
  encryptionKey: EncryptionKey;
  onClose: () => void;
}

export const AwsCmekPermissionsInstructions = ({
  encryptionKey,
  onClose,
}: AwsInstructionsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const keyPolicy = `{
    "Sid": "Allow access for Neo4j",
    "Effect": "Allow",
    "Principal": {
        "AWS": "*"
    },
    "Action": [
        "kms:Encrypt",
        "kms:Decrypt",
        "kms:ReEncrypt*",
        "kms:GenerateDataKey*",
        "kms:DescribeKey",
        "kms:CreateGrant",
        "kms:ListGrants",
        "kms:RevokeGrant"
    ],
    "Resource": "*",
    "Condition": {
        "StringEquals": {
            "kms:CallerAccount": "${encryptionKey.awsProperties?.permissions?.accountId}"
        }
    }
}`;

  return (
    <Dialog
      open={true}
      modalProps={{
        'data-testid': 'get-cmek-encryption-key-dialog',
      }}
      onClose={onClose}
    >
      <Dialog.Header>Customer Managed Key Permissions</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        <div>
          <p>
            In order for Neo4j components to use the provided key, you will have to add the
            following KMS policy to this key.
          </p>
          <ol className="tw-list-decimal tw-pl-6 tw-my-6">
            <li>In AWS, go to KMS and select the key you want to add to Aura.</li>
            <li>
              Then under &quot;Key policy&quot; paste the JSON snippet into the list of policies.
            </li>
          </ol>
          <p>Note that you should append the policy and not replace existing ones.</p>
          <div className="tw-my-6">
            <CodeBlock
              style={{ overflow: 'visible' }}
              actions={[
                {
                  'aria-label': 'copy',
                  title: 'copy',
                  children: (
                    <Tip isOpen={open}>
                      <Tip.Trigger hasButtonWrapper={true} style={{ display: 'flex' }}>
                        <Icon title={'Copy to clipboard'} name={'ClipboardIconOutline'} />
                      </Tip.Trigger>
                      <Tip.Content isPortaled={false} style={{ width: '150px' }}>
                        Copied to Clipboard
                      </Tip.Content>
                    </Tip>
                  ),
                  onClick: () => {
                    globals.window.navigator.clipboard.writeText(keyPolicy);
                    setOpen(true);

                    setTimeout(() => {
                      setOpen(false);
                    }, 1000);
                  },
                },
              ]}
              code={keyPolicy}
              language="json"
            />
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Actions className="tw-justify-between">
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Close
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
