import { useMemo } from 'react';
import { InstantiatedNotificationStore } from './store';
import { Notification, NotificationAction, NotificationLevel } from './types';

interface NotifyOptions {
  title?: string;
  expiresAfter?: number | null; // ms
  onClose?: () => void;
  actions?: NotificationAction[];
}

const DEFAULT_EXPIRATION = 6000;

type PartialNotification = Omit<Notification, 'id' | 'expiresAfter'> & {
  expiresAfter?: Notification['expiresAfter'];
};

const defaultHandler = (notificationPartial: PartialNotification) => {
  const {
    message,
    level,
    expiresAfter = DEFAULT_EXPIRATION,
    title,
    onClose,
    actions,
  } = notificationPartial;

  InstantiatedNotificationStore.add({
    message,
    level,
    expiresAfter,
    title,
    onClose,
    actions,
  });
};

export const useNotify = () => {
  return useMemo(
    () => ({
      success: (message: string, options: NotifyOptions = {}) =>
        defaultHandler({ message, level: NotificationLevel.SUCCESS, ...options }),
      info: (message: string, options: NotifyOptions = {}) =>
        defaultHandler({ message, level: NotificationLevel.INFO, ...options }),
      error: (message: string, options: NotifyOptions = {}) =>
        defaultHandler({ message, level: NotificationLevel.ERROR, ...options }),
      warning: (message: string, options: NotifyOptions = {}) =>
        defaultHandler({ message, level: NotificationLevel.WARNING, ...options }),
    }),
    []
  );
};
