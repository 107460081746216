import DatabasesResource from 'remote/resources/databases';
import { DatabasesStore } from 'state';
import logger from 'logger';

const updateDatabaseName = (dbid: string, Name: string) => {
  const name = Name.trim();
  return DatabasesResource.update(dbid, { Name: name })
    .then(updatedDatabase => {
      DatabasesStore.upsert(dbid, updatedDatabase);
      logger.debug(`Database ${dbid} name updated successfully`);
    })
    .catch(error => {
      logger.debug('Failed to update DB name due to error:', error);
    });
};

export default updateDatabaseName;
