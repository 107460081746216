import DatabasesResource from 'remote/resources/databases';
import remote from 'remote/api-client';
import { DumpFileUploadHandlers } from 'pages/import/upload-handlers';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

const uploadDump = (dbid: string, file: File, handlers: DumpFileUploadHandlers) =>
  DatabasesResource.getSignedUrlObject(dbid, file.size, file.name.split('.').pop())
    .then(result => {
      const onSuccess = () =>
        DatabasesResource.startImportForCloudStorageObject(dbid, result.CloudStorageObject)
          .then(handlers.onUploadProcessingComplete)
          .catch(handlers.onUploadProcessingError);

      remote.xhrUpload(result.SignedUrl, file, handlers, onSuccess);
    })
    .catch((e: ApiClientRequestError) => {
      handlers.onError({
        message: e?.responseMessage || e?.message,
        reason: e?.reason,
      });
    });

export default uploadDump;
