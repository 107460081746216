import React, { useState, useEffect } from 'react';
import { Page } from 'foundation/page';
import Actions from 'actions';
import { TransferInstanceModal } from './transfer-instance-modal';
import { scrollToFirstError } from 'components/utils';
import { Alert } from 'foundation/alert';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import TransferInstanceForm, {
  TransferInstanceData,
  TransferType,
  validate as validateForm,
} from './transfer-instance-form';
export { TransferType } from './transfer-instance-form';
import { Button } from 'foundation/button';

const defaults = (): TransferInstanceData => {
  return {
    targetDbid: '',
    targetEmail: '',
    targetUserId: '',
    targetTenantId: '',
  };
};

interface TransferInstancePageProps {
  transferType?: TransferType;
}

const TransferInstancePage = ({
  transferType = TransferType.TRANSFER_TO_USER,
}: TransferInstancePageProps) => {
  const [error, setError] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [validation, setValidation] = useState(null);
  const [data, setData] = useState<TransferInstanceData>(defaults());

  const permission = usePermissions();
  const canTransferFrom = permission.allow(Action.TRANSFER, 'namespaces/*/databases/*');
  const canTransferTo = permission.allow(Action.TRANSFER, 'namespaces/*/databases');
  const isAuthorized = canTransferFrom && canTransferTo;

  const clearForm = () => {
    setData(defaults());
  };

  const resetState = () => {
    setError(null);
    setIsSuccessful(false);
    setLoading(false);
    setOpenModal(false);
    setValidation(null);
    clearForm();
  };

  // When TransferType prop changes, reset any state that may be left behind
  // from the other transfer page.
  useEffect(resetState, [transferType]);

  const validateDataAndSetError = () => {
    const invalid = validateForm(data);
    setValidation(invalid);
    if (invalid) {
      setLoading(false);
      scrollToFirstError();
      return invalid;
    }
  };

  const onTransferDbSubmit = () => {
    setLoading(true);
    setError(null);
    setIsSuccessful(false);
    const invalid = validateDataAndSetError();
    if (invalid) return;
    setOpenModal(false);
    Actions.databases
      .transferDatabase(data)
      .then(() => {
        setIsSuccessful(true);
        setLoading(false);
        setOpenModal(false);
        setValidation(null);
        clearForm();
      })
      .catch(e => {
        setLoading(false);
        setError(e.responseMessage);
      });
  };

  const onOpenModal = () => {
    const valid = !validateDataAndSetError();
    if (valid) setOpenModal(true);
  };
  const onCloseModal = () => setOpenModal(false);

  return (
    <Page fullWidth>
      {isAuthorized ? (
        <>
          <h5>
            Transfer Instance to{' '}
            {transferType === TransferType.TRANSFER_TO_USER ? 'User' : 'Project'}
          </h5>
          {transferType === TransferType.TRANSFER_TO_USER && (
            <Alert
              className="tw-mt-4 tw-mb-8"
              title="DEPRECATED"
              description="Transferring an Instance to a user only transfers Instance ownership within that project. To transfer an Instance between projects use the 'Transfer Instance to Project' page"
              actions={[
                { href: '#admin/transfer-to-tenant', label: 'Transfer Instance to Project' },
              ]}
              type="warning"
            />
          )}
          <TransferInstanceForm
            transferType={transferType}
            data={data}
            onChange={setData}
            validation={validation}
          />
          {error && <Alert className="tw-mt-4" description={error} type="danger" />}
          {isSuccessful && (
            <Alert
              className="tw-mt-4"
              description="Instance was successfully transferred."
              type="success"
            />
          )}
          <Button
            data-testid="transfer-open-modal-test"
            className="tw-mt-4"
            onClick={onOpenModal}
            loading={loading}
            disabled={
              !data.targetDbid || !(data.targetEmail || data.targetUserId || data.targetTenantId)
            }
          >
            Transfer
          </Button>
          <TransferInstanceModal
            data={data}
            open={open}
            onClose={onCloseModal}
            onSubmit={onTransferDbSubmit}
            loading={loading}
          />
        </>
      ) : (
        <h5 data-testid="transfer-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default TransferInstancePage;
