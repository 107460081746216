import React from 'react';
import { Link } from 'foundation';
import cx from 'classnames';
import './breadcrumbs.css';
import capitaliseFirstLetter from 'utils/capitalise-first-letter';

export interface BreadcrumbItem {
  title: string;
  href?: string;
  capitalization?: Capitalization;
}

export enum Capitalization {
  FULL = 'full',
  FIRST_LETTER = 'first_letter',
  NONE = 'none',
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

export const Breadcrumbs = ({ items, className }: BreadcrumbsProps) => {
  const classes = cx('console-breadcrumbs n-body-medium tw-capitalize', className);
  return (
    <div className={classes} data-testid="console-breadcrumbs">
      {items.map((item, index) => {
        const title =
          item.capitalization === Capitalization.NONE
            ? item.title
            : item.capitalization === Capitalization.FULL
            ? item.title.toUpperCase()
            : capitaliseFirstLetter(item.title);
        return (
          <span key={item.title}>
            {item.href && index !== items.length - 1 ? (
              <Link href={item.href} aria-label={item.title}>
                {title}
              </Link>
            ) : (
              title
            )}
          </span>
        );
      })}
    </div>
  );
};
