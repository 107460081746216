import { getTime, parseISO } from 'date-fns';
import { Snapshot, SnapshotProfile, SnapshotStatus } from 'types/snapshot';

const reverseChronological = (a: Snapshot, b: Snapshot) =>
  getTime(parseISO(b.Timestamp)) - getTime(parseISO(a.Timestamp));

const splitPending = (snapshots: Snapshot[]): [Snapshot[], Snapshot[]] => {
  const nonPendingSnapshots = [];
  const pendingSnapshots = [];
  for (const snapshot of snapshots) {
    if (snapshot.Status === SnapshotStatus.PENDING) {
      pendingSnapshots.push(snapshot);
    } else {
      nonPendingSnapshots.push(snapshot);
    }
  }
  return [pendingSnapshots, nonPendingSnapshots];
};

const adhocSnapshotIsInProgress = (snapshots: Snapshot[]): boolean =>
  snapshots.some(
    snapshot =>
      snapshot.Status === SnapshotStatus.IN_PROGRESS && snapshot.Profile === SnapshotProfile.AD_HOC
  );

export const filterAndSortSnapshots = (snapshots: Snapshot[]): Snapshot[] => {
  const [pendingSnapshots, nonPendingSnapshots] = splitPending(snapshots);
  nonPendingSnapshots.sort(reverseChronological);

  const displayPending = !adhocSnapshotIsInProgress(nonPendingSnapshots);
  const snapshotsToDisplay = displayPending
    ? [...pendingSnapshots, ...nonPendingSnapshots]
    : nonPendingSnapshots;

  return snapshotsToDisplay;
};
