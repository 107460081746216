import { StripeAddressField } from 'components/application/billing-form';
import { Button, Checkbox, Form, FormInput, LoadingSpinner, Tip } from 'components/foundation';
import Icon from 'components/ui/icons';
import React, { useMemo } from 'react';
import { BillingAddress, CustomerCardDetails, isSameAddress } from 'types/customer';
import { Validation } from 'utils/validation';
import { SessionState } from 'state/session-store';
import { BillingMethod } from 'entities/tenant';

interface BillingData {
  fetching: boolean;
  error?: string;
  card?: CustomerCardDetails;
  address?: BillingAddress;
  serviceAddress?: BillingAddress;
  companyName?: string;
  isServiceAddress?: boolean;
}

interface StripeData {
  address: BillingAddress;
  isServiceAddress: boolean;
  companyName?: string;
  loading: boolean;
}
export const defaultStripeAddress = (): BillingAddress => ({
  address_line1: '',
  address_city: '',
  address_zip: '',
  address_state: '',
  address_country: 'US',
});

interface BillingAddressSectionProps {
  originalData: BillingData;
  stripeData: StripeData;
  onUpdateCustomer: () => any;
  onAddressChange: (address: BillingAddress) => void;
  onCompanyNameChange: (companyName: string) => void;
  onIsServiceAddressChange: (isChecked: boolean) => void;
  companyNameValidation: Validation<{ companyName: string }>;
  session: SessionState;
}

export const BillingAddressSection = ({
  stripeData: data,
  originalData,
  onAddressChange,
  onCompanyNameChange,
  onIsServiceAddressChange,
  onUpdateCustomer,
  companyNameValidation,
  session,
}: BillingAddressSectionProps) => {
  const updateButtonDisabled = useMemo(() => {
    if (data.companyName && originalData.companyName !== data.companyName) return false;

    if (!data.isServiceAddress) {
      return (
        originalData.serviceAddress &&
        isSameAddress(originalData.serviceAddress, data.address) &&
        !originalData.isServiceAddress
      );
    } else {
      return (
        originalData.address &&
        isSameAddress(originalData.address, data.address) &&
        originalData.isServiceAddress
      );
    }
  }, [originalData, data]);

  const handleStripeCompanyNameChange = ({ target: { value } }) => {
    onCompanyNameChange(value);
  };

  const handleIsServiceAddressChange = ({ target: { checked } }) => {
    onIsServiceAddressChange(checked);
  };

  const shouldDisableSettings = session.tenant.billingMethod === BillingMethod.PREPAID;

  return (
    <Form
      onSubmit={onUpdateCustomer}
      className="console-billing-section tw-h-auto tw-p-12 tw-bg-palette-neutral-bg-weak tw-rounded-2xl tw-w-full"
    >
      {originalData.fetching && <LoadingSpinner expand className="tw-w-full" />}
      {!originalData.fetching && (
        <>
          <div className="tw-flex tw-flex-col tw-gap-4 tw-w-1/2">
            <h5 className="tw-mb-4">
              Service Address
              <Tip allowedPlacements={['right']}>
                <Tip.Trigger>
                  <span className="tw-inline">
                    <Icon
                      name="QuestionMarkCircleIconOutline"
                      type="solid"
                      className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1"
                      aria-label="Service address information"
                    />
                  </span>
                </Tip.Trigger>
                <Tip.Content className="tw-max-w-xs" isPortaled={false}>
                  This is the physical address of the company purchasing Neo4j Aura. It is used to
                  calculate any applicable sales taxes.
                </Tip.Content>
              </Tip>
            </h5>

            <Checkbox
              data-testid="payment-section-is-service-address"
              checked={data.isServiceAddress}
              label="Same as billing address"
              onChange={handleIsServiceAddressChange}
              disabled={shouldDisableSettings}
            />
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-4">
              <StripeAddressField
                data={data.address ?? defaultStripeAddress()}
                onChange={onAddressChange}
                disabled={data.isServiceAddress || shouldDisableSettings}
                testIdPrefix="payment-"
              />
            </div>
          </div>
          <div className="tw-w-full tw-h-px tw-bg-palette-neutral-border-weak" />
          <div className="tw-w-1/2">
            <FormInput
              label="Company name"
              helpText="This company name will appear on your invoices and may be used for communication and customer support purposes."
              value={data.companyName}
              onChange={handleStripeCompanyNameChange}
              data-testid="input-company-name"
              errorText={companyNameValidation?.companyName?.message}
              fluid
              disabled={shouldDisableSettings}
            />
            {!shouldDisableSettings && (
              <Button
                className="tw-mt-4 tw-float-right"
                disabled={updateButtonDisabled}
                type="submit"
                loading={data.loading}
                data-testid="update-billing-details"
              >
                Update
              </Button>
            )}
          </div>
        </>
      )}
    </Form>
  );
};
