import React from 'react';
import { useQuery } from 'react-query';

import Actions from 'actions';
import formatDollars from 'utils/format-dollars';
import LoadingSpinner from 'foundation/loading-spinner';
import { TransformedDiscount } from 'types/usage';
import { SessionState } from 'state/session-store';

const TEN_MINUTES = 10 * 60 * 1000;

interface Props {
  session: SessionState;
}

const BillingUsageSummary = ({ session }: Props) => {
  const usage = useQuery(
    ['usage', session.tenant.id],
    () => Actions.billingUsage.fetch(session.tenant.id, null),
    {
      refetchInterval: TEN_MINUTES,
    }
  );

  if (usage.isError) {
    return null;
  }

  if (usage.isLoading) {
    return <LoadingSpinner />;
  }

  const { totalCost, discount } = usage.data;

  const subTotal = totalCost ? parseFloat(totalCost) : 0;
  const reduction = discount ? parseFloat(discount.dollarAmountOff) : 0;
  const total = subTotal - reduction;

  let title;
  if (reduction > 0) {
    title =
      `(Usage of ${formatDollars(subTotal)}, with ` +
      `${formatDollars((discount as TransformedDiscount).dollarAmountOff)} discount)`;
  }

  return (
    <div data-testid="billing-usage-summary" title={title} className="n-subheading-small">
      <span className="tw-hidden lg:tw-inline">
        {total < 0 ? 'Credit remaining: ' : 'Usage this month: '}
      </span>
      <strong className="tw-text-palette-neutral-text-weak">
        {formatDollars(Math.abs(total))}
      </strong>
    </div>
  );
};

export default BillingUsageSummary;
