export const boltRoutingProtocol = 'bolt+routing://';
export const neo4jProtocol = 'neo4j+s://';

export interface BoltConnection {
  hostname: string;
  withDefaultProtocol: string;
}

export const boltConnectionValues = (boltUrl: string) => {
  const hostname = boltUrl ? boltUrl.replace(/^[^:]+:\/\//, '') : null;
  const withDefaultProtocol = hostname ? `${neo4jProtocol}${hostname}` : null;

  return {
    hostname,
    withDefaultProtocol,
  };
};
