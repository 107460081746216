import ApiClient from 'remote/api-client';
import { InviteStatus } from 'types/invite';
import { RoleName } from 'types/user';

const getByTenant = (tenantId: string, status?: InviteStatus) =>
  ApiClient.get('/invites')
    .query({ NamespaceId: tenantId, ...(status && { Status: status }) })
    .ignoreAllErrors()
    .promise();

const inviteToTenant = (tenantId: string, email: string, roles: RoleName[]) =>
  ApiClient.post('/invites')
    .issue({
      NamespaceId: tenantId,
      Email: email,
      Roles: roles,
    })
    .ignoreAllErrors()
    .promise();

const revokeInvite = (inviteId: string) =>
  ApiClient.delete(`/invites/${inviteId}`)
    .ignoreAllErrors()
    .promise();

const updateInvite = (inviteId: string, roles: RoleName[]) =>
  ApiClient.patch(`/invites/${inviteId}`)
    .issue({
      Roles: roles,
    })
    .ignoreAllErrors()
    .promise();

const acceptInvite = (inviteId: string) =>
  ApiClient.post(`/invites/${inviteId}/accept`)
    .ignoreAllErrors()
    .promise();

const declineInvite = (inviteId: string) =>
  ApiClient.post(`/invites/${inviteId}/decline`)
    .ignoreAllErrors()
    .promise();

const getByUser = (userId: string) =>
  ApiClient.get(`/users/${userId}/invites`)
    .ignoreAllErrors()
    .promise();

export default {
  getByTenant,
  inviteToTenant,
  revokeInvite,
  updateInvite,
  acceptInvite,
  declineInvite,
  getByUser,
};
