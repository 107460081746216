import BaseStore from './base-store';
import { Snapshot, SnapshotStatus } from 'types/snapshot';

const PENDING_OR_IN_PROGRESS = new Set([SnapshotStatus.PENDING, SnapshotStatus.IN_PROGRESS]);

export interface SnapshotState {
  snapshots: Record<string, Snapshot[]>;
}

class SnapshotsStore extends BaseStore<SnapshotState> {
  constructor() {
    super('SnapshotsStore');
  }

  initialState() {
    return {
      snapshots: {},
    };
  }

  findByDbId(dbId) {
    return this.state.snapshots[dbId];
  }

  anySnapshotPendingOrInProgress = dbId =>
    dbId in this.state.snapshots &&
    this.state.snapshots[dbId].some(s => PENDING_OR_IN_PROGRESS.has(s.Status));

  refresh(dbId, snapshots) {
    this._updateState({
      ...this.state,
      snapshots: {
        ...this.state.snapshots,
        [dbId]: snapshots,
      },
    });
  }

  _stateHasChanged(oldState, newState) {
    return super._stateHasChanged(oldState.snapshots, newState.snapshots);
  }
}

export default SnapshotsStore;
