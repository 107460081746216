import { ForwardedRef, forwardRef as reactForwardRef, ReactElement, RefAttributes } from 'react';

// Recasting forwardRef to this type makes it compatible
// with generic components. This only works for function
// components but since this library only exports
// function components it shouldn't be an issue
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
// @see https://fettblog.eu/typescript-react-generic-forward-refs/
type ForwardRefType = <T, P = Record<string, never>>(
  render: (props: P, ref: ForwardedRef<T>) => ReactElement | null
) => (props: P & RefAttributes<T>) => ReactElement | null;

export const forwardRef = reactForwardRef as ForwardRefType;
