import React from 'react';

import { TextLink } from 'foundation';

const CDCDocLinkPausedResumed = 'https://neo4j.com/docs/cdc/current/get-started/aura/#pause-resume';

export const CDCPauseDatabaseTooltipMessage = (
  <p>
    Pause/resume is not recommended on a CDC enabled database, please stop your CDC application and
    reset your application cursor. Neo4j recommends disabling programmatic pausing of this database.
    Please read the{' '}
    <TextLink
      href={CDCDocLinkPausedResumed}
      externalLink
      className="tw-text-palette-neutral-text-inverse hover:tw-text-palette-neutral-text-inverse"
    >
      Paused/Resumed databases
    </TextLink>{' '}
    for more info.
  </p>
);

export const CDCResumeDatabaseTooltipMessage = (
  <p>
    Pause/resume is not recommended on a CDC enabled database, please reset your application cursor
    that tracks changes. Please read the{' '}
    <TextLink
      href={CDCDocLinkPausedResumed}
      externalLink
      className="tw-text-palette-neutral-text-inverse hover:tw-text-palette-neutral-text-inverse"
    >
      Paused/Resumed databases
    </TextLink>{' '}
    for more info.
  </p>
);
