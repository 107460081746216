import React from 'react';
import { Tip } from 'foundation';

interface PermissionTipProps {
  hasPermission: boolean;
  children: React.ReactNode;
  hasButtonWrapper?: boolean;
}

export const PermissionTip = ({
  children,
  hasPermission,
  hasButtonWrapper = false,
}: PermissionTipProps) => {
  return (
    <Tip isDisabled={hasPermission}>
      <Tip.Trigger hasButtonWrapper={hasButtonWrapper}>{children}</Tip.Trigger>
      <Tip.Content style={{ width: 200 }}>
        You don&apos;t have permission to perform this action.
      </Tip.Content>
    </Tip>
  );
};
