import React, { useMemo } from 'react';

import { IconButton, StickyActionsDataGrid, useDefaultTable, createColumnHelper } from 'foundation';
import {
  Action,
  OrganizationMember,
  RoleName,
  isOrganizationRole,
  organizationRoleToFriendlyName,
} from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import { usePermissions } from 'store';

interface DataRowActions {
  edit?: React.ReactElement;
}

interface DataRow {
  email: String;
  role: String;
  actions: DataRowActions;
}

interface UsersTableProps {
  usersList: OrganizationMember[];
  onEditUserClick: (member: OrganizationMember) => void;
  allowDeleteOwnerRoles: boolean;
}

const headerHelper = createColumnHelper<DataRow>();

const EditIcon = ({ allowEdit, onClick }) => (
  <PermissionTip hasPermission={allowEdit}>
    <IconButton
      title="Edit User"
      aria-label="Edit User"
      iconName="PencilIconOutline"
      disabled={!allowEdit}
      onClick={onClick}
      clean
    />
  </PermissionTip>
);

const UsersTable = ({
  usersList = [],
  onEditUserClick,
  allowDeleteOwnerRoles,
}: UsersTableProps) => {
  const { allow } = usePermissions();
  const allowEditUser = user =>
    allow(Action.UPDATE, `organizations/${user.OrganizationId}/members/${user.UserId}`) &&
    (user.Roles.some(role => role.Name === RoleName.ORGANIZATION_OWNER)
      ? allowDeleteOwnerRoles
      : true);
  const data = useMemo(() => {
    const rows: DataRow[] = [];

    rows.push(
      ...usersList.map(
        (user: OrganizationMember): DataRow => ({
          email: user.Email,
          role: organizationRoleToFriendlyName(
            user.Roles.find(role => isOrganizationRole(role.Name)).Name
          ),
          actions: {
            edit: (
              <div data-testid={`edit-${user.UserId}`}>
                <EditIcon allowEdit={allowEditUser(user)} onClick={() => onEditUserClick(user)} />
              </div>
            ),
          },
        })
      )
    );

    return rows;
  }, [usersList]);

  const columns = useMemo(
    () => [
      headerHelper.accessor('email', {
        header: 'Email',
        cell: c => c.getValue(),
        size: 450,
      }),
      headerHelper.accessor('role', {
        header: 'Role',
        cell: c => c.getValue(),
      }),
      headerHelper.accessor('actions', {
        header: null,
        enableSorting: false,
        cell: c => c.getValue().edit,
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    []
  );

  const table = useDefaultTable({ columns, data, state: {} });
  return <StickyActionsDataGrid tableInstance={table} />;
};

export default UsersTable;
