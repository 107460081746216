import React, { useMemo } from 'react';
import { OauthClient } from 'types/oauth';
import { IconButton, createColumnHelper, useDefaultTable, StickyActionsDataGrid } from 'foundation';
import { parseISO, format } from 'date-fns';
import { RTCellInfo, RTRowInfo } from 'types/shared';
interface Props {
  clients: OauthClient[];
  onDelete: (clientId: string) => void;
}

const headerHelper = createColumnHelper<OauthClient>();

export const OauthClientTable = ({ clients, onDelete }: Props) => {
  const columns = useMemo(
    () => [
      headerHelper.accessor('Name', {
        minSize: 100,
      }),
      headerHelper.accessor('ClientId', {
        header: 'Client ID',
        minSize: 100,
      }),
      headerHelper.accessor('CreatedAt', {
        header: 'Created At',
        cell: c => format(parseISO(c.cell.getValue()), 'do MMM H:mm a x'),
        minSize: 100,
      }),
      headerHelper.display({
        id: 'actions',
        size: 80,
        cell: c => {
          const client = c.row.original;
          return (
            <IconButton
              iconName="TrashIconOutline"
              data-testid={`oauth-client-delete-${client.ClientId}`}
              onClick={() => onDelete(client.ClientId)}
              aria-label="Delete OAuth client"
              title="Delete OAuth client"
              danger
              clean
            />
          );
        },
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    [onDelete]
  );
  const table = useDefaultTable({
    columns: columns,
    data: clients,
  });

  return <StickyActionsDataGrid tableInstance={table} />;
};

export interface OauthRowInfo extends RTRowInfo {
  original: OauthClient;
}
export interface OauthCellInfo extends RTCellInfo {
  row: OauthRowInfo;
}
