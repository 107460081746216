import NamespacesResource from 'remote/resources/namespaces';
import { SessionStore } from 'state';
import { TenantUser, TenantUserRole, RoleName } from 'types/user';

const refresh = async () => {
  const tenantId = SessionStore.state.currentTenant;
  const tenant = await NamespacesResource.get(tenantId);
  SessionStore.setTenant(tenant);
};

export const getMemberRoles = (tenantId: string): Promise<TenantUserRole[]> =>
  NamespacesResource.getMemberRoles(tenantId);

export const changeMemberRole = (member: TenantUser, newRoleName: RoleName): Promise<void> =>
  NamespacesResource.updateMemberRoles(member.NamespaceId, member.UserId, [
    newRoleName,
  ]).then(() => {});

export const fetchTenantMembers = (tenant: string): Promise<TenantUser[]> =>
  NamespacesResource.getMembers(tenant);

export const removeTenantMember = (member: TenantUser): Promise<void> =>
  NamespacesResource.removeMember(member);

export const editTenant = (tenantId: string, payload): Promise<void> =>
  NamespacesResource.editTenant(tenantId, payload);

export const updateIsolationIds = (tenantId: string, isolationIds: string[]) =>
  NamespacesResource.updateIsolationIds(tenantId, isolationIds);

export const updateSsoConfigId = (tenantId: string, ssoConfigId: string) =>
  NamespacesResource.linkSsoConfigId(tenantId, ssoConfigId);

export default {
  changeMemberRole,
  fetchTenantMembers,
  getMemberRoles,
  refresh,
  removeTenantMember,
  editTenant,
  updateIsolationIds,
  updateSsoConfigId,
};
