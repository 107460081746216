import React from 'react';
import location from 'browser/location';
import { Page } from 'foundation/page';
import { Alert } from 'foundation/alert';

export const TenantNotFoundPage = () => {
  const linkWithoutTenant = new URL(location.href);
  linkWithoutTenant.searchParams.delete('tenant');

  return (
    <Page plain>
      <Alert
        description="Either this project does not exist or you don't have access to it."
        title="Project not found"
        data-testid="tenant-403-page"
        type="danger"
        actions={[
          {
            label: 'Reload page with a valid project',
            onClick: () => {
              location.assign(linkWithoutTenant);
              location.reload();
            },
          },
        ]}
      />
    </Page>
  );
};
