export enum NotificationLevel {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface NotificationAction {
  label: string;
  onClick: () => void;
}

export interface Notification {
  id: string;
  level: NotificationLevel;
  title?: string;
  message: string;
  expiresAfter: number;
  onClose?: () => void;
  actions?: NotificationAction[];
}

export interface NotificationState {
  notifications: Notification[];
}
