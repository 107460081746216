import { deepEquals } from './utils';
import logger from 'logger';
// TODO test this directly rather than through the other stores

type StateChangeListener<State> = (newState: State) => any;

abstract class BaseStore<State> {
  private _name: string;
  private _state: State;
  private _listeners: StateChangeListener<State>[];

  constructor(name) {
    this._name = name;
    this._state = this.initialState();
    this._listeners = [];
  }

  abstract initialState(): State;

  resetState() {
    this._updateState(this.initialState());
  }

  stopListening() {
    this._listeners = [];
  }

  resetAll() {
    this.stopListening();
    this.resetState();
  }

  get state(): State {
    return this._state;
  }

  listen(callback: StateChangeListener<State>) {
    this._listeners.push(callback);
  }

  _updateState(newState: State) {
    if (this._stateHasChanged(this._state, newState)) {
      logger.debug(`New ${this._name} state:`, this._redactFilter(newState));

      this._state = newState;
      this._listeners.forEach(callback => callback(newState));
    }
  }

  _redactFilter(stateForLogging: State): State {
    return stateForLogging;
  }

  _redactField(stateForLogging: State, field: keyof State) {
    const value = stateForLogging[field];
    if (!value || typeof value !== 'string') {
      return stateForLogging;
    }

    return {
      ...stateForLogging,
      [field]: value.replace(/./g, '*'),
    };
  }

  _stateHasChanged(oldState: State, newState: State) {
    if (!oldState || !newState) {
      return oldState !== newState;
    }
    if (newState.constructor === Array) {
      return !this._equalArrays(oldState, newState);
    }

    return !deepEquals(oldState, newState);
  }

  _equalArrays(a, b) {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (!deepEquals(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }
}

export default BaseStore;
