import React, { BaseSyntheticEvent, ReactNode } from 'react';
import { asPercentageString } from 'components/utils';
import {
  Database,
  DatabaseStatus,
  friendlyCloudProviderName,
  friendlyRegionName,
  getConnectionUri,
  getRemainingTrialTimeMessage,
  Tier,
} from 'entities/database';
import { getNeo4jVersionText, needsMigration } from 'utils/neo4j-versions';
import Statuses from 'application/db-status';
import './card.css';
import { Button, CopyTextToClipBoardButton, Label, LoadingSpinner, Tip } from 'foundation';
import {
  OpenWithBloom,
  OpenWithBrowser,
  OpenWithImporter,
  OpenWithWorkspace,
} from 'application/open-with';
import transitionValue from './transition-value';
import { DatabaseActions } from 'application/db-actions';
import location from 'browser/location';
import classnames from 'classnames';
import { useSession } from 'store';
import Actions from 'actions';
import globals from 'browser/globals';
import { availableRegionsForTier, PlanType } from 'entities/tenant';
import Icon from 'ui/icons';
import { useDarkTheme } from 'utils/hooks';
import { isCDCEnrichmentModeEnabled } from 'entities/cdc-enrichment-mode';
import { useListByDatabase } from 'remote/resources/custom-endpoints';
import { customEndpointURI } from 'entities/custom-endpoints';

const getMemoryText = (db: Database) => {
  return transitionValue(db.AppliedSettings.Memory, db.DesiredSettings.Memory, db.DatabaseStatus);
};

const getCpuText = (db: Database) => {
  return transitionValue(db.AppliedSettings.Cpu, db.DesiredSettings.Cpu, db.DatabaseStatus);
};

const getStorageText = (db: Database) => {
  return transitionValue(db.AppliedSettings.Storage, db.DesiredSettings.Storage, db.DatabaseStatus);
};

const getSecondariesCountText = (db: Database) => {
  return transitionValue(
    db.AppliedSettings.SecondariesCount,
    db.DesiredSettings.SecondariesCount,
    db.DatabaseStatus
  );
};

const getCDCEnrichmentModeText = (db: Database) => {
  return transitionValue(
    db.AppliedSettings.CDCEnrichmentMode,
    db.DesiredSettings.CDCEnrichmentMode,
    db.DatabaseStatus
  );
};

const DbInfo = ({
  title,
  value,
  testIdPrefix,
  className = '',
}: {
  title: string;
  value: ReactNode;
  testIdPrefix: string;
  className?: string;
}) => {
  const classes = classnames('db-info', className);
  return (
    <div className={classes}>
      <span className="info-title" data-testid={`${testIdPrefix}-title`}>
        {title}
      </span>
      <span className="info-value" data-testid={`${testIdPrefix}-value`}>
        {value || '-'}
      </span>
    </div>
  );
};

const DbConnectionUri = ({
  connectionUri,
  title = 'Connection URI',
  dataTestid = 'connection-uri',
}: {
  connectionUri: string;
  title?: string;
  dataTestid?: string;
}) => (
  <div className="db-info">
    <span className="info-title" data-testid={`${dataTestid}-title`}>
      {title}
    </span>
    <div
      className="info-value tw-truncate tw-cursor-text"
      data-testid={`${dataTestid}-body`}
      onClick={e => e.stopPropagation()}
    >
      {connectionUri ?? '-'}
    </div>
    {connectionUri && (
      <span onClick={e => e.stopPropagation()} style={{ height: '0px' }}>
        <CopyTextToClipBoardButton
          text={connectionUri}
          className="tw-ml-1 tw-mr-2"
          style={{ position: 'relative', bottom: '8px' }}
          iconButtonProps={{ size: 'small', title: 'Copy URI' }}
        />
      </span>
    )}
  </div>
);

interface Props {
  database: Database;
  className?: string;
}

const interactiveDbStatusMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: true,
  [DatabaseStatus.RUNNING]: true,
  [DatabaseStatus.PAUSING]: true,
  [DatabaseStatus.SUSPENDING]: true,
  [DatabaseStatus.PAUSED]: true,
  [DatabaseStatus.SUSPENDED]: true,
  [DatabaseStatus.RESUMING]: true,
  [DatabaseStatus.LOADING_DATA]: true,
  [DatabaseStatus.LOADING_FAILED]: true,
  [DatabaseStatus.UPDATING]: true,
  [DatabaseStatus.DESTROYING]: false,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: true,
  [DatabaseStatus.OVERWRITING]: true,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: true,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: true,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: true,
};

const dbLoadingStateMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: true,
  [DatabaseStatus.RUNNING]: false,
  [DatabaseStatus.PAUSING]: false,
  [DatabaseStatus.SUSPENDING]: false,
  [DatabaseStatus.PAUSED]: false,
  [DatabaseStatus.SUSPENDED]: false,
  [DatabaseStatus.RESUMING]: false,
  [DatabaseStatus.LOADING_DATA]: false,
  [DatabaseStatus.LOADING_FAILED]: false,
  [DatabaseStatus.UPDATING]: false,
  [DatabaseStatus.DESTROYING]: true,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: false,
  [DatabaseStatus.OVERWRITING]: false,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: false,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: false,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: false,
};

const isCardInteractive = (database: Database) => interactiveDbStatusMap[database.DatabaseStatus];

const openInNewTab = (url: string): void => {
  const newWindow = globals.window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const noPropagateHandler = <T extends BaseSyntheticEvent>(handler: (e: T) => void) => {
  return (e: T) => {
    e.stopPropagation();
    handler(e);
  };
};

const stopPropagation = (e: BaseSyntheticEvent) => {
  e.stopPropagation();
};

export const DbCard = ({ database, className, ...rest }: Props) => {
  const { CurrentNodes, CurrentRelationships } = database.Counts || {};
  const { MaxNodes, MaxRelationships } = database.Limits || {};
  const interactive = isCardInteractive(database);
  const connectionUri = getConnectionUri(database);
  const privateBoltUri = database.PrivateBoltUrl;
  const shouldDisplayPrivateUri =
    privateBoltUri && [Tier.ENTERPRISE, Tier.AURA_DSE].includes(database.Tier);
  const shouldDisplayCmkIcon = !!database.EncryptionKey;

  const session = useSession();
  const isDarkTheme = useDarkTheme();

  const customEndpoints = useListByDatabase(database.DbId, {
    skip: !session.tenant.capabilities.custom_endpoints,
  });
  const shouldDisplayCustomEndpoint =
    session.tenant.capabilities.custom_endpoints &&
    customEndpoints.data &&
    customEndpoints.data.length !== 0;

  const hash = `#databases/${database.DbId}/detail`;
  const href = `${location.origin}${location.pathname}${location.search}${hash}`;

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!interactive) return;

    // Open in new tab on ctrl click
    if (e.ctrlKey) {
      openInNewTab(href);
    } else {
      Actions.navigate.push(href);
    }
  };

  const handleCardKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      Actions.navigate.push({ hash });
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!interactive) return;

    // Scroll wheel click
    if (e.button === 1) {
      openInNewTab(href);
    }
  };

  const classes = classnames(
    'console-db-card tw-shadow-raised focus-visible:tw-shadow-overlay tw-bg-palette-neutral-bg-weak tw-text-palette-neutral-text-default hover:tw-text-palette-neutral-text-default',
    className,
    {
      interactive,
      'hover:tw-shadow-overlay': interactive,
      'console-party': database.Name.toLowerCase().endsWith('(party-mode)'),
      rr: database.Name.toLowerCase().endsWith('(rick-rolled)'),
    }
  );

  const regionIconName =
    isDarkTheme && database.CloudProvider === 'aws' ? 'aws-dark' : database.CloudProvider;

  const showSecondaries =
    session.tenant.capabilities?.secondaries &&
    database.DesiredSettings.Version === '5' &&
    database.Tier === Tier.ENTERPRISE;

  const shouldDisplayCDCEnrichmentMode = isCDCEnrichmentModeEnabled(session, database);
  const showTierLabel = session.planType === PlanType.SELF_SERVE;
  // Whether to show the VDC type label as a separate row like UPX, instead of as a tier label
  const showType = !showTierLabel;

  return (
    <div
      className={classes}
      data-testid="db-card"
      data-dbid={database.DbId}
      {...rest}
      data-appcues-id="db-card"
      onClick={handleCardClick}
      onKeyDown={handleCardKeyDown}
      onMouseDown={handleMouseDown}
      tabIndex={0}
    >
      <div className="db-card-title tw-flex tw-flex-nowrap tw-truncate">
        <strong className="db-name tw-mr-2" title={database.Name}>
          {database.Name}
        </strong>

        {showTierLabel && (
          <Label
            fill="semi-filled"
            className="db-card-label tw-capitalize"
            data-testid="tier-label"
          >
            {database.TierDisplayName}
          </Label>
        )}
        {database.ProTrialEndTime && (
          <div
            className="n-body-small tw-text-palette-neutral-text-weaker tw-ml-2"
            data-testid="trial-countdown"
          >
            {getRemainingTrialTimeMessage(database)}
          </div>
        )}
        {shouldDisplayPrivateUri && (
          <>
            <Label
              fill="semi-filled"
              className="db-card-label tw-capitalize"
              data-testid="private-label"
            >
              Private Connection
            </Label>
            <Tip allowedPlacements={['top', 'bottom']}>
              <Tip.Trigger>
                <div className="tw-ml-2 tw-inline-flex">
                  {database.PublicAccessEnabled ? (
                    <Icon
                      name="ShieldExclamationIconOutline"
                      aria-label="Private Connection with public access enabled"
                      size="large"
                    />
                  ) : (
                    <Icon
                      name="ShieldCheckIconOutline"
                      aria-label="Private Connection"
                      size="large"
                    />
                  )}
                </div>
              </Tip.Trigger>
              <Tip.Content className="tw-z-10">
                {database.PublicAccessEnabled
                  ? 'Private Connection with public access enabled'
                  : 'Private Connection'}
              </Tip.Content>
            </Tip>
          </>
        )}
        {shouldDisplayCmkIcon && (
          <Tip allowedPlacements={['top', 'bottom']}>
            <Tip.Trigger>
              <div className="tw-ml-2 tw-mb-1 tw-inline-flex">
                <Icon name="cmk-key" aria-label="CMK encrypted instance" size="large" />
              </div>
            </Tip.Trigger>
            <Tip.Content className="tw-z-10">{'Instance encrypted with CMK'}</Tip.Content>
          </Tip>
        )}
      </div>
      {dbLoadingStateMap[database.DatabaseStatus] ? (
        <div className="db-card-status tw-relative">
          <LoadingSpinner
            expand
            className="db-card-status tw-mb-2"
            size="large"
            data-testid="db-card-loading"
            text={
              <>
                <span className="tw-capitalize">{database.DatabaseStatus}</span> Instance...
                {database.DatabaseStatus === DatabaseStatus.CREATING && (
                  <div>(This may take a few minutes)</div>
                )}
              </>
            }
          />
        </div>
      ) : (
        <>
          <div className="db-card-buttons">
            {session.workspaceEnabled ? (
              <OpenWithWorkspace database={database}>
                {({ onClick, href: workspaceHref, disabled }) => (
                  <Button
                    size="medium"
                    fill="outlined"
                    className="workspace-button"
                    disabled={disabled}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={noPropagateHandler(onClick)}
                    onKeyDown={stopPropagation}
                    data-appcues-id="open-with-workspace-btn"
                    iconName="ArrowTopRightOnSquareIconOutline"
                    title="Open with workspace"
                    {...(!disabled && { href: workspaceHref })}
                  >
                    Open
                  </Button>
                )}
              </OpenWithWorkspace>
            ) : (
              <>
                <OpenWithBloom database={database}>
                  {({ onClick, href: bloomHref, disabled }) => (
                    <Button
                      size="small"
                      fill="outlined"
                      className="bloom-button db-legacy-buttons"
                      disabled={disabled}
                      iconName="bloom-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={noPropagateHandler(onClick)}
                      onKeyDown={stopPropagation}
                      title="Explore your data in Neo4j Bloom"
                      data-appcues-id="open-with-bloom-btn"
                      {...(!disabled && { href: bloomHref })}
                    >
                      Explore
                    </Button>
                  )}
                </OpenWithBloom>
                <OpenWithBrowser database={database}>
                  {({ onClick, href: browserHref, disabled }) => (
                    <Button
                      size="small"
                      fill="outlined"
                      className="browser-button db-legacy-buttons"
                      disabled={disabled}
                      iconName="browser-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={noPropagateHandler(onClick)}
                      onKeyDown={stopPropagation}
                      title="Query your data in Neo4j Browser"
                      data-appcues-id="open-with-browser-btn"
                      {...(!disabled && { href: browserHref })}
                    >
                      Query
                    </Button>
                  )}
                </OpenWithBrowser>
                <OpenWithImporter database={database}>
                  {({ onClick, href: importerHref, disabled }) => (
                    <Button
                      size="small"
                      fill="outlined"
                      className="importer-button db-legacy-buttons"
                      disabled={disabled}
                      iconName="data-importer-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={noPropagateHandler(onClick)}
                      onKeyDown={stopPropagation}
                      title="Import data via Neo4j Data Importer"
                      data-appcues-id="open-with-data-importer-btn"
                      {...(!disabled && { href: importerHref })}
                    >
                      Import
                    </Button>
                  )}
                </OpenWithImporter>
              </>
            )}
          </div>
          <div
            className="db-card-id tw-flex n-body-small tw-text-palette-neutral-text-weaker tw-flex-wrap tw-whitespace-nowrap"
            aria-label="Instance ID"
          >
            {database.DbId}
          </div>
          <div className="db-card-status tw-flex tw-items-center tw-pb-6 tw-pt-3">
            <Statuses database={database} size="small" data-testid="db-card-status" />
          </div>
          <div className="db-card-info tw-gap-1 tw-flex tw-flex-col tw-text-xs tw-justify-end">
            {showType && (
              <DbInfo title="Type" value={database.TierDisplayName} testIdPrefix="type" />
            )}
            <DbInfo
              title="Neo4j version"
              className="tw-items-center"
              value={
                <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                  {getNeo4jVersionText(database.DesiredSettings.Version)}
                  {needsMigration(database.DesiredSettings.Version) && (
                    <Label
                      data-testid="migration-recommended"
                      color="warning"
                      fill="semi-filled"
                      withIcon
                      style={{ textTransform: 'none' }}
                    >
                      Migration recommended
                    </Label>
                  )}
                </div>
              }
              testIdPrefix="version"
            />
            {database.Tier === Tier.FREE && (
              <>
                <DbInfo
                  title="Nodes"
                  value={asPercentageString(CurrentNodes, MaxNodes)}
                  testIdPrefix="nodes"
                />
                <DbInfo
                  title="Relationships"
                  value={asPercentageString(CurrentRelationships, MaxRelationships)}
                  testIdPrefix="relationships"
                />
              </>
            )}
            {database.Tier !== Tier.FREE && (
              <>
                <DbInfo title="Memory" value={getMemoryText(database)} testIdPrefix="memory" />
                <DbInfo title="CPU" value={getCpuText(database)} testIdPrefix="cpu" />
                <DbInfo title="Storage" value={getStorageText(database)} testIdPrefix="storage" />
              </>
            )}
            {showSecondaries && (
              <DbInfo
                title="Secondary Count"
                value={getSecondariesCountText(database)}
                testIdPrefix="secondaries-count"
              />
            )}
            {shouldDisplayCDCEnrichmentMode && (
              <DbInfo
                title="CDC Mode"
                value={getCDCEnrichmentModeText(database)}
                testIdPrefix="cdc-enrichment-mode"
              />
            )}
            <DbInfo
              title="Region"
              value={
                <div className="tw-flex tw-flex-row tw-gap-2">
                  {friendlyRegionName(
                    database,
                    availableRegionsForTier(session.providerConfigs, database.Tier)
                  )}
                  <Icon
                    name={regionIconName}
                    title={friendlyCloudProviderName(database)}
                    height="16"
                  />
                </div>
              }
              testIdPrefix="region"
            />
            {/* This is to make it work without the api changes */}
            {(database.PublicAccessEnabled ?? true) && (
              <DbConnectionUri connectionUri={connectionUri} />
            )}
            {shouldDisplayPrivateUri && (
              <DbConnectionUri connectionUri={privateBoltUri} title="Private URI" />
            )}
            {shouldDisplayCustomEndpoint &&
              customEndpoints.data.map(endpoint => (
                <DbConnectionUri
                  key={endpoint.id}
                  connectionUri={customEndpointURI(endpoint)}
                  title="Custom Endpoint"
                  dataTestid={`custom-endpoint-${endpoint.id}`}
                />
              ))}
          </div>
          <div
            className="db-card-actions tw-flex tw-items-end tw-gap-2"
            onClick={stopPropagation}
            onKeyDown={stopPropagation}
          >
            <DatabaseActions database={database} size="medium" />
          </div>
        </>
      )}
    </div>
  );
};
