import React, { useState } from 'react';
import { Tabs, Page } from 'foundation';
import { LookupTenantForm } from './lookup-tenant-form';
import { SearchTenantsForm } from './search-tenants-form';
import { SearchByIsolationIdForm } from './search-isolationid-form';
import { usePermissions } from 'store';
import { Action } from 'types/user';

type TabId = number | string;

enum TabIds {
  InternalName,
  TenantId,
  IsolationId,
}

const LookupTenantPage = () => {
  const permission = usePermissions();
  const canReadTenants = permission.allow(Action.READ, 'namespaces/*');
  const [selectedTabId, setSelectedTabId] = useState<TabId>(TabIds.InternalName);

  return (
    <Page fullWidth>
      {canReadTenants ? (
        <>
          <h5 className="tw-mb-4" data-testid="lookup-tenant-page-header">
            Lookup Project(s)
          </h5>

          <Tabs className="tw-mb-8" value={selectedTabId} onChange={setSelectedTabId}>
            <Tabs.Tab tabId={TabIds.InternalName}>Internal Name</Tabs.Tab>
            <Tabs.Tab tabId={TabIds.TenantId}>Project UUID</Tabs.Tab>
            <Tabs.Tab tabId={TabIds.IsolationId}>Isolation ID</Tabs.Tab>
          </Tabs>

          <Tabs.TabPanel
            tabId={TabIds.InternalName}
            value={selectedTabId}
            data-testid="search-internal-name-page"
          >
            <SearchTenantsForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel
            tabId={TabIds.TenantId}
            value={selectedTabId}
            data-testid="search-uuid-page"
          >
            <LookupTenantForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel
            tabId={TabIds.IsolationId}
            value={selectedTabId}
            data-testid="search-isolation-id-page"
          >
            <SearchByIsolationIdForm />
          </Tabs.TabPanel>
        </>
      ) : (
        <h5 data-testid="authorize-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default LookupTenantPage;
