import React, { useState } from 'react';
import { Form, FormInput } from 'foundation/form-element';
import AuthorizedUsersResource from 'remote/resources/authorized-users';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import Button from 'foundation/button';
import { Alert } from 'foundation/alert';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';

const emailSchema = yup.object({
  email: yup.string().email('Must be a valid email'),
});

export const validateEmail = data => {
  return validateYup(emailSchema, data, false);
};

export const AuthorizeUsersForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const authorizeButtonDisabled = email === '' || validationError !== null;

  const handleEmailChange = ({ target: { value } }) => {
    setValidationError(null);
    const err = validateEmail({ email: value });
    if (err) {
      setValidationError(err);
    }

    setEmail(value);
  };

  const handleSubmit = async () => {
    if (!email) return;

    setError(null);
    setIsSuccessful(false);
    try {
      await AuthorizedUsersResource.create(email.trim().toLowerCase());

      setEmail('');
      setIsSuccessful(true);
    } catch (err) {
      if (err instanceof ApiClientRequestError && err.response.status === 409) {
        setError(`User already authorized with email ${email}`);
      } else {
        setError(String(err));
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormInput
          fluid
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          type="email"
          label="Email"
          data-testid="authorize-users-by-email-input"
          name="userid-or-email"
          autoComplete="email"
          errorText={validationError?.email?.message}
        />
        <Button
          className="tw-mt-4"
          type="submit"
          color="primary"
          disabled={authorizeButtonDisabled}
          data-testid="authorize-users-by-email-button"
        >
          Authorize user
        </Button>
        {error && (
          <Alert
            className="tw-mt-4"
            description={error}
            type="danger"
            data-testid="authorize-users-error"
          />
        )}
        {isSuccessful && (
          <Alert
            className="tw-mt-4"
            description="User was successfully authorized!"
            type="success"
          />
        )}
      </Form>
    </>
  );
};
