import React from 'react';
import classnames from 'classnames';

export const Dimmer = ({ children, ...props }) => {
  return (
    <div
      className="tw-absolute tw-z-10 tw-inset-0 tw-bg-palette-neutral-bg-weak"
      style={{ opacity: '0.8' }}
      {...props}
    >
      {children}
    </div>
  );
};

const Dimmable = ({ children, className = '', ...props }) => {
  const classes = classnames('tw-relative', className);
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Dimmer.Dimmable = Dimmable;
