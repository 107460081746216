import React from 'react';
import { LoadingSpinner as NDLLoadingSpinner } from '@neo4j-ndl/react';
import classnames from 'classnames';

interface LoadingSpinnerProps extends React.ComponentProps<typeof NDLLoadingSpinner> {
  /**
   * If the loading spinner should take up as much space as possible and
   * center itself in that space
   */
  expand?: boolean;

  // Only works with expand
  minHeight?: number;

  // Only works with expand
  text?: React.ReactNode;
}

const LoadingSpinner = ({
  expand = false,
  minHeight,
  text,
  className,
  ...rest
}: LoadingSpinnerProps) => {
  if (expand) {
    const classes = classnames(
      className,
      'tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full'
    );
    return (
      <div className={classes} style={{ minHeight }}>
        <NDLLoadingSpinner data-testid="loading-spinner" {...rest} />
        {text && <div className="body-medium tw-mt-2 tw-text-center">{text}</div>}
      </div>
    );
  }

  return <NDLLoadingSpinner data-testid="loading-spinner" className={className} {...rest} />;
};

export default LoadingSpinner;
