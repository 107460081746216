import { TenantNotification } from 'entities/tenant';
import { NotificationAction } from 'state/notifications';
import Actions from 'actions';

const getActionById = (actionId: string, callbacks: ActionCallbacks) => {
  switch (actionId) {
    case 'extend-pro-trial':
      return () => callbacks.openExtendTrial();
    case 'enter-billing-details':
      return () => {
        const search = new URLSearchParams(location.search);
        search.set('tab', 'payment');
        Actions.navigate.push({
          hash: '#settings/billing',
          search: search.toString(),
        });
      };
    case 'create-pro-trial-db':
      return () => Actions.navigate.push({ hash: '#databases/create' });
    default:
      return () => {};
  }
};

export interface ActionCallbacks {
  openExtendTrial: () => void;
}

export const getActionsForNotification = (
  tenantNotification: TenantNotification,
  callbacks: ActionCallbacks
): NotificationAction[] =>
  tenantNotification.actions.map(({ label, actionId }) => ({
    label,
    onClick: getActionById(actionId, callbacks),
  }));
