import React, { useMemo, useState } from 'react';
import { Database, Region, Tier } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { useDefaultErrorHandler } from 'remote/error-handler';
import {
  AwsEndpointConnectionState,
  TrafficConfig,
  TrafficEnablement,
} from 'types/traffic-configs';
import TrafficConfigResources from 'remote/resources/traffic-configs';
import { validateYup, Validation } from 'utils/validation';
import * as yup from 'yup';
import { productFriendlyName } from 'types/product';
import { Alert, Button, Dialog, FormSelect, LoadingSpinner, TextLink } from 'components/foundation';
import { useNotify } from 'state/notifications';
import { CopyInput } from 'components/application/copy-input';
import {
  ConfirmCheckbox,
  ConnectionRequestAcceptor,
  PrivateConnection,
  VpnMessage,
} from './shared';
import { getRegionsForTier } from 'utils/tiers-and-regions';
import { useTracking } from 'react-tracking';

interface FormData {
  tier?: Tier;
  region?: string;
  privateTraffic: TrafficEnablement;
  publicTraffic: TrafficEnablement;
  endpointIds: string[];
  endpointIdField: string;
}

const schema = yup.object({
  tier: yup
    .string()
    .oneOf(Object.values(Tier))
    .required()
    .label('Product'),
  region: yup
    .string()
    .required()
    .label('Region'),
  privateTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  publicTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  endpointIds: yup
    .array()
    .required()
    .of(
      yup
        .string()
        .min(1)
        .label('AWS Endpoint ID')
    ),
});

const validate = (data: FormData): Validation<FormData> => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  endpointIds: trafficConfig ? trafficConfig.awsProperties.endpointIds : [''],
  endpointIdField: '',
  privateTraffic: trafficConfig?.privateTraffic ?? TrafficEnablement.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TrafficEnablement.ENABLED,
});

const getConnectionStateLabelColor = (state: AwsEndpointConnectionState) => {
  if (state === AwsEndpointConnectionState.ACCEPTED) {
    return 'success';
  }

  if (state === AwsEndpointConnectionState.REJECTED) {
    return 'danger';
  }

  return 'default';
};

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const AWSTrafficConfigDialog = ({
  tenant,
  trafficConfig,
  title,
  onClose,
  onSuccess,
  databases,
  // Only needed in create mode
  // Used to prepopulate the props
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: {
  tenant: Tenant;
  trafficConfig?: TrafficConfig;
  title: string;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<Tier, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  databases: Database[];
}) => {
  const notify = useNotify();
  const providerConfigs = tenant.providerConfigs;
  const editMode = !!trafficConfig;
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const defaultErrorHandler = useDefaultErrorHandler();
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [enableError, setEnableError] = useState('');
  const [loading, setLoading] = useState(false);
  // Default to step 2 if we've already done the initial create
  const [step, setStep] = useState(trafficConfig ? 2 : 1);
  const [confirm, setConfirm] = useState(false);
  const tracking = useTracking();
  const [endpointConnectionMessage, setEndpointConnectionMessage] = useState<{
    message: React.ReactNode;
    messageType: 'warning' | 'danger' | 'info' | 'success';
  }>(null);
  const affectedDatabases = useMemo(() => {
    let dbs: Database[] = [];
    if (data.region && data.tier && data.publicTraffic === TrafficEnablement.DISABLED) {
      dbs = databases.filter(db => db.Region === data.region && db.Tier === data.tier);
    }
    return dbs;
  }, [data.publicTraffic]);
  const handleClose = () => {
    onClose();
  };

  const handleTierChange = option => {
    tracking.trackEvent({
      action: 'new_network_access_product_select',
      properties: { event_label: 'selected', tier: option.value },
    });
    setData(prev => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = option => {
    const newRegion = option.value;
    tracking.trackEvent({
      action: 'new_network_access_region_select',
      properties: { event_label: 'selected', region: newRegion },
    });
    setData(prev => {
      const existingTrafficConfig = existingTrafficConfigs.find(
        c => c.tier === prev.tier && c.region === newRegion
      );
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingEndpointIds = existingTrafficConfig?.awsProperties?.endpointIds ?? [];
      return {
        ...prev,
        region: newRegion,
        endpointIds: existingEndpointIds,
      };
    });
  };

  const handleEndpointIdFieldChange = e => {
    setEndpointConnectionMessage(null);
    const newValue = e.target.value;
    setData(prev => {
      return { ...prev, endpointIdField: newValue };
    });
  };
  const handleAddEndpointId = () => {
    setData(prev => {
      const endpointConnection = trafficConfig.status.awsStatus.endpointConnections.find(
        conn => conn.endpointId === prev.endpointIdField
      );

      if (!endpointConnection) {
        setEndpointConnectionMessage({
          message:
            'Endpoint connection request could not be found. Please follow the instructions on the previous step to create an endpoint connection request, and/or check that your endpoint id is correct.',
          messageType: 'danger',
        });
        return prev;
      }

      if (prev.endpointIds.includes(endpointConnection.endpointId)) {
        setEndpointConnectionMessage({
          message: (
            <>
              The endpoint connection accept request for <b>{endpointConnection.endpointId}</b> has
              already been sent. The setup can take a couple of minutes.
            </>
          ),
          messageType: 'info',
        });
        return prev;
      }

      const newData = {
        ...prev,
        endpointIds: [...prev.endpointIds, prev.endpointIdField],
        endpointIdField: '',
      };

      tracking.trackEvent({
        action: 'new_network_access_connection_accept_request',
        properties: { event_label: 'added' },
      });

      handleSubmitWithData(newData, { next: false });

      setEndpointConnectionMessage({
        message: (
          <>
            The endpoint connection accept request for <b>{endpointConnection.endpointId}</b> has
            been sent. The setup can take a couple of minutes.
          </>
        ),
        messageType: 'success',
      });

      return newData;
    });
  };

  const handleConfirmChange = e => {
    tracking.trackEvent({
      action: 'vpn_statement_acknowledgement',
      properties: { event_label: 'checked' },
    });
    setConfirm(e.target.checked);
  };

  const handleTestGuidesClick = () => {
    tracking.trackEvent({ action: 'link_to_connection_test_guides' });
  };

  const handlePublicTrafficDisabledChange = e => {
    const newValue = !e.target.checked;
    tracking.trackEvent({
      action: newValue ? 'enable_public_traffic' : 'disable_public_traffic',
      properties: { event_label: 'checked' },
    });
    setData(prev => ({
      ...prev,
      publicTraffic: newValue ? TrafficEnablement.ENABLED : TrafficEnablement.DISABLED,
    }));
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 4));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleSubmit = async (e, close = false) => {
    tracking.trackEvent({
      action: 'new_network_access_enable_private_link',
      properties: { event_label: 'clicked' },
    });
    handleSubmitWithData(data, { close });
  };

  const handleFinishLater = () => {
    tracking.trackEvent({
      action: 'new_network_access_finish_later',
      properties: { event_label: 'clicked' },
    });
    onClose();
  };

  const handleSubmitWithData = async (toSubmit: FormData, { close = false, next = true } = {}) => {
    const errors = validate(toSubmit);
    if (errors) {
      setValidation(errors);
      return;
    }

    setValidation({});
    setLoading(true);

    try {
      const response = await TrafficConfigResources.update(
        tenant.id,
        toSubmit.tier,
        toSubmit.region,
        {
          privateTraffic: toSubmit.privateTraffic,
          publicTraffic: toSubmit.publicTraffic,
          awsProperties: { endpointIds: toSubmit.endpointIds },
        }
      );
      if (next) handleNextStep();
      if (onSuccess) onSuccess(response);
      if (close) handleClose();
    } catch (err) {
      if (err.response.status === 422) {
        setEnableError(err.response.responseMessage);
      } else if (err.response.status <= 499 && err.response.status >= 400) {
        notify.error('Failed to update network security configuration');
      } else {
        defaultErrorHandler(err);
      }
    }

    setLoading(false);
  };

  const handleSubmitAndClose = e => handleSubmit(e, true);

  const trackCopyEndpoint = () => {
    tracking.trackEvent({ action: 'copy_aws_endpoint_service_name' });
  };

  const tiers = [...new Set(existingTrafficConfigs.map(config => config.tier))];
  let regions: Region[] | null = null;
  let regionOptions = [];
  if (data.tier) {
    regions = getRegionsForTier(providerConfigs, data.tier);
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs
      .filter(tc => tc.tier === data.tier)
      .map(tc => tc.region);
    regions = regions.filter(
      r => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name)
    );
    regionOptions = regions.map(r => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }

  const tierOptions = tiers
    .filter(t => [Tier.ENTERPRISE, Tier.AURA_DSE].includes(t)) // Shouldn't happen in practice, but my devenv is whack
    .map(tier => {
      const product = getProductFromTier(tier);
      return {
        key: tier,
        value: tier,
        label: productFriendlyName(product),
      };
    });

  const endpointServiceName = trafficConfig?.status?.awsStatus?.endpointServiceName;
  const endpointConnections = trafficConfig?.status?.awsStatus?.endpointConnections ?? [];
  const configCreating = !trafficConfig || !endpointServiceName;

  return (
    <Dialog open onClose={handleClose}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
        <p>Step {step} of 4</p>
        {step === 1 && (
          <>
            <FormSelect
              label="Product"
              options={tierOptions}
              value={data.tier}
              onChange={handleTierChange}
              disabled={editMode}
              errorText={validation.tier?.message}
              data-testid="aws-dialog-select-product"
            />
            {regions && (
              <>
                {regionOptions.length === 0 && (
                  <Alert
                    type="warning"
                    title="All regions configured"
                    description={`All ${productFriendlyName(
                      getProductFromTier(data.tier)
                    )} regions have been configured.`}
                  />
                )}
                {regionOptions.length > 0 && (
                  <FormSelect
                    label="Region"
                    helpText="AWS PrivateLink applies to all instances in the region."
                    options={regionOptions}
                    value={data.region ?? ''}
                    onChange={handleRegionChange}
                    disabled={editMode}
                    errorText={validation.region?.message}
                    data-testid="aws-dialog-select-region"
                  />
                )}
              </>
            )}
            {data.region === 'us-east-1' && (
              <Alert description="In AWS region `us-east-1`, we do not support the Availability Zone with ID `use1-az3` for private endpoints. If you have workloads running in Availability Zone `use1-az3`, they will not be able to connect directly to the PrivateLink Endpoint and will rely on cross-zone routing." />
            )}
            {enableError && <Alert description={enableError} type="danger" />}
          </>
        )}

        {step === 2 && (
          <>
            {configCreating && (
              <Alert
                title={
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="PrivateLink is being configured..."
              />
            )}
            {!configCreating && (
              <>
                <Alert icon type="success" title="Accepted" />
                <CopyInput
                  id="copy-aws-endpoint-service-name"
                  label="Endpoint Service Name"
                  value={endpointServiceName}
                  readOnly
                  onCopy={trackCopyEndpoint}
                />
              </>
            )}
            <div className="tw-mt-2">
              <h6>
                <span className="tw-font-normal">Create a VPC Endpoint</span>
              </h6>
              <ol className="console-network-instructions tw-list-decimal tw-mt-3 tw-ml-8">
                <li>
                  Log in to your AWS console{' '}
                  <TextLink href="https://aws.amazon.com/console/" externalLink rel="noreferrer">
                    https://aws.amazon.com/console/
                  </TextLink>
                </li>
                <li>
                  Navigate to the VPC Service by searching in the global search bar for <i>VPC</i>,
                  or navigating to <b>Services</b> &gt; <b>Networking & Content Delivery</b> &gt;{' '}
                  <b>VPC</b>.
                </li>
                <li>
                  Select the <b>Endpoints</b> item in the side menu.
                </li>
                <li>
                  Click <b>Create Endpoint</b>.
                </li>

                <li>
                  Set <b>Service Category</b> to <b>Other endpoint services</b>.
                </li>
                {configCreating && (
                  <li>
                    Once PrivateLink is done configuring, set <b>Service name</b> to the Endpoint
                    Service Name.
                  </li>
                )}
                {!configCreating && (
                  <li>
                    Set <b>Service name</b> to <i>{endpointServiceName}</i>.
                  </li>
                )}
                <li>
                  Set <b>VPC</b> to the VPC you want to connect to your Neo4j instances from.
                  <ul className="tw-list-[lower-alpha] tw-ml-6">
                    <li>
                      For <b>Subnets</b>, select at least one subnet. It is recommended to select 3
                      subnets, one in every Availability Zone to prevent a single point of failure.
                    </li>
                    <li>
                      For <b>VPC Security group</b>, select a Security group that allows ports 80,
                      443, 7687 and 8491.
                    </li>
                  </ul>
                </li>

                <li>
                  Click <b>Create endpoint</b>.
                </li>
                <li>
                  Save <b>VPC endpoint ID</b> of the newly created endpoint. It will be used in the
                  next step.
                </li>
              </ol>
              <div className="n-body-medium tw-mt-4">
                *{' '}
                <TextLink
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/create-interface-endpoint.html"
                  externalLink
                >
                  <i>AWS PrivateLink documentation</i>
                </TextLink>
              </div>
            </div>
          </>
        )}
        {step === 3 && (
          <div className="tw-flex tw-flex-col tw-gap-8">
            <ConnectionRequestAcceptor
              value={data.endpointIdField}
              onChange={handleEndpointIdFieldChange}
              onAccept={handleAddEndpointId}
              inputErrorText={validation?.endpointIdField?.message}
              acceptMessage={endpointConnectionMessage}
              loading={loading}
              connectionRequests={endpointConnections.map(c => ({
                id: c.endpointId,
                state: c.state,
              }))}
              stateColorMapper={getConnectionStateLabelColor}
            />
            <div>
              <h6>
                <span className="tw-font-normal">Enable Private DNS in AWS console</span>
              </h6>
              <ol className="console-network-instructions tw-list-decimal tw-mt-3 tw-ml-8">
                <li>
                  Log in to your AWS console{' '}
                  <TextLink href="https://aws.amazon.com/console/" externalLink rel="noreferrer">
                    https://aws.amazon.com/console/
                  </TextLink>
                </li>
                <li>
                  Navigate to the VPC Service by searching in the global search bar for <i>VPC</i>,
                  or navigating to <b>Services</b> &gt; <b>Networking & Content Delivery</b> &gt;{' '}
                  <b>VPC</b>.
                </li>
                <li>
                  Select the <b>Endpoints</b> item in the side menu.
                </li>
                <li>
                  Select the newly accepted endpoint from the table. This endpoint should have been
                  accepted using the above form.
                </li>

                <li>
                  Click <b>Actions</b>.
                </li>
                <li>
                  Click <b>Modify private DNS name</b>.
                </li>
                <li>
                  Under <b>Modify private DNS name settings</b> &gt; <b>Enable private DNS names</b>
                  , check <b>Enable for this endpoint</b>.
                </li>
                <li>
                  Click <b>Save changes</b>.
                </li>
              </ol>
              <div className="tw-mt-4">
                The configuration should now be complete. Once you have an Aura instance created you
                should be able to test the connection using{' '}
                <TextLink
                  href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                  externalLink
                  onClick={handleTestGuidesClick}
                >
                  this guide.
                </TextLink>
              </div>
              <div className="n-body-medium tw-mt-4">
                *{' '}
                <TextLink
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/interface-endpoints.html#enable-private-dns-names"
                  externalLink
                >
                  <i>AWS PrivateLink documentation</i>
                </TextLink>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status?.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedDatabases={affectedDatabases}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_aws_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="tw-justify-between">
        <Button onClick={handleFinishLater} fill="outlined" data-testid="aws-dialog-finish-later">
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={handleSubmit}
            fill="filled"
            loading={loading}
            disabled={!data.region || !data.tier}
            data-testid="aws-dialog-enable-privatelink"
          >
            Enable PrivateLink
          </Button>
        )}
        {step === 1 && editMode && (
          <Button onClick={handleNextStep} fill="filled" data-testid="aws-dialog-next">
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              disabled={!endpointServiceName}
              data-testid="aws-dialog-next"
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button onClick={handleNextStep} fill="filled" data-testid="aws-dialog-next">
              Next
            </Button>
          </div>
        )}
        {step === 4 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              loading={loading}
              disabled={!confirm}
              data-testid="aws-dialog-save"
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};
