export const auth0Domain = ['staging', 'production'].includes(process.env.ENVIRONMENT)
  ? 'https://manage.auth0.com/dashboard/us/neo4j-sync'
  : 'https://manage.auth0.com/dashboard/eu/neo4j-dev';

export const auraApiAuth0Domain =
  process.env.ENVIRONMENT === 'production'
    ? 'https://manage.auth0.com/dashboard/eu/aura-api'
    : process.env.ENVIRONMENT === 'staging'
    ? 'https://manage.auth0.com/dashboard/eu/aura-api-staging'
    : 'https://manage.auth0.com/dashboard/eu/aura-api-dev';
