import { useEffect } from 'react';

import globals from 'browser/globals';
import { Product } from 'types/product';
import { SessionState } from 'state/session-store';
import { getAvailableProducts, PlanType } from 'entities/tenant';
import { useDarkTheme } from 'utils/hooks';

export const CANNY_APP_ID = '5eb66d2ff9f4665cc1bed233';

const CANNY_LABEL_IDS: {
  [planType in PlanType | 'allPlanTypes']: {
    [product in Product | 'allProducts']: string[];
  };
} = {
  [PlanType.SELF_SERVE]: {
    [Product.AURA_DB]: [
      '6239a04faa376c33ba74fcef', // AuraDB Free
      '6239a04aaa376c33ba74f6c0', // AuraDB Professional
    ],
    [Product.AURA_DS]: [
      '6239a058eaf5bb082db76ac6', // AuraDS Self-Start
    ],
    allProducts: [
      '6239a0c787c34c6baebda0fd', // Browser
      '6239acd058204e32b7bcd151', // Data Importer
      '62835cb66bb7970b7745d86d', // Import
    ],
  },
  [PlanType.ENTERPRISE]: {
    [Product.AURA_DB]: [
      '62399c92aa376c33ba71511c', // AuraDB Enterprise
    ],
    [Product.AURA_DS]: [
      '6239a062aa376c33ba7513b6', // AuraDS Enterprise
    ],
    allProducts: [],
  },
  allPlanTypes: {
    [Product.AURA_DB]: [],
    [Product.AURA_DS]: [],
    allProducts: [
      '5fa16962c2207b64553f52e7', // Advanced Warning Aura Platform
      '6239a0bbcab550241dab79ee', // Bloom
      '603e2acc68e6a050d82910ed', // Maintenance Notice
      '62835caf158bcc0b63b36f4a', // Query
    ],
  },
};

const getLabelIDs = (planType: PlanType, availableProducts: Product[]): string[] => [
  ...CANNY_LABEL_IDS.allPlanTypes.allProducts,
  ...CANNY_LABEL_IDS[planType].allProducts,
  ...availableProducts.reduce(
    (labelIDs, product) => [
      ...labelIDs,
      ...CANNY_LABEL_IDS.allPlanTypes[product],
      ...CANNY_LABEL_IDS[planType][product],
    ],
    []
  ),
];

declare global {
  interface Window {
    Canny: Function;
  }
}

const initializeChangelog = (appID: string, labelIDs: string[], theme = 'light') => {
  globals.window.Canny?.('initChangelog', {
    appID,
    position: 'bottom',
    align: 'right',
    labelIDs,
    theme,
  });
};

const identifyUser = (appID: string, email: string, name: string, picture: string) => {
  globals.window.Canny?.('identify', {
    appID,
    user: {
      email,
      name,
      id: email,
      avatarURL: picture,
    },
  });
};

export const useCanny = (session: SessionState) => {
  const { planType, email, name, picture, providerConfigs } = session;
  const isDarkTheme = useDarkTheme();
  const availableProducts = getAvailableProducts(providerConfigs);
  const labelIDs = getLabelIDs(planType, availableProducts);
  // The theme doesn't re-render the component so the dark theme won't be set if updating the prefs.
  // https://canny.io/feedback/p/widget-dark-theme
  useEffect(() => initializeChangelog(CANNY_APP_ID, labelIDs, isDarkTheme ? 'dark' : 'light'), []);
  useEffect(() => identifyUser(CANNY_APP_ID, email, name, picture), [
    CANNY_APP_ID,
    email,
    name,
    picture,
  ]);
};

export default useCanny;
