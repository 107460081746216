import InvitesResource from 'remote/resources/invites';
import { SessionStore } from 'state';
import { InviteDetails, InviteStatus } from 'types/invite';
import { RoleName } from 'types/user';

export const getInvitesByTenant = (tenantId: string, status?: InviteStatus) =>
  InvitesResource.getByTenant(tenantId, status);

export const inviteUserToTenant = (tenantId: string, email: string, roles: RoleName[]) =>
  InvitesResource.inviteToTenant(tenantId, email, roles);

export const revokeInvite = (inviteId: string) => InvitesResource.revokeInvite(inviteId);

export const updateInvite = (inviteId: string, roles: RoleName[]) =>
  InvitesResource.updateInvite(inviteId, roles);

export const acceptInvite = (inviteId: string) => InvitesResource.acceptInvite(inviteId);

export const declineInvite = (inviteId: string) => InvitesResource.declineInvite(inviteId);

export const getInvitesByUser = (userId: string): Promise<InviteDetails[]> =>
  InvitesResource.getByUser(userId);

export const refreshUserInvites = (userId: string) =>
  InvitesResource.getByUser(userId).then(invites => SessionStore.setInvites(invites));

export default {
  getInvitesByTenant,
  inviteUserToTenant,
  updateInvite,
  revokeInvite,
  acceptInvite,
  declineInvite,
  getInvitesByUser,
  refreshUserInvites,
};
