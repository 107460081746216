import DatabasesResource from 'remote/resources/databases';
import { DatabasesStore } from 'state';
import logger from 'logger';

interface Parameters {
  Memory?: string;
  Cpu?: string;
  Storage?: string;
  SecondariesCount?: number;
  CDCEnrichmentMode?: string;
  VectorOptimized?: boolean;
}

const updateDatabase = (dbid: string, parameters: Parameters) =>
  DatabasesResource.update(dbid, {
    ...parameters,
  })
    .then(res => {
      logger.debug(`Database ${dbid} successfully scheduled for update`);
      DatabasesStore.upsert(dbid, res);
    })
    .catch(error => {
      logger.debug('Failed to update DB due to error:', error);
    });

export default updateDatabase;
