import React, { SyntheticEvent, useState } from 'react';
import { Database, DatabaseStatus } from 'entities/database';
import ClearDatabaseModal from './modal';

interface Props {
  database: Database;
}

interface ModalProps {
  open: boolean;
  database: Database;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const isClearEnabledDatabaseStatusMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: false,
  [DatabaseStatus.RUNNING]: true,
  [DatabaseStatus.PAUSING]: false,
  [DatabaseStatus.SUSPENDING]: false,
  [DatabaseStatus.PAUSED]: false,
  [DatabaseStatus.SUSPENDED]: false,
  [DatabaseStatus.RESUMING]: false,
  [DatabaseStatus.LOADING_DATA]: false,
  [DatabaseStatus.LOADING_FAILED]: false,
  [DatabaseStatus.UPDATING]: false,
  [DatabaseStatus.DESTROYING]: false,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: false,
  [DatabaseStatus.OVERWRITING]: false,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: false,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: false,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: false,
};

const ClearModal = ({ open, database, _onClose, onClose }: ModalProps) => (
  <ClearDatabaseModal
    open={open}
    database={database}
    onSuccess={_onClose(onClose)}
    onClose={_onClose(onClose)}
  />
);

export const useClearDatabaseModal = ({ database }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const handleClose = onClose => () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const disabled =
    database.IsBeingCloned || !isClearEnabledDatabaseStatusMap[database.DatabaseStatus];

  const modalProps = {
    open,
    database,
    _onClose: handleClose,
  };

  return { onClick: handleClick, disabled, Modal: ClearModal, modalProps };
};
