import React, { useEffect } from 'react';
import Actions from 'actions';
import { Page } from 'components/foundation';
import { useLocation } from 'store';
import { parse } from 'query-string';

const Logout = () => {
  const location = useLocation();

  const search = parse(location.search);

  useEffect(() => {
    const returnTo = search.returnTo ? { hash: `#${search.returnTo.toString()}` } : undefined;
    void Actions.auth.logout(returnTo);
  }, []);

  return <Page loading={true} loadingText="Logging out..." />;
};

export default Logout;
