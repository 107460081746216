import React from 'react';
import { SessionState } from 'state/session-store';
import './fallback-avatar.css';

interface Props {
  session: SessionState;
  className?: string;
  [key: string]: any;
}

export const FallbackAvatar = ({ session, className, ...rest }: Props) => (
  <div
    className={`fallback-avatar tw-text-center tw-leading-10 tw-flex tw-items-center tw-justify-center${
      className ? ` ${className}` : ''
    }`}
    {...rest}
  >
    {formatInitials(session?.name || session?.email)}
  </div>
);

export const formatInitials = nameOrEmail => {
  const sanitised = nameOrEmail || '';

  // Strip @domain from email or anything after a +
  const nameOrUser = sanitised.replace(/[@+].*/, '');

  // Split by word boundary / underscore (covers most weird email address characters)
  const tokens = nameOrUser.split(/[\W_]/);

  let initials;
  switch (tokens.length) {
    case 0:
      initials = '';
      break;
    case 1:
      initials = tokens[0].substring(0, 2);
      break;
    case 2:
    default:
      initials = tokens[0].substring(0, 1) + tokens[tokens.length - 1].substring(0, 1);
      break;
  }

  initials = initials.toUpperCase();

  return initials.match(/^[A-Z]{1,2}$/) ? initials : '';
};
