import React, { useState } from 'react';
import { Alert, Button, Dialog, Form, FormInput } from 'foundation';
import { ErrorReasons } from 'remote/error-handler/error-reason-codes';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';

const reasonSchema = yup.object({
  reason: yup
    .string()
    .label('Reason')
    .min(10)
    .required(),
});

const validateReason = data => {
  return validateYup(reasonSchema, data, false);
};

interface ModalProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  suspensionReason?: string;
  setSuspensionReason?: React.Dispatch<React.SetStateAction<string>>;
}

const getErrorMessage = (err: Error): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case ErrorReasons.ONGOING_DATABASE_OPERATION:
      return 'Some of the project databases are still ongoing instance operations (Creating, Deleting, Resuming, etc.); retry suspending the project in a while.';
    default:
      return `Error: ${err.reason || 'unknown'}: ${err.message}`;
  }
};

export const SuspendTenantModal = ({
  open,
  loading,
  error,
  onClose,
  onConfirm,
  suspensionReason,
  setSuspensionReason,
}: ModalProps) => {
  const [validationError, setValidationError] = useState(null);
  const submitDisabled =
    suspensionReason === null || suspensionReason === '' || validationError !== null;

  const handleSuspensionReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(null);
    const error = validateReason({ reason: e.target.value });
    if (error) {
      setValidationError(error);
    }

    setSuspensionReason(e.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} modalProps={{ 'data-testid': 'suspend-tenant-modal' }}>
      <Dialog.Header>Suspend Project</Dialog.Header>
      <Form onSubmit={onConfirm}>
        <Dialog.Content>
          <div>
            This will prevent all users from managing databases in this project. Please enter a
            reason for suspending below.
          </div>
          <FormInput
            fluid
            label="Reason"
            className="tw-mt-2 tw-mb-5"
            value={suspensionReason}
            onChange={e => {
              handleSuspensionReasonChange(e);
            }}
            helpText="The suspension reason can only be viewed internally"
            errorText={validationError?.reason?.message}
            data-testid="suspension-reason-input"
          />
          <Alert
            type="info"
            description="This action will also trigger suspend on all running instances for this project."
            className="tw-my-2"
          />
          {error && <Alert type="danger">{getErrorMessage(error)}</Alert>}
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            color="neutral"
            fill="outlined"
            onClick={onClose}
            data-testid="suspend-tenant-modal-cancel"
          >
            Cancel
          </Button>
          <Button
            color="danger"
            type="submit"
            loading={loading}
            data-testid="suspend-tenant-modal-clone"
            disabled={submitDisabled}
          >
            Suspend
          </Button>
        </Dialog.Actions>
      </Form>
    </Dialog>
  );
};

export const ReinstateTenantModal = ({ open, loading, error, onClose, onConfirm }: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} modalProps={{ 'data-testid': 'reinstate-tenant-modal' }}>
      <Dialog.Header>Reinstate Project</Dialog.Header>
      <Dialog.Content>
        This will enable users to manage databases this project.
        {error && <Alert type="danger">{getErrorMessage(error)}</Alert>}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          onClick={onClose}
          data-testid="reinstate-tenant-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onConfirm}
          loading={loading}
          data-testid="reinstate-tenant-modal-clone"
        >
          Reinstate
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
