import React from 'react';
import { Page } from 'foundation/page';
import CreateTenantForm from './create-tenant-form';
import { usePermissions } from 'store';
import { Action } from 'types/user';

interface Props {
  organizationId?: string;
}

const CreateTenantPage = ({ organizationId }: Props) => {
  const permission = usePermissions();
  const canCreateTenants = permission.allow(Action.CREATE, 'namespaces');

  return (
    <Page fullWidth>
      {canCreateTenants ? (
        <>
          <h5 className="tw-mb-4">Create a New Project</h5>
          <CreateTenantForm lockedOrganizationId={organizationId} />
        </>
      ) : (
        <h5 data-testid="create-tenant-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default CreateTenantPage;
