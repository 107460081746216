import React from 'react';
import { Menu, MenuItem } from 'foundation';
import track, { useTracking } from 'react-tracking';
import { PlanType } from 'entities/tenant';
import { SessionState } from 'state/session-store';

interface HelpMenuDropdownProps {
  session: SessionState;
  open: boolean;
  anchorEl: HTMLElement;
  onClose: () => void;
}

const HelpMenuDropdown = ({ session, ...props }: HelpMenuDropdownProps) => {
  const tracking = useTracking();

  return (
    <Menu {...props}>
      <Menu.Items>
        <MenuItem
          title="Docs"
          as="a"
          data-testid="docs"
          href="https://neo4j.com/docs/aura/current/"
          target="_blank"
          onClick={() => {
            tracking.trackEvent({
              action: 'docs_link',
              properties: { event_label: 'clicked' },
            });
          }}
        />
        <MenuItem
          title="GraphAcademy"
          as="a"
          data-testid="graph-academy"
          href="https://graphacademy.neo4j.com/?ref=aura-help"
          target="_blank"
          onClick={() => {
            tracking.trackEvent({
              action: 'graphacademy_link',
              properties: { event_label: 'clicked' },
            });
          }}
        />
        <MenuItem
          title="Community"
          as="a"
          data-testid="community"
          href="https://dev.neo4j.com/discuss-aura"
          target="_blank"
          onClick={() => {
            tracking.trackEvent({
              action: 'community_link',
              properties: { event_label: 'clicked' },
            });
          }}
        />
        <MenuItem
          title="Support"
          as="a"
          data-testid="support"
          href="https://support.neo4j.com/s/"
          target="_blank"
          onClick={() => {
            tracking.trackEvent({
              action: 'support_link',
              properties: { event_label: 'clicked' },
            });
          }}
        />
        <MenuItem
          title="Status"
          as="a"
          data-testid="status"
          href="https://status.neo4j.io/"
          target="_blank"
          onClick={() => {
            tracking.trackEvent({
              action: 'status_link',
              properties: { event_label: 'clicked' },
            });
          }}
        />
        {session.planType === PlanType.SELF_SERVE && (
          <MenuItem
            title="Send Feedback"
            as="a"
            data-testid="feedback"
            target="_blank"
            href="https://aura.feedback.neo4j.com/"
            data-appcues-id="feedback-navigation"
            onClick={() => {
              tracking.trackEvent({
                action: 'feedback_link',
                properties: { event_label: 'clicked' },
              });
            }}
          />
        )}
      </Menu.Items>
    </Menu>
  );
};

export default track()(HelpMenuDropdown);
