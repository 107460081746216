import React, { useMemo } from 'react';
import {
  createColumnHelper,
  IconButton,
  useDefaultTable,
  Label,
  StickyActionsDataGrid,
} from 'foundation';
import {
  CloudwatchDestination,
  DestinationType,
  LogAnalyticsDestination,
  LogForwarding,
  LogForwardingIdentity,
  StackdriverDestination,
} from 'remote/resources/log-forwarding';
import { CloudProvider } from 'entities/database';
import { getProductFromTier } from 'entities/tenant/index';
import { productFriendlyName } from 'types/product';
import { useDatabaseState } from 'store/index';
import { regionNameWithInstanceCount } from './helpers';

interface Props {
  data: LogForwarding[];
  cloudProvider: CloudProvider;
  onEdit: (logForwarding: LogForwarding) => any;
  onDelete: (logForwarding: LogForwarding) => any;
  logForwardingIdentities: LogForwardingIdentity[];
}

const helper = createColumnHelper<LogForwarding>();

const destinationColumns = (
  cloudProvider: CloudProvider,
  logForwardingIdentities: LogForwardingIdentity[]
): ReturnType<typeof helper.accessor | typeof helper.display>[] => {
  switch (cloudProvider) {
    case CloudProvider.GCP:
      return [
        helper.accessor('destination', {
          header: 'Target GCP Project ID',
          minSize: 100,
          cell: cell => (cell.getValue() as StackdriverDestination).gcpProjectId,
        }),
        helper.display({
          id: 'service-account-email',
          header: 'Service Account Email',
          minSize: 100,
          cell: cell => {
            const region = cell.row.original.region;
            const tier = cell.row.original.tier;
            const identity = logForwardingIdentities.find(
              id => id.region === region && id.tier === tier
            );
            return identity?.destinationType === DestinationType.STACKDRIVER ? (
              <span className="tw-truncate">{identity?.serviceAccountEmail ?? 'unknown'}</span>
            ) : (
              <></>
            );
          },
        }),
      ];
    case CloudProvider.AZURE:
      return [
        helper.accessor('destination', {
          header: 'Target Workspace ID',
          minSize: 200,
          cell: cell => (cell.getValue() as LogAnalyticsDestination).customerId,
        }),
      ];
    case CloudProvider.AWS:
      return [
        helper.accessor('destination', {
          header: 'AWS Region',
          minSize: 200,
          cell: cell => (cell.getValue() as CloudwatchDestination).region,
        }),
        helper.display({
          id: 'log-arn',
          header: 'Role ARN',
          minSize: 200,
          cell: cell => (cell.row.original.destination as CloudwatchDestination).logArn,
        }),
        helper.display({
          id: 'group-name',
          header: 'Log group name',
          minSize: 100,
          cell: cell => (cell.row.original.destination as CloudwatchDestination).logGroupName,
        }),
        helper.display({
          id: 'stream-name',
          header: 'Log stream name',
          minSize: 100,
          cell: cell => (cell.row.original.destination as CloudwatchDestination).logStreamName,
        }),
        helper.display({
          id: 'retention-days',
          header: 'Retention days',
          minSize: 100,
          cell: cell => (cell.row.original.destination as CloudwatchDestination).logRetentionDays,
        }),
        helper.display({
          id: 'neo4j-log-arn',
          header: 'Neo4j ARN',
          minSize: 150,
          cell: cell => {
            const region = cell.row.original.region;
            const tier = cell.row.original.tier;
            const identity = logForwardingIdentities.find(
              id => id.region === region && id.tier === tier
            );
            return identity?.destinationType === DestinationType.CLOUDWATCH ? (
              <>{identity?.logArn ?? 'unknown'}</>
            ) : (
              <></>
            );
          },
        }),
        helper.display({
          id: 'neo4j-account-id',
          header: 'Neo4j Account Id',
          minSize: 150,
          cell: cell => {
            const region = cell.row.original.region;
            const tier = cell.row.original.tier;
            const identity = logForwardingIdentities.find(
              id => id.region === region && id.tier === tier
            );
            return identity?.destinationType === DestinationType.CLOUDWATCH ? (
              <span className="tw-truncate">{identity?.accountId ?? 'unknown'}</span>
            ) : (
              <></>
            );
          },
        }),
      ];
  }
};

export const LogForwardingTable = ({
  data,
  cloudProvider,
  onEdit,
  onDelete,
  logForwardingIdentities,
}: Props) => {
  const { databases } = useDatabaseState();

  const columns = useMemo(
    () => [
      helper.accessor('name', {
        header: 'Process',
        minSize: 100,
      }),
      helper.accessor('logType', {
        header: 'Logs',
        minSize: 100,
        cell: cell => <span className="tw-capitalize">{cell.getValue()}</span>,
      }),
      helper.accessor('tier', {
        cell: c => {
          const product = getProductFromTier(c.getValue());
          const value = productFriendlyName(product);
          return value;
        },
        header: 'Product',
      }),
      helper.accessor('region', {
        header: 'Region',
        minSize: 100,
        cell: cell => {
          const region = cell.row.original.region;
          const tier = cell.row.original.tier;
          const dbs = databases.filter(db => db.Region === region && db.Tier === tier);
          return regionNameWithInstanceCount(region, dbs);
        },
      }),
      ...destinationColumns(cloudProvider, logForwardingIdentities),
      helper.accessor('ready', {
        header: 'Status',
        minSize: 100,
        cell: cell =>
          cell.getValue() ? (
            <Label withIcon fill="semi-filled" color="success">
              Forwarding
            </Label>
          ) : (
            <Label withIcon fill="semi-filled">
              Setting up
            </Label>
          ),
      }),
      helper.display({
        id: 'actions',
        cell: cell => (
          <span className="tw-flex tw-gap-3 tw-justify-end tw-w-full">
            <IconButton
              onClick={() => onEdit(cell.row.original)}
              iconName="PencilIconOutline"
              title="Edit log forwarding process"
              aria-label="Edit log forwarding process"
              size="small"
              clean
              data-testid="edit-log-forwarding-button"
            />
            <IconButton
              onClick={() => onDelete(cell.row.original)}
              iconName="TrashIconOutline"
              title="Delete log forwarding process"
              aria-label="Delete log forwarding process"
              size="small"
              danger
              clean
              data-testid="delete-log-forwarding-button"
            />
          </span>
        ),
        meta: {
          isStickyAction: true,
        },
        size: 110,
      }),
    ],
    [cloudProvider, logForwardingIdentities, databases]
  );

  const table = useDefaultTable({
    columns,
    data,
  });

  return (
    <div
      data-testid="log-forwarding-table"
      className="tw-p-8 tw-rounded-lg tw-bg-palette-neutral-bg-weak"
    >
      <StickyActionsDataGrid tableInstance={table} />
    </div>
  );
};
