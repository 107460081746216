import React, { useMemo } from 'react';
import {
  createColumnHelper,
  useDefaultTable,
  Label,
  IconButton,
  Alert,
  Button,
  StickyActionsDataGrid,
} from 'foundation';
import invoicesResource from 'remote/resources/invoices';
import { Invoice } from 'types/invoice';
import { parseISO } from 'date-fns';
import { safeFormat } from 'utils/date-time';
import formatDollars from 'utils/format-dollars';
import track, { useTracking } from 'react-tracking';
import { useQuery } from 'react-query';
import { useSession } from 'store';

const columnHelper = createColumnHelper<Invoice>();

const InvoicesSection = () => {
  const tracking = useTracking();
  const session = useSession();
  // Get all invoices for the current tenant.
  // The invoices are sorted by creation date, with the most recently created invoice appearing first.
  const result = useQuery('APIgetInvoices', () => invoicesResource.list(session.tenant.id));

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('number', {
        header: () => 'Invoice #',
      }),
      columnHelper.accessor('period_start', {
        header: () => 'Period',
        cell: cx => safeFormat(parseISO(cx.getValue()), 'MMM yyyy'),
      }),
      columnHelper.accessor('created', {
        header: () => 'Invoice date',
        cell: cx => safeFormat(parseISO(cx.getValue()), 'dd MMM yyyy'),
      }),
      columnHelper.accessor('due_date', {
        header: () => 'Due date',
        cell: cx => {
          return cx.getValue() ? safeFormat(parseISO(cx.getValue()), 'dd MMM yyyy') : '';
        },
      }),
      columnHelper.accessor('amount_due', {
        header: () => 'Amount',
        cell: cx => {
          if (cx.row.original.currency?.toLowerCase() === 'usd') {
            return formatDollars(cx.getValue());
          } else if (cx.row.original.currency) {
            return (
              <>
                <span>{cx.getValue()}</span>
                <span className="tw-ml-2">({cx.row.original.currency.toUpperCase()})</span>
              </>
            );
          } else {
            return cx.getValue();
          }
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: cx => {
          return (
            cx.getValue() && (
              <Label
                data-testid="invoice-status"
                color={cx.getValue()?.toLowerCase() === 'paid' ? 'success' : 'default'}
                className="tw-capitalize"
                fill="outlined"
              >
                {cx.getValue()}
              </Label>
            )
          );
        },
        enableResizing: false,
      }),
      columnHelper.accessor('invoice_pdf', {
        id: 'invoice-pdf',
        header: '',
        cell: cx => {
          const invoiceUrl = cx.getValue();
          const hostedUrl = cx.row.original.hosted_invoice_url;
          const invoiceOpen = cx.row.original.status === 'open';
          return (
            <div className="tw-flex tw-justify-end tw-w-full tw-gap-2">
              {invoiceUrl && (
                <IconButton
                  onClick={() => {
                    tracking.trackEvent({
                      action: 'download_invoice',
                      properties: { event_label: 'neo4j-browser' },
                    });
                  }}
                  title="Export invoice"
                  data-testid="invoice-link"
                  className="tw-shrink-0"
                  href={invoiceUrl}
                  target={'_blank'}
                  iconName="ArrowDownTrayIconOutline"
                  aria-label="Export invoice"
                  clean
                />
              )}
              {hostedUrl && invoiceOpen && (
                <Button
                  href={hostedUrl}
                  target="_blank"
                  rel="noreferrer"
                  color="success"
                  className="tw-ml-1 tw-shrink-0"
                  onClick={() => {
                    tracking.trackEvent({
                      action: 'pay_invoice',
                    });
                  }}
                >
                  Pay Invoice
                </Button>
              )}
            </div>
          );
        },
        meta: {
          isStickyAction: true,
        },
        size: 200,
      }),
    ];
  }, []);

  const table = useDefaultTable({
    data: result.isLoading || result.error ? [] : result.data,
    columns,
  });

  if (result.isError) {
    return (
      <Alert
        type="danger"
        title="Loading failed"
        description={'Failed to retrieve invoices. Please refresh this page and try again.'}
      />
    );
  }

  return (
    <div
      className="tw-p-8 tw-bg-palette-neutral-bg-weak tw-rounded-tr-lg tw-rounded-b-lg"
      data-testid="invoice-list"
    >
      <StickyActionsDataGrid
        isLoading={result.isLoading}
        isResizable={true}
        tableInstance={table}
      />
    </div>
  );
};

export default track()(InvoicesSection);
