import React from 'react';
import { Button, Form, FormInput, LoadingSpinner, Tip } from 'foundation';
import { Validation } from 'utils/validation';
import Icon from 'components/ui/icons';

interface BillingEmailData {
  email: string;
  loading: boolean;
}

interface BillingEmailFormProps {
  data: BillingEmailData;
  onEmailChange: (string) => any;
  onSubmit: () => any;
  fetching: boolean;
  validation: Validation<BillingEmailData>;
}

export const BillingEmailSection = ({
  data,
  onEmailChange,
  onSubmit,
  fetching,
  validation,
}: BillingEmailFormProps) => {
  const { email, loading } = data;
  const updateButtonDisabled = email === '' || validation !== null;

  const handleEmailChange = ({ target: { value } }) => {
    onEmailChange(value);
  };

  return (
    <Form
      onSubmit={onSubmit}
      className="console-email-section tw-h-auto tw-p-12 tw-bg-palette-neutral-bg-weak tw-rounded-2xl tw-w-full"
    >
      {fetching && <LoadingSpinner expand className="tw-w-full" />}
      {!fetching && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
          <h5 className="tw-mb-4">
            Billing Email
            <Tip allowedPlacements={['right']}>
              <Tip.Trigger>
                <span className="tw-inline">
                  <Icon
                    name="QuestionMarkCircleIconOutline"
                    type="solid"
                    className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1"
                    aria-label="Billing email information"
                  />
                </span>
              </Tip.Trigger>
              <Tip.Content className="tw-max-w-xs" isPortaled={false}>
                This is the email that will receive receipts and invoices.
              </Tip.Content>
            </Tip>
          </h5>
          <div className="tw-flex tw-flex-row tw-gap-4 tw-items-start">
            <FormInput
              aria-label="Billing Email"
              className="tw-w-full"
              value={email}
              placeholder="Email"
              onChange={handleEmailChange}
              data-testid="input-billing-email"
              errorText={validation?.email?.message}
              fluid
            />
            <Button
              className="tw-float-right"
              disabled={updateButtonDisabled}
              loading={loading}
              type="submit"
              data-testid="update-billing-details"
            >
              Update
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
