export const metricsIntegrationApiVersion = 'v1';

const promConfigTemplate = (metricsPathParam, auraApiDomain, cmiDomain: string) => {
  return `global:
    scrape_interval: '1m'
    scrape_timeout: '10s'
    evaluation_interval: '5s'
scrape_configs:
  - job_name: 'aura-metrics'
    metrics_path: '/api/${metricsIntegrationApiVersion}/${metricsPathParam}/metrics'
    scheme: 'https'
    static_configs:
      - targets: ['${cmiDomain}']
    oauth2:
        client_id: '<AURA_CLIENT_ID>'
        client_secret: '<AURA_CLIENT_SECRET>'
        token_url: 'https://${auraApiDomain}/oauth/token'
`;
};

export default promConfigTemplate;
