import React from 'react';
import { CloudProvider } from 'entities/database';
import { CreateLogForwardingPayload, LogForwardingIdentity } from 'remote/resources/log-forwarding';
import { CreateStackdriverConfigModal } from './create-stackdriver-config-modal';
import { CreateLogAnalyticsConfigModal } from './create-log-analytics-config-modal';
import { CreateCloudwatchConfigModal } from './create-cloudwatch-config-modal';
import { Tenant } from 'entities/tenant';

// The following constant defines the maximum allowed number of log fwd to be created per tier/region
export const MAX_LOG_FWD_PROCESSES = 1;

interface Props {
  cloudProvider: CloudProvider;
  tenant: Tenant;
  onCreate: (payload: CreateLogForwardingPayload) => any;
  onClose: () => any;
  open: boolean;
  loading: boolean;
  error: Error | null;
  logForwardingIdentities: LogForwardingIdentity[];
  hasReachedProcessLimit: (region: String, tier: String) => boolean;
}

export const CreateConfigModal = ({ cloudProvider, ...commonProps }: Props) => {
  switch (cloudProvider) {
    case CloudProvider.GCP:
      return <CreateStackdriverConfigModal {...commonProps} />;
    case CloudProvider.AZURE:
      return <CreateLogAnalyticsConfigModal {...commonProps} />;
    case CloudProvider.AWS:
      return <CreateCloudwatchConfigModal {...commonProps} />;
  }
};
