import { useState } from 'react';
import { ManageCustomEndpointModal } from './modal';

interface ManageCustomEndpointProps {
  onClose?: () => void;
}

export const useManageCustomEndpointModal = ({
  onClose = () => null,
}: ManageCustomEndpointProps) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    onClose();
  };
  const modalProps = { open, onClose: closeModal };

  return {
    onClick: () => setOpen(true),
    Modal: ManageCustomEndpointModal,
    modalProps,
  };
};
