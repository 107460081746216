import './login-page.css';
import Button from 'foundation/button';
import Actions from 'actions';
import React from 'react';

const LoginPage = ({ loading }) => {
  const handleLogin = () => Actions.auth.login();

  return (
    <div className="login-page">
      <Button onClick={handleLogin} loading={loading}>
        Login
      </Button>
    </div>
  );
};

export default LoginPage;
