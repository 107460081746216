import './metrics.css';
import React from 'react';
import { SegmentedControl } from 'components/foundation/segment-control';
import { TimeWindow, TimeWindowValue } from './charts';

interface DatabaseMetricTimeSelectorProps {
  value: string;
  options: TimeWindow[];
  onChange: (value: TimeWindowValue) => void;
}

const DatabaseMetricTimeSelector = ({
  options,
  onChange,
  value,
}: DatabaseMetricTimeSelectorProps) => (
  <div className="metrics-time-selector">
    <SegmentedControl onChange={onChange} size="small" selected={value}>
      {options.map(option => (
        <SegmentedControl.Item value={option.value} key={option.value}>
          {option.label}
        </SegmentedControl.Item>
      ))}
    </SegmentedControl>
  </div>
);

export default DatabaseMetricTimeSelector;
