import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  FormSelect,
  Radio,
  SelectOption,
  TextInput,
  TextLink,
} from 'components/foundation';
import { Tier } from 'entities/database';
import {
  DestinationType,
  CreateLogForwardingPayload,
  LogType,
} from 'remote/resources/log-forwarding';
import { validateUUID } from 'utils/uuid';
import { regionNameWithInstanceCount } from './helpers';
import { getRegionsForTier, getTiersForAllCsps } from 'utils/tiers-and-regions';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { productFriendlyName } from 'types/product';
import { useDatabaseState } from 'store/index';
import { Banner } from '@neo4j-ndl/react';
import { DisclaimerDialogStep } from 'pages/log-forwarding/disclaimer-dialog-step';

interface Props {
  tenant: Tenant;
  onCreate: (payload: CreateLogForwardingPayload) => any;
  onClose: () => any;
  open: boolean;
  loading: boolean;
  error: Error | null;
  hasReachedProcessLimit: (region: String, tier: String) => boolean;
}

export const CreateLogAnalyticsConfigModal = ({
  tenant,
  onCreate,
  onClose,
  open,
  loading,
  error,
  hasReachedProcessLimit,
}: Props) => {
  const [step, setStep] = useState(-1);
  const [name, setName] = useState('');
  const [region, setRegion] = useState<string>(null);
  const [workspaceId, setWorkspaceId] = useState('');
  const [azureSharedKey, setAzureSharedKey] = useState('');
  const [workspaceIdValidationError, setWorkspaceIdValidationError] = useState<string>(null);
  const { databases } = useDatabaseState();

  const availableTiers = getTiersForAllCsps(tenant.providerConfigs);
  const [tier, setTier] = useState<Tier>(availableTiers[0]);

  let regionOptions = [];

  const tierOptions = availableTiers.map(tier => {
    const product = getProductFromTier(tier);
    return {
      key: tier,
      value: tier,
      label: productFriendlyName(product),
    };
  });

  const availableRegions = getRegionsForTier(tenant.providerConfigs, tier);
  regionOptions = availableRegions.map(r => {
    const dbsInRegion = databases.filter(db => db.Region === r.name && db.Tier === tier);
    return {
      key: r.name,
      label: regionNameWithInstanceCount(r.friendly, dbsInRegion),
      value: r.name,
      databases: dbsInRegion,
    };
  });

  const formatOptionLabel = ({ label, value, databases }, { context }) => {
    if (context === 'value') {
      return <div>{label}</div>;
    } else if (context === 'menu') {
      return (
        <div data-testid={`region-${value}`}>
          <p>{label}</p>
          <p className="tw-pl-4">{databases.map(db => db.Name).join(', ')}</p>
        </div>
      );
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleTierChange = (option: SelectOption<Tier>) => setTier(option.value);
  const handleRegionChange = (option: SelectOption<string>) => setRegion(option.value);
  const handleWorkspaceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setWorkspaceId(value);

    if (workspaceIdValidationError) {
      setWorkspaceIdValidationError(validateUUID(value));
    }
  };

  const handleAzureSharedKeyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureSharedKey(e.target.value.trim());

  const handleWorkspaceIdBlur = () => {
    setWorkspaceIdValidationError(validateUUID(workspaceId));
  };

  const handleNextStep = () => {
    setStep(1);
  };

  const handleCreate = () => {
    const payload: CreateLogForwardingPayload = {
      destination: {
        type: DestinationType.LOG_ANALYTICS,
        customerId: workspaceId,
        sharedKey: azureSharedKey,
      },
      logType: LogType.SECURITY,
      name,
      region,
      tier,
      tenantId: tenant.id,
    };
    onCreate(payload);
  };

  const isValid = !!region && !!name.trim();

  const isValidPayload =
    isValid && !!workspaceId && !!azureSharedKey && !workspaceIdValidationError;

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>New log forwarding process</Dialog.Header>
      {step === -1 && <DisclaimerDialogStep onAccept={() => setStep(0)} onDecline={onClose} />}
      {step === 0 ? (
        <>
          <Dialog.Content className="tw-space-y-6">
            <TextInput
              label="Name"
              value={name}
              onChange={handleNameChange}
              onBlur={trimName}
              placeholder="Process name"
              fluid
              data-testid="log-forwarding-name-input"
            />
            <div>
              <p className="tw-mb-2">Logs to forward:</p>
              <Radio checked readOnly label="Security logs" />
            </div>
            {hasReachedProcessLimit(region, tier) && (
              <Banner
                icon
                type="warning"
                description="The selected product and region already has a log forwarding process defined"
              />
            )}
            <FormSelect
              label="Product"
              options={tierOptions}
              value={tier}
              onChange={handleTierChange}
              data-testid="log-forwarding-product-select"
            />
            <FormSelect
              label="Region"
              onChange={handleRegionChange}
              options={regionOptions}
              value={region}
              formatOptionLabel={formatOptionLabel}
              helpText="All future instances' logs in the selected region will be forwarded as part of this process."
              data-testid="log-forwarding-region-select"
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!isValid || hasReachedProcessLimit(region, tier)}
              color="primary"
              onClick={handleNextStep}
              data-testid="log-forwarding-next-step"
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      ) : (
        <>
          <Dialog.Content className="tw-space-y-6">
            {error && <Alert type="danger">{String(error)}</Alert>}
            <p>Follow these steps:</p>
            <ol className="tw-list-decimal tw-pl-6 tw-my-6">
              <li>
                Go to the{' '}
                <TextLink href="https://portal.azure.com/" externalLink>
                  Azure portal
                </TextLink>{' '}
                and log in with your Azure account.
              </li>
              <li>
                Locate and select your Log Analytics workspace or create a new one. If your
                workspace is within a resource group, start by navigating to that resource group.
              </li>
              <li>
                In the left-hand menu, click on &quot;Agents&quot; in the &quot;Security&quot;
                section.
              </li>
              <li>
                The &quot;Workspace ID&quot; is located at the top of this page. Click the
                &quot;Copy&quot; button next to the Workspace ID to copy it to your clipboard.
              </li>
              <li>
                Look for the &quot;Primary key&quot; listed on the same page. Use the
                &quot;Copy&quot; button next to the Primary key to copy it to your clipboard.
              </li>
            </ol>
            <TextInput
              label="Workspace ID"
              value={workspaceId}
              onChange={handleWorkspaceIdChange}
              onBlur={handleWorkspaceIdBlur}
              placeholder="your-workspace-id"
              fluid
              data-testid="log-forwarding-azure-workspace-id-input"
              errorText={workspaceIdValidationError}
            />
            <TextInput
              label="Primary Key"
              value={azureSharedKey}
              onChange={handleAzureSharedKeyChange}
              placeholder="your-primary-key"
              fluid
              data-testid="log-forwarding-azure-shared-key-input"
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={() => setStep(0)}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              loading={loading}
              data-testid="log-forwarding-create"
              disabled={!isValidPayload}
            >
              Create process
            </Button>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};
