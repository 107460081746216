import { TenantType } from 'entities/tenant';
import { SessionState } from 'state/session-store';
import { covers } from 'store/hooks/permissions';
import { Action } from 'types/user';

export const isLogForwardingEnabled = (session: SessionState): boolean => {
  const { permissions, tenant } = session;

  // There's no guarantee that tenant is successfully loaded
  if (!tenant) return false;

  return (
    tenant.capabilities.log_forwarding &&
    tenant.tenantType === TenantType.ENTERPRISE &&
    covers(permissions, Action.READ, `namespaces/${tenant.id}/log-forwarding`)
  );
};
