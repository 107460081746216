import { CloudProvider, Region, Tier } from 'entities/database';
import { Product } from 'types/product';
import { TenantType, ProviderConfigs } from '../model';
import { SessionState } from 'state/session-store';
import { PlanType } from 'entities/tenant';

export const availableRegionsForCloudProviderAndTier = (
  providerConfigs: ProviderConfigs,
  cloudProvider: CloudProvider,
  tier: Tier
): Region[] => providerConfigs[cloudProvider]?.tiers[tier]?.regions || [];

export const availableRegionsForTier = (providerConfigs: ProviderConfigs, tier: Tier): Region[] => {
  const cloudProvider = getCloudProvider(providerConfigs);
  return availableRegionsForCloudProviderAndTier(providerConfigs, cloudProvider, tier);
};

export const getProductFromTier = (tier: Tier): Product => {
  switch (tier) {
    case Tier.AURA_DSE:
    case Tier.GDS:
      return Product.AURA_DS;
    case Tier.ENTERPRISE:
    case Tier.FREE:
    case Tier.PROFESSIONAL:
    case Tier.MTE:
      return Product.AURA_DB;
    default:
      throw new Error(`Tier ${tier} does not belong to a product`);
  }
};

export const getAvailableTiers = (session: SessionState): Set<Tier> => {
  const availableTiersSet = new Set<Tier>();

  Object.values(session.providerConfigs).forEach(({ tiers }) => {
    Object.keys(tiers).forEach((tier: Tier) => {
      availableTiersSet.add(tier);
    });
  });

  // TODO: is there some way we can move this logic to the API?
  if (!session.allowFreeDatabaseCreation || session.tenant.tenantType !== TenantType.PERSONAL) {
    availableTiersSet.delete(Tier.FREE);
  }

  // And this?
  switch (session.product) {
    case Product.AURA_DB:
      availableTiersSet.delete(Tier.GDS);
      availableTiersSet.delete(Tier.AURA_DSE);
      break;
    case Product.AURA_DS:
      availableTiersSet.delete(Tier.FREE);
      availableTiersSet.delete(Tier.PROFESSIONAL);
      availableTiersSet.delete(Tier.ENTERPRISE);
      availableTiersSet.delete(Tier.MTE);
      break;
  }

  switch (session.planType) {
    case PlanType.SELF_SERVE:
      availableTiersSet.delete(Tier.AURA_DSE);
      break;
    case PlanType.ENTERPRISE:
      availableTiersSet.delete(Tier.FREE);
      availableTiersSet.delete(Tier.PROFESSIONAL);
      availableTiersSet.delete(Tier.MTE);
      availableTiersSet.delete(Tier.GDS);
      break;
  }

  return availableTiersSet;
};

export const getAvailableProducts = (providerConfigs: ProviderConfigs): Product[] => {
  const availableProductsSet = new Set<Product>();
  Object.values(providerConfigs).forEach(({ tiers }) => {
    Object.keys(tiers).forEach((tier: Tier) => {
      const product = getProductFromTier(tier);
      availableProductsSet.add(product);
    });
  });
  return Array.from(availableProductsSet);
};

export const getCloudProvider = (providerConfigs: ProviderConfigs): CloudProvider => {
  const cloudProviders = Object.keys(providerConfigs) as CloudProvider[];

  if (cloudProviders.length === 0) {
    throw new Error('Namespace is missing support for any cloud providers');
  }

  return cloudProviders.find(cp => cp === CloudProvider.GCP) || cloudProviders[0];
};

export const isProductAvailable = (providerConfigs: ProviderConfigs, product: Product): boolean =>
  getAvailableProducts(providerConfigs).includes(product);

export const getAvailableCloudProviders = (
  providerConfigs: ProviderConfigs,
  tenantType: TenantType
) => {
  const cloudProviders = Object.keys(providerConfigs) as CloudProvider[];
  switch (tenantType) {
    case TenantType.MARKETPLACE_AWS:
      return cloudProviders.filter(cp => cp === CloudProvider.AWS);
    case TenantType.MARKETPLACE_AZURE:
      return cloudProviders.filter(cp => cp === CloudProvider.AZURE);
    case TenantType.N4GCP:
      return cloudProviders.filter(cp => cp === CloudProvider.GCP);
    default:
      return cloudProviders;
  }
};

export const filterProviderConfigFromTenant = (
  providerConfigs: ProviderConfigs,
  tenantType: TenantType
) => {
  switch (tenantType) {
    case TenantType.MARKETPLACE_AWS:
      return { [CloudProvider.AWS]: providerConfigs.aws };
    case TenantType.MARKETPLACE_AZURE:
      return { [CloudProvider.AZURE]: providerConfigs.azure };
    case TenantType.N4GCP:
      return { [CloudProvider.GCP]: providerConfigs.gcp };
  }
  return providerConfigs;
};
