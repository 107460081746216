import React, { useState } from 'react';
import { Alert, Button, Page } from 'foundation';
import { useSession } from 'store';
import Actions from 'actions';
import { InviteDetails } from 'types/invite';
import { SessionStore } from 'state';
import { invitesToShow } from 'application/invite-consent';
import { InviteConsentItem } from 'application/invite-consent/invite-consent-item';
import logger from 'logger';
import { errorToFriendly } from 'application/invite-consent/error-messages';
import { useDarkTheme } from 'utils/hooks';

export const InviteConsentPage = () => {
  const session = useSession();
  const invites = invitesToShow(session);
  const isDarkTheme = useDarkTheme();

  const [loading, setLoading] = useState({ reject: false, accept: false });
  const [error, setError] = useState('');

  const [consentedInvites, setConsentedInvites] = useState<InviteDetails[]>([]);

  const handleCheckedChange = (invite: InviteDetails, checked: boolean) => {
    if (checked) {
      setConsentedInvites([...consentedInvites, invite]);
    } else {
      setConsentedInvites(consentedInvites.filter(value => value.InviteId !== invite.InviteId));
    }
  };

  const handleReject = async () => {
    setLoading({ reject: true, accept: false });

    try {
      for (let invite of consentedInvites) {
        await Actions.invites.declineInvite(invite.InviteId);
      }

      setLoading({ reject: false, accept: false });
      SessionStore.setHideInviteConsentForm(true);
      Actions.invites.refreshUserInvites(session.userId).catch(err => logger.error(err));
    } catch (e) {
      setLoading({ ...loading, reject: false });
      setError(errorToFriendly(e));
    }
  };

  const handleConfirm = async () => {
    if (loading.accept || loading.reject) {
      return;
    }
    setLoading({ reject: false, accept: true });

    try {
      for (let invite of consentedInvites) {
        await Actions.invites.acceptInvite(invite.InviteId);
      }
      setLoading({ reject: false, accept: false });
      Actions.tenants.clear();
    } catch (e) {
      setLoading({ ...loading, accept: false });
      setError(errorToFriendly(e));
    }
  };

  const actionDisabled = loading.accept || loading.reject || consentedInvites.length === 0;
  const theme = isDarkTheme ? 'ndl-theme-dark' : 'ndl-theme-light';

  return (
    <div className={`console-page ${theme}`}>
      <div className="console-content tw-py-12">
        <Page>
          <div className="tw-m-8">
            <h4>Project invitation</h4>
            <div className="n-body-large tw-my-6">
              You have been invited to join the following project(s):
            </div>
            <ul>
              {invites.map(invite => (
                <InviteConsentItem
                  invite={invite}
                  checked={consentedInvites.includes(invite)}
                  onCheckedChange={handleCheckedChange}
                  key={invite.InviteId}
                />
              ))}
            </ul>
            {error && <Alert className="tw-my-6" type="danger" description={error} />}
            <div className="tw-gap-3 tw-flex">
              <Button
                onClick={() => SessionStore.setHideInviteConsentForm(true)}
                disabled={loading.accept || loading.reject}
                data-testid="postpone-invites-button"
                color="neutral"
                fill="text"
              >
                Later
              </Button>
              <Button
                onClick={handleReject}
                loading={loading.reject}
                disabled={actionDisabled}
                data-testid="reject-invites-button"
                color="danger"
                fill="outlined"
              >
                Decline
              </Button>
              <Button
                onClick={handleConfirm}
                loading={loading.accept}
                disabled={actionDisabled}
                data-testid="accept-invites-button"
              >
                Accept invite(s)
              </Button>
            </div>
          </div>
        </Page>
      </div>
    </div>
  );
};
