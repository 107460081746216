import { fromUnixTime, isValid, format } from 'date-fns';

export const dateFromUnixTimestamp = (timestamp: number | string): Date => {
  const timestampNum = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
  return fromUnixTime(timestampNum);
};

export const safeFormat: typeof format = (date, formatStr, options) => {
  if (isValid(date)) {
    return format(date, formatStr, options);
  }
  return 'Invalid Date';
};
