import { PartnerMessage } from 'application/partner/partner';
import { Alert, Button, Dialog, LoadingSpinner, TextLink, Typography } from 'foundation';
import CardBrand from 'ui/brands';
import { BillingMethod, TenantType } from 'entities/tenant';
import React, { useState, SyntheticEvent } from 'react';
import { BillingAddress, CustomerCardDetails } from 'types/customer';
import { SessionState } from 'state/session-store';
import { useTracking } from 'react-tracking';
import StripeForm from 'application/billing-form';
import countryList from 'components/application/billing-form/country-list';
import cn from 'classnames';

const NEO4_CONTACT_LINK = 'https://neo4j.com/contact-us/';

export interface BillingData {
  fetching: boolean;
  error?: string;
  card?: CustomerCardDetails;
  address?: BillingAddress;
  serviceAddress?: BillingAddress;
  companyName?: string;
  isServiceAddress?: boolean;
}

interface BillingCardSectionProps {
  session: SessionState;
  originalData: BillingData;
  onSuccess: () => any;
}

const toAddressString = (address: BillingAddress) => {
  return `${address?.address_line1 ? `${address.address_line1}, ` : ' '}${address?.address_city}, ${
    address.address_zip
  } ${countryList.find(country => [country.label, country.value].includes(address.address_country))
    ?.label ?? address.address_country}`;
};

export const BillingCardSection = ({
  originalData,
  session,
  onSuccess,
}: BillingCardSectionProps) => {
  const tracking = useTracking();
  const [open, setOpen] = useState(false);
  const [showInvoiceAlert, setShowInvoiceAlert] = useState(false);
  const {
    hasBilling,
    tenantType,
    googleProjectId,
    hasUnpaidInvoices,
    billingMethod,
    capabilities,
  } = session.tenant;

  const hasPrepaidBillingMethod = billingMethod === BillingMethod.PREPAID;

  const handleSuccess = () => {
    if (hasUnpaidInvoices) {
      setShowInvoiceAlert(true);
      setTimeout(() => {
        setShowInvoiceAlert(false);
      }, 5000);
    }
    onSuccess();
  };

  const handleSwitchBilling = (e: SyntheticEvent) => {
    e.stopPropagation();

    tracking.trackEvent({
      action: 'switch_billing_link',
      properties: {
        billingMethod,
      },
    });
  };

  const SwitchBillingLink = () => (
    <Typography variant="body-medium" data-testid="switch-billing-link">
      <TextLink href={NEO4_CONTACT_LINK} externalLink onClick={handleSwitchBilling}>
        Switch to prepaid billing. Contact us
      </TextLink>
    </Typography>
  );

  const PaymentMethod = () => {
    if (originalData.fetching) {
      return <LoadingSpinner expand className="tw-w-full" />;
    }
    if (tenantType === TenantType.PERSONAL) {
      return (
        <>
          <div className="tw-grid tw-grid-cols-2 tw-gap-x-1 tw-mb-4 tw-w-full">
            <div className="tw-self-center">
              <CardMessage />
            </div>
          </div>
          <div className="tw-flex tw-absolute tw-right-8 tw-top-7 tw-gap-x-2 tw-items-baseline">
            {!hasPrepaidBillingMethod && (
              <>
                {capabilities.multi_tenant_enterprise && <SwitchBillingLink />}
                <Button
                  color="neutral"
                  fill="outlined"
                  data-testid="add-payment-method"
                  title="Payment method"
                  size="small"
                  aria-label="Add payment method"
                  className="tw-mt-1"
                  onClick={() => setOpen(true)}
                >
                  {hasBilling ? 'Replace payment method' : 'Add new payment method'}
                </Button>
              </>
            )}
          </div>
        </>
      );
    }
    if (tenantType === TenantType.N4GCP) {
      return <PartnerMessage googleProjectId={googleProjectId} />;
    }

    return null;
  };
  const CardMessage = () => {
    if (!hasBilling) {
      return (
        <span data-testid="no-payment-method">
          There is no payment method attached to your account
        </span>
      );
    }

    if (originalData.error) {
      return <span data-testid="fetching-card-error">{originalData.error}</span>;
    }

    if (!originalData.fetching) {
      return (
        <div
          className={cn(
            'tw-text-palette-neutral-text-weak tw-grid tw-grid-cols-2 tw-items-center tw-gap-4',
            { 'tw-text-palette-neutral-text-weakest': hasPrepaidBillingMethod }
          )}
        >
          <div className="n-label">Credit/Debit card</div>
          <div className={'tw-text-red tw-flex tw-flex-row'}>
            <div className="tw-pr-4 tw-whitespace-nowrap">
              <CardBrand brand={originalData.card?.brand} disabled={hasPrepaidBillingMethod} />
            </div>

            <div
              className="tw-pr-12 n-body-medium tw-whitespace-nowrap"
              data-testid="cc-digits"
            >{`•••• •••• •••• ${originalData.card?.last4}`}</div>
            <div className="n-body-medium">{`${originalData.card?.expMonth?.padStart(
              2,
              '0'
            )}/${originalData.card?.expYear?.substring(2)}`}</div>
          </div>
          {/* 
            We need to have this check in order to stay backwards compatiable since
            most users won't have an address connected to their account
          */}
          {(originalData.address || originalData.card?.address) && (
            <>
              <div className="n-label">Billing address for this card</div>
              <div className="n-body-medium tw-whitespace-nowrap">
                {toAddressString(
                  originalData.address ?? originalData.serviceAddress ?? originalData.card?.address
                )}
              </div>
            </>
          )}
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <div
        className="console-payment-method tw-relative tw-p-12 tw-bg-palette-neutral-bg-weak tw-rounded-tr-lg tw-rounded-b-lg"
        data-testid="payment-section"
      >
        <h5 className="tw-mb-8">Payment Method</h5>
        {hasPrepaidBillingMethod && (
          <div className="n-subheading-medium tw-bg-palette-neutral-bg-weaker tw-mb-4">
            This tenant has prepaid billing
          </div>
        )}
        <PaymentMethod />
        {showInvoiceAlert && (
          <Alert
            icon
            type="info"
            title="Invoice"
            className="tw-absolute tw-top-24 tw-right-8 tw-ml-8"
            style={{ maxWidth: 620, zIndex: 500 }}
          >
            <div className="n-body-medium">
              Any unpaid invoices will be payed within a couple of days.
            </div>
          </Alert>
        )}
      </div>
      <PaymentsModal
        session={session}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export interface PaymentsModalProps {
  session: SessionState;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const PaymentsModal = ({ session, open, onClose, onSuccess }: PaymentsModalProps) => {
  const [success, setSuccess] = useState(false);

  const tracking = useTracking();

  const handleSuccess = () => {
    tracking.trackEvent({ action: 'add_payment' });
    setSuccess(true);
    onSuccess();
    setTimeout(() => {
      setSuccess(false);
      onClose();
    }, 2000);
  };

  return (
    <Dialog open={open} onClose={onClose} size="large">
      <div className="tw-mx-auto console-w-fit">
        <div className="tw-w-full tw-mx-auto tw-my-0 console-max-w-medium">
          <StripeForm
            onSuccess={handleSuccess}
            onCancel={onClose}
            session={session}
            submitButtonText="Add card"
            cancelButtonText="Cancel"
          />
          {success && (
            <Alert
              closeable
              onClose={() => setSuccess(null)}
              type="success"
              title="Success"
              description="Card added to account"
              className="tw-mt-2"
              data-testid="billing-success"
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
