import React from 'react';
import classnames from 'classnames';
import './divider.css';

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  hidden?: boolean;
  horizontal?: boolean;
}

export const Divider = ({ className, horizontal, hidden, ...rest }: DividerProps) => {
  const classes = classnames('console-divider', className, {
    'console-divider-hidden': hidden,
    'console-divider-horizontal': horizontal,
  });
  return <div {...rest} className={classes} />;
};
