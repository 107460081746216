import React, { useEffect, useState } from 'react';
import { Page } from 'foundation/page';
import InviteForm from './invite-form';
import { Alert, Link, LoadingSpinner } from 'foundation';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import NamespaceRecourse from 'remote/resources/namespaces';
import { Tenant } from 'entities/tenant';

interface Props {
  tenantId: string;
}

const InvitePage = ({ tenantId }: Props) => {
  const permission = usePermissions();
  const canInviteToTenant = permission.allow(Action.CREATE, `namespaces/${tenantId}/invites`);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [err, setErr] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    NamespaceRecourse.get(tenantId)
      .then(tenantResponse => setTenant(tenantResponse))
      .catch(errResponse => setErr(errResponse))
      .finally(() => setLoading(false));
  }, [tenantId]);

  if (err) {
    return (
      <Page>
        <Alert type="danger" data-testid="user-invite-tenant-error">
          {err}
        </Alert>
      </Page>
    );
  }

  if (loading) {
    return (
      <Page>
        <LoadingSpinner data-testid="user-invite-tenant-loading" />
      </Page>
    );
  }
  return (
    <Page fullWidth>
      {canInviteToTenant ? (
        <>
          <div className="tw-mb-8">
            <Link href={`#admin/projects/${tenantId}/users`}>Go to project users page</Link>
          </div>
          <div>
            <h5 className="tw-mb-4" data-testid="invite-heading">
              Invite User to Project: {tenantId}
            </h5>
            <InviteForm tenant={tenant} />
          </div>
          <div className="tw-mt-8">
            <Link href="#admin/users">Back to lookup users page</Link>
          </div>
        </>
      ) : (
        <h5 data-testid="invite-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default InvitePage;
