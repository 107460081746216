import centralityImg from 'components/ui/images/algorithm-categories/centrality-and-importance.svg';
import pathfindingImg from 'components/ui/images/algorithm-categories/neo4j-pathfinder.svg';
import communityDetectionImg from 'components/ui/images/algorithm-categories/community-detection.svg';
import nodeEmbeddingImg from 'components/ui/images/algorithm-categories/graph-embeddings.svg';
import similarityImg from 'components/ui/images/algorithm-categories/similarity.svg';
import machineLearningImg from 'components/ui/images/algorithm-categories/graph-native-machine-learning.svg';

import Icon from 'ui/icons';
import React from 'react';

import './algorithm-category-cards.css';

interface AlgoCategoryCardProps {
  img: any;
  title: string;
  children: any;
  selected: boolean;
  onClick(): void;
  testid: string;
  disabled: boolean;
}

const AlgoCategoryCard = ({
  img,
  title,
  children,
  selected,
  onClick,
  testid,
  disabled,
}: AlgoCategoryCardProps) => (
  <button
    onClick={onClick}
    data-testid={testid}
    role="checkbox"
    aria-checked={selected}
    type="button"
    className={
      disabled ? 'console-algorithm-category-card disabled' : 'console-algorithm-category-card'
    }
  >
    <div className="tw-flex tw-flex-row tw-mb-4 tw-h-16 tw-items-start">
      <img className="tw-mr-3 tw-w-14 tw-max-h-16" src={img} />
      <h6>{title}</h6>
    </div>
    <p className="n-body-small">{children}</p>
    {selected && (
      <Icon
        className="tw-absolute tw-right-2 tw-top-2 tw-text-palette-primary-icon"
        name="CheckCircleIconSolid"
        size="large"
        aria-label="Algorithm Selected"
      />
    )}
  </button>
);

interface AlgoCategoryCardInstanceProps {
  selected: boolean;
  onClick(): void;
  testid: string;
  disabled: boolean;
}

export const PathfindingAndSearch = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={pathfindingImg} title="Pathfinding & Search" {...props}>
    Find optimal paths and evaluate the availability and quality of paths.
    <br />
    <i>e.g. Dijkstra Single-Source</i>
  </AlgoCategoryCard>
);

export const CentralityAndImportance = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={centralityImg} title="Centrality & Importance" {...props}>
    Determine the importance of distinct nodes within your network.
    <br />
    <i>e.g PageRank</i>
  </AlgoCategoryCard>
);

export const CommunityDetection = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={communityDetectionImg} title="Community Detection" {...props}>
    Detect how groups of nodes are clustered or partitioned.
    <br />
    <i>e.g. Louvain</i>
  </AlgoCategoryCard>
);

export const Similarity = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={similarityImg} title="Similarity" {...props}>
    Evaluate how alike nodes within the graph are.
    <br />
    <i>e.g. Node Similarity</i>
  </AlgoCategoryCard>
);

export const NodeEmbedding = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={nodeEmbeddingImg} title="Node Embedding" {...props}>
    Compute low-dimensional vector representations of nodes within your graph.
    <br />
    <i>e.g. Node2Vec</i>
  </AlgoCategoryCard>
);

export const SupervisedMachineLearning = (props: AlgoCategoryCardInstanceProps) => (
  <AlgoCategoryCard img={machineLearningImg} title="Supervised Machine Learning" {...props}>
    Train and save supervised machine learning models to make predictions on your graph.
    <br />
    <i>e.g. Node Classification</i>
  </AlgoCategoryCard>
);
