export enum Product {
  AURA_DB = 'aura-db',
  AURA_DS = 'aura-ds',
}

export const findProductByValue = (valueToFind: string): Product | null => {
  const match = Object.entries(Product).find(entry => entry[1] === valueToFind);
  return match ? match[1] : null;
};

export const DEFAULT_PRODUCT = Product.AURA_DB;

const productFriendlyNameMap: Record<Product, string> = {
  [Product.AURA_DB]: 'AuraDB',
  [Product.AURA_DS]: 'AuraDS',
};
export const productFriendlyName = (product: Product): string => {
  return productFriendlyNameMap[product];
};
