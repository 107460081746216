import React, { useState, useEffect } from 'react';
import FeatureToggleOverridesResource, {
  FeatureToggleOverride,
} from 'remote/resources/feature-toggle-overrides';
import { FeatureTogglesOverviewTable } from './feature-toggles-overview-table';
import { Alert, LoadingSpinner, FormSelect } from 'foundation';
import { useSession } from 'store';

interface Props {
  className?: string;
}

export const FeatureTogglesOverview = ({ className }: Props) => {
  // Dropdown state / select / options for filtering by feature toggle name
  const [featureToggleFilter, setFeatureToggleFilter] = useState<string>(null);
  const handleFeatureToggleChange = e => setFeatureToggleFilter(e.value);
  const { featureToggles } = useSession();
  const featureToggleOptions = Object.keys(featureToggles).map(ft => ({
    key: ft,
    value: ft,
    label: ft,
  }));

  // States for loading the filtered feature toggle overrides from the API
  const [featureToggleOverrides, setFeatureToggleOverrides] = useState<FeatureToggleOverride[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>(null);

  useEffect(() => {
    if (featureToggleFilter) {
      setLoading(true);
      FeatureToggleOverridesResource.listByFeatureToggle(featureToggleFilter)
        .then(setFeatureToggleOverrides)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [featureToggleFilter]);

  return (
    <div className={className}>
      <div className="tw-flex tw-mb-4">
        <h5 className="tw-flex-1">Feature toggles overview</h5>
      </div>
      <FormSelect
        value={featureToggleFilter}
        onChange={handleFeatureToggleChange}
        options={featureToggleOptions}
        label="Feature toggle"
        placeholder="Select"
      />
      {loading && <LoadingSpinner className="tw-mt-8" />}
      {error && (
        <Alert type="danger" className="tw-mt-8">
          {String(error)}
        </Alert>
      )}
      {!loading && !error && (
        <div className="tw-mt-8">
          <FeatureTogglesOverviewTable data={featureToggleOverrides} />
        </div>
      )}
    </div>
  );
};
