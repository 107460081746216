import { Button, Alert } from 'foundation';
import React from 'react';

export const SuccessMessage = () => (
  <Alert type="success" title="Upload complete!" data-testid="upload-successful">
    <p>{"Your database will go in to a 'loading' state as the dump is being applied."}</p>
  </Alert>
);

export const UploadAgain = ({ onTryAgainClick }) => (
  <Button data-testid="upload-again" onClick={onTryAgainClick}>
    Try upload again
  </Button>
);

export const ErrorMessage = ({ message }) => (
  <>
    {!!message && (
      <Alert type="danger" title="Error" data-testid="upload-error">
        <p>{message}</p>
      </Alert>
    )}
  </>
);

export const InfoMessage = ({ message, header }) => (
  <>
    {!!message && (
      <Alert type="info" title={header} data-testid="upload-info">
        <p>{message}</p>
      </Alert>
    )}
  </>
);
