/* eslint-disable no-console */

enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

const levelToScale = {
  [LogLevel.DEBUG]: 0,
  [LogLevel.INFO]: 1,
  [LogLevel.WARN]: 2,
  [LogLevel.ERROR]: 3,
};

const environment = process.env.NODE_ENV;
const logsEnabled = ['development', 'test'].includes(environment);
const logLevel = process.env.LOG_LEVEL ?? LogLevel.INFO;

const isLogsEnabled = (logType: LogLevel) => {
  if (!logsEnabled) return false;
  if (levelToScale[logLevel] > levelToScale[logType]) return false;

  return true;
};

const log: typeof console.log = (...data) => {
  if (isLogsEnabled(LogLevel.INFO)) console.log(...data);
};

const info: typeof console.info = (...data) => {
  if (isLogsEnabled(LogLevel.INFO)) console.info(...data);
};

const debug: typeof console.debug = (...data) => {
  if (isLogsEnabled(LogLevel.DEBUG)) console.debug(...data);
};

const warn: typeof console.warn = (...data) => {
  if (isLogsEnabled(LogLevel.WARN)) console.warn(...data);
};

const error: typeof console.error = (...data) => {
  if (isLogsEnabled(LogLevel.ERROR)) console.error(...data);
};

export default { log, info, debug, warn, error };
