import React, { useState } from 'react';
import logForwardingBlankImage from 'components/ui/images/log-forwarding.svg';
import { Page } from 'components/foundation/page';
import { usePermissions, useSession } from 'store';
import { Alert, Button } from 'components/foundation';
import LogForwardingResource, {
  CloudwatchDestination,
  CreateLogForwardingPayload,
  DestinationType,
  EditLogForwardingPayload,
  LogAnalyticsDestination,
  LogForwarding,
  StackdriverDestination,
} from 'remote/resources/log-forwarding';
import { useQuery } from 'react-query';
import { CreateConfigModal, MAX_LOG_FWD_PROCESSES } from './create-config-modal';
import { getCloudProvider } from 'entities/tenant';
import { useModalState } from 'utils/use-modal-state';
import { LogForwardingTable } from './log-forwarding-table';
import { ConfirmDeleteModal } from './confirm-delete-modal';
import { useDatabasesRequest } from 'components/helpers/databases';
import {
  EditStackdriverConfigModal,
  EditLogAnalyticsConfigModal,
  EditCloudwatchConfigModal,
} from './edit-config-modal';
import logger from 'logger';
import { Action } from 'types/user';
import { filter } from 'lodash';

const LogForwardingPage = () => {
  const { tenant } = useSession();
  const cloudProvider = getCloudProvider(tenant.providerConfigs);
  const [itemToDelete, setItemToDelete] = useState<LogForwarding>(null);
  const [itemToEdit, setItemToEdit] = useState<LogForwarding>(null);
  const dbsRequest = useDatabasesRequest();
  const createModal = useModalState();
  const deleteModal = useModalState();
  const editModal = useModalState();

  const logForwardingQuery = useQuery(
    ['log-forwarding', tenant.id],
    () => LogForwardingResource.list(tenant.id),
    { refetchInterval: 30 * 1000 }
  );

  const logForwardingIdentities = useQuery(['log-forwarding-identities', tenant.id], () =>
    LogForwardingResource.listIdentities(tenant.id)
  );

  const createLogForwarding = (payload: CreateLogForwardingPayload) => {
    createModal.setLoading(true);
    LogForwardingResource.create(payload)
      .then(() => {
        // ideally, instead of refetching, we should just modify the array :thinking:
        // react-query makes this difficult
        logForwardingQuery.refetch();
        createModal.close();
        createModal.refreshKey();
      })
      .catch(err => {
        createModal.setError(err);
      })
      .finally(() => {
        createModal.setLoading(false);
      });
  };

  const showConfirmDeletionModal = (logForwarding: LogForwarding) => {
    setItemToDelete(logForwarding);
    deleteModal.show();
  };

  const closeDeleteModal = () => {
    deleteModal.close();
    deleteModal.setError(null);
    deleteModal.setLoading(false);
    setItemToDelete(null);
  };

  const deleteLogForwarding = () => {
    deleteModal.setLoading(true);
    LogForwardingResource.delete(tenant.id, itemToDelete.id)
      .then(() => {
        logForwardingQuery.refetch();
        setItemToDelete(null);
        deleteModal.close();
      })
      .catch(err => {
        deleteModal.setError(err);
      })
      .finally(() => {
        deleteModal.setLoading(false);
      });
  };

  const showEditModal = (logForwarding: LogForwarding) => {
    setItemToEdit(logForwarding);
    editModal.show();
  };

  const closeEditModal = () => {
    editModal.close();
    editModal.setError(null);
    editModal.setLoading(false);
    setItemToEdit(null);
  };

  const editLogForwarding = (payload: EditLogForwardingPayload) => {
    editModal.setLoading(true);
    LogForwardingResource.edit(tenant.id, itemToEdit.id, payload)
      .then(() => {
        logForwardingQuery.refetch();
        setItemToEdit(null);
        editModal.close();
      })
      .catch(err => {
        logger.error(err);
        editModal.setError(err);
      })
      .finally(() => {
        editModal.setLoading(false);
      });
  };

  if (logForwardingQuery.isLoading || logForwardingIdentities.isLoading || dbsRequest.loading) {
    return <Page loading />;
  }
  if (logForwardingQuery.isError) {
    return <Alert type="danger">{String(logForwardingQuery.error)}</Alert>;
  }
  if (logForwardingIdentities.isError) {
    return <Alert type="danger">{String(logForwardingIdentities.error)}</Alert>;
  }
  if (dbsRequest.error) {
    return <Alert type="danger">{String(dbsRequest.error)}</Alert>;
  }

  return (
    <Page plain style={{ maxWidth: 'unset' }}>
      {logForwardingQuery.data.length === 0 ? (
        <>
          <h4>Log Forwarding</h4>
          <div className="tw-text-center">
            <img src={logForwardingBlankImage} className="tw-mx-auto" />
            <Button data-testid="create-log-forwarding-button" onClick={createModal.show}>
              Create new log forwarding process
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="tw-flex tw-mb-6">
            <h4 className="tw-flex-1">Log Forwarding</h4>
            <Button onClick={createModal.show}>Create new log forwarding process</Button>
          </div>
          <LogForwardingTable
            data={logForwardingQuery.data}
            logForwardingIdentities={logForwardingIdentities.data}
            cloudProvider={cloudProvider}
            onEdit={showEditModal}
            onDelete={showConfirmDeletionModal}
          />
        </>
      )}

      <CreateConfigModal
        cloudProvider={cloudProvider}
        tenant={tenant}
        onClose={createModal.close}
        onCreate={createLogForwarding}
        open={createModal.visible}
        loading={createModal.loading}
        error={createModal.error}
        key={createModal.key}
        logForwardingIdentities={logForwardingIdentities.data}
        hasReachedProcessLimit={(region, tier) => {
          return (
            filter(logForwardingQuery.data, { region: region, tier: tier }).length >=
            MAX_LOG_FWD_PROCESSES
          );
        }}
      />
      {itemToDelete && (
        <ConfirmDeleteModal
          logForwarding={itemToDelete}
          region={itemToDelete.region}
          onConfirm={deleteLogForwarding}
          open={deleteModal.visible}
          onClose={closeDeleteModal}
          loading={deleteModal.loading}
          error={deleteModal.error}
        />
      )}
      {itemToEdit && (
        <>
          {itemToEdit.destination.type === DestinationType.STACKDRIVER && (
            <EditStackdriverConfigModal
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              logForwarding={itemToEdit as LogForwarding<StackdriverDestination>}
              onEdit={editLogForwarding}
            />
          )}
          {itemToEdit.destination.type === DestinationType.LOG_ANALYTICS && (
            <EditLogAnalyticsConfigModal
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              logForwarding={itemToEdit as LogForwarding<LogAnalyticsDestination>}
              onEdit={editLogForwarding}
            />
          )}
          {itemToEdit.destination.type === DestinationType.CLOUDWATCH && (
            <EditCloudwatchConfigModal
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              logForwarding={itemToEdit as LogForwarding<CloudwatchDestination>}
              onEdit={editLogForwarding}
            />
          )}
        </>
      )}
    </Page>
  );
};

const LogForwardingPageGuard = () => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowReadLogForwarding = allow(
    Action.READ,
    `namespaces/${session.currentTenant}/log-forwarding`
  );

  if (!allowReadLogForwarding) {
    return <Alert type="danger">You do not have sufficient permissions to access this page.</Alert>;
  }

  return <LogForwardingPage />;
};

export default LogForwardingPageGuard;
