import React, { ChangeEvent, useState } from 'react';
import { IdpType } from 'types/sso-config';
import { Alert, Checkbox, FormInput, FormSelect } from 'foundation';
import { Validation } from 'utils/validation';
import { TenantMultiSelect } from 'components/pages/organization-settings/sso-config/tenant-multi-select';
import { LinkedTenant, SsoConfigFormAction, SsoConfigFormData } from '../types';

export interface SsoConfigFormFieldsProps {
  config: SsoConfigFormData;
  onChange(data: SsoConfigFormData): void;
  validation: Validation<SsoConfigFormData>;
  formAction: SsoConfigFormAction;
  organizationId?: string;
  isSSOAllowed: boolean;
}

const SsoConfigFormFields = ({
  config,
  onChange,
  validation,
  formAction,
  organizationId,
  isSSOAllowed,
}: SsoConfigFormFieldsProps) => {
  const isEdit = formAction === SsoConfigFormAction.UPDATE;
  const isCreate = formAction === SsoConfigFormAction.CREATE;
  const [showAdditionalFields, setShowAdditionalFields] = useState(
    !!(config.issuer || config.authorizationEndpoint || config.jwksURI || config.tokenEndpoint)
  );
  const updateData = (param: string, value: any) => onChange({ ...config, [param]: value });

  const handleDisplayNameChange = ({
    target: { value: displayName },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('displayName', displayName);
  };

  const handleIdpTypeChange = (idpType: IdpType) => {
    updateData('idpType', idpType);
  };

  const handleClientSecretChange = ({
    target: { value: clientSecret },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('clientSecret', clientSecret);
  };

  const handleRoleMappingChange = ({
    target: { value: roleMapping },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('roleMapping', roleMapping);
  };

  const handleDiscoveryURIChange = ({
    target: { value: discoveryURI },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('discoveryURI', discoveryURI);
  };

  const handleIssuerChange = ({ target: { value: issuer } }: ChangeEvent<HTMLInputElement>) => {
    updateData('issuer', issuer);
  };

  const handleAuthorizationEndpointChange = ({
    target: { value: authorizationEndpoint },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('authorizationEndpoint', authorizationEndpoint);
  };

  const handleTokenEndpointChange = ({
    target: { value: tokenEndpoint },
  }: ChangeEvent<HTMLInputElement>) => {
    updateData('tokenEndpoint', tokenEndpoint);
  };

  const handleJwksURIChange = ({ target: { value: jwksURI } }: ChangeEvent<HTMLInputElement>) => {
    updateData('jwksURI', jwksURI);
  };

  const handleClientIDChange = ({ target: { value: clientID } }: ChangeEvent<HTMLInputElement>) => {
    updateData('clientID', clientID);
  };

  const handleOrganizationLoginMethodChange = ({
    target: { checked: organizationLoginMethod },
  }) => {
    updateData('organizationLoginMethod', organizationLoginMethod);
  };

  const handleTenantLoginMethodChange = ({ target: { checked: tenantLoginMethod } }) => {
    updateData('tenantLoginMethod', tenantLoginMethod);
  };

  const handleLinkedTenantsChange = (tenants: LinkedTenant[]) => {
    updateData('linkedTenants', tenants);
  };

  return (
    <>
      <div className="tw-mt-4">
        <Checkbox
          label="Use as login method for the Organization"
          checked={config?.organizationLoginMethod}
          onChange={handleOrganizationLoginMethodChange}
          data-testid="sso-organization-login-method-check"
        />
        <Checkbox
          label="Use as login method for instances within Projects in this Org"
          checked={config?.tenantLoginMethod}
          onChange={handleTenantLoginMethodChange}
          data-testid="sso-tenant-login-method-check"
        />
        {config?.tenantLoginMethod && (
          <div className="tw-flex tw-mt-2">
            <Alert
              type="warning"
              description="Adding or deleting sso configs will only be applied to new instances in the project. Existing instances will not be affected."
            />
            {isSSOAllowed && (
              <Alert
                type="warning"
                description="Instance SSO is only available for Business Critical instances. Professional and Free instances within your selected projects will not have SSO configured on them."
              />
            )}
          </div>
        )}
      </div>
      {config?.tenantLoginMethod && (
        <div className="tw-mt-4">
          <TenantMultiSelect
            linkedTenants={config?.linkedTenants.map(t => t.tenantId)}
            organizationId={organizationId}
            setLinkedTenants={handleLinkedTenantsChange}
            errorText={validation?.tenantLoginMethod?.message}
          />
        </div>
      )}

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Display Name"
          aria-label="Display name"
          data-testid="sso-config-display-name-input"
          value={config?.displayName}
          onChange={handleDisplayNameChange}
          errorText={validation?.displayName?.message}
          required
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          Users will see this name when they try to login to Aura Console or an instance via SSO.
        </div>
      </div>

      <div className="tw-mt-4">
        <FormSelect
          searchable={false}
          value={config?.idpType}
          label="Identity Provider (IdP)"
          options={[
            {
              label: 'Okta',
              key: IdpType.OKTA,
              value: IdpType.OKTA,
            },
            {
              label: 'Microsoft Entra ID',
              key: IdpType.AAD,
              value: IdpType.AAD,
            },
          ]}
          onChange={idp => {
            handleIdpTypeChange(idp.value);
          }}
        />
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Client ID"
          aria-label="SSO config client id"
          data-testid="sso-config-client-id-input"
          value={config?.clientID}
          onChange={handleClientIDChange}
          errorText={validation?.clientID?.message}
          required
        />
        <div className="form-field-hint n-body-medium tw-mt-1">{'Provided by your IdP.'}</div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Client Secret"
          placeholder={isEdit ? 'Replace current client secret' : undefined}
          data-testid="sso-config-client-secret-input"
          aria-label="SSO config client secret"
          value={config.clientSecret ?? ''}
          onChange={handleClientSecretChange}
          errorText={validation?.clientSecret?.message}
          required={isCreate}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">{'Provided by your IdP.'}</div>
      </div>

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Discovery URI"
          aria-label="SSO config discovery uri"
          data-testid="sso-config-discovery-uri-input"
          value={config?.discoveryURI}
          onChange={handleDiscoveryURIChange}
          errorText={validation?.discoveryURI?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {
            'Provided by your IdP. For example "https://some-project.okta.com/.well-known/openid-configuration"'
          }
        </div>
      </div>

      {!showAdditionalFields && (
        <span
          className="tw-underline tw-text-palette-primary-text tw-cursor-pointer n-body-medium"
          onClick={() => setShowAdditionalFields(true)}
          data-testid="show-additional-sso-uri-fields"
        >
          Missing a Discovery URI? Need to configure URLs manually?
        </span>
      )}

      {showAdditionalFields && (
        <>
          <div className="tw-mt-4">
            <FormInput
              fluid
              label="Issuer"
              aria-label="SSO config issuer"
              data-testid="sso-config-issuer-input"
              value={config?.issuer}
              onChange={handleIssuerChange}
              errorText={validation?.issuer?.message}
            />
            <div className="form-field-hint n-body-medium tw-mt-1">
              {'Provided by your IdP. For example "https://some-project.okta.com"'}
            </div>
          </div>

          <div className="tw-mt-4">
            <FormInput
              fluid
              label="Authorization Endpoint"
              aria-label="SSO config authorization endpoint"
              data-testid="sso-config-authorization-endpoint-input"
              value={config?.authorizationEndpoint}
              onChange={handleAuthorizationEndpointChange}
              errorText={validation?.authorizationEndpoint?.message}
            />
            <div className="form-field-hint n-body-medium tw-mt-1">
              {
                'Provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/authorize"'
              }
            </div>
          </div>

          <div className="tw-mt-4">
            <FormInput
              fluid
              label="Token Endpoint"
              aria-label="SSO config token endpoint"
              data-testid="sso-config-token-endpoint-input"
              value={config?.tokenEndpoint}
              onChange={handleTokenEndpointChange}
              errorText={validation?.tokenEndpoint?.message}
            />
            <div className="form-field-hint n-body-medium tw-mt-1">
              {'Provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/token"'}
            </div>
          </div>

          <div className="tw-mt-4">
            <FormInput
              fluid
              label="JWKS URI"
              aria-label="SSO config jwks uri"
              data-testid="sso-config-jwks-uri-input"
              value={config?.jwksURI}
              onChange={handleJwksURIChange}
              errorText={validation?.jwksURI?.message}
            />
            <div className="form-field-hint n-body-medium tw-mt-1">
              {
                'URI for JSON Web Key Sets provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/keys"'
              }
            </div>
          </div>
        </>
      )}

      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Role Mapping"
          aria-label="SSO config role mapping"
          data-testid="sso-config-role-mapping-input"
          value={config?.roleMapping}
          onChange={handleRoleMappingChange}
          errorText={validation?.roleMapping?.message}
        />
        <div className="form-field-hint n-body-medium tw-mt-1">
          {'Role mapping only applies for Instance SSO. For example "group1"=role1;"group2"=role2'}
        </div>
      </div>
    </>
  );
};

export default SsoConfigFormFields;
