/* global window */

import 'cookieconsent/build/cookieconsent.min.css';
import 'cookieconsent/build/cookieconsent.min';

import React from 'react';

// Cookieconsent is globally initialized on the window object,
// so we track in a global variable whether we've initialized it,
// independent of how many times this component is mounted.
let COOKIE_CONSENT_INITIALIZED = false;

class CookieConsent extends React.Component {
  render() {
    return <span className="cookie-consent" />;
  }

  componentDidMount() {
    if (COOKIE_CONSENT_INITIALIZED) {
      return;
    }

    COOKIE_CONSENT_INITIALIZED = true;
    (window as any).cookieconsent.initialise({
      palette: {
        popup: {
          background: '#237afc',
        },
        button: {
          background: '#fff',
          text: '#237afc',
        },
      },
      content: {
        message:
          'We use cookies to optimize your experience. ' +
          'By continuing to use the site, you agree to our use of cookies.',
      },
    });
  }
}

export default CookieConsent;
