import { SyntheticEvent, useState } from 'react';

import logger from 'logger';
import Actions from 'actions';
import track, { useTracking } from 'react-tracking';
import { Database, canBeSnapshot } from 'entities/database';

import { SnapshotsStore } from 'state';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';

export interface TakeSnapshotChildArgs {
  onClick: (e: SyntheticEvent) => void;
  loading: boolean;
  disabled: boolean;
  allowTakeSnapshot: boolean;
}

export interface TakeSnapshotProps {
  database: Database;
  children: (args: TakeSnapshotChildArgs) => JSX.Element;
}

const TakeSnapshot = track()(({ database, children }: TakeSnapshotProps) => {
  const session = useSession();
  const tracking = useTracking();
  const [loading, setLoading] = useState(false);
  const { allow } = usePermissions();
  const allowTakeSnapshot = allow(
    Action.CREATE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}/snapshots`
  );

  const handleTakeSnapshotClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    tracking.trackEvent({
      action: 'take_snapshot_db',
      properties: { event_label: 'clicked' },
    });

    setLoading(true);

    logger.debug(`Taking snapshot of database ${database.DbId}!`);
    Actions.databases.takeSnapshot(database.DbId).finally(() => {
      setLoading(false);
    });
  };

  const disabled =
    SnapshotsStore.anySnapshotPendingOrInProgress(database.DbId) || !canBeSnapshot(database);

  return children({
    loading,
    disabled: disabled || loading || !allowTakeSnapshot,
    onClick: handleTakeSnapshotClick,
    allowTakeSnapshot,
  });
});

export default TakeSnapshot;
