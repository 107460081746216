/* eslint-disable max-classes-per-file */

export class ApiClientRequestError extends Error {
  response: Response;
  reason: string | undefined;
  responseMessage: string | undefined;
  extra: any | undefined;
  fieldErrors: Record<string, string[]> | undefined;
  errorId: string | null;

  constructor(
    response: Response,
    reason?: string,
    responseMessage?: string,
    extra?: any,
    fieldErrors?: Record<string, string[]>
  ) {
    super(`Got invalid HTTP response: ${response.status}`);
    this.response = response;
    this.reason = reason;
    this.responseMessage = responseMessage;
    this.extra = extra;
    this.fieldErrors = fieldErrors;
    this.errorId = response.headers.get('x-request-id');
  }
}

export class NetworkError extends Error {}
