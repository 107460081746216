import React, { useEffect, useState } from 'react';

import { Alert, Button, Dialog, Form, FormSelect, SelectOption, TextInput } from 'foundation';
import { RoleName, getTenantRoleOptions } from 'types/user';
import { useSession } from 'store';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';
import { Tenant } from 'entities/tenant';

const emailSchema = yup.object({
  email: yup.string().email('Must be a valid email'),
});

export const validateEmail = data => {
  return validateYup(emailSchema, data, false);
};

interface Props {
  open: boolean;
  inviteInProgress: boolean;
  onClose: () => void;
  onInvite: (tenant: Tenant, email: string, newRoleName: RoleName) => void;
  errorMessage?: React.ReactNode;
}

const InviteUserModal = ({
  open,
  inviteInProgress,
  onClose,
  onInvite,
  errorMessage = null,
}: Props) => {
  const [selectedRoleName, setSelectedRoleName] = useState(RoleName.TENANT_MEMBER);
  const [emailAddress, setEmailAddress] = useState('');
  const [validationError, setValidationError] = useState(null);
  const session = useSession();

  const inviteButtonDisabled = emailAddress === '' || validationError || inviteInProgress;

  useEffect(() => {
    if (!open) {
      resetModal();
    }
  }, [open]);

  const resetModal = () => {
    setEmailAddress('');
    setValidationError(null);
    setSelectedRoleName(RoleName.TENANT_MEMBER);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    setValidationError(null);
    const error = validateEmail({ email: value });
    if (error) {
      setValidationError(error);
    }

    setEmailAddress(value);
  };

  const roleOptions = getTenantRoleOptions(session.tenant);

  return (
    <Dialog size="small" open={open} onClose={onClose}>
      <Dialog.Header>Invite User</Dialog.Header>
      <Dialog.Content>
        {errorMessage && (
          <Alert type="danger" title="Error" description={errorMessage} className="tw-mb-6" />
        )}
        <Form onSubmit={() => onInvite(session.tenant, emailAddress, selectedRoleName)}>
          <TextInput
            fluid
            label="Email"
            placeholder="Email"
            value={emailAddress}
            className="tw-my-6"
            onChange={handleEmailChange}
            errorText={validationError?.email?.message}
          />
          <FormSelect
            label="Role"
            searchable={false}
            value={selectedRoleName}
            options={roleOptions}
            onChange={(role: SelectOption) => {
              setSelectedRoleName(role.value as RoleName);
            }}
          />
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={inviteButtonDisabled}
          loading={inviteInProgress}
          onClick={() => onInvite(session.tenant, emailAddress, selectedRoleName)}
          type="submit"
        >
          Invite
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default InviteUserModal;
