import React, { useCallback } from 'react';
import Actions from 'actions';
import globals from 'browser/globals';
import cx from 'classnames';
import Icon from 'components/ui/icons';

const isExternal = (href: string) => {
  try {
    const url = new URL(href);
    return url.hostname !== globals.window.location.hostname;
  } catch (e) {
    return false;
  }
};

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  href: string;
  children?: React.ReactNode;
  newTab?: boolean;
  onClick?: (event: any) => void;
}

export const Link = ({
  href,
  onClick = null,
  newTab = false,
  children = null,
  className = '',
  ...rest
}: Props) => {
  const handleClick = useCallback(
    event => {
      const external = isExternal(href);

      if (
        event.ctrlKey ||
        event.metaKey ||
        event.altKey ||
        event.shiftKey ||
        event.button !== 0 ||
        (external && !newTab)
      ) {
        return;
      }

      if (!external) {
        event.preventDefault();
        Actions.navigate.push(href);
      }

      if (onClick) {
        onClick(event);
      }
    },
    [href, onClick]
  );

  const classes = cx('console-link', className);

  return (
    <a
      className={classes}
      href={href}
      onClick={handleClick}
      {...(newTab ? { target: '_blank', rel: 'noreferrer' } : {})}
      {...rest}
    >
      {children}
      {newTab && (
        <Icon
          name="ArrowTopRightOnSquareIconOutline"
          style={{ width: '0.75rem', height: '0.75rem', display: 'inline-block' }}
          className="tw-ml-1 tw-mb-1"
          title={href}
        />
      )}
    </a>
  );
};

export const NewTabLink = (props: Omit<Props, 'newTab'>) => {
  return <Link {...props} newTab />;
};
