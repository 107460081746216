import React, { SyntheticEvent, useState } from 'react';
import { Database } from 'entities/database';
import ChangeCDCEnrichmentModeDatabaseModal from './modal';
import { canEditCDCEnrichmentMode } from 'entities/cdc-enrichment-mode';
import { useSession } from 'store';
import { useTracking } from 'react-tracking';

interface Props {
  database: Database;
}

interface ModalProps {
  open: boolean;
  database: Database;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const ChangeCDCEnrichmentModeModal = ({ open, database, _onClose, onClose }: ModalProps) => {
  if (!open) return null;

  return (
    <ChangeCDCEnrichmentModeDatabaseModal open onClose={_onClose(onClose)} database={database} />
  );
};

export const useChangeCDCEnrichmentModeModal = ({ database }: Props) => {
  const [open, setOpen] = useState(false);
  const session = useSession();
  const tracking = useTracking();

  const handleClose = onClose => () => {
    setOpen(false);

    if (onClose) onClose();
  };

  const handleCDCEnrichmentModeClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    tracking.trackEvent({
      action: 'open_cdc_enrichment_mode_modal',
      properties: {
        dbId: database.DbId,
      },
    });

    setOpen(true);
  };

  const disabled = !canEditCDCEnrichmentMode(session, database);

  const modalProps = {
    open,
    database,
    _onClose: handleClose,
  };

  return {
    onClick: handleCDCEnrichmentModeClick,
    disabled,
    Modal: ChangeCDCEnrichmentModeModal,
    modalProps,
  };
};
