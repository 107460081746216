import React from 'react';
import { CopyTextToClipBoardButton, TextInput } from 'foundation';
import classNames from 'classnames';

export const CopyInput = ({
  label,
  value,
  id,
  readOnly,
  wrapperClassName = '',
  inputClassName = '',
  copyButtonClassName = '',
  onCopy = null,
}) => {
  const toClipBoardStyle = {
    height: '3rem',
    border: '1px solid rgb(var(--theme-palette-neutral-border-strong))',
    borderRadius: '0 var(--border-radius-sm) var(--border-radius-sm) 0',
    borderLeft: '0',
    paddingTop: '6px',
    paddingLeft: '3px',
  };

  const textInputStyle = {
    height: '3rem',
    border: '1px solid rgb(var(--theme-palette-neutral-border-strong))',
    borderRight: '0px',
    borderRadius: 'var(--border-radius-sm) 0 0 var(--border-radius-sm)',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: 'var(--font-size-subheading-medium)',
    fontWeight: 400,
    letterSpacing: '0px',
  };

  const wrapperClassNames = classNames('tw-flex tw-items-end', wrapperClassName);
  const inputClassNames = classNames('tw-flex-1', inputClassName);
  const copyButtonClassNames = classNames('tw-w-11', copyButtonClassName);

  return (
    <>
      <div className={wrapperClassNames}>
        <TextInput
          label={label}
          className={inputClassNames}
          /* NDL TODO: style is set on the input element, className is set on the ndl-form-item div. */
          style={textInputStyle}
          fluid
          value={value}
          readOnly={readOnly}
          data-testid={id}
          aria-label="Text to copy"
        />
        <CopyTextToClipBoardButton
          style={toClipBoardStyle}
          className={copyButtonClassNames}
          text={value}
          /* NDL TODO: tw-rounded-r tw-rounded-l-none is over written by .ndl-btn.rectangle */
          iconButtonProps={{
            size: 'medium',
          }}
          onCopy={onCopy}
        />
      </div>
    </>
  );
};
