import { useQuery, UseQueryResult } from 'react-query';
import ApiClient from 'remote/api-client';
import { SsoConfig, SsoConfigResourceData } from 'types/sso-config';

export const transformSsoConfig = (response: any): SsoConfig => {
  return {
    ssoConfigId: response.SsoConfigId,
    displayName: response.DisplayName,
    channel: response.Channel,
    idpType: response.IdpType,
    discoveryURI: response.DiscoveryUri,
    issuer: response.Issuer,
    authorizationEndpoint: response.AuthorizationEndpoint,
    tokenEndpoint: response.TokenEndpoint,
    jwksURI: response.JwksUri,
    clientID: response.ClientId,
    emailDomains: response.EmailDomains,
    roleMapping: response.RoleMapping,
    organizationId: response.OrganizationId,
    organizationLoginMethod: response.OrganizationLoginMethod,
  };
};

const get = async (organizationId: string, ssoConfigId: string): Promise<SsoConfig> =>
  ApiClient.get(`/organizations/${organizationId}/sso-configs/${ssoConfigId}`)
    .promise()
    .then(transformSsoConfig);

const del = async (organizationId: string, ssoConfigId: string): Promise<void> =>
  ApiClient.delete(`/organizations/${organizationId}/sso-configs/${ssoConfigId}`).promise();

const listByOrganizationId = (organizationId: string): Promise<SsoConfig[]> => {
  return ApiClient.get(`/organizations/${organizationId}/sso-configs`)
    .ignoreAllErrors()
    .promise()
    .then(configs => configs.map(transformSsoConfig));
};

const listByTenantId = (tenantId: string): Promise<SsoConfig[]> => {
  return ApiClient.get(`/namespaces/${tenantId}/sso-configs`)
    .ignoreAllErrors()
    .promise()
    .then(configs => configs.map(transformSsoConfig));
};

export const useTenantSsoConfigsQuery = (tenantId: string): UseQueryResult<SsoConfig[]> => {
  const query = async () => {
    const ssoConfigs = await listByTenantId(tenantId);
    return ssoConfigs;
  };

  return useQuery(`namespace-${tenantId}-sso-configs`, query, {
    enabled: !!tenantId,
    refetchInterval: 10000,
  });
};

export const useOrganizationSsoConfigsQuery = (
  organizationId: string
): UseQueryResult<SsoConfig[]> => {
  const query = async () => {
    const ssoConfigs = await listByOrganizationId(organizationId);
    return ssoConfigs;
  };

  return useQuery(`organization-${organizationId}-sso-configs`, query, {
    enabled: !!organizationId,
    refetchInterval: 10000,
  });
};

const createSsoConfig = (
  organizationId: string,
  ssoConfig: SsoConfigResourceData
): Promise<SsoConfig> => {
  return ApiClient.post(`/organizations/${organizationId}/sso-configs`)
    .issue({
      DisplayName: ssoConfig.displayName,
      IdpType: ssoConfig.idpType,
      ClientId: ssoConfig.clientID,
      ClientSecret: ssoConfig.clientSecret,
      OrganizationLoginMethod: ssoConfig.organizationLoginMethod,
      DiscoveryUri: ssoConfig.discoveryURI || null,
      Issuer: ssoConfig.issuer || null,
      AuthorizationEndpoint: ssoConfig.authorizationEndpoint || null,
      TokenEndpoint: ssoConfig.tokenEndpoint || null,
      JwksUri: ssoConfig.jwksURI || null,
      RoleMapping: ssoConfig.roleMapping || null,
    })
    .promise()
    .then(transformSsoConfig);
};

const update = async (ssoConfigId: string, ssoConfig: SsoConfigResourceData): Promise<SsoConfig> =>
  ApiClient.put(`organizations/${ssoConfig.organizationId}/sso-configs/${ssoConfigId}`)
    .issue({
      IdpType: ssoConfig.idpType,
      DisplayName: ssoConfig.displayName,
      ClientId: ssoConfig.clientID,
      DiscoveryUri: ssoConfig.discoveryURI || undefined,
      Issuer: ssoConfig.issuer || undefined,
      AuthorizationEndpoint: ssoConfig.authorizationEndpoint || undefined,
      TokenEndpoint: ssoConfig.tokenEndpoint || undefined,
      JwksUri: ssoConfig.jwksURI || undefined,
      RoleMapping: ssoConfig.roleMapping || undefined,
      ClientSecret: ssoConfig.clientSecret || undefined,
      OrganizationLoginMethod: ssoConfig.organizationLoginMethod || false,
    })
    .promise()
    .then(transformSsoConfig);

export default {
  createSsoConfig,
  get,
  del,
  update,
};
