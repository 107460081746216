import React from 'react';
import { Page } from 'components/foundation';
import { Action } from 'types/user';
import { usePermissions } from 'store';
import CreateOrganizationForm from './create-organization-form';

const CreateOrganizationPage = () => {
  const permission = usePermissions();
  const canCreateOrganization = permission.allow(Action.CREATE, 'organizations');
  return (
    <Page>
      {canCreateOrganization ? (
        <CreateOrganizationForm />
      ) : (
        <h5 data-testid="authorize-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default CreateOrganizationPage;
