import React from 'react';
import { Checkbox, Label } from 'foundation';
import { InviteDetails } from 'types/invite';
import { isTenantRole, tenantRoleToFriendlyName } from 'types/user';

interface InviteConsentItemProps {
  invite: InviteDetails;
  checked: boolean;
  onCheckedChange: (invite: InviteDetails, checked: boolean) => void;
}

export const InviteConsentItem = ({ invite, checked, onCheckedChange }: InviteConsentItemProps) => {
  const { InvitedBy, Roles, NamespaceId, FriendlyName } = invite;
  const handleChange = ({ target: { checked: isChecked } }) => {
    onCheckedChange(invite, isChecked);
  };

  const roles = Roles.filter(isTenantRole).map(tenantRoleToFriendlyName);
  return (
    <li className="tw-my-6">
      <Checkbox
        checked={checked}
        onChange={handleChange}
        aria-label={`Check invite to ${FriendlyName} from ${InvitedBy}`}
        label={
          <div>
            <div className="tw-flex tw-gap-2 tw-mb-2 tw-items-center">
              <span className="n-body-large tw-font-bold">{FriendlyName}</span>
              {roles.map(role => (
                <Label color="default" fill="outlined" key={role}>
                  {role}
                </Label>
              ))}
              <span className="n-body-medium tw-text-palette-neutral-text-weaker">
                Invitation by {InvitedBy}
              </span>
            </div>
            <span className="n-body-medium tw-text-palette-neutral-text-weaker">{NamespaceId}</span>
          </div>
        }
        data-testid={`invite-consent-item-${invite.InviteId}`}
      />
    </li>
  );
};
