import React, { useEffect, useState } from 'react';
import { validateYup, Validation } from 'utils/validation';
import { Dialog } from 'foundation/dialog';
import * as yup from 'yup';
import { Alert, Button, Form, FormInput } from 'components/foundation';
import OrganizationResources from 'remote/resources/organizations';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { TenantSummary } from 'entities/tenant';

const getErrorContent = (
  err: ApiClientRequestError,
  organizationId: string,
  tenant: TenantSummary
): JSX.Element => {
  switch (err.reason) {
    case 'organization-not-found':
      return (
        <Alert type="danger" data-testid="transfer-tenant-error-message" className="tw-mt-2">
          An Organization with UUID {organizationId} could not be found.
        </Alert>
      );
    case 'tenant-already-assigned-to-org':
      return (
        <Alert type="danger" data-testid="transfer-tenant-error-message" className="tw-mt-2">
          Project {tenant.name} is already assigned to an Organization with UUID {organizationId}.
        </Alert>
      );
    case 'plan-type-mismatch':
      return (
        <Alert type="danger" data-testid="transfer-tenant-error-message" className="tw-mt-2">
          The plan type of project {tenant.name} does not match that of Organization with UUID{' '}
          {organizationId}.
        </Alert>
      );
    case 'tenant-transfer-unavailable':
      return (
        <Alert type="danger" data-testid="transfer-tenant-error-message" className="tw-mt-2">
          Project {tenant.name} could not be transferred to Organization with UUID {organizationId}.
          Error: {err.responseMessage}.
        </Alert>
      );
    default:
      return (
        <Alert type="danger" data-testid="transfer-tenant-error-message" className="tw-mt-2">
          Something unexpected went wrong. Please try again or contact the Aura Console team for
          assistance.
        </Alert>
      );
  }
};

export interface TransferTenantModalProps {
  tenant: TenantSummary;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface Data {
  organizationId: string;
}

const schema = yup.object({
  organizationId: yup
    .string()
    .required()
    .label('Organization UUID'),
});

const defaults = (): Data => {
  return {
    organizationId: '',
  };
};

const validate = (data: Data) => {
  return validateYup(schema, data);
};

export const TransferTenantModal = ({
  tenant,
  open,
  onClose,
  onSuccess,
}: TransferTenantModalProps) => {
  const [data, setData] = useState(defaults());
  const [validation, setValidation] = useState<Validation<Data>>({});
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const disabled = data.organizationId === '';

  const resetForm = () => {
    setValidation({});
    setResponseError(null);
    setData(defaults());
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleOrganizationIdChange = e => {
    setResponseError(null);
    const value = e.target.value;
    setData(prev => ({ ...prev, organizationId: value }));
  };

  const handleSubmit = async () => {
    setResponseError(null);
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }
    setLoading(true);

    try {
      await OrganizationResources.transferTenant(data.organizationId, tenant.id);
      onSuccess();
    } catch (e) {
      setResponseError(getErrorContent(e, data.organizationId, tenant));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      modalProps={{ 'data-testid': 'transfer-tenant-modal' }}
      open={open}
      onClose={handleClose}
    >
      <Dialog.Header>Transfer Project to another Organization</Dialog.Header>
      <Dialog.Content>
        <Form onSubmit={handleSubmit}>
          <FormInput
            label="Destination Organization UUID"
            placeholder="Enter Organization UUID..."
            value={data.organizationId || ''}
            onChange={handleOrganizationIdChange}
            autoFocus
            fluid
            errorText={validation.organizationId?.message}
            data-testid="organization-id-input"
          />
        </Form>
        {responseError}
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={handleClose} fill="text">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          loading={loading}
          data-testid="transfer-tenant-submit"
          disabled={disabled}
        >
          Transfer
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
