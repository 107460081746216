import React from 'react';
import { Alert, FormSelect } from 'foundation';
import { useTracking } from 'react-tracking';
import { Database } from 'entities/database';
import {
  EditCDCEnrichmentModeDatabaseFormData,
  EditCDCEnrichmentModeDatabaseFormDataCount,
  CDCEnrichmentMode,
} from 'application/edit-cdc-enrichment-mode/form-data';
import { CDCDisableWarning, CDCEnableWarning } from './cdc-warnings';

type CDCEnrichmentModeOption = {
  value: CDCEnrichmentMode;
  label: string;
  isDisabled: boolean;
  key: CDCEnrichmentMode;
};

type DatabaseCDCEnrichmentModePickerProps = {
  value: CDCEnrichmentMode;
  options: EditCDCEnrichmentModeDatabaseFormDataCount[];
  initialValue: CDCEnrichmentMode | undefined;
  onChange: (value: EditCDCEnrichmentModeDatabaseFormData) => void;
  errorMessage: string | undefined;
  database: Database;
};

type EditCDCEnrichmentModeDatabaseFormProps = {
  data: EditCDCEnrichmentModeDatabaseFormData;
  initialValue: CDCEnrichmentMode | undefined;
  onChange: (value: EditCDCEnrichmentModeDatabaseFormData) => void;
  errorMessage?: CDCEnrichmentMode | null;
  database: Database;
};

const availableModes: EditCDCEnrichmentModeDatabaseFormDataCount[] = [
  { cdcEnrichmentMode: CDCEnrichmentMode.OFF },
  { cdcEnrichmentMode: CDCEnrichmentMode.DIFF },
  { cdcEnrichmentMode: CDCEnrichmentMode.FULL },
];

export const EditCDCEnrichmentModeDatabaseForm = ({
  onChange,
  data,
  initialValue,
  errorMessage = null,
  database,
}: EditCDCEnrichmentModeDatabaseFormProps) => {
  const handleCDCEnrichmentModeChange = (
    cdcEnrichmentMode: EditCDCEnrichmentModeDatabaseFormDataCount
  ) => {
    onChange({ ...data, ...cdcEnrichmentMode });
  };

  return (
    <DatabaseCDCEnrichmentModePicker
      value={data.cdcEnrichmentMode}
      onChange={handleCDCEnrichmentModeChange}
      options={availableModes}
      initialValue={initialValue}
      errorMessage={errorMessage}
      database={database}
    />
  );
};

const DatabaseCDCEnrichmentModePicker = ({
  value,
  options,
  initialValue,
  onChange,
  errorMessage,
  database,
}: DatabaseCDCEnrichmentModePickerProps) => {
  const tracking = useTracking();
  const isOptionDisabled = (value: CDCEnrichmentMode) =>
    initialValue !== undefined && value === initialValue;

  const cdcEnrichmentModeOptions: CDCEnrichmentModeOption[] = options.map(
    ({ cdcEnrichmentMode }) => ({
      value: cdcEnrichmentMode,
      label: cdcEnrichmentMode,
      isDisabled: isOptionDisabled(cdcEnrichmentMode),
      key: cdcEnrichmentMode,
    })
  );

  const handleChange = (newValue: CDCEnrichmentModeOption) => {
    if (newValue.isDisabled) return;
    tracking.trackEvent({
      action: 'change_cdc_enrichment_mode',
      properties: {
        cdc_enrichment_mode: newValue.value,
        dbId: database.DbId,
      },
    });
    onChange({ cdcEnrichmentMode: newValue.value });
  };

  const currentValue =
    cdcEnrichmentModeOptions.find(v => v.value === value) ?? cdcEnrichmentModeOptions[0];

  return (
    <>
      <FormSelect
        value={currentValue}
        label="CDC Mode"
        data-testid="dropdown-cdc-enrichment-mode"
        options={cdcEnrichmentModeOptions}
        searchable={false}
        onChange={handleChange}
      />
      {errorMessage && (
        <Alert type="danger" className="tw-mt-2">
          {errorMessage}
        </Alert>
      )}
      {initialValue === CDCEnrichmentMode.OFF && initialValue !== value && <CDCEnableWarning />}
      {initialValue !== CDCEnrichmentMode.OFF && value === CDCEnrichmentMode.OFF && (
        <CDCDisableWarning />
      )}
    </>
  );
};
