import { TrialCustomerInfo } from 'types/organization';
import { ProfileInformationFormData } from './form-profile-information-data';
import subdivisions from './subdivisions';

export enum UseCase {
  GenerativeAI = 'generative_ai',
  FraudDetection = 'fraud_detection',
  Customer360 = 'customer_360',
  SupplyChainAnalytics = 'supply_chain_analytics',
  DigitalTwin = 'digital_twin',
  Recommendations = 'recommendations',
  KnowledgeGraph = 'knowledge_graph',
  Other = 'other',
  None = 'none',
}

export enum JobRole {
  SoftwareEngineer = 'software_engineer',
  SolutionArchitect = 'solution_architect',
  DataScientist = 'data_scientist',
  OtherTechnical = 'other_technical',
  OtherBusiness = 'other_business',
  Student = 'student',
}

const useCaseLabels: { [key in UseCase]: string } = {
  [UseCase.GenerativeAI]: 'Generative AI',
  [UseCase.FraudDetection]: 'Fraud Detection',
  [UseCase.Customer360]: 'Customer 360',
  [UseCase.SupplyChainAnalytics]: 'Supply Chain Analytics',
  [UseCase.DigitalTwin]: 'Digital Twin',
  [UseCase.Recommendations]: 'Recommendations',
  [UseCase.KnowledgeGraph]: 'Knowledge Graph',
  [UseCase.Other]: 'Other',
  [UseCase.None]: "I don't have a use case",
};

export const useCaseOptions: { key: string; value: string; label: string }[] = [
  UseCase.GenerativeAI,
  UseCase.FraudDetection,
  UseCase.Customer360,
  UseCase.SupplyChainAnalytics,
  UseCase.DigitalTwin,
  UseCase.Recommendations,
  UseCase.KnowledgeGraph,
  UseCase.Other,
  UseCase.None,
].map(useCase => ({
  key: useCase,
  value: useCase,
  label: useCaseLabels[useCase],
}));

const jobRoleLabels: { [key in JobRole]: string } = {
  [JobRole.SoftwareEngineer]: 'Software Engineer / Developer',
  [JobRole.SolutionArchitect]: 'Solution Architect / Engineer Team Lead',
  [JobRole.DataScientist]: 'Data Scientist / Data Engineer / ML Engineer / Analyst',
  [JobRole.OtherTechnical]: 'Other - Technical Role',
  [JobRole.OtherBusiness]: 'Other - Business Role',
  [JobRole.Student]: 'Student',
};

export const jobRoleOptions: { key: string; value: string; label: string }[] = [
  JobRole.SoftwareEngineer,
  JobRole.SolutionArchitect,
  JobRole.DataScientist,
  JobRole.OtherTechnical,
  JobRole.OtherBusiness,
  JobRole.Student,
].map(jobRole => ({
  key: jobRole,
  value: jobRole,
  label: jobRoleLabels[jobRole],
}));

export const isUnspecifiedJobRole = (jobRole: string) => {
  return [JobRole.OtherBusiness.toString(), JobRole.OtherTechnical.toString()].includes(jobRole);
};

export const getSubdivisionOptions = (
  country: string
): { key: string; value: string; label: string }[] =>
  Object.entries(subdivisions[country] || {}).map(([code, name]) => ({
    key: code,
    value: code,
    label: name as string,
  }));

export const requiresSubdivision = (country: string) => {
  return Object.keys(subdivisions).includes(country);
};

export const transformProfileInformationFormData = (
  data: ProfileInformationFormData
): TrialCustomerInfo => {
  return { ...data, jobRole: data.jobRole!, country: data.country! };
};
