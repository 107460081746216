import React from 'react';
import { InvitesTable } from './invites-table';
import { Tenant } from 'entities/tenant';
import { Invite } from 'types/invite';

export const InviteTab = ({
  pendingInvites,
  handleInviteUpdate,
  tenant,
  expiredInvites,
}: {
  pendingInvites: Invite[];
  handleInviteUpdate: () => any;
  tenant: Tenant;
  expiredInvites: Invite[];
}) => {
  return (
    <div>
      <div className="tw-mt-8 tw-mb-4" data-testid="lookup-pending-invites-header">
        <h5>Pending Invites</h5>
      </div>
      {pendingInvites.length > 0 ? (
        <InvitesTable invites={pendingInvites} onUpdate={handleInviteUpdate} tenant={tenant} />
      ) : (
        'No invites'
      )}
      <h5 className="tw-mt-8 tw-mb-4" data-testid="lookup-expired-invites-header">
        Expired Invites
      </h5>
      {expiredInvites.length > 0 ? (
        <InvitesTable invites={expiredInvites} onUpdate={handleInviteUpdate} tenant={tenant} />
      ) : (
        'No invites'
      )}
    </div>
  );
};
