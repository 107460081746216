import React from 'react';
import cx from 'classnames';
import './backdrop.css';

type DivProps = React.ComponentProps<'div'>;

interface Props extends DivProps {
  visible: boolean;
}

export const Backdrop = ({ className, onClick, visible, ...props }: Props) => {
  const classes = cx(
    className,
    'console-backdrop',
    'tw-fixed',
    'tw-left-0',
    'tw-right-0',
    'tw-top-0',
    'tw-bottom-0',
    { 'backdrop-visible': visible }
  );
  return <div {...props} className={classes} onClick={onClick} />;
};
