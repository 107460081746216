import React, { SyntheticEvent, useState } from 'react';
import { Database, canEditSecondaries } from 'entities/database';
import ChangeSecondariesDatabaseModal from './modal';

interface Props {
  database: Database;
}

interface ModalProps {
  open: boolean;
  database: Database;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const ChangeSecondariesModal = ({ open, database, _onClose, onClose }: ModalProps) => {
  if (!open) return null;

  return <ChangeSecondariesDatabaseModal open onClose={_onClose(onClose)} database={database} />;
};

export const useChangeSecondariesModal = ({ database }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = onClose => () => {
    setOpen(false);

    if (onClose) onClose();
  };

  const handleChangeSecondariesClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const disabled = !canEditSecondaries(database);

  const modalProps = {
    open,
    database,
    _onClose: handleClose,
  };

  return {
    onClick: handleChangeSecondariesClick,
    disabled,
    Modal: ChangeSecondariesModal,
    modalProps,
  };
};
