import React from 'react';
import { getOnlineStatusColor, Database } from 'entities/database';
import dbStatusText from './db-status-text';

import cx from 'classnames';
import './statuses.css';
import { StatusIndicator } from 'foundation/status-indicator';
import { useSession } from 'store';

interface Props {
  database: Database;
  size?: 'small' | 'large';
  className?: string;
}

const Statuses = ({ database, size = 'large', className = '', ...rest }: Props) => {
  const session = useSession();
  const base = cx({
    'tw-text-xs': size === 'small',
    'tw-text-sm': size === 'large',
  });

  return (
    <div className={`console-status ${base} ${className}`} {...rest}>
      <StatusIndicator
        name="status"
        type={getOnlineStatusColor(database)}
        className="tw-mr-1 tw-mb-0"
      />
      <span className="status-text">
        {dbStatusText({
          database,
          session,
        })}
        {database.IsBeingCloned && ', Cloning'}
      </span>
    </div>
  );
};

export default Statuses;
