import React, { forwardRef } from 'react';
import { IconButton as NdlIconButton, IconButtonProps, StatusIndicator } from '@neo4j-ndl/react';
import Icon, { IconNames } from 'ui/icons';
import classnames from 'classnames';
import './button.css';

type StatusProps = React.ComponentProps<typeof StatusIndicator>;
export type StatusType = StatusProps['type'];

interface WithoutAriaLabelProps extends Omit<IconButtonProps, 'children'> {
  iconName: IconNames;
  title: string;
  ['aria-label']?: string;
  statusType?: StatusType;
}

interface WithAriaLableProps extends Omit<IconButtonProps, 'children'> {
  iconName: IconNames;
  ['aria-label']: string;
  title?: string;
  statusType?: StatusType;
}

type Props = WithAriaLableProps | WithoutAriaLabelProps;

export const IconButton = forwardRef(
  ({ iconName, title, statusType, className, 'aria-label': ariaLabel, ...rest }: Props, ref) => {
    const classes = classnames(className, 'tw-relative');
    return (
      <NdlIconButton
        title={title}
        className={classes}
        aria-label={ariaLabel || title}
        ref={ref}
        {...rest}
      >
        <Icon name={iconName} />
        {statusType && (
          <StatusIndicator
            type={statusType}
            className="tw-absolute"
            style={{ right: '0', top: '4px' }}
          />
        )}
      </NdlIconButton>
    );
  }
);
