import React, { useState } from 'react';
import { Dialog, Button, Alert, Form } from 'foundation';
import { Validation } from 'utils/validation';
import InternalSsoConfigResource from 'remote/resources/internal-sso-configs';
import { validateSsoConfig } from './sso-config-validation';
import SsoConfigFormFields, { SsoConfigFormAction } from './sso-config-form-fields';
import { Channel, SsoConfig } from 'types/sso-config';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { SsoConfigFormData } from './types';

export interface EditSsoConfigModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(config: SsoConfig): void;
  config: SsoConfig;
}

const defaults = (config: SsoConfig): SsoConfigFormData => {
  return { ...config, clientSecret: undefined, needsSecret: false };
};

const EditSsoConfigModal = ({
  open,
  closeModal,
  saveAndClose,
  config,
}: EditSsoConfigModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ssoConfig, setSsoConfig] = useState<SsoConfigFormData>(defaults(config));
  const [validation, setValidation] = useState<Validation<any>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);

  const handleClose = () => {
    setSsoConfig(defaults(config));
    setErrorMessage(null);
    setValidation(null);
    setHasFailedValidation(false);
    closeModal();
  };

  const handleSubmit = async () => {
    setErrorMessage(null);
    const newValidation = validateSsoConfig(ssoConfig);
    if (newValidation) {
      setValidation(newValidation);
      setHasFailedValidation(true);
      return;
    }
    setLoading(true);
    try {
      const { needsSecret, clientSecret, ...dataToSubmit } = ssoConfig;
      const updatedConfig = await InternalSsoConfigResource.update(config.ssoConfigId, {
        ...dataToSubmit,
        clientSecret: dataToSubmit.channel === Channel.BACK ? clientSecret : undefined,
      });

      setLoading(false);
      saveAndClose(updatedConfig);
    } catch (err) {
      if (err instanceof ApiClientRequestError) {
        setErrorMessage(err.responseMessage || 'Unknown error.');
      } else {
        setErrorMessage(err.toString());
      }
      setLoading(false);
    }
  };

  const maybeValidate = (data: SsoConfigFormData) => {
    if (hasFailedValidation) {
      setValidation(validateSsoConfig(data));
    }
  };

  const handleChange = (data: SsoConfigFormData) => {
    setSsoConfig(data);
    maybeValidate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'edit-sso-config-modal' }}
    >
      <Dialog.Header>Edit SSO Config</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">Edit SSO Config {`"${config.ssoConfigId}"`}</div>
      </Dialog.Description>
      <Dialog.Content data-testid="edit-sso-config-modal-content">
        <Form onSubmit={handleSubmit} id="update-sso-config-form">
          <SsoConfigFormFields
            originalConfig={config}
            config={ssoConfig}
            onChange={handleChange}
            validation={validation}
            formAction={SsoConfigFormAction.UPDATE}
            organizationId={ssoConfig.organizationId}
          />
        </Form>

        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="edit-sso-config-error-message"
            description={`There was an error editing the SSO Config: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={handleClose}
          color="neutral"
          fill="outlined"
          disabled={loading}
          data-testid="discard-sso-config-changes-button"
        >
          Discard
        </Button>
        <Button
          loading={loading}
          type="submit"
          form="update-sso-config-form"
          data-testid="update-sso-config-button"
        >
          Save Changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditSsoConfigModal;
