import React, { useEffect } from 'react';

import { usePermissions, useSession } from 'store/hooks';
import UserManagement from './user-management';
import { Action, TenantUser } from 'types/user';
import { Page, Alert, LoadingSpinner } from 'foundation';
import { useQuery } from 'react-query';
import { Invite, InviteStatus } from 'types/invite';
import { getInvitesByTenant } from 'actions/invite-actions';
import { fetchTenantMembers } from 'actions/namespace-actions';

const UserManagementPage = () => {
  const session = useSession();
  const { allow, deny } = usePermissions();

  if (
    deny(Action.READ, `namespaces/${session.currentTenant}/users`) ||
    deny(Action.READ, `namespaces/${session.currentTenant}/roles`)
  ) {
    return <Alert type="danger">You do not have permission to view this resource.</Alert>;
  }

  const canInvite = allow(Action.READ, `namespaces/${session.currentTenant}/invites`);

  return (
    <Page>
      {canInvite ? (
        <UserManagementWithInvites tenant={session.currentTenant} />
      ) : (
        <UserManagementWithoutInvites tenant={session.currentTenant} />
      )}
    </Page>
  );
};

interface UserManagementPageProps {
  tenant: string;
}

const UserManagementWithInvites = ({ tenant }: UserManagementPageProps) => {
  const invites = useQuery(
    `namespaces/${tenant}/invites`,
    (): Promise<Invite[]> => getInvitesByTenant(tenant, InviteStatus.PENDING)
  );
  const members = useQuery(
    `namespaces/${tenant}/members`,
    (): Promise<TenantUser[]> => fetchTenantMembers(tenant)
  );

  const refetchData = () => {
    invites.refetch();
    members.refetch();
  };

  useEffect(refetchData, [tenant]);

  if (invites.isLoading || members.isLoading) {
    return <LoadingSpinner expand minHeight={250} />;
  }

  if (invites.isError || members.isError) {
    return <Alert type="danger" description="Error fetching data. Please reload the page." />;
  }

  return (
    <UserManagement
      tenant={tenant}
      invites={invites.data}
      users={members.data}
      onRefetchData={refetchData}
    />
  );
};

const UserManagementWithoutInvites = ({ tenant }: UserManagementPageProps) => {
  const users = useQuery(
    `namespaces/${tenant}/members`,
    (): Promise<TenantUser[]> => fetchTenantMembers(tenant)
  );

  const refetchData = () => {
    users.refetch();
  };

  useEffect(refetchData, [tenant]);

  if (users.isLoading) {
    return <LoadingSpinner expand minHeight={250} />;
  }

  if (users.isError) {
    return <Alert type="danger" description="Error fetching data. Please reload the page." />;
  }

  return (
    <UserManagement tenant={tenant} invites={[]} users={users.data} onRefetchData={refetchData} />
  );
};

export default UserManagementPage;
