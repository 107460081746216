import { NavigationStore, SessionStore } from 'state';
import * as browserHelper from 'browser/helpers';
import { hasReloaded } from 'browser/helpers';
import authenticationClient from './authentication';
import globals from '../browser/globals';
import tracking from './user-tracking-actions';
import logger from 'logger';
import location from 'browser/location';
import Actions from 'actions';
import { hrefFromLocation, locationFromUrl, NavigationLocation } from 'state/navigation';
import { parse } from 'query-string';
import { omit } from 'utils/javascript';
import { PartnerAccount } from 'types/user';
import { AuthenticateOptions } from './authentication/auth0-universal-login-auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import userDetailsActions from 'actions/user-details-actions';
import LogoutUser from 'remote/resources/logout-user';

const Auth0QueryParams = ['state', 'code'];

export interface AuthData {
  bearerToken: string;
  profile: {
    name?: string;
    email?: string;
    picture?: string;
    sub?: string;
  };
  partnerAccount?: PartnerAccount;
}

const login = (options: AuthenticateOptions = {}) => {
  browserHelper.clearStoredAuthBearerTokens();

  browserHelper.clearStoredAuthData();

  // Unfortunately the Auth0 universal login library doesn't back-propagate errors, so we
  // have to handles it in this ugly fashion :(
  globals.window.addEventListener('unhandledrejection', onAuthClientErrorEvent);

  // This returns a promise that resolves but can still lead to subsequent (unhandled)
  // errors
  authenticationClient.authenticate(options);
};

const onAuthClientErrorEvent = event => {
  onAuthenticated(event.reason, null);
  event.preventDefault();
};

const getCurrentLocation = (returnToLocation: Partial<NavigationLocation>) => {
  const rootLocation = { ...NavigationStore.state.location, hash: '', search: '', pathname: '/' };
  let fullReturnToLocation = undefined;
  if (!returnToLocation) {
    // By default, return to home
    fullReturnToLocation = rootLocation;
  } else {
    fullReturnToLocation = { ...rootLocation, ...returnToLocation };
  }

  return fullReturnToLocation;
};

const logout = async (returnToLocation: Partial<NavigationLocation> = undefined) => {
  logger.debug('Logout action triggered');
  tracking.trackEvent('LOGOUT');

  const logoutLocation = getCurrentLocation(returnToLocation);

  try {
    await LogoutUser.logout();
  } catch (e) {
    logger.error(e);
  }

  // Just clear auth data in localstorage. Don't mess with any stores.
  // The authenticationClient.logout command will redirect the browser
  // to an auth0 url, meaning the stores will all be reset anyways
  // since the browser does a full refresh
  browserHelper.clearStoredAuthData();

  authenticationClient.logout(hrefFromLocation(logoutLocation));
};

const loginRedirect = (returnToLocation: Partial<NavigationLocation> = undefined) => {
  const loginLocation = getCurrentLocation(returnToLocation);
  authenticationClient.loginRedirect(hrefFromLocation(loginLocation));
  browserHelper.clearStoredAuthData();
};

const emailVerificationRequired = () => SessionStore.emailVerificationRequired();

const attemptCachedLogin = async () => {
  const authData = browserHelper.loadStoredAuthData();
  if (!authData) {
    // Its important that we logout session locally
    // instead of calling `logout` as ALL pages call this function
    // so if we have no auth data and called logout
    // instead, we would keep calling this function and
    // keep navigating to auth0 logout page in an
    // infinite loop
    SessionStore.loggedOut();
    return false;
  }

  if (!verifyStoredAuthdata(authData)) {
    await logout(location);
    return false;
  }

  onSignedIn(authData);
  return true;
};

const isPartnerPunchout = query =>
  query && query.includes('user_id=') && query.includes('project_id=');

const verifyStoredAuthdata = (authData: AuthData) => {
  const query = location.search;
  if (hasReloaded() && authData.partnerAccount) {
    return true;
  }
  if (!authData.partnerAccount && !isPartnerPunchout(query)) {
    return true;
  }
  if (authData.partnerAccount && isPartnerPunchout(query)) {
    const params = new globals.URLSearchParams(query.substring(1));
    return (
      params.get('user_id') === authData.partnerAccount.userId &&
      params.get('project_id') === authData.partnerAccount.projectId
    );
  }
  return false;
};

const onAuthenticating = () => {
  SessionStore.loggingIn();
};

const onAuthenticated = (err, authResult) => {
  if (err) {
    logger.error('Authentication failed -', err);

    let hash = null;
    if (/Expiration Time \(exp\) claim error/.test(err)) {
      hash = '#login-error/expired';
    } else if (/Issued At \(iat\) claim error/.test(err)) {
      hash = '#login-error/clock-sync';
    } else if (/Authentication Time \(auth_time\) claim/.test(err)) {
      hash = '#login-error/reauthentication-required';
    } else {
      hash = '#login-error/unknown';
    }

    Actions.navigate.push({ hash });
    SessionStore.loggedOut();
    return;
  }

  // No authentication error happened, so stop listening for them.
  // See corresponding addEventListener() above
  globals.window.removeEventListener('unhandledrejection', onAuthClientErrorEvent);
  browserHelper.storeAuthData({
    bearerToken: authResult.accessToken,
    profile: {
      name: authResult.profile.name,
      email: authResult.profile.email,
      picture: authResult.profile.picture,
      sub: authResult.profile.sub,
    },
    partnerAccount: authResult.partnerAccount,
  });

  postAuthRedirect();

  onSignedIn(browserHelper.loadStoredAuthData());

  tracking.trackEvent('LOGIN', {
    email: authResult.profile.email,
  });
};
authenticationClient.onAuthenticating = onAuthenticating;
authenticationClient.onAuthenticated = onAuthenticated;

const onSignedIn = (authData: AuthData) => {
  const additionalProps = authData.partnerAccount
    ? {
        partnerAccount: authData.partnerAccount,
      }
    : {};

  const jwt = jwtDecode<JwtPayload & { org_id?: string }>(authData.bearerToken);
  SessionStore.loggedIn({
    bearerToken: authData.bearerToken,
    ssoOrgId: jwt.org_id,
    name: authData.profile.name,
    email: authData.profile.email,
    picture: authData.profile.picture,
    sub: authData.profile.sub,
    ...additionalProps,
  } as any);
  // TODO: our stored auth data does not contain all the
  // details necessary to call loggedIn
};

const postAuthRedirect = () => {
  // Purge unwanted url params
  const locationObject = locationFromUrl(location.href);
  const filteredParams = omit(parse(locationObject.search), Auth0QueryParams);

  Actions.navigate.replace({ ...locationObject, search: filteredParams });
};

// Returns true if needs login
const authenticateWithSsoOrg = async (ssoOrgId: string) => {
  const existingTokenInSessionStorage = SessionStore.state.ssoOrgBearerTokens?.[ssoOrgId];
  if (existingTokenInSessionStorage) {
    SessionStore.setBearerToken(existingTokenInSessionStorage?.token);
    // We need to refetch user details to get the new permissions for the tenant.
    await userDetailsActions.refetch();
    return false;
  }

  login({ organization: ssoOrgId });
  return true;
};

const getCurrentAuthenticatedSsoOrg = () => {
  const token = SessionStore.state.oauthBearerToken;
  if (token) {
    const decodedToken = jwtDecode<JwtPayload & { org_id?: string }>(token);
    return decodedToken.org_id;
  }

  return;
};

export default {
  attemptCachedLogin,
  login,
  loginRedirect,
  logout,
  emailVerificationRequired,
  authenticateWithSsoOrg,
  getCurrentAuthenticatedSsoOrg,
};
