import React, { useEffect, useRef, useState } from 'react';
import { add, format, formatISO, isBefore, sub, subMinutes } from 'date-fns';

import Actions from 'actions';
import { Alert, Button, DateTimePicker, Dialog, Radio, Slider } from 'components/foundation';
import { Log, LogFile, RemoteLog } from 'types/log';

import { currentTimeUTC, isSecurityLogsEnabled, mapRemoteLog } from 'entities/log';

import './logs.css';
import { Database } from 'entities/database';

const DATEPICKER_FORMAT = 'MMM dd h:mm aa';

enum RequestLogsTimeSelection {
  LAST_15 = 'last15',
  LAST_HOUR = 'lastHour',
  LAST_6_HOURS = 'last6Hours',
  LAST_12_HOURS = 'last12Hours',
  CUSTOM_RANGE = 'customRange',
}

const settingsForFile = {
  [LogFile.QUERY]: {
    sliderDefault: 5,
    sliderMin: 1,
    sliderMax: 60,
    sliderStep: 1,
    timeSelectionOptions: [
      { label: 'Last 15 min', value: RequestLogsTimeSelection.LAST_15 },
      { label: 'Last hour', value: RequestLogsTimeSelection.LAST_HOUR },
    ],
  },
  [LogFile.SECURITY]: {
    sliderDefault: 60,
    sliderMin: 5,
    sliderMax: 60 * 12,
    sliderStep: 5,
    timeSelectionOptions: [
      { label: 'Last 6 hours', value: RequestLogsTimeSelection.LAST_6_HOURS },
      { label: 'Last 12 hours', value: RequestLogsTimeSelection.LAST_12_HOURS },
    ],
  },
};

// TODO: There are currently no tests for the custom interval selection. This is mainly
// due to uncertainty of which final UI solution we settle with. Once we have figured
// out the final solution, we should add tests.

interface Props {
  database: Database;
  open: boolean;
  onClose: () => void;
  onSuccess: (log: Log) => void;
}

const RequestLogsModal = ({ database, open, onClose, onSuccess }: Props) => {
  const [timeSelection, setTimeSelection] = useState<RequestLogsTimeSelection>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<Date>(null);
  const [customLengthMins, setCustomLengthMins] = useState(5);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [requestPending, setRequestPending] = useState(false);
  const [file, setFile] = useState(LogFile.QUERY);

  const datepickerRef = useRef(null);
  const settings = settingsForFile[file];

  const clearForm = () => {
    setTimeSelection(null);
    setErrorMessage(null);
    setSubmitEnabled(false);
    setSelectedStartTime(null);
    setCustomLengthMins(5);
    setRequestPending(false);
    setFile(LogFile.QUERY);
  };

  const validateDateTime = (requestedStart: Date) => {
    if (!requestedStart) {
      throw new Error('Requested time is invalid.');
    }

    // We present the times in the picker as though they are UTC - but the Date
    // generated and passed to us is in local time. So we undo the local-ness
    // with some timezone arithmetic.
    const requestedStartAsLocal = subMinutes(requestedStart, requestedStart.getTimezoneOffset());
    const now = new Date();

    if (isBefore(now, requestedStartAsLocal)) {
      throw new Error('Requested time is too far in the future.');
    }

    if (isBefore(requestedStartAsLocal, sub(now, { days: 30 }))) {
      throw new Error('Requested time is beyond the 30 day retention period.');
    }
  };

  useEffect(() => {
    switch (timeSelection) {
      case RequestLogsTimeSelection.CUSTOM_RANGE:
        datepickerRef.current.refs.input.current.focus();
        break;
      case RequestLogsTimeSelection.LAST_15:
      case RequestLogsTimeSelection.LAST_HOUR:
      case RequestLogsTimeSelection.LAST_6_HOURS:
      case RequestLogsTimeSelection.LAST_12_HOURS:
        datepickerRef.current.refs.input.current.blur();
        setSubmitEnabled(true);
        setSelectedStartTime(null);
        setErrorMessage(null);
        break;
    }
  }, [timeSelection]);

  useEffect(() => {
    if (timeSelection === RequestLogsTimeSelection.CUSTOM_RANGE) {
      if (selectedStartTime === null) {
        setSubmitEnabled(false);
      } else {
        try {
          validateDateTime(selectedStartTime);
          setSubmitEnabled(true);
          setErrorMessage(null);
        } catch (ex) {
          setSubmitEnabled(false);
          setErrorMessage(ex.message);
        }
      }
    }
  }, [selectedStartTime]);

  useEffect(() => {
    setSubmitEnabled(false);
    setTimeSelection(null);
    setSelectedStartTime(null);
    setCustomLengthMins(settings.sliderDefault);
  }, [file]);

  const determineInterval = () => {
    let start: Date, end: Date;
    let selectedAsUTC: Date;
    const now = new Date();

    switch (timeSelection) {
      case RequestLogsTimeSelection.LAST_15:
        start = sub(now, { minutes: 15 });
        end = now;
        break;
      case RequestLogsTimeSelection.LAST_HOUR:
        start = sub(now, { hours: 1 });
        end = now;
        break;
      case RequestLogsTimeSelection.LAST_6_HOURS:
        start = sub(now, { hours: 6 });
        end = now;
        break;
      case RequestLogsTimeSelection.LAST_12_HOURS:
        start = sub(now, { hours: 12 });
        end = now;
        break;
      case RequestLogsTimeSelection.CUSTOM_RANGE:
        selectedAsUTC = subMinutes(selectedStartTime, selectedStartTime.getTimezoneOffset());
        start = selectedAsUTC;
        end = add(selectedAsUTC, { minutes: customLengthMins });
        break;
    }

    return [start, end];
  };

  const handleSubmit = () => {
    setErrorMessage(null);
    setRequestPending(true);

    const [startDate, endDate] = determineInterval();
    const start = formatISO(startDate);
    const end = formatISO(endDate);

    Actions.logs
      .requestLog(database.DbId, start, end, [file])
      .then((remoteLog: RemoteLog) => {
        const log: Log = mapRemoteLog(remoteLog);
        onSuccess(log);
        clearForm();
      })
      .catch(ex => {
        if (ex.reason === 'operations-limit-reached') {
          setErrorMessage(
            'Export request limit reached for this database, please try again later.'
          );
        } else {
          setErrorMessage(ex.responseMessage);
        }
      })
      .finally(() => {
        setRequestPending(false);
      });
  };

  const handleSliderUpdate = value => {
    setCustomLengthMins(value);
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setFile(event.target.value as LogFile);
  };

  const prettyPrintInterval = () => {
    if (timeSelection !== RequestLogsTimeSelection.CUSTOM_RANGE || !selectedStartTime) {
      return null;
    }

    const start = selectedStartTime;
    const end = add(selectedStartTime, { minutes: customLengthMins });

    const dateFormat = 'MMM d HH:mm';

    return `${format(start, dateFormat)} - ${format(end, dateFormat)} UTC`;
  };

  return (
    <Dialog
      modalProps={{
        className: 'request-logs-dialog',
      }}
      open={open}
      onClose={() => {
        clearForm();
        onClose();
      }}
      size="small"
    >
      <Dialog.Header>Request Log</Dialog.Header>
      <Dialog.Subtitle>All times presented here are in UTC.</Dialog.Subtitle>
      <Dialog.Content>
        {errorMessage && <Alert type="danger" title={errorMessage} className="tw-mb-6" />}

        {isSecurityLogsEnabled(database) && (
          <div className="tw-mb-3">
            <p>Type</p>
            <Radio
              label="Query log"
              className="tw-my-1"
              value={LogFile.QUERY}
              checked={file === LogFile.QUERY}
              onChange={handleFileChange}
              data-testid="query-logs-radio"
            />
            <Radio
              label="Security log"
              className="tw-my-1"
              value={LogFile.SECURITY}
              checked={file === LogFile.SECURITY}
              onChange={handleFileChange}
              data-testid="security-logs-radio"
            />
          </div>
        )}

        <p>Range</p>
        {settings.timeSelectionOptions.map(({ label, value }) => (
          <Radio
            className="tw-my-1"
            checked={timeSelection === value}
            onChange={() => {
              setTimeSelection(value);
            }}
            label={label}
            value={value}
            key={value}
          />
        ))}
        <div className="tw-flex tw-flex-col tw-justify-between tw-content-center">
          <DateTimePicker
            className="custom-range-start-picker"
            ref={datepickerRef}
            dateFormat={DATEPICKER_FORMAT}
            inputProps={{
              fluid: true,
              placeholder: 'Start time',
              readOnly: true,
              value: prettyPrintInterval(),
              onFocus: () => setTimeSelection(RequestLogsTimeSelection.CUSTOM_RANGE),
            }}
            showTimeInput
            timeInputLabel={null}
            calendarStartDay={1}
            selected={currentTimeUTC()}
            value={selectedStartTime}
            onChange={setSelectedStartTime}
            onError={() => setSubmitEnabled(false)}
          />
          <div className="tw-flex tw-my-2">
            <div className="slider-value-display tw-flex tw-justify-center tw-items-center tw-mx-2">
              {selectedStartTime ? format(selectedStartTime, 'HH:mm') : '--:--'}
            </div>
            <Slider
              disabled={!selectedStartTime || !customLengthMins}
              className="slider-container tw-flex-1"
              thumbClassName="stud"
              trackClassName="slider"
              min={settings.sliderMin}
              max={settings.sliderMax}
              step={settings.sliderStep}
              value={customLengthMins}
              onChange={handleSliderUpdate}
            />
            <div className="slider-value-display tw-flex tw-justify-center tw-items-center tw-mx-2">
              {selectedStartTime && customLengthMins
                ? format(add(selectedStartTime, { minutes: customLengthMins }), 'HH:mm')
                : '--:--'}
            </div>
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={() => {
            clearForm();
            onClose();
          }}
          fill="text"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          loading={requestPending}
          disabled={!submitEnabled}
          onClick={() => {
            handleSubmit();
          }}
          data-testid="clone-button"
        >
          Request
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default RequestLogsModal;
