import { SessionStore } from 'state';
import { Product, findProductByValue, DEFAULT_PRODUCT } from 'types/product';
import localStorage, { LocalStorage } from 'browser/localStorage';
import { getAvailableProducts, isProductAvailable, ProviderConfigs } from 'entities/tenant';
import { parse } from 'query-string';
import Actions from 'actions';
import location from 'browser/location';

export const queryParamKey = 'product';
export const productKey = 'aura-console-product';

// This function was added to remove circular dependency between setProduct
// and localStorage.
export const setProductState = (product: Product) => {
  SessionStore.setProduct(product);
  localStorage.setItem(productKey, product);
};

export const setProduct = (
  product: Product,
  { redirect = false, replaceSearch = false, navigateReplace = false } = {}
) => {
  setProductState(product);
  let params: Record<string, any> = {};
  if (!replaceSearch) {
    params = parse(location.search);
  }

  params.product = product;
  const navigate = navigateReplace ? Actions.navigate.replace : Actions.navigate.push;
  if (redirect) {
    navigate({ hash: '#databases', search: params });
  } else {
    navigate({ search: params });
  }
};

export const inferDefaultProduct = (
  params: URLSearchParams,
  storage: LocalStorage,
  providerConfigs: ProviderConfigs
): Product => {
  const productFromParams = findProductByValue(params.get(queryParamKey));
  // If the user specifies a product in the url, always go to it even
  // if its not available. They should get the "product not available" message
  if (productFromParams) {
    return productFromParams;
  }

  const productFromStorage = findProductByValue(storage.getItem(productKey));
  if (isProductAvailable(providerConfigs, productFromStorage)) {
    return productFromStorage;
  }

  if (isProductAvailable(providerConfigs, DEFAULT_PRODUCT)) {
    return DEFAULT_PRODUCT;
  }

  // If we get here, there should only be one available product, and it will be whichever
  // one that is not the default based on the plan type
  return getAvailableProducts(providerConfigs)[0];
};

export default {
  setProduct,
  inferDefaultProduct,
};
