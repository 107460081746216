import { Alert, TextLink } from 'foundation';
import gcpLogo from 'ui/png/gcp-cloud.png';
import { FormInput } from 'foundation/form-element';
import React from 'react';

export interface PartnerFormInputProps {
  googleProjectId: string;
}

export const PartnerFormInput = ({ googleProjectId }: PartnerFormInputProps) => {
  return (
    <FormInput
      fluid
      readOnly
      label="Google Project Number"
      placeholder={googleProjectId}
      data-testid="google-project"
    />
  );
};

export interface PartnerMessageProps {
  googleProjectId: string;
  className?: string;
}

export const PartnerMessage = ({ googleProjectId, className }: PartnerMessageProps) => {
  const title = (
    <div className="tw-flex tw-items-center tw-gap-2">
      <img src={gcpLogo} className="tw-w-6 tw-h-6" />
      <span>
        Managed by Google Project{' '}
        <TextLink
          href={`https://console.cloud.google.com/home/dashboard?project=${googleProjectId}`}
          externalLink
        >
          {googleProjectId}
        </TextLink>
      </span>
    </div>
  );

  return (
    <Alert
      type="info"
      data-testid="partner-message"
      className={className}
      description={title}
      name={`Managed by Google Project ${googleProjectId}`}
    />
  );
};
