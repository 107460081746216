import React, { useEffect, useState } from 'react';

import { Alert, Button, Dialog, FormSelect, SelectOption, TextInput } from 'foundation';
import {
  RoleName,
  OrganizationMember,
  isOrganizationRole,
  getOrganizationRoleOptions,
} from 'types/user';

const getRoleOptions = (showOwnerOption: boolean) => {
  let roleOptions = getOrganizationRoleOptions();

  if (!showOwnerOption) {
    roleOptions = roleOptions.filter(role => role.value !== RoleName.ORGANIZATION_OWNER);
  }

  return roleOptions;
};

interface Props {
  open: boolean;
  user: OrganizationMember;
  editInProgress: boolean;
  onClose: () => void;
  onEdit: (user: OrganizationMember, newRoleName: RoleName) => void;
  allowCreateOwnerRoles: boolean;
  errorMessage?: string;
}

const EditUserModal = ({
  open,
  user,
  editInProgress,
  onClose,
  onEdit,
  allowCreateOwnerRoles,
  errorMessage = null,
}: Props) => {
  const currentRoleName = user.Roles.find(role => isOrganizationRole(role.Name)).Name;

  const [selectedRoleName, setSelectedRoleName] = useState(currentRoleName);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    setSaveEnabled(currentRoleName !== selectedRoleName && !editInProgress);
  }, [selectedRoleName, editInProgress]);

  const roleOptions = getRoleOptions(allowCreateOwnerRoles);

  return (
    <Dialog size="small" open={open} onClose={onClose}>
      <Dialog.Header>Edit User Details</Dialog.Header>
      <Dialog.Content>
        <TextInput fluid disabled readOnly label="Email" value={user.Email} className="tw-my-6" />
        <FormSelect
          label="Role"
          searchable
          value={selectedRoleName}
          options={roleOptions}
          onChange={(role: SelectOption) => {
            setSelectedRoleName(role.value as RoleName);
          }}
          data-testid="role-options-dropdown"
          menuPosition="fixed"
        />
        {errorMessage && <Alert type="danger" description={errorMessage} className="tw-mt-8" />}
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!saveEnabled}
          loading={editInProgress}
          data-testid="save-role-edit"
          onClick={() => onEdit(user, selectedRoleName)}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditUserModal;
