import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import NamespacesResource from 'remote/resources/namespaces';
import { TenantsTable } from './tenants-table';
import { InternalTenant } from 'entities/tenant';
import { useNotify } from 'state/notifications';

export const SearchByIsolationIdForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [tenants, setTenants] = useState<InternalTenant[]>([]);
  const notify = useNotify();

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleSearchSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setTenants([]);
    setError(null);

    try {
      const searchResult = await NamespacesResource.searchByIsolationId(query.trim());
      setTenants(searchResult.tenants);
      if (searchResult.hasHitLimit) {
        notify.warning('Only the first 100 search results are displayed.');
      }
    } catch (err) {
      setError(String(err));
    }
    setLoading(false);
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">This form enables you to search for projects by isolation ID.</p>
      <p className="tw-mb-2">
        Please note that an exact match is required for the search, i.e. you need to enter the
        entire Isolation ID before you search.
      </p>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          aria-label="Isolation id"
          data-testid="search-tenants-by-isolationId-input"
          name="isolation_id_query"
          autoComplete="isolation_id_query"
          helpText="Search for tenants by Isolation ID"
        />
        <Button
          className="tw-mt-8"
          type="submit"
          color="primary"
          loading={loading}
          disabled={query === '' || loading}
          data-testid="search-tenants-by-isolation-id-button"
        >
          Search
        </Button>

        {error && (
          <Alert
            type="danger"
            data-testid="search-tenants-by-isolationId-error-message"
            className="tw-mt-8"
          >
            {error}
          </Alert>
        )}
      </Form>

      {tenants.length > 0 && (
        <TenantsTable
          className="tw-mt-8"
          tenants={tenants}
          data-testid="search-tenants-isolation-id-result-table"
        />
      )}
    </div>
  );
};
