import React, { useMemo } from 'react';
import { createColumnHelper, IconButton, useDefaultTable, StickyActionsDataGrid } from 'foundation';
import { FeatureToggleOverride } from 'remote/resources/feature-toggle-overrides';

interface Props {
  data: FeatureToggleOverride[];
  deleteDisabled: boolean;
  onDelete: (override: FeatureToggleOverride) => any;
}

const helper = createColumnHelper<FeatureToggleOverride>();

export const FeatureToggleOverridesTable = ({ data, deleteDisabled, onDelete }: Props) => {
  const columns = useMemo(
    () => [
      helper.accessor('FeatureToggle', {
        header: 'Feature toggle',
        minSize: 100,
      }),
      helper.accessor('NewValue', {
        header: 'Value',
        minSize: 100,
        cell: cell => <code>{String(cell.getValue())}</code>,
      }),
      helper.display({
        id: 'actions',
        cell: cell => (
          <span className="tw-flex tw-gap-3 tw-justify-end tw-w-full">
            <IconButton
              disabled={deleteDisabled}
              onClick={() => onDelete(cell.row.original)}
              iconName="TrashIconOutline"
              title="Delete feature toggle override"
              aria-label="Delete feature toggle override"
              size="small"
              danger
              data-testid="delete-feature-toggle-override-button"
              clean
            />
          </span>
        ),
        meta: {
          isStickyAction: true,
        },
        size: 90,
      }),
    ],
    []
  );

  const table = useDefaultTable({ columns, data });

  return (
    <div data-testid="feature-toggle-override-table">
      <StickyActionsDataGrid tableInstance={table} />
    </div>
  );
};
