import React from 'react';
import ImportDropzone from './dropzone';
import { Status } from './upload-handlers';
import { SuccessMessage } from './messages';
import { Database } from 'entities/database';

interface Props {
  status: Status;
  onSubmit: (file: File) => any;
  fileMetadata: { name: string; size: number };
  progress: number;
  database: Database;
  isTesting?: boolean;
  message?: string;
  errorCode?: string;
}

export const ImportComponent = ({
  status,
  onSubmit,
  fileMetadata,
  progress,
  database,
  isTesting = false,
  message,
  errorCode,
}: Props) => {
  if (status === Status.COMPLETE) {
    return <SuccessMessage />;
  } else {
    return (
      <ImportDropzone
        progress={progress ?? 0}
        uploading={status === Status.UPLOADING}
        processing={status === Status.PROCESSING}
        onSubmit={onSubmit}
        database={database}
        status={status}
        fileMetadata={fileMetadata || undefined}
        isTesting={isTesting}
        message={message}
        errorCode={errorCode}
      />
    );
  }
};
