import React from 'react';
import { FormInput } from 'foundation/form-element';
import { validateYup, Validation } from 'utils/validation';
import { Divider } from 'foundation';
import * as yup from 'yup';

export interface TransferInstanceData {
  targetDbid: string;
  targetTenantId?: string;
  targetEmail?: string;
  targetUserId?: string;
}

export enum TransferType {
  TRANSFER_TO_USER,
  TRANSFER_TO_TENANT,
}

const numberOfTargets = (data: TransferInstanceData): number => {
  let count = 0;
  if (data.targetUserId) count += 1;
  if (data.targetEmail) count += 1;
  if (data.targetTenantId) count += 1;
  return count;
};

const schema = yup
  .object({
    targetDbid: yup.string().required('You need to specify an Instance Id'),
    targetTenantId: yup.string(),
    targetEmail: yup.string().email('Needs to be an email'),
    targetUserId: yup.string(),
  })
  .test(
    'exactly one target',
    'exactly one target is required (project ID, user ID, OR email)',
    (data: TransferInstanceData) => numberOfTargets(data) === 1
  );

export const validate = (data: TransferInstanceData) => {
  return validateYup(schema, data);
};

interface TransferInstanceFormProps {
  data: TransferInstanceData;
  transferType: TransferType;
  onChange: (data: TransferInstanceData) => any;
  validation: Validation<TransferInstanceData>;
}

const TransferInstanceForm = ({
  data,
  transferType,
  onChange,
  validation,
}: TransferInstanceFormProps) => {
  const handleTargetDbidChange = ({ target: { value } }) =>
    onChange({ ...data, targetDbid: value });

  const handleTargetTenantIdChange = ({ target: { value } }) =>
    onChange({ ...data, targetTenantId: value });

  const handleTargetEmailChange = ({ target: { value } }) =>
    onChange({ ...data, targetEmail: value });

  const handleTargetUserIdChange = ({ target: { value } }) =>
    onChange({ ...data, targetUserId: value });

  return (
    <form>
      <FormInput
        data-testid="transfer-instance-id-test"
        className="tw-my-2"
        placeholder="Instance Id"
        label="Instance Id *"
        value={data.targetDbid}
        onChange={handleTargetDbidChange}
        errorText={validation?.targetDbid?.message}
        required
        fluid
      />

      <Divider hidden />

      {transferType === TransferType.TRANSFER_TO_TENANT ? (
        <FormInput
          data-testid="transfer-tenant-input"
          className="tw-my-2"
          placeholder="Project ID"
          label="Target Project ID"
          value={data.targetTenantId}
          onChange={handleTargetTenantIdChange}
          disabled={!data.targetTenantId && numberOfTargets(data) > 0}
          errorText={validation?.targetTenantId?.message}
          fluid
        />
      ) : (
        <>
          <h6>Choose one of the following</h6>

          <FormInput
            data-testid="transfer-email-test"
            className="tw-my-2"
            placeholder="Email"
            label="Target Email"
            value={data.targetEmail}
            onChange={handleTargetEmailChange}
            disabled={!data.targetEmail && numberOfTargets(data) > 0}
            errorText={validation?.targetEmail?.message}
            fluid
          />
          <FormInput
            data-testid="transfer-user-id-test"
            className="tw-my-2"
            placeholder="User ID"
            label="Target User ID"
            value={data.targetUserId}
            onChange={handleTargetUserIdChange}
            disabled={!data.targetUserId && numberOfTargets(data) > 0}
            errorText={validation?.targetUserId?.message}
            fluid
          />
        </>
      )}
    </form>
  );
};

export default TransferInstanceForm;
