import React from 'react';
import { useSession } from 'store';
import { Product, productFriendlyName } from 'types/product';
import { PlanType, tierDisplayName } from 'entities/tenant';
import { Tier } from 'entities/database';
import Icon, { IconNames } from 'ui/icons';
import { useDarkTheme } from 'utils/hooks';

interface Props {
  product: Product;
}

const logosMap: Record<PlanType, Record<Product, IconNames>> = {
  [PlanType.ENTERPRISE]: {
    [Product.AURA_DB]: 'logo-enterprise-colored',
    [Product.AURA_DS]: 'logo-dsenterprise-colored',
  },
  [PlanType.SELF_SERVE]: {
    [Product.AURA_DB]: 'logo-colored',
    [Product.AURA_DS]: 'logo-ds-colored',
  },
};

const darkLogosMap: Record<PlanType, Record<Product, IconNames>> = {
  [PlanType.ENTERPRISE]: {
    [Product.AURA_DB]: 'logo-enterprise',
    [Product.AURA_DS]: 'logo-dsenterprise',
  },
  [PlanType.SELF_SERVE]: {
    [Product.AURA_DB]: 'logo',
    [Product.AURA_DS]: 'logo-ds',
  },
};

const learnMoreLinkMap: Record<Product, string> = {
  [Product.AURA_DB]: 'https://neo4j.com/cloud/platform/aura-graph-database/?ref=aura',
  [Product.AURA_DS]: 'https://neo4j.com/cloud/platform/aura-graph-data-science/?ref=aura',
};

export const DisabledProductPage = ({ product }: Props) => {
  const { tenant, planType } = useSession();
  const isDarkTheme = useDarkTheme();

  let message: string;
  if (product === Product.AURA_DB) {
    message =
      'For large scale, mission-critical applications that require advanced security and 24x7 support.';
  } else if (product === Product.AURA_DS) {
    message = `${tierDisplayName(
      tenant,
      Tier.AURA_DSE
    )} is a fully managed graph data science platform with enterprise scale, security, and premium support.`;
  }

  const logo = isDarkTheme ? darkLogosMap[planType][product] : logosMap[planType][product];
  const learnMoreLink = learnMoreLinkMap[product];

  return (
    <div className="n-subheading-medium" data-testid="disabled-product">
      <Icon name={logo} className="tw-mb-12" />
      {planType === PlanType.ENTERPRISE && <p className="tw-mb-4">{message}</p>}
      <p>
        You don&apos;t have this product right now, reach out to{' '}
        <a href="mailto:cloudsales@neo4j.com" target="_blank" rel="noreferrer">
          cloudsales@neo4j.com
        </a>
      </p>
      <p className="tw-mt-8">
        <a href={learnMoreLink} target="_blank" rel="noreferrer">
          Learn more
        </a>{' '}
        about {productFriendlyName(product)}
      </p>
    </div>
  );
};
