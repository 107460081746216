export interface CustomEndpoint {
  id: string;
  dbId?: string;
  namespaceId: string;
  name: string;
  suffix: string;
  isTransferable: boolean;
  isRevertible: boolean;
  sourceDbId?: string;
}

export enum CustomEndpointStatus {
  // ACTIVE, but no assigned instance
  DISCONNECTED = 'DISCONNECTED',
  // Unrecognized status:
  UNKNOWN = 'UNKNOWN',
  // Traffic Config API States:
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
}

export interface CustomEndpointDetails {
  id: string;
  dbId?: string;
  namespaceId: string;
  name: string;
  suffix: string;
  isTransferable: boolean;
  isRevertible: boolean;
  sourceDbId?: string;
  status: CustomEndpointStatus;
}

// API

export interface CustomEndpointResponse {
  id: string;
  dbid?: string;
  namespace_id: string;
  name: string;
  suffix: string;
  is_transferable: boolean;
  is_revertible: boolean;
  source_dbid?: string;
}

export interface CustomEndpointDetailsResponse {
  id: string;
  dbid?: string;
  namespace_id: string;
  name: string;
  suffix: string;
  is_transferable: boolean;
  is_revertible: boolean;
  source_dbid?: string;
  status: CustomEndpointStatus;
}
