import { percentageComplete } from 'components/utils';
import { UploadHandlers, UploadError } from 'remote/api-client/upload-client';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export enum Status {
  COMPLETE = 'complete',
  ERROR = 'error',
  UPLOADING = 'uploading',
  PROCESSING = 'processing',
  IDLE = 'idle',
}

export interface DumpFileUploadHandlers extends UploadHandlers {
  onComplete(): void;
  onUploadProcessingError(e: Error): void;
  onUploadProcessingComplete(): void;
}

export const setUpHandlers = (cb): DumpFileUploadHandlers => ({
  onUploadProcessingComplete: () => {
    cb({
      status: Status.COMPLETE,
    });
  },
  onUploadProcessingError: (e: ApiClientRequestError) => {
    cb({
      status: Status.ERROR,
      message: e?.responseMessage || e?.message,
      errorCode: e?.reason,
    });
  },
  onLoadStart: file =>
    cb({
      fileMetadata: { name: file.name, size: file.size },
      progress: undefined,
    }),
  onUploadComplete: () =>
    cb({
      status: Status.UPLOADING,
      progress: 100,
    }),
  setProgress: e =>
    cb({
      status: Status.UPLOADING,
      progress: percentageComplete(e.loaded, e.total),
    }),
  onComplete: () =>
    cb({
      status: Status.COMPLETE,
      message: 'Upload successful',
    }),
  onError: (error: UploadError) =>
    cb({
      status: Status.ERROR,
      message: error.message,
      errorCode: error.reason,
    }),
});
