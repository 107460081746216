import location from 'browser/location';
import { listByUserId } from 'remote/resources/tenants';
import { PartnerAccount } from 'types/user';
import { PlanType, TenantSummary, TenantType } from 'entities/tenant';

export class TenantNotFoundError extends Error {}
export class TenantWrongType extends Error {}

const findCurrentTenant = async (
  userId: string,
  partnerAccount: PartnerAccount | undefined,
  userPlanType: string | undefined,
  queryParams: string,
  defaultTenantId: string,
  currentSsoOrgId?: string
): Promise<TenantSummary> => {
  const tenants = await listByUserId(userId);

  const params = new URLSearchParams(queryParams);
  const tenantId = params.get('tenant');
  if (tenantId) {
    const match = tenants.find(tenant => tenant.id === tenantId);

    if (match) {
      if (userPlanType && match.planType !== userPlanType) {
        throw new TenantWrongType('Tenant and User plan types do not match.');
      }
      return match;
    } else {
      throw new TenantNotFoundError(`Could not find tenant with id: ${tenantId}.`);
    }
  }

  if (partnerAccount) {
    const match = tenants.find(tenant => tenant.googleProjectId === partnerAccount.projectId);

    if (match) {
      return match;
    } else {
      throw new TenantNotFoundError(`Could not find N4GCP tenant: ${partnerAccount.projectId}.`);
    }
  }

  const enterpriseTenants = tenants.filter(tenant => tenant.tenantType === TenantType.ENTERPRISE);
  const otherTenants = tenants.filter(tenant => tenant.tenantType !== TenantType.ENTERPRISE);
  const defaultTenant = tenants.find(tenant => tenant.id === defaultTenantId);

  // Find sso tenant we are authenticated for
  if (enterpriseTenants.length > 0 && currentSsoOrgId) {
    for (const tenant of enterpriseTenants) {
      if (tenant.ssoOrganizationId === currentSsoOrgId) {
        return tenant;
      }
    }
  }

  // Use default tenant if no sso required
  if (defaultTenant && !defaultTenant.ssoOrganizationId) {
    return defaultTenant;
  }

  // We are enterprise, lets pick an enterprise tenant
  if (enterpriseTenants.length > 0) {
    // Otherwise find first enterprise tenant with no sso required
    for (const tenant of enterpriseTenants) {
      if (!tenant.ssoOrganizationId) {
        return tenant;
      }
    }

    // No non sso enterprise tenants to use, lets return default if its enterprise
    if (defaultTenant.planType === PlanType.ENTERPRISE) {
      return defaultTenant;
    }

    // Return any enterprise tenant
    return enterpriseTenants[0];
  }

  // Otherwise find any non enterprise tenant with no sso required
  if (otherTenants.length > 0) {
    for (const tenant of otherTenants) {
      if (!tenant.ssoOrganizationId) {
        return tenant;
      }
    }

    // No non sso non enterprise tenants to use, lets return default if its self serve
    if (defaultTenant.planType === PlanType.SELF_SERVE) {
      return defaultTenant;
    }

    // OMG JUST PICK ALREADY
    return otherTenants[0];
  }

  // Absolutely broken state, should trigger a "Load failed"
  throw new Error('User does not have access to their default tenant');
};

const set = (tenantId: string) => {
  const url = new URL(location.href);

  url.searchParams.set('tenant', tenantId);
  url.hash = '';

  // Intentionally trigger a page reload for now.
  location.replace(url);
};

const clear = () => {
  const url = new URL(location.href);

  url.searchParams.delete('tenant');
  url.hash = '';

  // Intentionally trigger a page reload for now.
  location.replace(url);
};

export default {
  findCurrentTenant,
  set,
  clear,
};
