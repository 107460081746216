import { EncryptionKey } from 'types/encryption-keys';
import ApiClient from 'remote/api-client';
import { useQuery, UseQueryResult } from 'react-query';
import { Tier } from 'entities/database';
import { Tenant } from 'entities/tenant';

const transformEncryptionKey = (data: any) => {
  const key: EncryptionKey = {
    encryptionKeyRef: data.encryption_key_ref,
    cloudProvider: data.cloud_provider,
    tier: data.tier,
    region: data.region,
    name: data.name,
    created: data.created,
    status: data.status,
    awsProperties: data.aws_properties
      ? {
          kmsKeyArn: data.aws_properties.kms_key_arn,
          permissions: {
            accountId: data.aws_properties.permissions.account_id,
          },
        }
      : null,
    gcpProperties: data.gcp_properties
      ? {
          keyId: data.gcp_properties.key_id,
          permissions: {
            projectNumber: data.gcp_properties.permissions.project_number,
            serviceAccounts: data.gcp_properties.permissions.service_accounts,
          },
        }
      : null,
    azureProperties: data.azure_properties
      ? {
          keyId: data.azure_properties.key_id,
          applicationURL: data.azure_properties.application_url,
          applicationName: data.azure_properties.application_name,
          applicationID: data.azure_properties.application_id,
        }
      : null,
  };
  return key;
};

export const list = async (tenantId: string): Promise<EncryptionKey[]> => {
  const keys = await ApiClient.get(`/namespaces/${tenantId}/encryption-keys`).promise();

  return keys.map(transformEncryptionKey);
};

export const get = async (tenantId: string, key_ref: string): Promise<EncryptionKey> => {
  const key = await ApiClient.get(`/namespaces/${tenantId}/encryption-keys/${key_ref}`).promise();

  return transformEncryptionKey(key);
};

export const create = async (
  tenantId: string,
  tier: Tier,
  region: string,
  name: string,
  keyId: string,
  cloudProvider: string
): Promise<EncryptionKey> => {
  return ApiClient.post(`/namespaces/${tenantId}/encryption-keys`)
    .issue({
      tier: tier,
      region: region,
      name: name,
      key_id: keyId,
      cloud_provider: cloudProvider,
    })
    .promise()
    .then(transformEncryptionKey);
};

export const deleteKey = (tenantId: string, key_ref: string) =>
  ApiClient.delete(`/namespaces/${tenantId}/encryption-keys/${key_ref}`).promise();

export const useCmeks = (tenant: Tenant): UseQueryResult<EncryptionKey[]> =>
  useQuery(
    `cmeks-${tenant.id}`,
    async () => {
      if (tenant.capabilities.cmek) {
        const keys = await list(tenant.id);
        return keys;
      } else {
        return Promise.resolve([]);
      }
    },
    {
      enabled: !!tenant.id,
      refetchInterval: 5000,
    }
  );

export default { list, get, deleteKey, create, useCmeks };
