import DatabaseMetricsResource from 'remote/resources/db-metrics';

import { xAxisFormatter, tooltipFormatter } from './helpers';

export const textForErrorCode = error => {
  switch (error) {
    case null:
    case undefined:
      return error;
    case DatabaseMetricsResource.NO_DATA:
      return 'There is no data to show right now, it should appear within a few more minutes.';
    case DatabaseMetricsResource.OFFLINE:
      return "We don't have any data to show because we can't connect to the server.";
    case DatabaseMetricsResource.SERVER_ERROR:
    default:
      return (
        'Data is unavailable at the moment due to a server error, this ' +
        'chart should hopefully be restored soon.'
      );
  }
};

export const COLOURS = [
  '#55BDC5',
  '#4D49CB',
  '#DC8B39',
  '#C9458D',
  '#8E8CF3',
  '#78DE7C',
  '#3F80E3',
  '#673FAB',
  '#DBBF40',
  '#BF732D',
  '#478A6E',
  '#ADE86B',
];

interface ChartBuilderProps {
  chart: any;
  error?: string;
}

export const chartBuilder = ({ chart, error }: ChartBuilderProps) => {
  const xAxisIntervalMinutes = 2 * 60;
  const baseFontSize = 14;
  const grey = '#cdcdcd';

  const name = chart.Name;
  const xAxisData =
    chart && chart.TimeSeries && chart.TimeSeries[0].DataPoints.map(_ => _.Timestamp);
  const haveData = chart && chart.TimeSeries && chart.TimeSeries[0].DataPoints.length !== 0;

  let type = chart.Type;
  if (type === 'ms' && chart.TimeSeries.find(ts => ts.DataPoints.find(_ => _.Value > 1000))) {
    type = 's';
  }

  const postFixValue = () => {
    switch (type) {
      case 'percentage':
        return '%';
      case 'count':
        return '';
      default:
        return type;
    }
  };

  const seriesType = type === 'count' ? 'bar' : 'line';

  const seriesNames = [];
  let startColour = 0;

  const seriesDataBuilder = chart.TimeSeries.map(ts => {
    const color = COLOURS[startColour % COLOURS.length];
    startColour += 1;
    seriesNames.push(ts.Alias);
    return {
      name: ts.Alias,
      type: seriesType,
      data: ts.DataPoints.map(_ => {
        if (!_.Value) {
          return _.Value;
        }

        if (type === 'percentage' && _.Value >= 100) {
          return 100;
        }
        if (type === 's') {
          return (_.Value / 1000).toFixed(3);
        }

        const isInt = _.Value % 1;
        return isInt ? _.Value.toFixed(2) : _.Value;
      }),
      symbol: 'circle',
      symbolSize: 6,
      showSymbol: false,
      silent: true,
      itemStyle: { color, z: 100 },
      lineStyle: { color },
      z: 50,
    };
  });

  const legend =
    seriesNames.length > 1
      ? {
          left: 10,
          bottom: -5,
          selectedMode: false,
          textStyle: {
            color: '#4D5157',
          },
          data: seriesNames.map(serieName => ({
            name: serieName,
            icon: 'path://M 36 32 H 30 c 0 -1 0 0 0 -1 H 36 c 0 1 0 0 0 1',
          })),
        }
      : null;

  return {
    ChartTitle: name,
    DescriptionHeader: chart.Title,
    Description: chart.Description,
    title: {
      text: textForErrorCode(error),
      left: 'center',
      top: '25%',
      textStyle: {
        fontSize: baseFontSize,
        fontWeight: 'normal',
      },
      smooth: true,
      show: !haveData,
    },
    textStyle: {
      fontFamily: "Helvetica, 'Helvetica Neue', Arial, sans-serif",
      fontSize: baseFontSize,
      color: grey,
    },
    grid: {
      top: 10,
      bottom: legend ? 50 : 25,
      left: 66,
      right: 12,
    },
    tooltip: {
      trigger: haveData ? 'axis' : 'none',
      formatter: tooltipFormatter.bind(this, postFixValue()),
      padding: 15,
    },
    legend,
    xAxis: {
      data: xAxisData,
      axisPointer: {
        lineStyle: {
          color: '#f6f6f6',
          width: 2,
        },
        z: 1,
      },
      axisLabel: {
        formatter: xAxisFormatter,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      interval: xAxisIntervalMinutes * 60 * 1000,
    },
    yAxis: {
      min: 0,
      max: type === 'percentage' ? 100 : null,
      offset: 10,
      minInterval: type === 'percentage' ? 25 : undefined,
      axisLabel: {
        formatter: value => {
          const v = value < 1 && value > 0 ? `${value.toFixed(2)}` : `${value}`;
          return `${v}${postFixValue()}`;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: haveData,
        lineStyle: {
          type: 'dashed',
          color: grey,
        },
      },
    },
    series: seriesDataBuilder,
  };
};
