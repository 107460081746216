import { Page } from 'foundation/page';
import { Link } from 'foundation';
import { parse } from 'query-string';
import { useLocation, useSession } from 'store';
import { Product } from 'types/product';
import { isProductAvailable } from 'entities/tenant';
import React from 'react';

const NotFoundPage = () => {
  const location = useLocation();
  const session = useSession();
  const urlProduct = parse(location.search)?.product;
  let inferredProduct: Product;

  if (session.providerConfigs) {
    inferredProduct = isProductAvailable(session.providerConfigs, Product.AURA_DB)
      ? Product.AURA_DB
      : Product.AURA_DS;
  }
  const product = urlProduct ?? inferredProduct;
  const homePath = session.loggedIn && product ? `/?product=${product}#` : '/#';
  return (
    <div className="main-body">
      <Page style={{ paddingTop: 16 }}>
        <h2>Not found</h2>
        <p>
          Sorry, but the page you were looking for could not be found. Try going back to the{' '}
          <Link href={homePath} className="is-primary-text">
            home page
          </Link>
          .
        </p>
      </Page>
    </div>
  );
};

export default NotFoundPage;
