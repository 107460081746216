import React from 'react';
import { Checkbox, NewTabLink } from 'foundation';
import { RegionalGDPRComplianceTier } from 'types/user';

interface MarketingConsentProps {
  regionalTier: RegionalGDPRComplianceTier;
  marketingPreference: boolean;
  onChange: () => void;
}

const MarketingConsentSection = ({
  regionalTier,
  marketingPreference,
  onChange,
}: MarketingConsentProps) => {
  const checkboxLabel = {
    [RegionalGDPRComplianceTier.TIER_2]: (
      <p>
        We would like to keep you updated on the latest news about graph database products, services
        & events. If you <span className="tw-underline">do not</span> want to hear from us going
        forward, please tick this box.
      </p>
    ),
    [RegionalGDPRComplianceTier.TIER_3]: (
      <p>
        Yes! Please keep me updated on the latest news about graph database products, services &
        events.
      </p>
    ),
  };
  return (
    <div
      className="tw-flex tw-justify-between tw-flex-col tw-gap-4 tw-py-4"
      data-testid="marketing-consent-section"
    >
      <span>
        By submitting this form, you confirm you are acting for your business/organization.
      </span>
      {regionalTier !== RegionalGDPRComplianceTier.TIER_1 && (
        <Checkbox
          checked={marketingPreference}
          onChange={onChange}
          label={checkboxLabel[regionalTier]}
          data-testid="marketing-consent-checkbox"
        />
      )}
      <span className="">
        The information you provide will be used in accordance with&nbsp;
        <NewTabLink className="tw-inline" href="https://neo4j.com/privacy-policy">
          Neo4j&apos;s Privacy Policy
        </NewTabLink>
        , including in respect of marketing our products, events and services. You may opt out at
        any time in accordance with our Privacy Policy
      </span>
    </div>
  );
};

export default MarketingConsentSection;
