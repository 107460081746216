import { add, formatDuration, getUnixTime, intervalToDuration, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Database, Tier } from 'entities/database';
import { Log, LogFile, LogStatus, RemoteLog } from 'types/log';
import { StatusType } from 'foundation';

export const isSecurityLogsEnabled = (database: Database): boolean => {
  if (![Tier.AURA_DSE, Tier.ENTERPRISE].includes(database.Tier)) {
    return false;
  }

  return true;
};

const DAYS_BEFORE_EXPIRY = 7;
const DATE_FORMAT = 'yyyy/MM/dd HH:mm:ss';

export const calculateTimeToExpire = (requestedAt: string, lifetimeDays: number) => {
  const expiresAt = add(parseISO(requestedAt), { days: lifetimeDays });
  return intervalToDuration({ start: new Date(), end: expiresAt });
};

export const currentTimeUTC = () => {
  const now = new Date();
  return add(now, { minutes: now.getTimezoneOffset() });
};

export const sortByRequestedAtDesc = (left, right) => {
  return getUnixTime(parseISO(right.requestedAt)) - getUnixTime(parseISO(left.requestedAt));
};

export const getStatusColour = (value: LogStatus): StatusType => {
  switch (value) {
    case LogStatus.COMPLETED:
      return 'success';
    case LogStatus.IN_PROGRESS:
      return 'unknown';
    case LogStatus.EXPIRING_SOON:
      return 'warning';
    case LogStatus.FAILED:
      return 'danger';
    default:
      return 'unknown';
  }
};

export const getStatusValue = (value: LogStatus) => {
  switch (value) {
    case LogStatus.COMPLETED:
      return 'Completed';
    case LogStatus.IN_PROGRESS:
      return 'In progress';
    case LogStatus.EXPIRING_SOON:
      return 'Expiring soon';
    case LogStatus.FAILED:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

const determineLogType = (files: LogFile[]): string => {
  for (const file of files) {
    if (file === LogFile.QUERY) return 'Query log';
    if (file === LogFile.SECURITY) return 'Security log';
  }

  return 'Unknown type';
};

export const mapRemoteLog = ({
  log_id: logId,
  dbid,
  start,
  end,
  status,
  files,
  requested_at: requestedAt,
}: RemoteLog): Log => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const expiresIn = calculateTimeToExpire(requestedAt, DAYS_BEFORE_EXPIRY);
  status = expiresIn.days <= 1 ? LogStatus.EXPIRING_SOON : status;
  let logDuration = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  const timePeriod = formatDuration(logDuration).toLowerCase();
  const type = determineLogType(files);
  const allowDownload = [LogStatus.EXPIRING_SOON, LogStatus.COMPLETED].includes(status);

  const startUTC = formatInTimeZone(startDate, 'UTC', DATE_FORMAT);
  const endUTC = formatInTimeZone(endDate, 'UTC', DATE_FORMAT);

  return {
    logId,
    dbid,
    status,
    start: startUTC,
    end: endUTC,
    requestedAt,
    files,
    type,
    timePeriod,
    expiresIn,
    downloadLog: allowDownload,
  };
};
