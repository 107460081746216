import DatabasesResource from 'remote/resources/databases';
import { DatabasesStore } from 'state';

const cloneToExistingDatabase = async (targetDbid: string, sourceDbid: string): Promise<any> => {
  const databaseDetails = await DatabasesResource.overwrite(targetDbid, sourceDbid);
  DatabasesStore.upsert(targetDbid, databaseDetails);
  return databaseDetails;
};

export default cloneToExistingDatabase;
