export interface SsoConfig {
  ssoConfigId: string;
  displayName: string;
  idpType: IdpType;
  clientID: string;
  discoveryURI?: string;
  issuer?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  jwksURI?: string;
  roleMapping?: string;
  organizationId: string;
  organizationLoginMethod: boolean;
  channel: Channel;
  emailDomains: string[];
}

export type SsoConfigResourceData = Omit<SsoConfig, 'channel' | 'emailDomains' | 'ssoConfigId'> & {
  clientSecret?: string;
};

export type InternalSsoConfigResourceData = Omit<SsoConfig, 'ssoConfigId'> & {
  clientSecret?: string;
};

export interface InternalSsoConfig extends SsoConfig {
  auth0ConnectionId: string;
  auth0ApplicationId?: string;
}

export enum IdpType {
  OKTA = 'okta',
  AAD = 'aad',
}

export enum Channel {
  FRONT = 'front_channel',
  BACK = 'back_channel',
}
