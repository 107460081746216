import {
  Database,
  MonitoringStatus,
  Tier,
  DatabaseSize,
  DatabaseStatus,
  CloudProvider,
  Region,
  DatabaseSizeMap,
  DATABASE_NAME_CHARACTER_LIMIT,
} from './model';
import logger from 'logger';
import { Tenant } from 'entities/tenant';
import { differenceInHours } from 'date-fns';

/**
 * Helper class with domain logic relating to a database entity
 *
 * Naming Convention:
 *  is<name>Visible is used to indicate logic related to whether or
 *  not the user should ever see this. If these functions return
 *  false the user should never know that this logic exists. For example,
 *  if isCloneVisible returns false then the user should never see any
 *  ui elements or text related to cloning. They shouldn't know cloning
 *  exists.
 *
 *  is<name>able is used to indicate whether or not the feature is activatable
 *  or usable **right now**. Once the database is in a valid state for the
 *  feature, this should return true. IE isCloneable should return false
 *  if cloning is unavailable atm such as when the database is pausing.
 *
 * Update:
 * Previous Maps of DatabaseStatus -> boolean were replaced by DatabaseCapabilities
 * These are sent by the Console API as a new object: database.capabilities and set
 * a boolean that decides whether an action (e.g. "pause") is allowed.
 *
 * Update 2.0:
 * The actions available on an instance has been migrated from DatabaseCapabilities
 * to AvailableDatabaseActions.
 */

// Like MonitoringStatus, only it takes DatabaseStatus into account (see below)
export enum OnlineStatus {
  ONLINE = 'online',
  DEGRADED = 'degraded',
  OFFLINE = 'offline',
}

export const getDatabaseOnlineStatus = (database: Database) => {
  const monitoringStatus = database.MonitoringStatus || MonitoringStatus.OK;

  switch (database.DatabaseStatus as DatabaseStatus | 'fetching') {
    case DatabaseStatus.ENCRYPTION_KEY_DELETED:
    case DatabaseStatus.ENCRYPTION_KEY_ERROR:
    case DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND:
      return OnlineStatus.DEGRADED;

    case DatabaseStatus.RUNNING:
    case DatabaseStatus.UPDATING:
    case DatabaseStatus.LOADING_DATA:
    case DatabaseStatus.LOADING_FAILED:
    case DatabaseStatus.OVERWRITING:
      switch (monitoringStatus) {
        case MonitoringStatus.OK:
          return OnlineStatus.ONLINE;

        case MonitoringStatus.WARNING:
          return OnlineStatus.DEGRADED;

        case MonitoringStatus.CRITICAL:
          return OnlineStatus.OFFLINE;

        default:
          logger.warn('Unhandled MonitoringStatus:', MonitoringStatus);
          return OnlineStatus.OFFLINE;
      }

    case DatabaseStatus.CREATING:
    case DatabaseStatus.DESTROYING:
    case DatabaseStatus.DESTROYED:
    case DatabaseStatus.RESTORING:
    case DatabaseStatus.PAUSING:
    case DatabaseStatus.SUSPENDING:
    case DatabaseStatus.PAUSED:
    case DatabaseStatus.SUSPENDED:
    case DatabaseStatus.RESUMING:
      return OnlineStatus.OFFLINE;

    default:
      logger.warn('Unhandled DatabaseStatus:', database.DatabaseStatus);
      return OnlineStatus.OFFLINE;
  }
};

const isNeoAppLinksAccessableDatabaseStatusMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: true,
  [DatabaseStatus.RUNNING]: true,
  [DatabaseStatus.PAUSING]: true,
  [DatabaseStatus.SUSPENDING]: true,
  [DatabaseStatus.PAUSED]: true,
  [DatabaseStatus.SUSPENDED]: true,
  [DatabaseStatus.RESUMING]: true,
  [DatabaseStatus.LOADING_DATA]: false,
  [DatabaseStatus.LOADING_FAILED]: true,
  [DatabaseStatus.UPDATING]: true,
  [DatabaseStatus.DESTROYING]: false,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: false,
  [DatabaseStatus.OVERWRITING]: true,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: false,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: false,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: false,
};

const isNeoAppLinksAccessableOnlineStatusMap: Record<OnlineStatus, boolean> = {
  [OnlineStatus.DEGRADED]: true,
  [OnlineStatus.OFFLINE]: false,
  [OnlineStatus.ONLINE]: true,
};

export const isNeoAppsAccessable = (database: Database) => {
  const onlineStatus = getDatabaseOnlineStatus(database);
  return (
    isNeoAppLinksAccessableOnlineStatusMap[onlineStatus] &&
    isNeoAppLinksAccessableDatabaseStatusMap[database.DatabaseStatus]
  );
};

export const getOnlineStatusColor = (database: Database) => {
  const onlineStatus = getDatabaseOnlineStatus(database);

  // For single-instance products, the DB becomes unavailable during updates
  const isSingleInstanceTier = [Tier.AURA_DSE, Tier.FREE, Tier.GDS].includes(database.Tier);
  if (isSingleInstanceTier && database.DatabaseStatus === DatabaseStatus.UPDATING) {
    return 'unknown';
  }

  switch (onlineStatus) {
    case OnlineStatus.ONLINE:
      return 'success';
    case OnlineStatus.DEGRADED:
      return 'warning';
    case OnlineStatus.OFFLINE:
      return 'unknown';
    default:
      logger.warn('Unhandled DatabaseEntity.databaseOnlineStatus():', onlineStatus);
      return 'unknown';
  }
};

export const isPauseVisible = (database: Database) => {
  return database.Capabilities.pause.enabled;
};

const resumeVisibleMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: false,
  [DatabaseStatus.RUNNING]: false,
  [DatabaseStatus.PAUSING]: true,
  [DatabaseStatus.SUSPENDING]: true,
  [DatabaseStatus.PAUSED]: true,
  [DatabaseStatus.SUSPENDED]: true,
  [DatabaseStatus.RESUMING]: false,
  [DatabaseStatus.LOADING_DATA]: false,
  [DatabaseStatus.LOADING_FAILED]: false,
  [DatabaseStatus.UPDATING]: false,
  [DatabaseStatus.DESTROYING]: false,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: false,
  [DatabaseStatus.OVERWRITING]: false,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: false,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: false,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: false,
};

/**
 * Databases that are paused are usually resumable
 * regardless of everything else. This allows
 * use to autopause databases that don't have pause
 * features permitted.
 */
export const isResumeVisible = (database: Database) => {
  return resumeVisibleMap[database.DatabaseStatus];
};

const isPausableOnlineStatusMap: Record<OnlineStatus, boolean> = {
  [OnlineStatus.ONLINE]: true,
  [OnlineStatus.DEGRADED]: false,
  [OnlineStatus.OFFLINE]: false,
};

export const isPausable = (database: Database) => {
  const onlineStatus = getDatabaseOnlineStatus(database);
  return (
    !database.IsBeingCloned &&
    database.AvailableActions.pause.enabled &&
    isPausableOnlineStatusMap[onlineStatus]
  );
};

export const isResumable = (database: Database, tenantSuspended: boolean) => {
  if (tenantSuspended) {
    return false;
  }
  return database.AvailableActions.resume.enabled;
};

export const isDeletable = (database: Database) => {
  return !database.IsBeingCloned && database.AvailableActions.delete.enabled;
};

// isConfigurable captures if an instance can be resized, or additional settings are able to be updated.
export const isConfigurable = (database: Database) =>
  isResizable(database) || isVectorOptimizable(database);

export const isVectorOptimizable = (database: Database) =>
  database.AvailableActions.vector_optimized && database.AvailableActions.vector_optimized.enabled;

export const isResizable = (database: Database) =>
  !database.IsBeingCloned && !isResizing(database) && database.AvailableActions.resize.enabled;

export const canEditSecondaries = (database: Database) =>
  !database.IsBeingCloned &&
  (database.Tier === Tier.ENTERPRISE ? !isChangingSecondariesCount(database) : true) &&
  database.AvailableActions.secondaries.enabled;

export const isResizing = (database: Database) => {
  return (
    database.DatabaseStatus === DatabaseStatus.UPDATING &&
    database.DesiredSettings.Memory &&
    database.AppliedSettings.Memory &&
    database.DesiredSettings.Memory !== database.AppliedSettings.Memory
  );
};

export const isChangingSecondariesCount = (database: Database) => {
  return (
    database.DatabaseStatus === DatabaseStatus.UPDATING &&
    (database.DesiredSettings.SecondariesCount ||
      database.DesiredSettings.SecondariesCount === 0) &&
    (database.AppliedSettings.SecondariesCount ||
      database.AppliedSettings.SecondariesCount === 0) &&
    database.DesiredSettings.SecondariesCount !== database.AppliedSettings.SecondariesCount
  );
};

export const getConnectionUri = (database: Database) => {
  return database.ConnectionUri || database.BoltUrl;
};

export const isCloneable = (database: Database) => database.AvailableActions.clone.enabled;

const isVersionViewableTierMap: Record<Tier, boolean> = {
  [Tier.FREE]: true,
  [Tier.PROFESSIONAL]: true,
  [Tier.MTE]: true,
  [Tier.ENTERPRISE]: true,
  [Tier.GDS]: true,
  [Tier.AURA_DSE]: true,
};

export const isVersionViewable = (database: Database) => isVersionViewableTierMap[database.Tier];

const isRegionViewableTierMap: Record<Tier, boolean> = {
  [Tier.FREE]: true,
  [Tier.PROFESSIONAL]: true,
  [Tier.MTE]: true,
  [Tier.ENTERPRISE]: true,
  [Tier.GDS]: true,
  [Tier.AURA_DSE]: true,
};

export const isRegionViewable = (database: Database) => isRegionViewableTierMap[database.Tier];

export const canBeSnapshot = (database: Database) =>
  database.AvailableActions.take_snapshot.enabled;

export const gibibytesStringToInt = (memoryString: string): number =>
  parseInt(memoryString.replace(/Gi?B/, ''), 10);

export const findDatabaseSize = (
  sizes: DatabaseSize[],
  memory: string
): DatabaseSize | undefined => {
  const target = gibibytesStringToInt(memory);
  return sizes.find(size => gibibytesStringToInt(size.memory) === target && !size.is_trial);
};

export const findTrialDatabaseSize = (
  sizes: DatabaseSize[],
  memory: string
): DatabaseSize | undefined => {
  const target = gibibytesStringToInt(memory);
  return sizes.find(size => gibibytesStringToInt(size.memory) === target && size.is_trial);
};

export const filterSizeMap = (
  sizeMap: DatabaseSizeMap,
  predicate: (size: DatabaseSize) => boolean
): DatabaseSizeMap => {
  const result = {};

  for (const [tier, sizes] of Object.entries(sizeMap)) {
    result[tier] = sizes.filter(predicate);
  }

  return result;
};

export const isSizeAvailableInRegion = (
  size: DatabaseSize,
  region: string,
  cloudProvider: string
): boolean => {
  const unavailable = size?.unavailable_regions?.some(
    entry => entry.cloud_provider === cloudProvider && entry.region === region
  );

  return !unavailable;
};

export function friendlyRegionName(database: Database, regions: Region[] = []): string {
  const region = regions.find(r => r.name === database.Region);
  const regionName = region ? region.friendly : database.Region;

  return regionName;
}

export const friendlyCloudProviderNameMap: Record<CloudProvider, string> = {
  [CloudProvider.AWS]: 'AWS',
  [CloudProvider.GCP]: 'GCP',
  [CloudProvider.AZURE]: 'Azure',
};

export function friendlyCloudProviderName(database: Database): string {
  return friendlyCloudProviderNameMap[database.CloudProvider];
}

export const friendlyCloudProviderRegionName = (
  database: Database,
  regions: Region[] = []
): string => {
  const cloudProviderName = friendlyCloudProviderName(database);
  const regionName = friendlyRegionName(database, regions);

  return `${regionName || ''}${cloudProviderName ? `, ${cloudProviderName}` : ''}`;
};

export const isOverwritable = (database: Database) => database.AvailableActions.clone.enabled;

export const getRemainingTrialTimeMessage = (database: Database): string => {
  const trialEndTime = database.ProTrialEndTime ? new Date(database.ProTrialEndTime) : null;
  const now = new Date();

  if (!trialEndTime) {
    return '';
  }

  if (trialEndTime < now) return 'Trial expired';

  const hoursRemaining = Math.max(differenceInHours(trialEndTime, now) + 1, 0);

  if (hoursRemaining <= 1) return '<1 hour trial remaining';
  if (hoursRemaining < 24) return `${hoursRemaining} hours trial remaining`;
  if (hoursRemaining === 24) return '1 day trial remaining';

  const daysRemaining = Math.ceil(hoursRemaining / 24);
  return `${daysRemaining} days trial remaining`;
};

export const hasActiveProTrial = (database: Database): boolean => {
  const trialEndTime = database.ProTrialEndTime ? new Date(database.ProTrialEndTime) : null;
  const now = new Date();
  // difference in days ignoring DST - https://date-fns.org/v3.6.0/docs/differenceInDays
  return trialEndTime ? trialEndTime > now : false;
};

export const isSizeAvailableForVersion = (size: DatabaseSize, version: string, tenant: Tenant) => {
  if (!size?.minimum_required_version) return true;
  const minimumRequiredVersion =
    tenant.capabilities?.gi_512_for_v4 && size.size_id.startsWith('enterprise-512')
      ? 4
      : Number(size.minimum_required_version);
  return Number(minimumRequiredVersion) <= Number(version);
};

export const getDefaultName = (sourceDbName: string, suffix: string) => {
  const padding = suffix.length + 1; // e.g. ' Clone'
  const cutoff = suffix.length + 4; // e.g. '... Clone'
  return `${
    sourceDbName.length + padding > DATABASE_NAME_CHARACTER_LIMIT
      ? sourceDbName.substring(0, DATABASE_NAME_CHARACTER_LIMIT - cutoff).concat('...')
      : sourceDbName
  } ${suffix}`;
};

export const isSizeEnabledByToggle = (
  size: DatabaseSize,
  tenant: Tenant,
  cloudProvider: string
) => {
  if (size.memory === '512GB' && cloudProvider === CloudProvider.AZURE) {
    return tenant.capabilities.azure_512_gi;
  }

  return true;
};
