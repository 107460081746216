import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  CopyTextToClipBoardButton,
  FormInput,
  Label,
  TextLink,
} from 'foundation';
import {
  AwsEndpointConnectionState,
  AzureEndpointConnectionState,
  TrafficEnablement,
} from 'types/traffic-configs';
import { Database } from 'entities/database';

export interface ConnectionRequest {
  id: string;
  state: AwsEndpointConnectionState | AzureEndpointConnectionState;
}

interface Props {
  value: string;
  loading: boolean;
  inputErrorText?: string;
  connectionRequests: ConnectionRequest[];
  acceptMessage?: {
    message: React.ReactNode;
    messageType: 'warning' | 'danger' | 'info' | 'success';
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAccept: () => void;
  stateColorMapper: (
    state: AwsEndpointConnectionState | AzureEndpointConnectionState
  ) => React.ComponentProps<typeof Label>['color'];
}

export const ConnectionRequestAcceptor = ({
  value,
  loading,
  inputErrorText,
  connectionRequests,
  acceptMessage,
  onChange,
  onAccept,
  stateColorMapper,
}: Props) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <h6>Endpoint Connection Requests</h6>
      <div className="tw-ml-6">
        {connectionRequests.length === 0 && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            <p>No endpoint connections requests</p>
            <p className="tw-text-color-secondary">
              Follow the instructions on the previous step to make an endpoint connection request.
            </p>
          </div>
        )}

        {connectionRequests.length > 0 && (
          <ul className="tw-list-disc tw-flex tw-flex-col tw-gap-2 tw-ml-6">
            {connectionRequests.map(conn => {
              return (
                <li key={conn.id}>
                  <div className="tw-flex tw-flex-nowrap tw-gap-2 tw-justify-between tw-items-center">
                    <p
                      className="tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden"
                      title={conn.id}
                    >
                      {conn.id}
                    </p>
                    <span className="tw-flex tw-flex-nowrap tw-items-center tw-gap-1">
                      <CopyTextToClipBoardButton text={conn.id} />
                      <Label
                        title={conn.state}
                        fill="semi-filled"
                        color={stateColorMapper(conn.state)}
                      >
                        {conn.state}
                      </Label>
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="tw-flex tw-flex-nowrap tw-gap-2 tw-ml-6 tw-items-end">
        <FormInput
          label="Accept endpoint connection request"
          placeholder="Enter endpoint ID..."
          value={value}
          onChange={onChange}
          errorText={inputErrorText}
          className="tw-flex-1"
          fluid
          data-testid="endpoint-id"
        />
        <Button onClick={onAccept} loading={loading} fill="outlined" data-testid="accept-endpoint">
          Accept
        </Button>
      </div>
      {acceptMessage?.message && (
        <Alert type={acceptMessage.messageType} description={acceptMessage.message} />
      )}
    </div>
  );
};

interface PrivateConnectionProps {
  publicTraffic: TrafficEnablement;
  onPublicTrafficDisabledChange: (e: any) => void;
  dnsDomain?: string;
  affectedDatabases: Database[];
}

export const PrivateConnection = ({
  publicTraffic,
  onPublicTrafficDisabledChange,
  dnsDomain,
  affectedDatabases,
}: PrivateConnectionProps) => {
  return (
    <>
      <Checkbox
        checked={publicTraffic !== TrafficEnablement.ENABLED}
        onChange={onPublicTrafficDisabledChange}
        label="Disable public traffic"
        data-testid="dialog-disable-public"
      />
      {publicTraffic === TrafficEnablement.DISABLED && (
        <>
          <Alert
            icon
            type="warning"
            description={
              <>
                <p>
                  Ensure you have changed the application connection string to{' '}
                  <code>{`<dbid>.${dnsDomain}`}</code>, otherwise your applications will be unable
                  to connect to the database.
                </p>
                {affectedDatabases.length > 0 && (
                  <div className="tw-mt-2">
                    The following databases will be affected:
                    <ul className="tw-list-disc tw-mt-1 tw-list-inside tw-ml-4">
                      {affectedDatabases.map(db => (
                        <li key={db.DbId} className="n-label">
                          {db.Name} - {db.DbId}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            }
          />
          {affectedDatabases.length > 0 && (
            <Alert
              icon
              type="warning"
              description={
                <>
                  <p>
                    If you have SSO configured for any of the above instances you will need to
                    update the Redirect URLs in your IDP configuration. For more information, please
                    review our{' '}
                    <a
                      href={
                        'https://neo4j.com/docs/operations-manual/current/tutorial/tutorial-sso-configuration/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      documentation
                    </a>{' '}
                    or contact support.
                  </p>
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
};

interface ConfirmCheckboxProps {
  isConfirmed: boolean;
  onConfirmChange: (e: any) => void;
}

export const ConfirmCheckbox = ({ isConfirmed, onConfirmChange }: ConfirmCheckboxProps) => {
  return (
    <Checkbox
      checked={isConfirmed}
      onChange={onConfirmChange}
      aria-label="I have read and acknowledged the VPN statement, and have verified the
        functionality of my private endpoint connection."
      label={
        <div>
          I have read and acknowledged the VPN statement, and have verified the functionality of my
          private endpoint connection. To test your connectivity, please follow this{' '}
          <TextLink
            href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
            externalLink
          >
            guide.
          </TextLink>
        </div>
      }
      data-testid="dialog-confirm"
    />
  );
};

interface VpnMessageProps {
  href: string;
}

export const VpnMessage = ({ href }: VpnMessageProps) => {
  return (
    <Alert
      icon
      type="warning"
      description={
        <>
          <p>
            To continue accessing Browser and Bloom, you can configure a VPN (Virtual Private
            Network) in your VPC and connect to these services over the VPN.
          </p>
          <p className="tw-mt-3">
            See{' '}
            <TextLink href={href} externalLink rel="noreferrer">
              Aura Docs
            </TextLink>{' '}
            for more information on setting up a VPN.
          </p>
        </>
      }
    />
  );
};
