import { Page } from 'foundation/page';
import React from 'react';

const AccountTerminatedPage = () => (
  <Page>
    <h1>Account Terminated</h1>
    <p>
      If you believe this is an error or you wish to reactivate, then please contact{' '}
      <a href="https://support.neo4j.com/s/" target="_blank" rel="noopener noreferrer">
        our support team
      </a>
      .
    </p>
  </Page>
);

export default AccountTerminatedPage;
