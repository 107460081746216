import React, { useMemo, useState } from 'react';
import {
  createColumnHelper,
  NewTabLink,
  StickyActionsDataGrid,
  TextInput,
  useDefaultTable,
} from 'components/foundation';
import gdsSessionBlankImage from 'components/ui/images/gds-sessions.svg';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import { GdsSessionTableProps } from './helpers';
import './gds.css';
interface Props {
  rows: GdsSessionTableProps[];
}

const columnHelper = createColumnHelper<GdsSessionTableProps>();

const tableStructure = [
  columnHelper.accessor('sessionName', {
    cell: c => {
      const name = c.getValue();
      return <span>{name}</span>;
    },
    header: 'Session name',
  }),
  columnHelper.accessor('sessionId', {
    cell: c => {
      const id = c.getValue();
      return <span>{id}</span>;
    },
    header: 'Session ID',
  }),
  columnHelper.accessor('instanceName', {
    cell: c => {
      const name = c.getValue();
      return <span>{name}</span>;
    },
    header: 'Instance',
  }),
  columnHelper.accessor('memory', {
    cell: c => {
      const memory = c.getValue();
      return <span>{memory}</span>;
    },
    header: 'Memory',
  }),
  columnHelper.accessor('userEmail', {
    header: 'Database User',
    cell: c => {
      return <span>{c.getValue()}</span>;
    },
  }),
  columnHelper.accessor('timeRemaining', {
    cell: c => {
      const timeRemaining = c.getValue();

      return <span>{timeRemaining}</span>;
    },
    header: 'Time Remaining',
  }),
  columnHelper.accessor('delete', {
    header: null,
    enableSorting: false,
    cell: c => <span className="tw-flex tw-gap-1 tw-justify-end tw-flex-grow">{c.getValue()}</span>,
    meta: {
      isStickyAction: true,
    },
  }),
];

const NoDataPlaceholder = () => (
  <div
    className="tw-flex tw-items-center tw-gap-6 tw-justify-center tw-py-28"
    data-testid="no-data-placeholder"
  >
    <img className="tw-w-64 tw-h-64" src={gdsSessionBlankImage} />
    <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-2 tw-w-96 tw-h-64">
      <h4> Get started with sessions </h4>
      <p className="tw-break-words tw-overflow-hidden">
        Sessions are created and used via the Neo4j Graph Data Science Python client. Click below to
        see the documentation on how to get going
      </p>
      <NewTabLink href="https://neo4j.com/docs/graph-data-science-client/current/gds-session/">
        Learn more
      </NewTabLink>
    </div>
  </div>
);

const GdsSessionTable = ({ rows }: Props) => {
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = useMemo(() => tableStructure, []);

  const table = useDefaultTable({
    data: rows,
    columns,
    state: {
      globalFilter,
    },
  });

  return (
    <div className="gds-table tw-p-8 tw-rounded-lg tw-bg-palette-neutral-bg-weak">
      <div className="tw-flex tw-w-full tw-flex-wrap tw-justify-between tw-gap-2">
        <div className="tw-flex tw-basis-[400px] tw-gap-2">
          <TextInput
            fluid
            label="Search"
            className="tw-min-w-36 tw-grow"
            placeholder="Search"
            leftIcon={<MagnifyingGlassIconOutline />}
            value={globalFilter}
            onChange={event => setGlobalFilter(event.target.value)}
          />
        </div>
      </div>
      <StickyActionsDataGrid tableInstance={table} components={{ NoDataPlaceholder }} />
    </div>
  );
};

export default GdsSessionTable;
