import React, { useEffect, useState } from 'react';
import { User } from 'types/user';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { Alert, Button, Dialog, Form, FormSelect } from 'foundation';
import NamespaceActions from 'actions/namespace-actions';
import { useQuery } from 'react-query';
import { listByUserId } from 'remote/resources/tenants';

const getErrorMessage = (err: Error): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }
  return `Error: ${err.reason || 'unknown'}: ${err.responseMessage}`;
};

interface Props {
  user: User;
  currentTenant?: string;
  open: boolean;
  onClose: () => any;
}

export const RemoveUserFromTenantModal = ({ user, open, onClose, currentTenant }: Props) => {
  const [revokedTenants, setRevokedTenants] = useState(currentTenant ? [currentTenant] : []);
  const [error, setError] = useState<string>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRevokedTenantsChange = selectedOptions => {
    setRevokedTenants(selectedOptions.map(v => v.value));
  };

  const handleSubmit = async () => {
    if (!revokedTenants.length) return;

    setError(null);
    setLoading(true);

    try {
      for (const tenant of revokedTenants) {
        await NamespaceActions.removeTenantMember({
          UserId: user.UserId,
          Email: user.Email,
          NamespaceId: tenant,
          Roles: [],
        });
      }
      setSuccess(true);
      setRevokedTenants([]);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRevokedTenants(currentTenant ? [currentTenant] : []);
    setError('');
    setSuccess(false);
  }, [open]);

  const query = useQuery(['namespaces', user.UserId], () => listByUserId(user.UserId), {
    enabled: open,
  });
  let options = [];
  if (query.isFetched) {
    options = query.data
      .filter(tenant => tenant.id !== user.UserId)
      .map(tenant => ({
        label: `${tenant.name} (${tenant.id}, ${tenant.planType})`,
        key: tenant.id,
        value: tenant.id,
      }));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header data-testid="remove-user-from-tenant-modal-header">
        Revoke access
      </Dialog.Header>
      <Dialog.Content data-testid="remove-user-from-tenant-modal-content">
        <Dialog.Description>
          This will revoke the user&apos;s access to the selected tenants (can&apos;t revoke access
          to personal project).
        </Dialog.Description>
        <Form onSubmit={handleSubmit}>
          <FormSelect
            data-testid="remove-user-from-tenant-select"
            options={options}
            value={revokedTenants}
            onChange={handleRevokedTenantsChange}
            isMulti
          />
        </Form>

        {error && (
          <Alert type="danger" data-testid="remove-user-from-tenant-error" className="tw-mt-2">
            {error}
          </Alert>
        )}
        {query.error && (
          <Alert type="danger" data-testid="remove-user-from-tenant-error" className="tw-mt-2">
            Failed to fetch user projects: {String(query.error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="remove-user-from-tenant-success" className="tw-mt-2">
            User was successfully removed from the selected projects.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} fill="outlined" color="neutral">
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={handleSubmit}
          type="submit"
          loading={loading}
          disabled={!revokedTenants.length || loading || success}
          data-testid="remove-user-from-tenant-button"
        >
          Revoke
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
