import { useDatabasesPoll, useDatabasesRequest } from 'components/helpers/databases';
import { Database, Tier } from 'entities/database';
import { PlanType } from 'entities/tenant';
import { Button, LoadingSpinner, Page, Tip } from 'foundation';
import React, { useMemo } from 'react';
import { IgnoreErrors } from 'remote/api-client';
import { useDatabaseState, useLocation, usePermissions, useSession } from 'store';
import { Product } from 'types/product';
import { Action } from 'types/user';
import './databases.css';
import Actions from 'actions';
import DatabaseCard from 'application/db-card';
import NewTierSelector from 'components/application/create-db/new-tier-selector';

export interface DatabaseListProps {
  databases: Database[];
}

const DatabaseList = ({ databases }: DatabaseListProps) => {
  const session = useSession();

  const isDataScienceTier = (tier: Tier) => [Tier.GDS, Tier.AURA_DSE].includes(tier);

  const databasesFilteredByProduct = databases.filter(db => {
    if (session.product === Product.AURA_DS) {
      return isDataScienceTier(db.Tier);
    }
    if (session.product === Product.AURA_DB) {
      return !isDataScienceTier(db.Tier);
    }
    return true;
  });

  const sortedDatabases = [...databasesFilteredByProduct].sort(compareByNameThenId);

  return (
    <div className="db-cards" data-testid="database-list">
      {sortedDatabases.map(database => (
        <div className="db-card-sizing" key={database.DbId}>
          <DatabaseCard key={database.DbId} database={database} className="tw-flex-1 db-card" />
        </div>
      ))}
    </div>
  );
};

const DatabasesPageGuarded = () => {
  const request = useDatabasesRequest({ ignore: IgnoreErrors.ALL });
  useDatabasesPoll({ ignore: IgnoreErrors.ALL });
  const { databases } = useDatabaseState();

  return <DatabasesPage loading={request.loading} databases={databases} />;
};

export const DatabasesPage = ({ loading, databases }) => {
  const session = useSession();
  const location = useLocation();

  const openCreate = useMemo(() => {
    return location.hash === '#databases/create';
  }, [location.hash]);

  const { allow } = usePermissions();
  const tenantSuspended = session.tenant.suspended;
  const allowCreate = allow(Action.CREATE, `namespaces/${session.currentTenant}/databases`);
  let createDisableReason = '';
  if (tenantSuspended) {
    createDisableReason = 'Creating a new instance in a suspended project is not permitted.';
  } else if (!allowCreate) {
    createDisableReason = "You don't have permission to perform this action.";
  }

  return (
    <Page plain fullWidth className="tw-flex tw-flex-col" style={{ height: loading && '100%' }}>
      <div className="tw-flex tw-items-center tw-space-x-6">
        <h4 className="tw-grow-0">Instances</h4>
        <Tip isDisabled={!tenantSuspended && allowCreate}>
          <Tip.Trigger>
            <Button
              className="tw-grow-0"
              disabled={tenantSuspended || !allowCreate}
              {...(session.tenant.planType === PlanType.ENTERPRISE ||
              session.product === Product.AURA_DS
                ? { href: '#create-database' }
                : { href: '#databases/create' })}
              data-testid="create-new"
            >
              New Instance
            </Button>
          </Tip.Trigger>
          <Tip.Content style={{ width: 200 }}>{createDisableReason}</Tip.Content>
        </Tip>
      </div>

      {loading && <LoadingSpinner size="large" expand />}
      {!loading && <DatabaseList databases={databases} />}
      {openCreate && (
        <NewTierSelector onClose={() => Actions.navigate.push({ hash: '#databases' })} />
      )}
    </Page>
  );
};

const compareByNameThenId = (a: Database, b: Database) => {
  if (a.Name && b.Name) {
    return a.Name.localeCompare(b.Name);
  }
  if (a.Name) {
    return -1;
  }
  if (b.Name) {
    return 1;
  }

  return a.DbId.localeCompare(b.DbId);
};

export default DatabasesPageGuarded;
