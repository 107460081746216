import ApiClient from 'remote/api-client';
import { User } from 'types/user';

const projectsPath = '/projects';

const projectPathFor = (tenantId: string) => `${projectsPath}/${tenantId}`;

const MFAPathFor = (tenantId: string) => `${projectPathFor(tenantId)}/mfa`;

const setMFA = (tenantId: string, enabled: boolean) =>
  ApiClient.post(MFAPathFor(tenantId))
    .issue({
      Enabled: enabled,
    })
    .ignore([403, 404, 422])
    .promise();

const findUsers = (tenantId: string): Promise<User[]> =>
  ApiClient.get(`/projects/${tenantId}/users`).promise();

export default {
  findUsers,
  setMFA,
};
