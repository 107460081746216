import React, { useState } from 'react';
import { Alert, Button, Dialog, FormSelect } from 'foundation';

interface DeleteModalProps {
  open: boolean;
  loading: boolean;
  error: Error | null;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

export const DeleteFeatureToggleOverrideModal = ({
  open,
  loading,
  error,
  onClose,
  onConfirm,
}: DeleteModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{ 'data-testid': 'delete-feature-toggle-override-modal' }}
    >
      <Dialog.Header>Delete feature toggle override</Dialog.Header>
      <Dialog.Content>{error && <Alert type="danger">{String(error)}</Alert>}</Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          onClick={onClose}
          data-testid="delete-feature-toggle-override-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={onConfirm}
          loading={loading}
          data-testid="delete-feature-toggle-override-modal-confirm"
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

interface CreateModalProps {
  open: boolean;
  loading: boolean;
  error: Error | null;
  featureToggles: Record<string, boolean>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: (featureToggle: string, value: boolean) => any;
}

export const CreateFeatureToggleOverrideModal = ({
  open,
  loading,
  error,
  onClose,
  onConfirm,
  featureToggles,
}: CreateModalProps) => {
  const [featureToggle, setFeatureToggle] = useState(null);
  const [featureToggleValue, setFeatureToggleValue] = useState(true);

  const featureToggleOptions = Object.keys(featureToggles).map(ft => ({
    key: ft,
    value: ft,
    label: ft,
  }));

  const valueOptions = [
    { key: 'true', value: 'true', label: 'true' },
    { key: 'false', value: 'false', label: 'false' },
  ];

  const validPayload = featureToggle !== null;

  const handleFeatureToggleChange = e => setFeatureToggle(e.value);
  const handleValueChange = e => setFeatureToggleValue(e.value === 'true');
  const handleConfirm = () => onConfirm(featureToggle, featureToggleValue);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{ 'data-testid': 'create-feature-toggle-override-modal' }}
    >
      <Dialog.Header>Create feature toggle override</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        {error && <Alert type="danger">{String(error)}</Alert>}
        <FormSelect
          value={featureToggle}
          onChange={handleFeatureToggleChange}
          options={featureToggleOptions}
          label="Feature toggle"
          placeholder="Select"
        />
        <FormSelect
          value={String(featureToggleValue)}
          onChange={handleValueChange}
          options={valueOptions}
          label="Value"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          onClick={onClose}
          data-testid="create-feature-toggle-override-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!validPayload}
          onClick={handleConfirm}
          loading={loading}
          data-testid="create-feature-toggle-override-modal-confirm"
        >
          Create
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
