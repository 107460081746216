import React, { useState, useEffect } from 'react';
import FeatureToggleOverridesResource, {
  FeatureToggleOverride,
} from 'remote/resources/feature-toggle-overrides';
import { FeatureToggleOverridesTable } from './feature-toggle-overrides-table';
import { useModalState } from 'utils/use-modal-state';
import {
  CreateFeatureToggleOverrideModal,
  DeleteFeatureToggleOverrideModal,
} from './feature-toggle-override-modals';
import { Alert, Button, LoadingSpinner } from 'components/foundation';
import { Action } from 'types/user';
import { usePermissions, useSession } from 'store';

interface Props {
  tenantId: string;
  className?: string;
}

export const FeatureToggleOverridesPane = ({ tenantId, className }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>(null);
  const [featureToggleOverrides, setFeatureToggleOverrides] = useState<FeatureToggleOverride[]>([]);
  const [overrideToDelete, setOverrideToDelete] = useState<FeatureToggleOverride>(null);
  const deleteModal = useModalState();
  const createModal = useModalState();
  const { deny } = usePermissions();
  const { featureToggles } = useSession();

  const createDisabled = deny(Action.PUT, 'feature-toggles/overrides');
  const deleteDisabled = deny(Action.DELETE, 'feature-toggles/overrides/*');

  useEffect(() => {
    setLoading(true);
    FeatureToggleOverridesResource.listByTenantId(tenantId)
      .then(setFeatureToggleOverrides)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [tenantId]);

  const confirmDeleteFeatureToggleOverride = async (override: FeatureToggleOverride) => {
    setOverrideToDelete(override);
    deleteModal.show();
  };

  const deleteFeatureToggleOverride = async () => {
    deleteModal.setLoading(true);
    try {
      await FeatureToggleOverridesResource.delete(overrideToDelete.OverrideId);
      setOverrideToDelete(null);
      deleteModal.close();
      setFeatureToggleOverrides(
        featureToggleOverrides.filter(fto => fto.OverrideId !== overrideToDelete.OverrideId)
      );
    } catch (err) {
      deleteModal.setError(err);
    } finally {
      deleteModal.setLoading(false);
    }
  };

  const createFeatureToggleOverride = async (featureToggle: string, value: boolean) => {
    createModal.setLoading(true);
    try {
      const override = await FeatureToggleOverridesResource.create({
        NamespaceId: tenantId,
        FeatureToggle: featureToggle,
        Value: value,
      });
      createModal.refreshKey();
      createModal.close();
      setFeatureToggleOverrides([...featureToggleOverrides, override]);
    } catch (err) {
      createModal.setError(err);
    } finally {
      createModal.setLoading(false);
    }
  };

  return (
    <div className={className}>
      <div className="tw-flex tw-mb-4 tw-justify-end">
        <Button
          onClick={createModal.show}
          disabled={createDisabled}
          data-testid="create-feature-toggle-override-button"
        >
          Create
        </Button>
      </div>
      {loading && <LoadingSpinner />}
      {error && <Alert type="danger">{String(error)}</Alert>}
      {!loading && !error && (
        <FeatureToggleOverridesTable
          data={featureToggleOverrides}
          onDelete={confirmDeleteFeatureToggleOverride}
          deleteDisabled={deleteDisabled}
        />
      )}
      <CreateFeatureToggleOverrideModal
        open={createModal.visible}
        onClose={createModal.close}
        loading={createModal.loading}
        error={createModal.error}
        onConfirm={createFeatureToggleOverride}
        key={createModal.key}
        featureToggles={featureToggles}
      />
      <DeleteFeatureToggleOverrideModal
        open={deleteModal.visible}
        onClose={deleteModal.close}
        loading={deleteModal.loading}
        error={deleteModal.error}
        onConfirm={deleteFeatureToggleOverride}
      />
    </div>
  );
};
