import React from 'react';
import { Page } from 'foundation/page';
import { AuthorizeUsersForm } from './authorize-users-form';
import { usePermissions } from 'store';
import { Action } from 'types/user';

const AuthorizeUsersPage = () => {
  const permission = usePermissions();
  const canAuthorizeUsers = permission.allow(Action.CREATE, 'authorized-users');

  return (
    <Page fullWidth>
      {canAuthorizeUsers ? (
        <>
          <h5 data-testid="authorize-users-header">Authorize Users</h5>
          <AuthorizeUsersForm />
        </>
      ) : (
        <h5 data-testid="authorize-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default AuthorizeUsersPage;
