import { SessionStore } from 'state';
import { PlanType } from 'entities/tenant';
import { currentTsAndCsVersion } from '../tsandcs/content';
import logger from 'logger';

// Helper for matching the currentPage route
// Pass a regex element for each part of the route to match, where parts
// are the tokens between forward slashes
export const match = (...partMatchers) => currentPage => {
  if (currentPage.hierarchy.length > partMatchers.length) {
    return false;
  }
  for (let i = 0; i < partMatchers.length; i++) {
    // So that we can support optional/wild card hierarchy parts
    const hierarchyPart = currentPage.hierarchy.length > i ? currentPage.hierarchy[i] : '';

    if (!hierarchyPart.match(partMatchers[i])) {
      return false;
    }
  }

  return true;
};

// This is a helper for route matching, loading data and conditional rendering
// See withDefaultPageOptions() for details on options that can be passed
export const page = (customOptions = {}) => {
  const options = withDefaultPageOptions(customOptions);

  if (!options.render) {
    throw new Error('Missing required option: render() function');
  }

  return (currentPage, sessionState) => {
    if (!options.routeMatcher(currentPage)) {
      return false;
    }
    if (options.requiresLogin && !SessionStore.state.loggedIn) {
      return false;
    }
    if (
      options.requiresTsAndCs &&
      SessionStore.state.planType === PlanType.SELF_SERVE &&
      !hasAcceptedTsAndCs()
    ) {
      return false;
    }
    if (!options.stateMatcher(sessionState)) {
      logger.debug(`${options.description} route did not satisfy its stateMatcher()`);
      return false;
    }

    const data = options.loader(currentPage);

    if (!data) {
      logger.warn(`Could not load data required by ${options.description}`);
      return false;
    }

    return options.render(data);
  };
};

function withDefaultPageOptions(customOptions) {
  // True unless explicitly set to false
  const loginRequired = customOptions.requiresLogin !== false;

  return {
    // See: match() above, defaults to matching any URL
    routeMatcher: () => true,
    requiresLogin: loginRequired,
    requiresTsAndCs: loginRequired,
    // Optional predicate for feature toggle checks or inspection of store state
    stateMatcher: () => true,
    // Optionally load any relevant data here
    // Also acts as a predicate - returning falsy value will skip this route
    loader: () => true,
    ...customOptions,
  };
}

const hasAcceptedTsAndCs = () => SessionStore.state.acceptedTsAndCs === currentTsAndCsVersion();
