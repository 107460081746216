import React, { useState } from 'react';
import { Page, Alert, FormInput, Button, Form } from 'foundation';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import Actions from 'actions';

export const LookupDatabasePage = () => {
  const permission = usePermissions();
  const canLookupDatabases = permission.allow(Action.READ, 'namespaces/*/databases/*');

  if (!canLookupDatabases) {
    return (
      <Page>
        <Alert type="danger" data-testid="lookup-database-access-denied">
          Access Denied
        </Alert>
      </Page>
    );
  }

  return (
    <Page fullWidth>
      <h5 className="tw-mb-4" data-testid="lookup-database-header">
        Lookup Instance
      </h5>
      <LookupDatabaseForm />
    </Page>
  );
};

export const LookupDatabaseForm = () => {
  const [dbid, setDbid] = useState('');

  const handleDbidChange = ({ target: { value } }) => setDbid(value);

  const handleSubmit = () => {
    Actions.navigate.push({ hash: `#admin/databases/${dbid}` });
  };

  return (
    <>
      <p className="tw-mb-2">
        This form enables you to lookup an instance by its ID, such as{' '}
        <code>&apos;db1d1234&apos;</code>.
      </p>
      <p className="tw-mb-2">Only exact Instance ID matches can be found.</p>

      <Form onSubmit={handleSubmit} className="tw-mt-8">
        <FormInput
          fluid
          value={dbid}
          onChange={handleDbidChange}
          aria-label="Instance ID"
          data-testid="lookup-instance-by-id-input"
          helpText="The exact ID of an Instance"
        />
        <Button
          onClick={handleSubmit}
          className="tw-mt-8"
          type="submit"
          color="primary"
          disabled={!dbid || dbid.length !== 8}
          data-testid="lookup-instance-by-id-button"
        >
          Submit
        </Button>
      </Form>
    </>
  );
};
