import React, { useState } from 'react';
import { Dialog, Button, Alert } from 'foundation';
import SsoConfigResource from 'remote/resources/sso-configs';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { FormActionData } from '.';

export interface DeleteSsoConfigModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(): void;
  formAction: FormActionData;
}

const DeleteSsoConfigModal = ({
  open,
  closeModal,
  saveAndClose,
  formAction: { data: ssoConfig },
}: DeleteSsoConfigModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onCancel = () => {
    closeModal();
    setError(null);
  };

  const onClickDelete = async () => {
    setLoading(true);
    setError(null);
    try {
      await SsoConfigResource.del(ssoConfig.organizationId, ssoConfig.ssoConfigId);
      setLoading(false);
      saveAndClose();
    } catch (err) {
      if (err instanceof ApiClientRequestError && err.response.status === 422) {
        setError(err.responseMessage);
      } else {
        setError(err);
      }
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      modalProps={{ 'data-testid': 'delete-sso-config-modal' }}
    >
      <Dialog.Header>Delete SSO Config</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">
          <p>
            Are you sure you want to delete the SSO Config {`"${ssoConfig.displayName}"`} with ID{' '}
            {`"${ssoConfig.ssoConfigId}"`}?
          </p>
          <p>
            This will not remove SSO from instances which were created while SSO was configured.
          </p>
        </div>
      </Dialog.Description>
      <Dialog.Content>
        {error && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="delete-sso-config-error-message"
            description={
              'An error occurred while deleting the SSO Config. Please reload the page and try again. ' +
              error.toString()
            }
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onCancel}
          color="neutral"
          fill="outlined"
          disabled={loading}
          data-testid="cancel-delete-sso-config-button"
        >
          Cancel
        </Button>
        <Button
          onClick={onClickDelete}
          loading={loading}
          color="danger"
          data-testid="delete-sso-config-button"
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DeleteSsoConfigModal;
