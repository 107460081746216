import React from 'react';

import { Alert, Button, Dialog } from 'foundation';
import { TenantUser } from 'types/user';

interface Props {
  open: boolean;
  user: TenantUser;
  deleteInProgress: boolean;
  onClose: () => void;
  onDelete: (user: TenantUser) => void;
  errorMessage?: string;
}

const DeleteUserModal = ({
  open,
  user,
  deleteInProgress,
  onClose,
  onDelete,
  errorMessage = null,
}: Props) => {
  return (
    <Dialog size="small" open={open} onClose={onClose}>
      <Dialog.Header>Delete User</Dialog.Header>
      {errorMessage && <Alert type="danger" description={errorMessage} className="tw-mb-6" />}
      <Dialog.Content>
        <div>
          Are you sure you want to remove <i>{user.Email}</i> from the project?
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" loading={deleteInProgress} onClick={() => onDelete(user)}>
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DeleteUserModal;
