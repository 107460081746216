import React from 'react';
import { Page } from 'foundation/page';
import { LookupUsersForm } from './lookup-users-form';
import { usePermissions } from 'store';
import { Action } from 'types/user';

const LookupUsersPage = () => {
  const permission = usePermissions();
  const canManageUsers = permission.allow(Action.READ, 'users');

  return (
    <Page fullWidth>
      {canManageUsers ? (
        <>
          <h5 className="tw-mb-4" data-testid="lookup-users-header">
            Lookup Users
          </h5>
          <LookupUsersForm />
        </>
      ) : (
        <h5 data-testid="lookup-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default LookupUsersPage;
