import ApiClient from 'remote/api-client';

export interface OrderParameters {
  CustomerId: string;
  ProductId: string;
  Marketplace: string;
}

interface RegisterOrderResponse {
  namespace_id: string;
}

const register = (parameters: OrderParameters): Promise<RegisterOrderResponse> => {
  return ApiClient.post('/marketplace/register')
    .issue(parameters)
    .ignoreAllErrors()
    .promise();
};

export default {
  register,
};
