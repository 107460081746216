import React, { useEffect, useState } from 'react';
import { Button, Dialog } from 'foundation';
import { Invite } from 'types/invite';
import InviteFormFields, { defaults, validate } from 'pages/admin/invite/invite-form-fields';
import Actions from 'actions';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { useNotify } from 'state/notifications';
import { Tenant } from 'entities/tenant';

interface Props {
  invite: Invite;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  tenant: Tenant;
}

export const EditInviteModal = ({ open, onClose, onSuccess, invite, tenant }: Props) => {
  const [data, setData] = useState(() => defaults(tenant, invite));
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();
  const notify = useNotify();

  const resetForm = () => {
    setData(defaults(tenant, invite));
  };

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const handleChange = newData => setData(newData);

  const handleSubmit = () => {
    const errors = validate(data, false);

    if (errors) {
      setValidation(errors);
      return;
    }

    setLoading(true);

    let roles;
    if (tenant.organizationId) {
      roles = [data.tenantRole, data.organizationRole];
    } else {
      roles = [data.tenantRole];
    }
    Actions.invites
      .updateInvite(invite.InviteId, roles)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch(e => {
        setLoading(false);
        if (e.response.status === 409) {
          notify.error('Invite has already been accepted');
        } else {
          defaultErrorHandler(e);
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>Edit {invite.InviteId} Invite</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-2">
        <InviteFormFields
          data={data}
          validation={validation}
          disabled={loading}
          edit
          tenant={tenant}
          onChange={handleChange}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={handleSubmit} data-testid="invite-update-button">
          Update
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
