import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';

import SnapshotsTable from 'components/application/snapshot-table/snapshot-table';
import { Database, Tier } from 'entities/database';

import TakeSnapshot from 'application/take-snapshot';
import { SessionState } from 'state/session-store';
import { Snapshot } from 'types/snapshot';
import CloneToNewDatabaseModal from 'application/clone-db/to-new/modal';
import { Button, LoadingSpinner, Switch, Tip } from 'foundation';
import { PermissionTip } from 'application/permission-tip';
import Icon from 'components/ui/icons';
import { CDCRestoreSnapshotTableWarning } from '../edit-cdc-enrichment-mode/cdc-warnings';
import { getHasCDCEnrichmentMode } from 'application/edit-cdc-enrichment-mode/helpers';

type SnapshotDescriptionProps = {
  tier: Tier;
  version: String;
};

const determineSnapshotInterval = ({ tier, version }: SnapshotDescriptionProps) => {
  if (tier !== Tier.ENTERPRISE) return 'day';
  if (version.startsWith('4')) return 'six hours';
  return 'hour';
};

const SnapshotDescription = ({ tier, version }: SnapshotDescriptionProps) => {
  if (tier === Tier.GDS) {
    return (
      <div className="n-body-medium tw-text-palette-neutral-text-weak">
        <p>You can take a snapshot of your instance at anytime.</p>
        <p>Snapshots can be used to restore your instance or you can export them.</p>
        <p>We take snapshots before we perform routine system maintenance.</p>
      </div>
    );
  }

  if (tier === Tier.FREE) {
    return (
      <div className="n-body-medium tw-text-palette-neutral-text-weak">
        <p>Take a snapshot of your data at any point to download.</p>
        <p>The last snapshot you take will be listed and available here.</p>
      </div>
    );
  }

  const snapshotInterval = determineSnapshotInterval({ tier, version });
  return (
    <div className="n-body-medium tw-text-palette-neutral-text-weak">
      <p>Automatic snapshots are taken every {snapshotInterval}.</p>
      <p>Snapshots are also taken before we perform routine system maintenance.</p>
    </div>
  );
};

type SnapshotSectionProps = {
  database: Database;
  snapshots?: Snapshot[];
  session: SessionState;
};

const SnapshotSection = ({ database, snapshots, session }: SnapshotSectionProps) => {
  const tracking = useTracking();
  const loading = snapshots === undefined;
  const [showCreateFromSnapshotModal, setShowCreateFromSnapshotModal] = useState(false);

  const [snapshotId, setSnapshotId] = useState<string>(null);
  const hasCDCEnrichmentMode = getHasCDCEnrichmentMode(database);

  const [filterExportableOnly, setFilterExportableOnly] = useState<boolean>(false);

  const handleSnapshotFilterChange = () => {
    setFilterExportableOnly(!filterExportableOnly);
  };

  const filteredSnapshots = !snapshots ? undefined : snapshots.filter(s => s.Exportable);

  return (
    <>
      {!loading && (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <SnapshotDescription tier={database.Tier} version={database.DesiredSettings.Version} />
          </div>
          <div style={{ paddingLeft: '1rem' }}>
            <TakeSnapshot database={database}>
              {({ onClick, disabled, loading: takeSnapshotLoading, allowTakeSnapshot }) => (
                <PermissionTip hasPermission={allowTakeSnapshot}>
                  <Button
                    data-testid="take-snapshot"
                    onClick={onClick}
                    disabled={disabled}
                    loading={takeSnapshotLoading}
                  >
                    Take snapshot
                  </Button>
                </PermissionTip>
              )}
            </TakeSnapshot>
          </div>
        </div>
      )}
      {!loading && (
        <div className="tw-mt-5 tw-flex">
          <Switch
            checked={filterExportableOnly}
            onChange={handleSnapshotFilterChange}
            label="Show exportable only"
            data-testid="snapshot-filter-switch"
            className="tw-inline-block tw-mb-4"
          />
          <Tip allowedPlacements={['right', 'bottom']}>
            <Tip.Trigger>
              <span>
                <Icon
                  name="QuestionMarkCircleIconOutline"
                  type="solid"
                  className="tw-inline-block tw-h-5 tw-w-5 tw-ml-2 tw-mb-1"
                  aria-label="Size information"
                />
              </span>
            </Tip.Trigger>
            <Tip.Content className="tw-max-w-sm" isPortaled={false}>
              <p>
                On-demand snapshots and full backups are exportable within the retention period.
              </p>
            </Tip.Content>
          </Tip>
        </div>
      )}
      <div className="tw-my-5">
        {loading ? (
          <LoadingSpinner size="large" />
        ) : (
          <>
            {hasCDCEnrichmentMode && <CDCRestoreSnapshotTableWarning />}
            <SnapshotsTable
              database={database}
              snapshots={filterExportableOnly ? filteredSnapshots : snapshots}
              session={session}
              onCreateFromBackupClick={SnapshotId => {
                setSnapshotId(SnapshotId);
                setShowCreateFromSnapshotModal(true);
                tracking.trackEvent({
                  action: 'create_from_snapshot',
                  properties: { event_label: 'clicked' },
                });
              }}
            />
          </>
        )}
      </div>
      {showCreateFromSnapshotModal && (
        <CloneToNewDatabaseModal
          open={showCreateFromSnapshotModal}
          onClose={() => setShowCreateFromSnapshotModal(false)}
          database={database}
          snapshotId={snapshotId}
          tier={database.Tier === Tier.FREE ? Tier.PROFESSIONAL : database.Tier}
        />
      )}
    </>
  );
};

export default track({ view: 'backups' })(React.memo(SnapshotSection));
