import React, { useCallback, useMemo, useState } from 'react';
import LoadError from 'components/application/load-error';
import {
  Alert,
  Button,
  ConfirmModal,
  createColumnHelper,
  StickyActionsDataGrid,
  IconButton,
  LoadingSpinner,
  useDefaultTable,
} from 'components/foundation';
import { SsoConfig } from 'types/sso-config';
import { ConfirmAction } from 'components/foundation/confirm-modal';
import { InternalTenant } from 'entities/tenant';
import Actions from 'actions';
import { LinkSsoConfigModal } from './link-sso-config-modal';
import { useTenantSsoConfigsQuery } from 'remote/resources/sso-configs';

const UnlinkSsoConfigIdModal = ({
  tenant,
  ssoConfigId,
  onClose,
  onSuccess,
}: {
  tenant: InternalTenant;
  ssoConfigId: string;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const handleUnlinkConfirm = async () => {
    setLoading(true);
    try {
      await Actions.namespaces.updateSsoConfigId(tenant.id, ssoConfigId);
      onSuccess();
    } finally {
      setLoading(false);
    }
  };
  return (
    <ConfirmModal
      title="Are you sure?"
      loading={loading}
      content={
        <p>
          Are you sure you want to unlink SSO Config Id <code>{ssoConfigId}</code> from project (
          {tenant.id})
        </p>
      }
      action={ConfirmAction.UNLINK}
      confirmText={ssoConfigId}
      negative
      open={!!ssoConfigId}
      onCancel={onClose}
      onConfirm={handleUnlinkConfirm}
    />
  );
};

const TenantSsoConfigsGuard = (
  component: React.ComponentType<{
    tenant: InternalTenant;
    ssoConfigs: SsoConfig[];
    onRefreshSsoConfigs: () => void;
  }>
) => ({ tenant }: { tenant: InternalTenant }) => {
  const ssoConfigsQuery = useTenantSsoConfigsQuery(tenant.id); // eslint-disable-line

  const handleRefreshSsoConfigs = () => ssoConfigsQuery.refetch();

  if (ssoConfigsQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  if (ssoConfigsQuery.isError) {
    return <LoadError />;
  }

  const Component = component;
  return (
    <Component
      tenant={tenant}
      ssoConfigs={ssoConfigsQuery.data}
      onRefreshSsoConfigs={handleRefreshSsoConfigs}
    />
  );
};

const ssoConfigHeaderHelper = createColumnHelper<SsoConfig>();

export const TenantSsoConfigsBase = ({
  tenant,
  ssoConfigs,
  onRefreshSsoConfigs,
}: {
  tenant: InternalTenant;
  ssoConfigs: SsoConfig[];
  onRefreshSsoConfigs: () => void;
}) => {
  const [linkOpen, setLinkOpen] = useState(false);
  const [confirmSsoConfigId, setConfirmSsoConfigId] = useState(null);

  const handleLinkSsoConfig = () => setLinkOpen(true);
  const handleLinkClose = () => setLinkOpen(false);
  const handleLinkSuccess = () => {
    onRefreshSsoConfigs();
    setLinkOpen(false);
  };

  const handleUnlinkSuccess = () => {
    onRefreshSsoConfigs();
    setConfirmSsoConfigId(null);
  };

  // Memoized as it is a dependency for the table columns
  const handleUnlink = useCallback(
    ssoConfigId => {
      setConfirmSsoConfigId(ssoConfigId);
    },
    [setConfirmSsoConfigId]
  );

  const columns = useMemo(
    () => [
      ssoConfigHeaderHelper.accessor('ssoConfigId', {
        header: 'ID',
        minSize: 100,
      }),
      ssoConfigHeaderHelper.accessor('idpType', {
        header: 'IDP',
        minSize: 100,
      }),
      ssoConfigHeaderHelper.accessor('clientID', {
        header: 'Client ID',
        minSize: 100,
      }),
      ssoConfigHeaderHelper.accessor('options' as any, {
        header: null,
        minSize: 100,
        meta: {
          isStickyAction: true,
        },
        cell: c => {
          const ssoConfig = c.row.original;

          return (
            <span className="tw-flex tw-flex-grow">
              <IconButton
                iconName="TrashIconOutline"
                data-testid={`sso-config-unlink-${ssoConfig.ssoConfigId}`}
                onClick={() => handleUnlink(ssoConfig.ssoConfigId)}
                aria-label="Unlink SSO Config"
                title="Unlink SSO Config"
                danger
                clean
              />
              <IconButton
                iconName="PencilSquareIconOutline"
                data-testid={`sso-config-edit-${ssoConfig.ssoConfigId}`}
                href={`#admin/sso-configs/${ssoConfig.ssoConfigId}`}
                aria-label="Edit SSO Config"
                title="Edit SSO Config"
                danger
                clean
              />
            </span>
          );
        },
      }),
    ],
    [handleUnlink]
  );

  const table = useDefaultTable({
    columns: columns,
    data: ssoConfigs,
  });

  return (
    <div className="tw-mt-8">
      <div className="tw-flex tw-justify-between tw-mb-2 tw-mt-4">
        <div className="tw-pr-4">
          <Alert
            type="info"
            title="Only applies to newly created instances"
            description="SSO configs linked to this project only affect instances in the project that are created after the sso config is linked. Linking does NOT add the config to already created instances. Once the sso config is applied to the instance it can only be updated via the SRE Portal."
          />
        </div>
        <Button
          onClick={handleLinkSsoConfig}
          iconName="PlusIconOutline"
          fill="outlined"
          data-testid="link-sso-button"
        >
          Link
        </Button>
      </div>
      <StickyActionsDataGrid tableInstance={table} />
      <LinkSsoConfigModal
        tenant={tenant}
        open={linkOpen}
        onClose={handleLinkClose}
        onSuccess={handleLinkSuccess}
      />
      <UnlinkSsoConfigIdModal
        ssoConfigId={confirmSsoConfigId}
        tenant={tenant}
        onClose={() => setConfirmSsoConfigId(null)}
        onSuccess={handleUnlinkSuccess}
      />
    </div>
  );
};

export const TenantSsoConfigs = TenantSsoConfigsGuard(TenantSsoConfigsBase);
