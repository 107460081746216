import { useState } from 'react';

interface ModalState {
  visible: boolean;
  loading: boolean;
  error: Error | null;
}

interface ModalStateHook extends ModalState {
  show: () => void;
  close: () => void;
  setVisible: (visible: boolean) => void;
  setError: (err: Error) => void;
  setLoading: (loading: boolean) => void;

  /**
   * When `key={modalState.key}` is passed to the modal,
   * `refreshKey()` can be used to reset the modal's internal state
   * @see https://react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
   */
  key: string;
  refreshKey: () => void;
  closeAndRefreshKey: () => void;
}

const randomString = () => Math.random().toString(36);

export const useModalState = (initialState?: Partial<ModalState>): ModalStateHook => {
  const [visible, setVisible] = useState(initialState?.visible ?? false);
  const [loading, setLoading] = useState(initialState?.loading ?? false);
  const [error, setError] = useState(initialState?.error ?? null);
  const [key, setKey] = useState(randomString());

  const show = () => setVisible(true);
  const close = () => setVisible(false);
  const refreshKey = () => setKey(randomString());
  const closeAndRefreshKey = () => {
    close();
    refreshKey();
  };

  return {
    visible,
    loading,
    error,
    show,
    close,
    setVisible,
    setLoading,
    setError,
    key,
    refreshKey,
    closeAndRefreshKey,
  };
};
