import { CloudProvider } from 'entities/database';
import { EncryptionKey } from 'types/encryption-keys';
import { TenantType } from 'entities/tenant';
import { SessionState } from 'state/session-store';
import { covers } from 'store/hooks/permissions';
import { Action } from 'types/user';

export const getEncryptionKeyId = (key: EncryptionKey) => {
  const csp = key.cloudProvider;

  if (csp === CloudProvider.GCP) {
    return key.gcpProperties.keyId;
  } else if (csp === CloudProvider.AWS) {
    return key.awsProperties.kmsKeyArn;
  } else if (csp === CloudProvider.AZURE) {
    return key.azureProperties.keyId;
  }
};

export const isCmekEnabled = (session: SessionState): boolean => {
  const { permissions, tenant } = session;

  // There's no guarantee that tenant is successfully loaded
  if (!tenant) return false;

  return (
    tenant.capabilities.cmek &&
    tenant.tenantType === TenantType.ENTERPRISE &&
    // We check for Action.CREATE since members have READ access
    covers(permissions, Action.CREATE, `namespaces/${tenant.id}/encryption-keys`)
  );
};
