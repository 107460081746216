import globals from './globals';
import logger from 'logger';

let listener: ((this: WindowEventHandlers, ev: HashChangeEvent) => any) &
  ((this: Window, ev: HashChangeEvent) => any);

const location = {
  watchHashChanges: (callback: Function) => {
    if (listener) {
      throw new Error('Already listening for hashchange events!');
    }

    logger.debug('Watching for hash changes...');

    listener = () => {
      logger.debug('onhashchange event...');
      callback(globals.window.location.hash);
    };

    globals.window.onhashchange = listener;
  },
  stopWatching: () => {
    if (listener) {
      logger.debug('Stop watching hash changes...');
      globals.window.removeEventListener('hashchange', listener);
      listener = null;
    }
  },
  replace: (url: URL) => {
    globals.window.location.replace(url);
  },
  reload: () => {
    globals.window.location.reload();
  },
  assign: url => {
    globals.window.location.assign(url);
  },
  get hash() {
    return globals.window.location.hash;
  },
  set hash(newValue) {
    globals.window.location.hash = newValue;
  },
  get host() {
    return globals.window.location.host;
  },
  get hostname() {
    return globals.window.location.hostname;
  },
  get href() {
    return globals.window.location.href;
  },
  get origin() {
    return globals.window.location.origin;
  },
  get search() {
    return globals.window.location.search;
  },
  get pathname() {
    return globals.window.location.pathname;
  },
  set hostname(name: string) {
    globals.window.location.hostname = name;
  },
};

export default location;
