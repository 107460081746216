import React, { useMemo } from 'react';

import { IconButton, StickyActionsDataGrid, useDefaultTable, createColumnHelper } from 'foundation';
import { Invite } from 'types/invite';
import { Action, TenantUser, isTenantRole, tenantRoleToFriendlyName } from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import { usePermissions } from 'store';

enum NamespaceUserStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending invite',
}

interface DataRowActions {
  delete: React.ReactElement;
  edit?: React.ReactElement;
}

interface DataRow {
  email: String;
  role: String;
  status: NamespaceUserStatus;
  actions: DataRowActions;
}

interface UsersTableProps {
  invitesList: Invite[];
  usersList: TenantUser[];
  onEditUserClick: (member: TenantUser) => void;
  onDeleteUserClick: (member: TenantUser) => void;
  onRevokeInviteClick: (invite: Invite) => void;
}

const headerHelper = createColumnHelper<DataRow>();

const EditIcon = ({ allowEdit, onClick }) => (
  <PermissionTip hasPermission={allowEdit}>
    <IconButton
      title="Edit User"
      aria-label="Edit User"
      iconName="PencilIconOutline"
      disabled={!allowEdit}
      onClick={onClick}
      clean
    />
  </PermissionTip>
);

const DeleteIcon = ({ allowDelete, onClick }) => (
  <PermissionTip hasPermission={allowDelete}>
    <IconButton
      title="Delete User"
      aria-label="Delete User"
      iconName="TrashIconOutline"
      clean
      danger
      disabled={!allowDelete}
      onClick={onClick}
    />
  </PermissionTip>
);

const UsersTable = ({
  invitesList,
  usersList,
  onEditUserClick,
  onDeleteUserClick,
  onRevokeInviteClick,
}: UsersTableProps) => {
  const { allow } = usePermissions();
  const data = useMemo(() => {
    const rows: DataRow[] = [];

    rows.push(
      ...usersList.map(
        (user: TenantUser): DataRow => ({
          email: user.Email,
          role: tenantRoleToFriendlyName(user.Roles.find(role => isTenantRole(role.Name)).Name),
          status: NamespaceUserStatus.ACTIVE,
          actions: {
            edit: (
              <EditIcon
                allowEdit={allow(
                  Action.UPDATE,
                  `namespaces/${user.NamespaceId}/members/${user.UserId}`
                )}
                onClick={() => onEditUserClick(user)}
              />
            ),
            delete: (
              <DeleteIcon
                allowDelete={allow(
                  Action.DELETE,
                  `namespaces/${user.NamespaceId}/members/${user.UserId}`
                )}
                onClick={() => onDeleteUserClick(user)}
              />
            ),
          },
        })
      )
    );

    rows.push(
      ...invitesList.map(
        (invite: Invite): DataRow => ({
          email: invite.Email,
          role: tenantRoleToFriendlyName(invite.Roles.find(role => isTenantRole(role))),
          status: NamespaceUserStatus.PENDING,
          actions: {
            delete: (
              <DeleteIcon
                allowDelete={allow(
                  Action.UPDATE,
                  `namespaces/${invite.NamespaceId}/invites/${invite.InviteId}`
                )}
                onClick={() => onRevokeInviteClick(invite)}
              />
            ),
          },
        })
      )
    );

    return rows;
  }, [invitesList, usersList]);

  const renderPendingCell = cell =>
    cell.row.original.status === NamespaceUserStatus.PENDING ? (
      <span className="pending">{cell.getValue()}</span>
    ) : (
      cell.getValue()
    );

  const columns = useMemo(
    () => [
      headerHelper.accessor('email', {
        header: 'Email',
        cell: c => renderPendingCell(c),
        size: 450,
      }),
      headerHelper.accessor('role', {
        header: 'Role',
        cell: c => renderPendingCell(c),
      }),
      headerHelper.accessor('status', {
        header: 'Status',
      }),
      headerHelper.accessor('actions', {
        header: null,
        enableSorting: false,
        cell: c => (
          <span className="tw-flex tw-gap-1 tw-justify-end tw-flex-grow">
            {c.getValue().edit}
            {c.getValue().delete}
          </span>
        ),
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    []
  );

  const table = useDefaultTable({ columns, data, state: {} });
  return <StickyActionsDataGrid tableInstance={table} />;
};

export default UsersTable;
