import RemoteSnapshots from 'remote/resources/snapshots';
import { SnapshotsStore } from 'state';
import logger from 'logger';

const takeSnapshot = async (dbId: string) => {
  try {
    await RemoteSnapshots.create(dbId);
    const snapshots = await RemoteSnapshots.list(dbId);
    SnapshotsStore.refresh(dbId, snapshots);
  } catch (error) {
    logger.error('Failed to create snapshot due to error:', error);
  }
};

export default takeSnapshot;
