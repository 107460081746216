import React from 'react';
import { CloudProvider, Database, Tier } from 'entities/database';
import { Tenant } from 'entities/tenant';
import logger from 'logger';
import { useNotify } from 'state/notifications';
import { TrafficConfig } from 'types/traffic-configs';
import { GCPTrafficConfigDialog } from './gcp-dialog';
import { AWSTrafficConfigDialog } from './aws-dialog';
import { AzureTrafficConfigDialog } from './azure-dialog';

export const TrafficConfigDialog = ({
  tenant,
  trafficConfig,
  title,
  onClose,
  onSuccess,
  databases,
  // Used to prepopulate the props (e.g. ProjectIds)
  // when creating
  existingTrafficConfigs = [],
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {} as any,
}: {
  tenant: Tenant;
  trafficConfig?: TrafficConfig;
  title: string;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<Tier, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  databases: Database[];
}) => {
  const notify = useNotify();
  const cloudProviders = Object.keys(tenant.providerConfigs);
  if (cloudProviders.length > 1) {
    logger.error('Project has multiple cloud providers');
    notify.error(
      'Unable to configure network security for this project. Project is not isolated to a single cloud provider'
    );
    return;
  }

  const cloudProvider = cloudProviders[0];

  if (cloudProvider === CloudProvider.GCP) {
    return (
      <GCPTrafficConfigDialog
        tenant={tenant}
        trafficConfig={trafficConfig}
        title={title}
        onClose={onClose}
        onSuccess={onSuccess}
        existingTierRegions={existingTierRegions}
        existingTrafficConfigs={existingTrafficConfigs}
        databases={databases}
      />
    );
  } else if (cloudProvider === CloudProvider.AWS) {
    return (
      <AWSTrafficConfigDialog
        tenant={tenant}
        trafficConfig={trafficConfig}
        title={title}
        onClose={onClose}
        onSuccess={onSuccess}
        existingTierRegions={existingTierRegions}
        existingTrafficConfigs={existingTrafficConfigs}
        databases={databases}
      />
    );
  } else if (cloudProvider === CloudProvider.AZURE) {
    return (
      <AzureTrafficConfigDialog
        tenant={tenant}
        trafficConfig={trafficConfig}
        title={title}
        onClose={onClose}
        onSuccess={onSuccess}
        existingTierRegions={existingTierRegions}
        existingTrafficConfigs={existingTrafficConfigs}
        databases={databases}
      />
    );
  } else {
    logger.error(`Not implemented CSP: ${cloudProvider}`);
    notify.error(`Unable to render dialog for cloud provider ${cloudProvider}`);
  }
};
