import React, { useMemo, useState } from 'react';
import Button from 'foundation/button';
import { Database } from 'entities/database';
import { EditCDCEnrichmentModeDatabaseFormData } from 'application/edit-cdc-enrichment-mode/form-data';
import Actions from 'actions';
import { Dialog } from 'foundation/dialog';
import { Form } from 'foundation/form-element';
import { EditCDCEnrichmentModeDatabaseForm } from './edit-cdc-enrichment-mode-form';
import logger from 'logger';
import { useNotify } from 'state/notifications';

type Props = {
  open: boolean;
  onClose: () => void;
  database: Database;
};

const ChangeCDCEnrichmentModeDatabaseModal = ({ open, onClose, database }: Props) => {
  const notify = useNotify();
  const initialValue = database.DesiredSettings.CDCEnrichmentMode;
  const [data, setData] = useState<EditCDCEnrichmentModeDatabaseFormData>(() => ({
    cdcEnrichmentMode: initialValue,
  }));

  const [updateRequestInProgress, setUpdateRequestInProgress] = useState(false);

  const handleChange = (newData: EditCDCEnrichmentModeDatabaseFormData) => {
    setData({
      ...newData,
    });
  };

  const updateDatabase = async () => {
    setUpdateRequestInProgress(true);

    try {
      await Actions.databases.updateDatabase(database.DbId, {
        CDCEnrichmentMode: data.cdcEnrichmentMode,
      });
      setUpdateRequestInProgress(false);
      onClose();
    } catch (e) {
      setUpdateRequestInProgress(false);
      logger.error(e);
      notify.error('Failed to change CDC mode');
    }
  };

  const hasChangedValue = useMemo(() => data.cdcEnrichmentMode !== initialValue, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{
        'data-testid': 'change-cdc-enrichment-mode-database-modal',
      }}
    >
      <Dialog.Header>Edit CDC Mode</Dialog.Header>
      <Dialog.Content>
        <Form onSubmit={updateDatabase} data-testid="change-cdc-enrichment-mode-db-form">
          <EditCDCEnrichmentModeDatabaseForm
            data={data}
            onChange={handleChange}
            initialValue={initialValue}
            database={database}
          />
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onClose}
          data-testid="clone-cancel-button"
          fill="outlined"
          color="neutral"
          disabled={updateRequestInProgress}
        >
          Cancel
        </Button>
        <Button
          disabled={!hasChangedValue}
          onClick={updateDatabase}
          loading={updateRequestInProgress}
          data-testid="update-cdc-enrichment-mode"
        >
          Update
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ChangeCDCEnrichmentModeDatabaseModal;
