import { DatabaseStatus, Database } from 'entities/database';

import tracking from 'actions/user-tracking-actions';
import { compareDatabaseStates } from './helpers';

export const triggerTrackingEvents = (prevDatabases: Database[], newDatabases: Database[]) => {
  const comparisons = compareDatabaseStates(prevDatabases, newDatabases);
  for (const [prevState, newState] of comparisons) {
    if (
      prevState?.DatabaseStatus === DatabaseStatus.CREATING &&
      newState?.DatabaseStatus === DatabaseStatus.RUNNING
    ) {
      // Appcues Note: This event is important for Appcues Flow to trigger
      tracking.trackEvent('DB_RUNNING', {
        DbId: newState.DbId,
        tier: newState.Tier,
        DbSize: newState.DesiredSettings.Memory,
      });
    }
  }
};
