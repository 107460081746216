import React, { useEffect, useState } from 'react';
import { PaymentSection } from './payments';
import { usePermissions, useSession } from 'store';
import { BillingMethod, TenantType } from 'entities/tenant';
import actions from 'actions';
import { parse } from 'query-string';
import { Alert, Tabs } from 'foundation';
import InvoicesSection from './invoices';
import UsageSection from './usage';
import track from 'react-tracking';
import { Action } from 'types/user';

enum TabId {
  INVOICES = 'invoices',
  CURRENT_MONTH_USAGE = 'usage',
  PAYMENT_SETTINGS = 'payment',
}

const TabIdValues: string[] = Object.values(TabId);

// TODO: We dont have unit tests for this part, create new PR to add unit tests for this part
const getTab = (search: string, showTabs: boolean, visibleTabs: string[]): TabId => {
  const tab = parse(search)?.tab as string;

  if (showTabs && tab && visibleTabs.includes(tab)) {
    return tab as TabId;
  }

  return visibleTabs[0] as TabId;
};

const getVisibleTabs = (
  tenantType: TenantType,
  hasBilling: boolean,
  hasPrepaidBilling: boolean,
  hasConsumptionReportingEnabled: boolean
) => {
  if (tenantType === TenantType.PERSONAL && hasBilling && !hasPrepaidBilling) {
    return TabIdValues;
  }

  if (tenantType === TenantType.PERSONAL && hasPrepaidBilling) {
    return [TabId.PAYMENT_SETTINGS];
  }

  if (hasConsumptionReportingEnabled) {
    return [TabId.CURRENT_MONTH_USAGE];
  }

  return [TabId.PAYMENT_SETTINGS];
};

const BillingPage = () => {
  const session = useSession();
  const { tenantType, hasBilling, billingMethod, capabilities } = session.tenant;
  const hasPrepaidBilling = billingMethod === BillingMethod.PREPAID;
  const hasConsumptionReportingEnabled = capabilities?.consumption_reporting;
  const visibleTabs = getVisibleTabs(
    tenantType,
    hasBilling,
    hasPrepaidBilling,
    hasConsumptionReportingEnabled
  );
  const hasVisibleTabs = visibleTabs.length > 0;
  const [selectedTab, setSelectedTab] = useState(
    getTab(location.search, hasVisibleTabs, visibleTabs)
  );

  const handleTabChange = (tab: TabId) => {
    actions.navigate.push({ search: { tab } });
  };

  useEffect(() => {
    const showTabs =
      visibleTabs.length === 0
        ? false
        : visibleTabs.length > 1 || visibleTabs[0] !== TabId.PAYMENT_SETTINGS;
    const tab = getTab(location.search, showTabs, visibleTabs);
    setSelectedTab(tab);
  }, [location.search]);

  return (
    <div className="tw-ml-4 tw-pb-4">
      <h4 className="tw-mb-6">Billing</h4>
      {hasVisibleTabs && (
        <Tabs<TabId>
          className="tw-w-max"
          value={selectedTab}
          onChange={handleTabChange}
          data-testid="billing-tabs"
        >
          {visibleTabs.includes(TabId.CURRENT_MONTH_USAGE) && (
            <Tabs.Tab tabId={TabId.CURRENT_MONTH_USAGE} data-testid="usage-tab">
              {hasConsumptionReportingEnabled ? 'Usage' : 'Current month usage'}
            </Tabs.Tab>
          )}
          {visibleTabs.includes(TabId.INVOICES) && (
            <Tabs.Tab className="tw-capitalize" tabId={TabId.INVOICES} data-testid="invoices-tab">
              Invoices
            </Tabs.Tab>
          )}
          {visibleTabs.includes(TabId.PAYMENT_SETTINGS) && (
            <Tabs.Tab tabId={TabId.PAYMENT_SETTINGS} data-testid="account-tab">
              Payment settings
            </Tabs.Tab>
          )}
        </Tabs>
      )}
      <div
        style={{
          minWidth: 'var(--breakpoints-xs)',
        }}
      >
        {visibleTabs.includes(TabId.PAYMENT_SETTINGS) && (
          <Tabs.TabPanel
            tabId={TabId.PAYMENT_SETTINGS}
            value={selectedTab}
            data-testid="account-details-segment"
          >
            <PaymentSection />
          </Tabs.TabPanel>
        )}
        {hasVisibleTabs && (
          <>
            {visibleTabs.includes(TabId.CURRENT_MONTH_USAGE) && (
              <Tabs.TabPanel
                tabId={TabId.CURRENT_MONTH_USAGE}
                value={selectedTab}
                data-testid="billing-usage-segment"
              >
                <UsageSection />
              </Tabs.TabPanel>
            )}
            {visibleTabs.includes(TabId.INVOICES) && (
              <Tabs.TabPanel
                tabId={TabId.INVOICES}
                value={selectedTab}
                data-testid="billing-invoices-segment"
              >
                <InvoicesSection />
              </Tabs.TabPanel>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const BillingPageGuard = () => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowViewBilling = allow(Action.READ, `tenants/${session.currentTenant}/billing`);

  if (!allowViewBilling) {
    return (
      <Alert type="danger" data-testid="billing-page-permission-denied" className="tw-ml-4 tw-pb-4">
        Administrator permissions are required to access this page.
      </Alert>
    );
  }

  return <BillingPage />;
};

export default track()(BillingPageGuard);
