import React from 'react';
import { DatabaseSizePicker } from 'application/db-sizes';
import { ConfigureDatabaseFormData } from './form-data';
import { Database, DatabaseSize, gibibytesStringToInt, Tier } from 'entities/database';
import { PlanType } from 'entities/tenant';
import { calcMonthlyCost } from 'application/db-sizes/capacities';
import formatDollars from 'utils/format-dollars';
import { Alert, Switch, Tip, Typography } from 'foundation';
import { useSession } from 'store';

interface ResizeDatabaseFormProps {
  data: ConfigureDatabaseFormData;
  onChange: (data: ConfigureDatabaseFormData) => any;
  sizes: DatabaseSize[];
  cloudProvider: string;
  initialSize: DatabaseSize;
  resizeThreshold?: number;
  database: Database;
}

export const validate = (
  data: ConfigureDatabaseFormData,
  initialVectorOptimizedState: boolean,
  initialSize?: DatabaseSize
) => {
  const sizeChanged = data.size.memory !== initialSize?.memory;
  const vectorOptimizedChanged = data.vectorOptimized !== initialVectorOptimizedState;

  if (!data.size) {
    return false;
  }
  if (vectorOptimizedChanged && !sizeChanged) {
    return true;
  }
  if (sizeChanged) {
    return data.confirmed;
  }
  return false;
};

export const ResizeDatabaseForm = ({
  onChange,
  data,
  cloudProvider,
  sizes,
  initialSize,
  resizeThreshold,
  database,
}: ResizeDatabaseFormProps) => {
  const session = useSession();
  const handleSizeChange = (size: DatabaseSize) => onChange({ ...data, size });

  const showUnitPricing = session.planType === PlanType.SELF_SERVE;
  const showMonthlyCost =
    showUnitPricing && [Tier.PROFESSIONAL, Tier.ENTERPRISE].includes(database.Tier);

  return (
    <DatabaseSizePicker
      onChange={handleSizeChange}
      value={data.size}
      hidePricing={!showUnitPricing}
      options={sizes}
      cloudProvider={cloudProvider}
      selectedRegion={database.Region}
      hideMonthlyCost={!showMonthlyCost}
      selectedVersion={database.DesiredSettings.Version}
      initialSize={initialSize}
      resizeThreshold={resizeThreshold}
    />
  );
};

interface PriceDescriptionProps {
  planType: PlanType;
  tier: Tier;
  size: DatabaseSize;
}

export const PriceDescription = ({ planType, tier, size }: PriceDescriptionProps) => {
  if (planType === PlanType.ENTERPRISE) {
    return (
      <Alert
        data-testid="custom-pricing"
        description="Please refer to your contract for pricing."
        type="info"
      />
    );
  }

  if (tier === Tier.GDS) {
    const costPerHour = formatDollars(size.cost_per_hour);

    return (
      <Alert
        data-testid="aurads-pricing"
        description={`You will be charged ${costPerHour} per hour while an instance of this size is running.`}
        type="warning"
      />
    );
  }

  if ([Tier.PROFESSIONAL, Tier.MTE].includes(tier)) {
    const costPerHour = formatDollars(size.cost_per_hour);
    const costPerMonth = formatDollars(calcMonthlyCost(size.cost_per_hour));

    return (
      <Alert data-testid="auradb-pricing" type="warning">
        You will be charged {costPerHour} per hour while an instance of this size is running, and
        continuously running this database for 1 month will cost {costPerMonth} based on a 730 hour
        average month.
      </Alert>
    );
  }

  throw new Error(`No price description is defined for tier ${tier}`);
};

type VectorOptimizedUpdateProps = {
  data: ConfigureDatabaseFormData;
  onChange: (data: ConfigureDatabaseFormData) => void;
  database: Database;
};

export const VectorOptimizedForm = ({ data, onChange, database }: VectorOptimizedUpdateProps) => {
  const handleVectorOptimizeChange = (value: boolean) => {
    onChange({ ...data, vectorOptimized: value });
  };

  if (!database.AvailableActions.vector_optimized.enabled) {
    return (
      <p>Vector optimization unavailable: {database.AvailableActions.vector_optimized.message}</p>
    );
  }

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-2">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Typography variant="subheading-medium">Vector-optimized configuration</Typography>
        <Typography variant="body-medium" as="div" className="tw-text-palette-neutral-text-weak">
          Reserve memory for vector indexes to ensure optimal performance. Selecting this can have
          an impact on graph operation performance
        </Typography>
      </div>
      <div>
        <Tip
          isDisabled={
            (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
            database.DesiredSettings.Version === '5'
          }
        >
          <Tip.Trigger>
            <Switch
              aria-label="Toggle on Allocate vector index memory"
              checked={data.vectorOptimized}
              onChange={e => handleVectorOptimizeChange(e.target.checked)}
              disabled={
                (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                database.DesiredSettings.Version !== '5'
              }
            />
          </Tip.Trigger>
          <Tip.Content isPortaled={false}>
            4GB memory is required for the vector index configuration
          </Tip.Content>
        </Tip>
      </div>
    </div>
  );
};
