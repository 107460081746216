export enum CDCEnrichmentMode {
  OFF = 'OFF',
  DIFF = 'DIFF',
  FULL = 'FULL',
}

export interface EditCDCEnrichmentModeDatabaseFormData {
  cdcEnrichmentMode: CDCEnrichmentMode;
}

export interface EditCDCEnrichmentModeDatabaseFormDataCount {
  cdcEnrichmentMode: CDCEnrichmentMode;
}
