import BaseStore from '../base-store';
import { deepEquals } from '../utils';
import { Notification, NotificationState } from './types';
import { omit } from 'lodash';

const randomId = () =>
  Math.random()
    .toString(36)
    .slice(2);

class NotificationsStore extends BaseStore<NotificationState> {
  constructor() {
    super('NotificationsStore');
  }

  initialState() {
    return {
      notifications: [],
    };
  }

  add(notification: Omit<Notification, 'id'>) {
    if (!isUnique(this.state.notifications, notification)) {
      return;
    }

    const notificationWithId: Notification = { id: randomId(), ...notification };

    this._updateState({
      ...this.state,
      notifications: [...this.state.notifications, notificationWithId],
    });
  }

  remove(id: string) {
    const indextoRemove = this.state.notifications.findIndex(
      notification => notification.id === id
    );

    if (indextoRemove === -1) {
      throw new Error(`Cannot remove notification - id ${id} does not exist`);
    }
    const notifications = [
      ...this.state.notifications.slice(0, indextoRemove),
      ...this.state.notifications.slice(indextoRemove + 1),
    ];

    this._updateState({
      ...this.state,
      notifications,
    });
  }

  has(id: string) {
    return this.state.notifications.some(notification => notification.id === id);
  }

  _stateHasChanged(oldState, newState) {
    if (super._stateHasChanged(oldState.notifications, newState.notifications)) {
      return true;
    }

    return false;
  }
}

const isUnique = (array: Notification[], newElement: Omit<Notification, 'id'>) =>
  !array.find(notification =>
    deepEquals(
      omit(newElement, 'onClose', 'actions'),
      omit(notification, ['id', 'onClose', 'actions'])
    )
  );

export const InstantiatedNotificationStore = new NotificationsStore();

export default NotificationsStore;
