import React, { SyntheticEvent, useState } from 'react';
import { Database, DatabaseStatus } from 'entities/database';
import { RenameDatabaseModal } from './modal';

interface Props {
  database: Database;
}

interface ModalProps {
  open: boolean;
  database: Database;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const isRenameEnabledDatabaseStatusMap: Record<DatabaseStatus, boolean> = {
  [DatabaseStatus.CREATING]: true,
  [DatabaseStatus.RUNNING]: true,
  [DatabaseStatus.PAUSING]: true,
  [DatabaseStatus.SUSPENDING]: true,
  [DatabaseStatus.PAUSED]: true,
  [DatabaseStatus.SUSPENDED]: true,
  [DatabaseStatus.RESUMING]: true,
  [DatabaseStatus.LOADING_DATA]: true,
  [DatabaseStatus.LOADING_FAILED]: true,
  [DatabaseStatus.UPDATING]: true,
  [DatabaseStatus.DESTROYING]: false,
  [DatabaseStatus.DESTROYED]: false,
  [DatabaseStatus.RESTORING]: true,
  [DatabaseStatus.OVERWRITING]: true,
  [DatabaseStatus.ENCRYPTION_KEY_DELETED]: true,
  [DatabaseStatus.ENCRYPTION_KEY_ERROR]: true,
  [DatabaseStatus.ENCRYPTION_KEY_NOT_FOUND]: true,
};

const RenameModal = ({ open, database, _onClose, onClose }: ModalProps) => (
  <RenameDatabaseModal
    open={open}
    database={database}
    onSuccess={_onClose(onClose)}
    onClose={_onClose(onClose)}
    onFailure={() => null}
  />
);

export const useRenameModal = ({ database }: Props) => {
  const [open, setOpen] = useState(false);

  const handleRenameClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const handleClose = onClose => () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const disabled = !isRenameEnabledDatabaseStatusMap[database.DatabaseStatus];

  const modalProps = {
    open,
    database,
    _onClose: handleClose,
  };

  return { onClick: handleRenameClick, disabled, Modal: RenameModal, modalProps };
};
