import React from 'react';
import cx from 'classnames';
import './code-block.css';
import { CopyTextToClipBoardButton } from '../copy-text';

export { CodeBlock } from '@neo4j-ndl/react';

interface SingleLineCodeBlockProps extends React.ComponentProps<'div'> {
  code: string;
  copyToClipboard?: boolean;
}

export const SingleLineCodeBlock = ({
  code,
  className,
  copyToClipboard,
  ...props
}: SingleLineCodeBlockProps) => {
  return (
    <div className={cx(className, 'single-line-codeblock')} {...props}>
      <code>{code}</code>
      {copyToClipboard && (
        <CopyTextToClipBoardButton
          text={code}
          className="single-line-codeblock-action"
          iconButtonProps={{ size: 'small', title: 'Copy to clipboard' }}
        />
      )}
    </div>
  );
};
