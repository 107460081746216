import React, { useEffect } from 'react';
import { Breadcrumbs, Page, SideNavigation } from 'foundation';
import PreferencePage from './preferences';
import { useLocation, useSession } from 'store';
import { constructBreadcrumbs, getHomePath } from 'components/utils';
import track from 'react-tracking';
import actions from 'actions';
import './settings.css';
import BillingPage from './billing';
import { PlanType } from 'entities/tenant';
import { SessionState } from 'state/session-store';

const pageToRender = hash => {
  switch (hash) {
    case '#settings':
    case '#settings/preferences':
      return PreferencePage;
    case '#settings/billing':
      return BillingPage;
    default:
      return PreferencePage;
  }
};

const pageAvailableMap: Record<PlanType, string[]> = {
  [PlanType.ENTERPRISE]: ['#settings', '#settings/preferences'],
  [PlanType.SELF_SERVE]: ['#settings', '#settings/preferences', '#settings/billing'],
};

const GlobalSettingsPage = () => {
  const session = useSession();
  const location = useLocation();
  const homePath = getHomePath(session);

  const breadcrumbs = constructBreadcrumbs(location, homePath);

  const Component = pageToRender(location.hash);
  return (
    <Page plain fullWidth style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div className="tw-flex tw-h-full tw--pl-16 lg:tw-pl-0">
        <SettingsSideNavigation session={session} />
        <div className="tw-w-full tw-relative" style={{ overflowX: 'clip' }}>
          <Breadcrumbs items={breadcrumbs} className="tw-absolute tw--top-7 tw-left-4" />
          <Component />
        </div>
      </div>
    </Page>
  );
};

interface SettingsSideNavigationProps {
  session: SessionState;
}

const SettingsSideNavigation = ({ session }: SettingsSideNavigationProps) => {
  const location = useLocation();
  const { capabilities } = session.tenant;

  // Feature toggle for consumption reporting, if not enabled, hide the billing tab
  if (capabilities.consumption_reporting) {
    pageAvailableMap[session.planType].push('#settings/billing');
  }
  const shouldShowBilling = pageAvailableMap[session.planType].includes('#settings/billing');

  return (
    <div className="settings-sidenav ndl-side-navigation ndl-left ndl-expanded">
      <nav className="ndl-side-navigation-nav" data-testid="settings-sidenav">
        <SideNavigation.List data-testid="settings-sidenav-list">
          <SideNavigation.Item
            selected={['#settings', '#settings/preferences'].includes(location.hash)}
            href="#settings/preferences"
            data-testid="settings-side-nav-preferences"
          >
            Preferences
          </SideNavigation.Item>
          {shouldShowBilling && (
            <SideNavigation.Item
              selected={['#settings/billing'].includes(location.hash)}
              href="#settings/billing"
              data-testid="settings-side-nav-billing"
            >
              Billing
            </SideNavigation.Item>
          )}
        </SideNavigation.List>
      </nav>
    </div>
  );
};

const QueryParamsGuard = () => {
  const location = useLocation();
  const session = useSession();
  useEffect(() => {
    if (
      location.hash === '#settings' ||
      !pageAvailableMap[session.planType].includes(location.hash)
    ) {
      actions.navigate.replace('#settings/preferences');
    }
  }, [location.hash]);

  return <GlobalSettingsPage />;
};

export default track()(QueryParamsGuard);
