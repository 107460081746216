import logger from 'logger';

/**
 * Report an error that the system didn't know how to resolve on its own.
 * @param message A string message describing the problem
 * @param context Optional object with context data, anything that can be JSON-serialized.
 * @param stack Optional stack trace; if not provided one is calculated for the current stack frame
 */
const report = (message: string, context, stack) => {
  logger.error(message, context || {}, stack || stackTrace());
};

const stackTrace = () => {
  try {
    return new Error().stack;
  } catch (e) {
    // In case the browser does not support .stack
    return null;
  }
};

export default { report };
