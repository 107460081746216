import { Tier } from 'entities/database';
import ApiClient from 'remote/api-client';

export enum DestinationType {
  STACKDRIVER = 'stackdriver',
  LOG_ANALYTICS = 'log_analytics',
  CLOUDWATCH = 'cloudwatch',
}

export enum LogType {
  SECURITY = 'security',
}

export interface StackdriverDestination {
  type: DestinationType.STACKDRIVER;
  gcpProjectId: string;
}

export interface LogAnalyticsDestination {
  type: DestinationType.LOG_ANALYTICS;
  customerId: string;
  sharedKey: string;
}

export interface CloudwatchDestination {
  type: DestinationType.CLOUDWATCH;
  region: string;
  logGroupName: string;
  logStreamName: string;
  logArn: string;
  logRetentionDays: number;
}

export type Destination = StackdriverDestination | LogAnalyticsDestination | CloudwatchDestination;

export interface LogForwarding<T extends Destination = Destination> {
  region: string;
  tier: Tier;
  id: string;
  name: string;
  destination: T;
  logType: LogType;
  ready: boolean;
}

export interface StackdriverIdentity {
  region: string;
  tier: Tier;
  destinationType: DestinationType.STACKDRIVER;
  serviceAccountEmail: string;
}

export interface LogAnalyticsIdentity {
  region: string;
  tier: Tier;
  destinationType: DestinationType.LOG_ANALYTICS;
}

export interface CloudwatchIdentity {
  region: string;
  tier: Tier;
  destinationType: DestinationType.CLOUDWATCH;
  logArn: string;
  accountId: string;
}

export type LogForwardingIdentity = StackdriverIdentity | LogAnalyticsIdentity | CloudwatchIdentity;

const transformLogForwarding = (remoteBlob: any): LogForwarding => {
  return {
    region: remoteBlob.Region,
    tier: remoteBlob.Tier,
    id: remoteBlob.Id,
    name: remoteBlob.Name,
    destination: {
      type: remoteBlob.Destination.type,
      ...(remoteBlob.Destination.type === DestinationType.STACKDRIVER && {
        gcpProjectId: remoteBlob.Destination.stackdriver.gcp_project_id,
      }),
      ...(remoteBlob.Destination.type === DestinationType.LOG_ANALYTICS && {
        customerId: remoteBlob.Destination.log_analytics.customer_id,
        sharedKey: remoteBlob.Destination.log_analytics.shared_key,
      }),
      ...(remoteBlob.Destination.type === DestinationType.CLOUDWATCH && {
        region: remoteBlob.Destination.cloudwatch.region,
        logGroupName: remoteBlob.Destination.cloudwatch.log_group_name,
        logStreamName: remoteBlob.Destination.cloudwatch.log_stream_name,
        logArn: remoteBlob.Destination.cloudwatch.log_arn,
        logRetentionDays: remoteBlob.Destination.cloudwatch.log_retention_days,
      }),
    },
    logType: remoteBlob.LogType,
    ready: remoteBlob.Ready,
  };
};

export const transformIdentity = (remoteBlob: any): LogForwardingIdentity => {
  return {
    region: remoteBlob.Region,
    tier: remoteBlob.Tier,
    destinationType: remoteBlob.DestinationType,
    ...(remoteBlob.DestinationType === DestinationType.STACKDRIVER && {
      serviceAccountEmail: remoteBlob.ServiceAccountEmail,
    }),
    ...(remoteBlob.DestinationType === DestinationType.LOG_ANALYTICS &&
      {
        /* No extra properties */
      }),
    ...(remoteBlob.DestinationType === DestinationType.CLOUDWATCH && {
      logArn: remoteBlob.LogArn,
      accountId: remoteBlob.AccountId,
    }),
  };
};

const listIdentities = async (tenantId: string): Promise<LogForwardingIdentity[]> => {
  const response = await ApiClient.get(`/tenants/${tenantId}/log-forwarding-identities`)
    .ignoreServerErrors()
    .promise();

  return response.map(transformIdentity);
};

const list = async (tenantId: string): Promise<LogForwarding[]> => {
  const response = await ApiClient.get(`/tenants/${tenantId}/log-forwarding`)
    .ignoreServerErrors()
    .promise();

  return response.map(transformLogForwarding);
};

export interface CreateLogForwardingPayload {
  tenantId: string;
  region: string;
  tier: Tier;
  name: string;
  logType: string;
  destination: Destination;
}

const serializeDestination = (destination: Destination) => {
  switch (destination.type) {
    case DestinationType.STACKDRIVER:
      return {
        type: destination.type,
        stackdriver: {
          gcp_project_id: destination.gcpProjectId,
        },
      };
    case DestinationType.LOG_ANALYTICS:
      return {
        type: destination.type,
        log_analytics: {
          customer_id: destination.customerId,
          shared_key: destination.sharedKey,
        },
      };
    case DestinationType.CLOUDWATCH:
      return {
        type: destination.type,
        cloudwatch: {
          region: destination.region,
          log_group_name: destination.logGroupName,
          log_stream_name: destination.logStreamName,
          log_arn: destination.logArn,
          log_retention_days: destination.logRetentionDays,
        },
      };
  }
};

const create = async (payload: CreateLogForwardingPayload): Promise<LogForwarding> => {
  const response = await ApiClient.post(`/tenants/${payload.tenantId}/log-forwarding`)
    .issue({
      Name: payload.name,
      Region: payload.region,
      Tier: payload.tier,
      LogType: payload.logType,
      Destination: serializeDestination(payload.destination),
    })
    .ignoreServerErrors()
    .promise();

  return transformLogForwarding(response);
};

export interface EditLogForwardingPayload {
  name?: string;
  logType?: string;
  destination?: Destination;
}

const edit = async (
  tenantId: string,
  logForwardingId: string,
  payload: EditLogForwardingPayload
): Promise<LogForwarding> => {
  const response = await ApiClient.patch(`/tenants/${tenantId}/log-forwarding/${logForwardingId}`)
    .issue({
      // Any undefined values will not be altered
      Name: payload.name,
      LogType: payload.logType,
      Destination: payload.destination ? serializeDestination(payload.destination) : undefined,
    })
    .ignoreServerErrors()
    .promise();

  return transformLogForwarding(response);
};

const deleteLogForwarding = async (tenantId: string, logForwardingId: string) =>
  ApiClient.delete(`/tenants/${tenantId}/log-forwarding/${logForwardingId}`)
    .ignoreServerErrors()
    .promise();

export default {
  listIdentities,
  list,
  create,
  edit,
  delete: deleteLogForwarding,
};
