/**
 * Capitalise the first character in a given string.
 *
 * See https://stackoverflow.com/questions/1026069 for the many pitfalls and caveats when doing
 * this.
 *
 * @param word The string of which to capitalise the first letter.
 */
export default function capitaliseFirstLetter(word: string): string {
  const [first, ...rest] = word;
  return [first.toUpperCase(), ...rest].join('');
}
