import React from 'react';
import { Alert } from 'foundation/alert';
import { Dialog } from 'foundation/dialog';
import Button from 'foundation/button';

interface LoadErrorProps
  extends Omit<
    React.ComponentProps<typeof Alert>,
    'type' | 'closeable' | 'onClose' | 'title' | 'description'
  > {
  title?: React.ComponentProps<typeof Alert>['title'];
  description?: React.ComponentProps<typeof Alert>['description'];
}

interface LoadErrorModalProps
  extends Omit<React.ComponentProps<typeof Dialog>, 'title' | 'children'> {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export const LoadErrorModal = ({
  title = 'Load failed',
  description = 'Unable to load. Please refresh and try again',
  onClose,
  ...rest
}: LoadErrorModalProps) => {
  return (
    <Dialog size="small" {...rest} type="danger" onClose={onClose}>
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Description>{description}</Dialog.Description>
      <Dialog.Actions>
        <Button onClick={onClose}>Close</Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const LoadError = ({
  title = 'Load failed',
  description = 'Unable to load. Please refresh and try again',
  ...rest
}: LoadErrorProps) => {
  return <Alert type="danger" title={title} description={description} {...rest} />;
};

export default LoadError;
