import './tsandcs.css';
import React, { memo, useState } from 'react';
import { SessionStore } from 'state';
import { Page } from 'foundation/page';
import Button from 'foundation/button';
import Icon from 'ui/icons';
import logger from 'logger';

import Actions from 'actions';
import track, { useTracking } from 'react-tracking';
import TermsAndConditionsContent, { currentTsAndCsVersion } from './content';
import MarketingConsentSection from './marketing-section';
import { useDatabaseState, useSession } from 'store';
import { useDatabasesRequest } from 'components/helpers/databases';
import LoadingSpinner from 'foundation/loading-spinner';
import LoadError from 'application/load-error';
import { TenantType } from 'entities/tenant';
import cx from 'classnames';
import { useDarkTheme } from 'utils/hooks';
import { RegionalGDPRComplianceTier } from 'types/user';

export interface TermsAndConditionsPageProps {
  updatedVersion?: boolean;
  loggedIn: boolean;
  accepted?: boolean;
  redirectToCreateDbFlow?: boolean;
  marketingChoiceRequired?: RegionalGDPRComplianceTier;
}

type AcceptTermsAndConditionsObj = {
  AcceptedTermsAndConditions: string;
  MarketingPreference?: boolean;
};

const marketingChoice = (marketingPreference, regionalTier) => {
  if (regionalTier === RegionalGDPRComplianceTier.TIER_1) return true;
  return regionalTier !== RegionalGDPRComplianceTier.TIER_2
    ? marketingPreference
    : !marketingPreference;
};

const TermsAndConditionsPage = memo(
  ({
    updatedVersion = false,
    loggedIn,
    accepted = false,
    redirectToCreateDbFlow = false,
    marketingChoiceRequired = null,
  }: TermsAndConditionsPageProps) => {
    const tracking = useTracking();
    const [expanded, setExpanded] = useState(false);
    const [savingAgreement, setSavingAgreement] = useState(false);
    const [marketingPreference, setMarketingPreference] = useState(false);

    const accept = () => {
      tracking.trackEvent({ action: 'ts&cs_accepted' });
      setSavingAgreement(true);
      const newValue: AcceptTermsAndConditionsObj = {
        AcceptedTermsAndConditions: currentTsAndCsVersion(),
      };
      if (marketingChoiceRequired !== null)
        newValue.MarketingPreference = marketingChoice(
          marketingPreference,
          marketingChoiceRequired
        );
      Actions.userDetails
        .updateAcceptedTermsAndConditions(newValue)
        .then(() => {
          logger.debug('Terms and Conditions Accepted. Running Mixpanel Identity');
          const { email, name, sub: auth0SubjectId } = SessionStore.state;
          logger.debug(currentTsAndCsVersion(), email, name, auth0SubjectId);
          Actions.userTracking.handleMixpanelIdentity({
            email,
            name,
            auth0SubjectId,
          });

          if (Actions.marketplace.getMarketplaceRegistrationLocalStorage(email)) {
            Actions.navigate.push({ hash: '#marketplace-registration' });
          } else if (redirectToCreateDbFlow) {
            Actions.navigate.push({ hash: '#databases/create' });
          } else {
            Actions.navigate.push({ hash: '#databases' });
          }
        })
        .catch(() => setSavingAgreement(false));
    };
    const classes = cx('console-tsandcs', {
      'console-tsandcs-compressed': !expanded,
      'console-tsandcs-with-marketing-section': marketingChoiceRequired !== null && expanded,
    });

    return (
      <Page>
        <div data-testid="tos-page-container">
          <IntroMessage loggedIn={loggedIn} updatedVersion={updatedVersion} accepted={accepted} />
          <div className="tw-relative">
            <div
              data-testid="tos-page-container-scrollable"
              className={classes}
              onClick={() => setExpanded(true)}
            >
              <TermsAndConditionsContent />
            </div>
            {!expanded && (
              <Icon
                name="ArrowUpIconOutline"
                className="console-tsandcs-arrow"
                data-testid="tos-page-container-scrollable-arrow"
              />
            )}
          </div>
          {marketingChoiceRequired !== null && (
            <MarketingConsentSection
              regionalTier={marketingChoiceRequired}
              marketingPreference={marketingPreference}
              onChange={() => setMarketingPreference(!marketingPreference)}
            />
          )}
          <div className="tw-space-x-2 tw-flex tw-mt-8">
            <PrivacyLink />
            {loggedIn ? (
              <Button
                iconName="CheckIconOutline"
                onClick={accept}
                loading={savingAgreement}
                disabled={accepted}
              >
                {accepted ? 'Accepted' : 'I agree'}
              </Button>
            ) : null}
          </div>
        </div>
      </Page>
    );
  }
);

const TermsAndConditionsMinimalView = (props: TermsAndConditionsPageProps) => {
  const isDarkTheme = useDarkTheme();
  const classes = cx('console-page', {
    'ndl-theme-dark': isDarkTheme,
    'ndl-theme-light': !isDarkTheme,
  });
  return (
    <div className={classes}>
      <div className="console-content tw-py-12">
        <TermsAndConditionsPage {...props} />
      </div>
    </div>
  );
};

const FirstTimeMessage = () => (
  <div className="tw-mb-8">
    <h3 className="tw-mb-4">Terms of Service and Privacy Policy</h3>
    <p>
      By continuing to use this service, you agree to the{' '}
      <a href="https://neo4j.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and the Terms of Service below. Please review both and click &quot;I agree&quot; to continue.
    </p>
  </div>
);

const UpdatedMessage = () => (
  <div className="tw-mb-8">
    <h3 className="tw-mb-4">Terms of Service</h3>
    <p>
      We&rsquo;ve updated our Terms of Service. Please review and click &quot;I agree&quot; to
      continue.
    </p>
  </div>
);

const AcceptedMessage = () => (
  <div className="tw-mb-8">
    <h3 className="tw-mb-4">Terms of Service</h3>
    <p>These are the Terms of Service you have agreed to.</p>
  </div>
);

const PublicMessage = () => (
  <div className="tw-mb-8">
    <h3 className="tw-mb-4">Terms of Service</h3>
    <p>These are the terms this service is provided under.</p>
  </div>
);

const IntroMessage = ({ loggedIn, accepted, updatedVersion }) => {
  let introMessage = <PublicMessage />;

  if (loggedIn) {
    if (accepted) {
      introMessage = <AcceptedMessage />;
    } else if (updatedVersion) {
      introMessage = <UpdatedMessage />;
    } else {
      introMessage = <FirstTimeMessage />;
    }
  }

  return introMessage;
};

const PrivacyLink = () => (
  <Button
    href="https://neo4j.com/privacy-policy/"
    target="_blank"
    rel="noopener noreferrer"
    color="neutral"
    fill="outlined"
  >
    Privacy policy
    <Icon
      name="ArrowTopRightOnSquareIconOutline"
      style={{ width: '0.75rem', height: '0.75rem', display: 'inline-block' }}
      className="tw-ml-1 tw-mb-1"
      title="https://neo4j.com/privacy-policy/"
    />
  </Button>
);

export const TermsAndConditionsMinimalViewRoute = () => {
  const dbsRequest = useDatabasesRequest();

  const { databases } = useDatabaseState();
  const session = useSession();

  if (dbsRequest.loading) return <LoadingSpinner size="large" expand />;
  if (dbsRequest.error) return <LoadError />;

  return (
    <TermsAndConditionsMinimalView
      updatedVersion={session.acceptedTsAndCs < currentTsAndCsVersion()}
      loggedIn={true}
      redirectToCreateDbFlow={
        session.allowFreeDatabaseCreation &&
        session.tenant.tenantType === TenantType.PERSONAL &&
        databases.length === 0 &&
        session.tenant.requiresBilling
      }
      marketingChoiceRequired={session.marketingChoiceRequired}
    />
  );
};

export default track({ page: 'terms-and-conditions' })(TermsAndConditionsPage);
