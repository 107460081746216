import React from 'react';
import { UsersTable } from '../lookup-users/users-table';
import { Tenant } from 'entities/tenant';
import { User } from 'types/user';

export const UserTab = ({
  users,
  tenant,
  usersRole,
  refetchUsers,
}: {
  users: User[];
  tenant: Tenant;
  usersRole: {
    [key: string]: string;
  };
  refetchUsers: () => void;
}) => {
  return (
    <>
      {users.length > 0 ? (
        <UsersTable
          users={users}
          tenant={tenant}
          displaySearch={true}
          usersRole={usersRole}
          onRefetchData={refetchUsers}
        />
      ) : (
        'No users'
      )}
    </>
  );
};
