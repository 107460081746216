import * as yup from 'yup';

export type ValidationError = {
  message: string;
  error: yup.ValidationError;
};

export type Validation<T> = {
  [K in keyof T]?: ValidationError;
};

export const validateYup = <T>(
  schema: yup.AnySchema,
  data: T,
  onlyRequired: boolean = false
): Validation<T> => {
  let validation = null;
  try {
    schema.validateSync(data, { abortEarly: false });
  } catch (e) {
    const error = e as yup.ValidationError;

    validation = error.inner.reduce((acc, err) => {
      if (onlyRequired && err.type !== 'required') {
        return acc;
      }

      return { ...acc, [err.path]: { message: err.message, error: err } };
    }, {});

    if (Object.keys(validation).length === 0) {
      validation = null;
    }
  }

  return validation;
};
