import React, { useEffect, useState } from 'react';
import { Dialog } from 'foundation/dialog';
import Actions from 'actions';
import * as yup from 'yup';
import { validateYup, Validation } from 'utils/validation';
import { NewOauthClient } from 'types/oauth';
import { useNotify } from 'state/notifications';
import { Form, FormInput, Button, Alert } from 'foundation';
import { useTracking } from 'react-tracking';
import { CopyInput } from 'components/application/copy-input';
import globals from 'browser/globals';

const schema = yup.object({
  name: yup
    .string()
    .trim()
    .max(30)
    .required()
    .matches(
      /^[ a-zA-Z0-9_-]+$/,
      'Must only contain spaces, alphanumeric characters, hyphen or underscore'
    ),
});

interface FormData {
  name?: string;
}

const defaults = (): FormData => ({});

const validate = (data: FormData) => validateYup(schema, data);

export const CreateOauthClientModal = ({ open, onClose, onSuccess }) => {
  const [data, setData] = useState(defaults());
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [client, setClient] = useState<NewOauthClient | null>(null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const tracking = useTracking();

  const handleNameChange = ({ target: { value } }) =>
    setData(prev => {
      return { ...prev, name: value };
    });

  const handleSubmit = () => {
    const errors = validate(data);

    if (errors) {
      setValidation(errors);
      return;
    }

    setValidation({});
    setLoading(true);

    Actions.oauth
      .createOauthClient(data.name.trim())
      .then(newClient => {
        setClient(newClient);
        tracking.trackEvent({
          action: 'create_oauth_client',
          properties: { clientId: newClient.ClientId },
        });
        setLoading(false);
        onSuccess();
      })
      .catch(() => {
        notify.error('Failed to create Aura API credentials');
        setLoading(false);
      });
  };

  const credentialsFilename = `Neo4j-credentials-${
    data.name
  }-Created-${new Date().toISOString().slice(0, 10)}.txt`;

  const credentialsURL = (client: NewOauthClient) => {
    const content =
      `CLIENT_SECRET=${client.ClientSecret}\n` +
      `CLIENT_ID=${client.ClientId}\n` +
      `CLIENT_NAME=${data.name}\n`;
    const blob = new Blob([content], { type: 'text/plain' });
    return globals.URL.createObjectURL(blob);
  };

  const handleClose = onClose;

  const resetForm = () => {
    setValidation({});
    setData(defaults());
    setClient(null);
    setLoading(false);
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Header>Create Aura API Credentials</Dialog.Header>
      <Dialog.Content>
        {client && (
          <div
            className="tw-flex tw-flex-col tw-gap-2"
            data-testid="oauth-client-credentials-section"
          >
            <CopyInput value={client.ClientId} label="Client ID" id="client-id" readOnly />
            <CopyInput
              value={client.ClientSecret}
              label="Client Secret"
              id="client-secret"
              readOnly
            />
            <Alert
              type="warning"
              description="After closing this window, you will no longer be able to access your client secret. We recommend either securely storing it or downloading it for safekeeping."
              icon
            />
          </div>
        )}
        {!client && (
          <Form onSubmit={handleSubmit} data-testid="oauth-create-client-form">
            <FormInput
              fluid
              label="Name"
              value={data.name || ''}
              onChange={handleNameChange}
              errorText={validation?.name?.message}
              data-testid="oauth-client-name-input"
            />
          </Form>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        {client && (
          <Button
            data-testid="download"
            color="neutral"
            fill="outlined"
            href={credentialsURL(client)}
            onClick={() => {
              tracking.trackEvent('DOWNLOAD_API_CREDENTIALS');
            }}
            download={credentialsFilename}
          >
            Download
          </Button>
        )}
        {client && <Button onClick={handleClose}>Done</Button>}
        {!client && (
          <Button
            loading={loading}
            onClick={() => {
              handleSubmit();
            }}
            disabled={!data?.name}
            data-testid="oauth-client-create-button"
          >
            Create
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};

export const DestroyOauthClientModal = ({ clientId, open, onSuccess, onClose }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleClose = onClose;
  const tracking = useTracking();

  const handleConfirm = () => {
    setLoading(true);
    Actions.oauth
      .deleteOauthClient(clientId)
      .then(() => {
        tracking.trackEvent({ action: 'delete_oauth_client', properties: { clientId } });
        setLoading(false);
        onSuccess();
        handleClose();
      })
      .catch(() => {
        notify.error('Failed to delete Aura API credentials');
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Header>Delete Aura API Credentials</Dialog.Header>
      <Dialog.Content>
        <div className="tw-flex tw-flex-col">
          <p>
            Are you sure you want to delete credentials with Client ID <b>{clientId}</b>?
          </p>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button
          loading={loading}
          color="danger"
          onClick={handleConfirm}
          data-testid="oauth-client-delete-button"
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
