import { CloudProvider, Tier } from 'entities/database';

// A special constant value we don't want to confuse
// with actual CMEK / encryption key references
export const NEO4J_MANAGED_KEY = 'neo4j-managed-key';
export const NEO4J_MANAGED_KEY_LABEL = 'Neo4j Managed Key';

export type EncryptionKeyRef = string;

export interface EncryptionKey {
  encryptionKeyRef: string;
  cloudProvider: CloudProvider;
  tier: Tier;
  region: string;
  name: string;
  created: string;
  status: EncryptionKeyStatus;
  awsProperties?: AwsProperties;
  gcpProperties?: GcpProperties;
  azureProperties?: AzureProperties;
}

export enum EncryptionKeyStatus {
  READY = 'ready',
  PENDING = 'pending',
  DELETED = 'deleted',
  ERROR = 'error',
}

export interface GcpProperties {
  keyId: string;
  permissions: GcpKeyPermissions;
}

export interface AwsProperties {
  kmsKeyArn: string;
  permissions: AwsKeyPermissions;
}

export interface AzureProperties {
  keyId: string;
  applicationURL: string;
  applicationName: string;
  applicationID: string;
}

export interface AwsKeyPermissions {
  accountId?: string;
}

export interface GcpKeyPermissions {
  projectNumber?: string;
  serviceAccounts?: string[];
}
