import { getData } from 'country-list';

interface CountryData {
  code: string;
  name: string;
}

interface Country {
  key: string;
  value: string;
  label: string;
}

const completeCountryList: Country[] = getData().map((country: CountryData) => ({
  key: country.code,
  value: country.code,
  label: country.name,
}));

const blockedCountryCodes = [
  'BY', // Belarus
  'BI', // Burundi
  'CD', // Congo, Democratic Republic of
  'CU', // Cuba
  'IQ', // Iraq
  'IR', // Iran
  'LB', // Lebanon
  'LY', // Libya
  'NI', // Nicaragua
  'KP', // North Korea
  'RU', // Russian Federation
  'SO', // Somalia
  'SD', // Sudan
  'SY', // Syria
  'UA', // Ukraine
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];

const filteredCountryList = completeCountryList.filter(c => !blockedCountryCodes.includes(c.value));

const compare = (a: Country, b: Country) => {
  const textA = a.label.toUpperCase();
  const textB = b.label.toUpperCase();

  if (textA > textB) {
    return 1;
  }
  if (textA < textB) {
    return -1;
  }
  return 0;
};

const sortedFilteredList = () => filteredCountryList.sort(compare);

export default sortedFilteredList();
