import { Region } from 'entities/database';
import { useMemo } from 'react';
import { getNeo4jVersionText } from 'utils/neo4j-versions';
import { SelectOption } from './form-essentials';

export const LocationNames = {
  'europe-west1': 'Europe',
  'us-east1': 'US',
  'asia-east1': 'Asia',
};

export const getLocationName = region => {
  return LocationNames[region.name] || region.friendly;
};

export const useLocation = (regions: Region[]): SelectOption[] => {
  return useMemo(
    () =>
      regions.map(region => ({
        key: region.name,
        label: getLocationName(region),
        value: region.name,
      })),
    [regions]
  );
};

export const useRegionOptions = (regions: Region[]): SelectOption[] => {
  return useMemo(
    () =>
      regions.map(region => ({
        key: region.name,
        label: region.friendly,
        value: region.name,
      })),
    [regions]
  );
};

export const useNeo4jVersionOptions = (availableNeo4jVersions: string[]) => {
  return useMemo(
    () =>
      [...availableNeo4jVersions]
        .sort((ver1, ver2) => parseInt(ver2.charAt(0), 10) - parseInt(ver1.charAt(0), 10))
        .map(version => ({
          key: version,
          label: getNeo4jVersionText(version),
          value: version,
        })),
    [availableNeo4jVersions]
  );
};
