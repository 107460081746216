export const omit = (obj: Record<string, any>, keys: string[]): Record<string, any> => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const pick = (obj: Record<string, any>, keys: string[]): Record<string, any> => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (keys.includes(key)) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const isObjectEmpty = (obj: Record<string, any>) => Object.keys(obj).length === 0;

export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    value => value
  );

export const debounce = (func, delay: number) => {
  let timeout;

  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
  };
};
