import { useSession } from 'store';
import { Product } from 'types/product';

// Keep the alphabetical order please
const dictionary = {
  [Product.AURA_DB]: {
    Database: 'Instance',
    Databases: 'Instances',
    'a database': 'an instance',
    database: 'instance',
    databases: 'instances',
    AuraDB: 'AuraDB',
    'Aura DB': 'Aura DB',
  },
  [Product.AURA_DS]: {
    Database: 'Instance',
    Databases: 'Instances',
    'a database': 'an instance',
    database: 'instance',
    databases: 'instances',
    AuraDB: 'AuraDS',
    'Aura DB': 'Aura DS',
  },
};

export const useTerminology = () => {
  const session = useSession();

  function t(word: string): string {
    if (!session || !session.product) {
      return word;
    }
    const productDictionary = dictionary[session.product];
    if (!productDictionary.hasOwnProperty(word)) {
      return word;
    }
    return productDictionary[word];
  }

  return t;
};
