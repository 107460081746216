import * as OauthResource from 'remote/resources/oauth';
import { NewOauthClient, OauthClient } from 'types/oauth';

export const fetchOauthClients = (): Promise<OauthClient[]> => OauthResource.list();
export const createOauthClient = (name: string): Promise<NewOauthClient> =>
  OauthResource.create(name);
export const deleteOauthClient = (clientId: string): Promise<void> =>
  OauthResource.deleteClient(clientId);
export const findByClientID = (clientId: string): Promise<OauthClient> =>
  OauthResource.findByClientID(clientId);
