import React from 'react';
import { Page } from 'foundation';
import { FeatureTogglesOverview } from './feature-toggles-overview';
import { Alert } from 'foundation';
import { Action } from 'types/user';
import { usePermissions } from 'store';

const FeatureTogglesOverviewPage = () => {
  const { allow } = usePermissions();
  const listTogglesAllowed = allow(Action.READ, 'feature-toggles/overrides');

  return (
    <Page fullWidth>
      {listTogglesAllowed ? <FeatureTogglesOverview /> : <Alert type="danger">Access Denied</Alert>}
    </Page>
  );
};

export default FeatureTogglesOverviewPage;
