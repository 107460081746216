import React, { useState } from 'react';

import { Action, OrganizationMember, RoleName } from 'types/user';
import UsersTable from './users';
import EditUserModal from './edit-user-modal';

import '../../user-management/user-management.css';
import { Alert, LoadingSpinner } from 'components/foundation';
import { usePermissions, useSession } from 'store';
import { useNotify } from 'state/notifications';
import OrganizationsResource, { useOrganizationMembersQuery } from 'remote/resources/organizations';
import userDetailsActions from 'actions/user-details-actions';

interface UserManagementProps {
  organizationId: string;
}

export const UserManagement = ({ organizationId }: UserManagementProps) => {
  const [editedUser, setEditedUser] = useState(null);
  const [editInProgress, setEditInProgress] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState(null);
  const { allow } = usePermissions();
  const notify = useNotify();
  const session = useSession();

  const users = useOrganizationMembersQuery(organizationId);

  if (users.isLoading) {
    return <LoadingSpinner expand minHeight={250} />;
  }

  if (users.isError) {
    return <Alert type="danger" description="Error fetching data. Please reload the page." />;
  }

  const allowCreateOwnerRoles = allow(Action.CREATE, `organizations/${organizationId}/owner-roles`);
  const allowDeleteOwnerRoles = allow(Action.DELETE, `organizations/${organizationId}/owner-roles`);

  const handleUserRoleChange = async (user: OrganizationMember, newRoleName: RoleName) => {
    setEditInProgress(true);
    setEditErrorMessage(null);

    try {
      await OrganizationsResource.patchMemberRoles(organizationId, user.UserId, [newRoleName]);

      setEditedUser(null);
      if (user.UserId === session.userId) {
        // We refetch the user details in order to update the
        // users.data permission in the tenant. This will trigger a re-render of
        // the parent component rendering the component without invites.
        userDetailsActions
          .refetch()
          .then(() => users.refetch())
          .catch(() => {
            notify.error('Failed to refetch permissions. Please reload the page');
          });
      } else {
        users.refetch();
      }
    } catch (ex) {
      if (ex.response.status === 403) {
        setEditErrorMessage('You do not have permission to perform that action.');
      } else if (ex.reason === 'last-owner-leaving-organization') {
        // This will only apply to the last user trying to remove themselves as admin.
        setEditErrorMessage(
          'An Organization must have at least one Owner. You cannot remove yourself as an Owner until you have assigned another user as an Owner.'
        );
      } else {
        setEditErrorMessage(ex.responseMessage);
      }
    }
    setEditInProgress(false);
  };

  return (
    <div className="tw-ml-4" data-testid="organization-user-management">
      <h4 className="tw-mb-5">User Management</h4>
      <div className="user-management-wrapper tw-rounded-lg">
        <UsersTable
          usersList={users.data}
          onEditUserClick={member => setEditedUser(member)}
          allowDeleteOwnerRoles={allowDeleteOwnerRoles}
        />
        {editedUser && (
          <EditUserModal
            open={editedUser !== null}
            user={editedUser}
            onClose={() => {
              setEditedUser(null);
              setEditErrorMessage(null);
              setEditInProgress(false);
            }}
            onEdit={handleUserRoleChange}
            editInProgress={editInProgress}
            allowCreateOwnerRoles={allowCreateOwnerRoles}
            errorMessage={editErrorMessage}
          />
        )}
      </div>
    </div>
  );
};

export default UserManagement;
