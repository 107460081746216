import React, { useState } from 'react';
import UsersResource from 'remote/resources/users';
import { Button, Alert, Dialog } from 'foundation';
import { User } from 'types/user';

interface Props {
  user: User;
  onClose: () => any;
}

export const UpdateEmailMFAModal = ({ user, onClose }: Props) => {
  const [error, setError] = useState<Error>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      await UsersResource.updateEmailMFA(user.UserId, !user.EmailMFAEnabled);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header data-testid="terminate-user-auth-header">Are you sure?</Dialog.Header>
      <Dialog.Content data-testid="terminate-user-auth-content">
        <Dialog.Description>
          This will {user.EmailMFAEnabled ? 'disable' : 'enable'} Email MFA for user {user.Email}.
        </Dialog.Description>
        {error && (
          <Alert type="danger" className="tw-mt-2">
            {String(error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="terminate-user-auth-success" className="tw-mt-2">
            Email MFA was successfully {user.EmailMFAEnabled ? 'disabled' : 'enabled'}.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          data-testid="terminate-user-auth-cancel"
          onClick={onClose}
          fill="outlined"
          color="neutral"
        >
          Cancel
        </Button>

        <Button
          data-testid="submit-email-mfa-button"
          color={user.EmailMFAEnabled ? 'danger' : 'primary'}
          onClick={handleSubmit}
          disabled={loading || success}
        >
          {[undefined, true].includes(user.EmailMFAEnabled) ? 'Disable' : 'Enable'}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
