import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import _DatePicker from 'react-datepicker';
import { isValid as _isValid, getErrorText as _getErrorText, parse, format } from './utils';
import { Popover, TextInput } from '@neo4j-ndl/react';
import { forwardRef } from 'utils/forwardRef';
import Icon from 'ui/icons';

interface BaseProps {
  value: Date;
  onChange: (v: Date) => void;
  onError?: () => void;
  disabled?: boolean;
  errorMessage?: string;

  /**
   * The date format to display in the input
   */
  dateFormat?: string;

  selected?: Date;

  inputProps: React.ComponentProps<typeof TextInput>;

  popoverProps?: Partial<React.ComponentProps<typeof Popover>>;

  children: React.ReactElement;

  className?: string;

  style?: any;
}

interface PickerBaseProps extends Omit<BaseProps, 'children' | 'closeOnDateSelect' | 'inputProps'> {
  inputProps?: Partial<React.ComponentProps<typeof TextInput>>;
  label?: string;
}

const Base = forwardRef(
  (
    {
      className,
      value,
      onChange,
      onError,
      dateFormat,
      disabled,
      children,
      errorMessage,
      selected,
      inputProps,
      popoverProps = {},
    }: BaseProps,
    ref
  ) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [freehandDate, setFreehandDate] = useState<string | null>(null);
    const inputRef = useRef(null);
    const popoverRef = useRef(null);
    const [invalidValue, setInvalidValue] = useState(null);
    const now = useMemo(() => new Date(), []);

    const dispatchOnChange = (d: Date) => {
      const copiedDate = new Date(d.getTime());
      // react-datepicker doesn't emit dates with 0 as the milliseconds
      // @see https://github.com/Hacker0x01/react-datepicker/issues/1991
      copiedDate.setMilliseconds(0);
      copiedDate.setSeconds(0);
      onChange(copiedDate);
    };

    const handleInputFocus = e => {
      setOpen(true);
      if (inputProps?.onFocus) inputProps.onFocus(e);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handlePickerChange = (v: Date) => {
      dispatchOnChange(v);
      setFreehandDate(null);
      setInvalidValue(null);
    };

    const handleInputChange = e => {
      const dateString = e.target.value;
      const newDate = parse(dateString, dateFormat);

      setFreehandDate(dateString);

      if (isNaN(newDate.getTime())) {
        setInvalidValue(newDate);
        if (onError) onError();
      } else {
        setInvalidValue(null);
        dispatchOnChange(newDate);
      }
    };

    useImperativeHandle(ref, () => ({
      refs: {
        input: inputRef,
        popover: popoverRef,
      },
    }));

    const isValid = (v: Date) => _isValid(v, dateFormat);
    const getErrorText = (v: Date) => _getErrorText(v, dateFormat, errorMessage);

    const currentValue = invalidValue || value;

    const getInputProps = () => {
      let inputValue = inputProps.value || freehandDate;
      let errorText = getErrorText(currentValue);

      const valid = isValid(currentValue);

      if (valid && !inputValue) {
        inputValue = format(value, dateFormat);
      }

      return { ...inputProps, value: inputValue || '', errorText };
    };

    const calendar = React.cloneElement(children, {
      selected: isValid(currentValue) ? currentValue : selected ? selected : now,
      dateFormat,
      onChange: handlePickerChange,
    });

    return (
      <div className={className} ref={anchorRef}>
        <TextInput
          {...getInputProps()}
          ref={inputRef}
          onFocus={handleInputFocus}
          disabled={disabled}
          onChange={handleInputChange}
        />
        <Popover
          {...popoverProps}
          className="console-datepicker-popover"
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorPortal
          ref={popoverRef}
        >
          {calendar}
        </Popover>
      </div>
    );
  }
);

interface DatePickerProps extends PickerBaseProps {
  minDate?: Date;
  maxDate?: Date;

  showTimeInput?: Boolean;
  showTimeSelect?: Boolean;
  calendarStartDay?: Number;
  timeInputLabel?: string;
}

export const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      onError,
      dateFormat = 'MMM d',
      label = '',
      disabled,
      inputProps,
      popoverProps,
      className,
      ...rest
    }: DatePickerProps,
    ref
  ) => {
    return (
      <Base
        className={className}
        value={value}
        dateFormat={dateFormat}
        onChange={onChange}
        onError={onError}
        disabled={disabled}
        inputProps={{
          label,
          rightIcon: <Icon name="CalendarIconOutline" title="Select Date" />,
          ...inputProps,
        }}
        popoverProps={popoverProps}
        ref={ref}
      >
        <_DatePicker inline {...rest} />
      </Base>
    );
  }
);

interface TimePickerProps extends PickerBaseProps {
  /**
   * The minute gap between time intervals in the dropdown. Defaults to 15
   */
  timeIntervals?: number;

  minTime?: Date;
  maxTime?: Date;
}

export const TimePicker = forwardRef(
  (
    {
      value,
      onChange,
      onError,
      disabled,
      dateFormat = 'hh:mm aa',
      label = '',
      timeIntervals = 15,
      inputProps,
      popoverProps,
      className,
      ...rest
    }: TimePickerProps,
    ref
  ) => {
    return (
      <Base
        className={className}
        value={value}
        dateFormat={dateFormat}
        onChange={onChange}
        onError={onError}
        disabled={disabled}
        inputProps={{
          label,
          rightIcon: <Icon name="ClockIconOutline" title="Select Time" />,
          ...inputProps,
        }}
        popoverProps={popoverProps}
        ref={ref}
      >
        <_DatePicker
          inline
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals}
          {...rest}
        />
      </Base>
    );
  }
);

interface DateTimePickerProps extends TimePickerProps, DatePickerProps {}

export const DateTimePicker = forwardRef(
  (
    {
      value,
      onChange,
      onError,
      disabled,
      dateFormat = 'MMM d hh:mm aa',
      label = '',
      timeIntervals = 15,
      selected,
      inputProps,
      popoverProps,
      className,
      ...rest
    }: DateTimePickerProps,
    ref
  ) => {
    return (
      <Base
        className={className}
        value={value}
        dateFormat={dateFormat}
        onChange={onChange}
        onError={onError}
        disabled={disabled}
        selected={selected}
        inputProps={{
          label,
          rightIcon: <Icon name="CalendarIconOutline" title="Select Date & Time" />,
          ...inputProps,
        }}
        popoverProps={popoverProps}
        ref={ref}
      >
        <_DatePicker inline timeIntervals={timeIntervals} {...rest} />
      </Base>
    );
  }
);
