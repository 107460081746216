import React, { useEffect, useState } from 'react';
import { OauthClient } from 'types/oauth';
import { OauthClientTable } from './table';
import { CreateOauthClientModal, DestroyOauthClientModal } from './modal';
import Actions from 'actions';
import LoadError from 'application/load-error';
import { Button, LoadingSpinner, Link } from 'foundation';
import { useTracking } from 'react-tracking';

export const OauthClientSection = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [clients, setClients] = useState({
    items: [] as OauthClient[],
    loading: true,
    error: false,
  });
  const [clientId, setClientId] = useState(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const tracking = useTracking();

  const handleClickCreate = () => {
    tracking.trackEvent({ action: 'open_create_oauth_client' });
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => setOpenCreateModal(false);

  const handleSuccess = () => {
    fetchClients();
  };

  const fetchClients = () =>
    Actions.oauth
      .fetchOauthClients()
      .then(items => {
        setClients({ items, loading: false, error: false });
      })
      .catch(() => {
        setClients({ items: [], loading: false, error: true });
      });

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setClientId(undefined);
  };

  const handleDeleteClicked = (currentClientId: string) => {
    tracking.trackEvent({ action: 'open_delete_oauth_client' });
    setClientId(currentClientId);
    setOpenDeleteModal(true);
  };

  // On init
  useEffect(() => {
    fetchClients();
  }, []);

  const renderList = () => {
    if (clients.loading) {
      return <LoadingSpinner expand minHeight={200} />;
    }

    if (clients.error) {
      return <LoadError />;
    }

    return <OauthClientTable clients={clients.items} onDelete={handleDeleteClicked} />;
  };

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-flex-nowrap tw-my-8">
        <div className="tw-flex tw-items-center tw-gap-2">
          <div className="tw-flex tw-flex-nowrap tw-items-center">
            <h4>Aura API</h4>
          </div>
          <Button
            as={Link}
            size="small"
            fill="outlined"
            href="https://neo4j.com/docs/aura/platform/api/overview/"
            target="_blank"
          >
            Docs
          </Button>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-flex-nowrap tw-mb-4">
        <h6>Credentials</h6>
        <Button onClick={handleClickCreate}>Create</Button>
      </div>
      {renderList()}
      <CreateOauthClientModal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        onSuccess={handleSuccess}
      />
      <DestroyOauthClientModal
        clientId={clientId}
        open={openDeleteModal}
        onSuccess={handleSuccess}
        onClose={handleCloseDeleteModal}
      />
    </div>
  );
};
