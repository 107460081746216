import { SyntheticEvent } from 'react';
import track, { useTracking } from 'react-tracking';
import { isNeoAppsAccessable, Database, Tier } from 'entities/database';

export interface OpenWithImporterChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  href: string;
}
export interface OpenWithImporterProps {
  database: Database;
  children: (args: OpenWithImporterChildArgs) => JSX.Element;
}

const importerButtonEnabledMap: Record<Tier, boolean> = {
  [Tier.FREE]: true,
  [Tier.PROFESSIONAL]: true,
  [Tier.MTE]: true,
  [Tier.ENTERPRISE]: false,
  [Tier.GDS]: true,
  [Tier.AURA_DSE]: false,
};

const isImporterButtonEnabled = (database: Database) => importerButtonEnabledMap[database.Tier];

export const OpenWithImporter = track()(({ database, children }: OpenWithImporterProps) => {
  const tracking = useTracking();

  const handleImporterClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    // @Todo: remove the `open_db` event after AuraDS GA and Appcues triggers has been refactored
    tracking.trackEvent({
      action: 'open_db',
      properties: { event_label: 'neo4j-data-importer' },
    });

    tracking.trackEvent({
      action: 'open_db_data_importer',
    });
  };

  const importerUrl = database.DataImporterUrl;

  if (!isImporterButtonEnabled(database)) {
    return null;
  }

  return children({
    onClick: handleImporterClick,
    href: importerUrl,
    disabled: !importerUrl || !isNeoAppsAccessable(database),
  });
});
