import { Button, Page, Alert, Divider } from 'foundation';
import VerificationEmailResource from 'remote/resources/verification-email-request';
import React, { useState, useEffect } from 'react';
import { setMarketplaceQueryParameters } from 'components/utils';
import { useNotify } from 'state/notifications';

const EmailVerificationRequiredPage = () => {
  const [state, setState] = useState({
    loading: false,
    complete: false,
  });
  const notify = useNotify();

  const { loading, complete } = state;

  useEffect(() => {
    setMarketplaceQueryParameters();
  }, []);

  const EmailVerificationComplete = () =>
    complete ? (
      <Alert
        type="success"
        data-testid="resend-verification-email-complete"
        onClose={() => setState({ ...state, loading: false })}
        closeable
      >
        Email verification sent
      </Alert>
    ) : null;

  const resendClicked = e => {
    try {
      e.preventDefault();

      setState({ ...state, loading: true });
      VerificationEmailResource.create().then(() => {
        setState({ loading: false, complete: true });
      });
      return false;
    } catch (err) {
      if (err.response.status === 429) {
        notify.error('Too many resend requests. Please wait and try again');
      }
    }
  };

  return (
    <Page>
      <Alert type="warning" title="Email verification required">
        <p>
          You should have received an email with a link asking you to verify your email address.
          Check your spam folder if you have trouble finding it.
        </p>
        <Divider hidden />
        <div className="tw-flex tw-items-center tw-justify-center tw-space-x-2">
          <Button data-testid="resend-verification-email" loading={loading} onClick={resendClicked}>
            Resend
          </Button>
          <Button fill="outlined" href="/">
            Dashboard
          </Button>
          <Button
            fill="outlined"
            href="https://support.neo4j.com/s/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get help
          </Button>
        </div>
      </Alert>
      <EmailVerificationComplete />
    </Page>
  );
};

export default EmailVerificationRequiredPage;
