import React, { useState, useEffect } from 'react';
import DatabasesResource from 'remote/resources/databases';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { Page, Alert } from 'foundation';
import { DatabasesTable } from './databases-table';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { Database } from 'entities/database';

interface Props {
  dbid: string;
}

export const ViewDatabaseDetailsPage = ({ dbid }: Props) => {
  const permission = usePermissions();
  const canViewDatabase = permission.allow(Action.READ, `namespaces/*/databases/${dbid}`);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [database, setDatabase] = useState<Database | null>(null);

  const fetchDatabase = async () => {
    try {
      setDatabase(await DatabasesResource.get(dbid));
    } catch (err) {
      setError(getErrorMessage(err, dbid));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (canViewDatabase) {
      fetchDatabase();
    }
  }, [dbid, canViewDatabase]);

  if (!canViewDatabase) {
    return (
      <Page>
        <Alert type="danger" data-testid="dbid-details-dbs-error">
          Access Denied
        </Alert>
      </Page>
    );
  }

  return (
    <Page loading={loading} fullWidth>
      <h5 className="tw-mb-4" data-testid="dbid-details-page-header">
        Instance {dbid}
      </h5>

      {error ? (
        <Alert type="danger" data-testid="dbid-details-error">
          {error}
        </Alert>
      ) : (
        <DatabasesTable database={database} />
      )}
    </Page>
  );
};

const getErrorMessage = (err, dbid: string): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case 'db-not-found':
      return `Instance "${dbid}" does not exist`;
    default:
      return `${err.responseMessage || err} (error code: ${err.reason || 'unknown'})`;
  }
};
