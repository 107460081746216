import React, { useEffect, useState } from 'react';
import { useSession } from 'store';
import Actions from 'actions';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';

import { TenantType } from 'entities/tenant';
import './payments.css';
import { BillingAddress, isSameAddress } from 'types/customer';
import { BillingEmailSection } from './billing-email-section';
import { BillingCardSection, BillingData } from './billing-card-section';
import { BillingAddressSection, defaultStripeAddress } from './billing-address-section';
import { useNotify } from 'state/notifications';

const companyNameSchema = yup.object({
  companyName: yup
    .string()
    .min(3, 'The company name must be a minimum of 3 characters')
    .max(140, 'The company name can max be 140 characters')
    .required(),
});

export const validateCompanyName = originalData => {
  return validateYup(companyNameSchema, originalData, false);
};

const emailSchema = yup.object({
  email: yup.string().email('Must be a valid email'),
});

export const validateEmail = data => {
  return validateYup(emailSchema, data, false);
};

interface StripeData {
  address: BillingAddress;
  isServiceAddress: boolean;
  companyName?: string;
  loading: boolean;
}

export const PaymentSection = () => {
  const session = useSession();
  const notify = useNotify();
  const { tenantType, id: tenantId, hasBilling } = session.tenant;
  /*
   * In order to determine if the data has been changed we store the originally fetched data
   * while having the mutable data in a separate variable.
   */
  const [originalData, setOriginalData] = useState<BillingData>({
    fetching: hasBilling,
  });
  const [data, setData] = useState<StripeData>({
    address: defaultStripeAddress(),
    isServiceAddress: false,
    loading: false,
  });
  const [emailData, setEmailData] = useState({ email: '', loading: false });
  const [companyNameValidation, setCompanyNameValidation] = useState(null);
  const [emailValidation, setEmailValidation] = useState(null);

  const handleUpdateCardSuccess = () => {
    getCustomerData();
  };

  const handleEmailChange = (newEmail: string) => {
    setEmailValidation(null);
    const error = validateEmail({ email: newEmail });
    if (error) {
      setEmailValidation(error);
    }
    setEmailData(oldData => ({ ...oldData, email: newEmail }));
  };

  const handleEmailSubmit = async () => {
    setEmailValidation(null);
    const error = validateEmail({ email: emailData.email });
    if (error) {
      setEmailValidation(error);
      return;
    }
    setEmailData(oldData => ({ ...oldData, loading: true }));
    Actions.customer
      .updateCustomerEmail(tenantId, emailData.email)
      .then(response => {
        setEmailData({ email: response.email, loading: false });
        notify.success('Email successfully updated');
      })
      .catch(() => {
        setEmailData(oldData => ({ ...oldData, loading: false }));
        notify.error('Something went wrong while updating your email. Please try again later');
      });
  };

  const getCustomerData = async () => {
    setOriginalData(oldData => ({ ...oldData, fetching: true }));
    setEmailData(oldData => ({ ...oldData, loading: true }));
    try {
      const cardResponse = await Actions.customer.fetchCard(tenantId);
      let fetchedData: BillingData;

      fetchedData = {
        card: cardResponse,
        fetching: false,
      };

      const customerResponse = await Actions.customer.getCustomer(tenantId);
      const { serviceAddress, address, companyName, email } = customerResponse;
      let isServiceAddress = false;
      if (serviceAddress || address) {
        isServiceAddress = !serviceAddress || isSameAddress(address, serviceAddress);
        const stripeAddress = serviceAddress ?? address;
        setData(prev => ({
          ...prev,
          isServiceAddress,
          address: {
            ...stripeAddress,
            address_country: stripeAddress.address_country || 'United States',
          },
          companyName: cardResponse?.name === companyName ? '' : companyName,
        }));
      }
      setEmailData({ email, loading: false });
      setOriginalData({
        ...fetchedData,
        address,
        serviceAddress,
        companyName,
        isServiceAddress,
      });
    } catch (e) {
      setOriginalData({ error: e.message, fetching: false });
    }
    setData(prev => ({
      ...prev,
      loading: false,
    }));
  };

  useEffect(() => {
    if (hasBilling) {
      getCustomerData();
    }
  }, []);

  const handleStripeAddressChange = (stripeAddress: BillingAddress) => {
    setData(prev => ({ ...prev, address: stripeAddress }));
  };

  const handleStripeCompanyNameChange = (newCompanyName: string) => {
    setCompanyNameValidation(null);
    setData(prev => ({ ...prev, companyName: newCompanyName }));
  };

  const handleIsServiceAddressChange = (checked: boolean) => {
    setData(prev => ({
      ...prev,
      isServiceAddress: checked,
      address: checked
        ? originalData.address || originalData.card.address
        : originalData.serviceAddress
        ? originalData.serviceAddress
        : defaultStripeAddress(),
    }));
  };

  const handleUpdateCustomer = async () => {
    setData(prev => ({ ...prev, loading: true }));
    const errors = validateCompanyName({ companyName: data.companyName });
    if (errors) {
      setCompanyNameValidation(errors);
      return;
    }

    try {
      await Actions.customer.updateCustomer(tenantId, {
        ...(!data.isServiceAddress && {
          shipping: {
            address: data.address,
            // We need to have the billingName even though it is static since stripe requires it.
            name: originalData.card.name,
          },
        }),
        address: originalData.address ?? data.address,
        name: data.companyName,
      });
      getCustomerData();
    } catch (e) {
      setData(prev => ({ ...prev, loading: false }));
      throw Error('Failed to update customer information. Please try again.');
    }
  };

  return (
    <>
      <BillingCardSection
        originalData={originalData}
        onSuccess={handleUpdateCardSuccess}
        session={session}
      />
      {hasBilling &&
        !originalData.error &&
        ![TenantType.N4GCP, TenantType.MARKETPLACE_AWS, TenantType.MARKETPLACE_AZURE].includes(
          tenantType
        ) && (
          <>
            <BillingEmailSection
              data={emailData}
              onEmailChange={handleEmailChange}
              onSubmit={handleEmailSubmit}
              fetching={originalData.fetching}
              validation={emailValidation}
            />
            <BillingAddressSection
              stripeData={data}
              originalData={originalData}
              onUpdateCustomer={handleUpdateCustomer}
              onCompanyNameChange={handleStripeCompanyNameChange}
              onAddressChange={handleStripeAddressChange}
              companyNameValidation={companyNameValidation}
              onIsServiceAddressChange={handleIsServiceAddressChange}
              session={session}
            />
          </>
        )}
    </>
  );
};
