import Session from './session-store';
import Databases from './databases-store';
import { InstantiatedNavigationStore } from './navigation';
import Snapshots from './snapshots-store';
import { InstantiatedNotificationStore } from './notifications';

export const SessionStore = new Session();
export const DatabasesStore = new Databases();
export const NavigationStore = InstantiatedNavigationStore;
export const NotificationsStore = InstantiatedNotificationStore;
export const SnapshotsStore = new Snapshots();
