import React, { useState } from 'react';
import { Database, isOverwritable, Tier } from 'entities/database';
import {
  CloneToExistingDatabaseFormData,
  CloneToExistingDatabaseFormOptions,
} from 'application/clone-db/to-existing/form-data';
import CloneToExistingDatabaseFormFields, {
  defaults,
  validate,
} from 'application/clone-db/to-existing/form-fields';
import { Button, Dialog, Alert } from 'foundation';
import { Validation } from 'utils/validation';
import Actions from 'actions';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { useDatabaseState } from 'store';
import track, { useTracking } from 'react-tracking';
import { useDatabasesRequest } from 'helpers/databases';
import { LoadErrorModal } from 'application/load-error';
import LoadingModal from 'application/loading-modal';
import { CDCCloneDBWarning } from 'components/application/edit-cdc-enrichment-mode/cdc-warnings';
import { getHasCDCEnrichmentMode } from 'application/edit-cdc-enrichment-mode/helpers';
import { Tenant } from 'entities/tenant';
import { useSession } from 'store';

const getErrorMessage = (err: ApiClientRequestError) => {
  switch (err.reason) {
    case 'db-not-running':
      return 'Cannot clone from database because it is not running';
    case 'db-is-being-cloned':
      return 'Cannot overwrite database while it is being cloned';
    default:
      return `Error: ${err.reason || 'unknown'}`;
  }
};

interface Props {
  open: boolean;
  sourceDatabase: Database;
  targetTier: Tier;
  tenant: Tenant;
  onClose: () => void;
}

const useTargetDatabases = (sourceDatabase: Database, targetTier: Tier) => {
  const { databases } = useDatabaseState();

  return databases.filter(
    (database: Database) =>
      database.Tier === targetTier &&
      isOverwritable(database) &&
      database.DbId !== sourceDatabase.DbId &&
      database.DesiredSettings.Version >= sourceDatabase.DesiredSettings.Version
  );
};

const CloneToExistingDatabaseModalGuarded = props => {
  const { open, onClose } = props;
  const { loading, error } = useDatabasesRequest();

  if (loading) {
    return <LoadingModal open={open} />;
  }

  if (error) {
    return <LoadErrorModal open={open} onClose={onClose} />;
  }

  return <CloneToExistingDatabaseModal {...props} />;
};

// exported for testing
export const CloneToExistingDatabaseModal = ({
  open,
  onClose,
  sourceDatabase,
  targetTier,
  tenant,
  ...rest
}: Props) => {
  const targetDatabases = useTargetDatabases(sourceDatabase, targetTier);
  const session = useSession();
  const options: CloneToExistingDatabaseFormOptions = {
    targetTier,
    sourceDatabase,
    targetDatabases,
    tenant: session.tenant,
  };
  const hasCDCEnrichmentMode = getHasCDCEnrichmentMode(sourceDatabase);

  const [data, setData] = useState(defaults(options));
  const [validation, setValidation] = useState<Validation<CloneToExistingDatabaseFormData>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [loading, setLoading] = useState(false);
  const tracking = useTracking();

  const handleDataChange = (newData: CloneToExistingDatabaseFormData) => {
    setData(newData);

    if (hasFailedValidation) {
      setValidation(validate(newData));
    }
  };

  const handleSubmit = async () => {
    const newValidation = validate(data);
    if (newValidation) {
      setValidation(newValidation);
      setResponseError(null);
      setHasFailedValidation(true);
    } else {
      setValidation(null);
      setResponseError(null);
      setHasFailedValidation(false);
      setLoading(true);

      tracking.trackEvent({
        action: 'clone_db',
        properties: {
          sourceTier: sourceDatabase.Tier,
          targetTier,
          isCloneToNew: false,
        },
      });

      try {
        await Actions.databases.cloneToExistingDatabase(data.targetDbid, sourceDatabase.DbId);
        await Actions.databases.updateDatabaseName(data.targetDbid, data.name);
        setData(defaults(options));
        onClose();
      } catch (err) {
        setResponseError(getErrorMessage(err));
      }
      setLoading(false);
    }
  };

  const reset = () => {
    setValidation(null);
    setHasFailedValidation(false);
    setResponseError(null);
    setLoading(false);
    setData(defaults(options));
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const cloneButtonDisabled =
    !data.confirmed || !data.targetDbid || (hasFailedValidation && !!validation);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'clone-to-existing-db-modal' }}
      {...rest}
    >
      <Dialog.Header>Clone Database</Dialog.Header>
      <Dialog.Content>
        {hasCDCEnrichmentMode && <CDCCloneDBWarning />}
        <form>
          <CloneToExistingDatabaseFormFields
            data={data}
            onChange={handleDataChange}
            validation={validation}
            options={options}
          />
          {responseError && (
            <Alert type="danger" data-testid="clone-to-existing-db-modal-error" className="tw-mt-2">
              {responseError}
            </Alert>
          )}
        </form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          onClick={handleClose}
          data-testid="clone-to-existing-db-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          disabled={cloneButtonDisabled}
          data-testid="clone-to-existing-db-modal-clone"
        >
          Clone
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default track()(CloneToExistingDatabaseModalGuarded);
