import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  SingleLineCodeBlock,
  FormSelect,
  Radio,
  SelectOption,
  TextInput,
} from 'components/foundation';
import { Tier } from 'entities/database';
import {
  DestinationType,
  CreateLogForwardingPayload,
  LogType,
  StackdriverIdentity,
  LogForwardingIdentity,
} from 'remote/resources/log-forwarding';
import { regionNameWithInstanceCount } from './helpers';
import { getRegionsForTier, getTiersForAllCsps } from 'utils/tiers-and-regions';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { productFriendlyName } from 'types/product';
import { useDatabaseState } from 'store/index';
import { Banner } from '@neo4j-ndl/react';
import { DisclaimerDialogStep } from 'pages/log-forwarding/disclaimer-dialog-step';

interface Props {
  tenant: Tenant;
  onCreate: (payload: CreateLogForwardingPayload) => any;
  onClose: () => any;
  open: boolean;
  loading: boolean;
  error: Error | null;
  logForwardingIdentities: LogForwardingIdentity[];
  hasReachedProcessLimit: (region: String, tier: String) => boolean;
}

// Matching the validation in log-forwarding-api:
// components/log-forwarding-api/server/logforwarding/validate.go
const gcpProjectIdRegex = /^[a-z][-a-z0-9]{4,28}[a-z0-9]$/;
const bannedWords = ['google', 'null', 'undefined', 'ssl'];

const validateGcpProjectId = (projectId: string): string | null => {
  if (!gcpProjectIdRegex.test(projectId)) {
    return 'Must be a valid GCP Project ID: 6 to 30 characters in length, can only contain lowercase letters, numbers and hyphens, must start with a letter and cannot end with a hyphen.';
  }
  if (bannedWords.some(word => projectId.includes(word))) {
    const bannedWordsWithQuotes = bannedWords.map(w => `"${w}"`).join(', ');
    return `GCP Project ID must not contain any of the words ${bannedWordsWithQuotes}.`;
  }

  return null;
};

export const CreateStackdriverConfigModal = ({
  tenant,
  onCreate,
  onClose,
  open,
  loading,
  error,
  logForwardingIdentities,
  hasReachedProcessLimit,
}: Props) => {
  const [step, setStep] = useState(-1);
  const [name, setName] = useState('');
  const [region, setRegion] = useState<string>(null);
  const [gcpProjectId, setGcpProjectId] = useState('');
  const [gcpProjectIdValidationError, setGcpProjectIdValidationError] = useState<string>(null);
  const { databases } = useDatabaseState();

  const availableTiers = getTiersForAllCsps(tenant.providerConfigs);
  const [tier, setTier] = useState<Tier>(availableTiers[0]);

  let regionOptions = [];

  const tierOptions = availableTiers.map(tier => {
    const product = getProductFromTier(tier);
    return {
      key: tier,
      value: tier,
      label: productFriendlyName(product),
    };
  });

  const availableRegions = getRegionsForTier(tenant.providerConfigs, tier);
  regionOptions = availableRegions.map(r => {
    const dbsInRegion = databases.filter(db => db.Region === r.name && db.Tier === tier);
    return {
      key: r.name,
      label: regionNameWithInstanceCount(r.friendly, dbsInRegion),
      value: r.name,
      databases: dbsInRegion,
    };
  });

  const formatOptionLabel = ({ label, value, databases }, { context }) => {
    if (context === 'value') {
      return <div>{label}</div>;
    } else if (context === 'menu') {
      return (
        <div data-testid={`region-${value}`}>
          <p>{label}</p>
          <p className="tw-pl-4">{databases.map(db => db.Name).join(', ')}</p>
        </div>
      );
    }
  };

  // We are making an assumption that this exists, and it _should_ exist
  // but what if it doesn't?
  const identity = logForwardingIdentities.find(id => id.region === region && id.tier === tier);
  const serviceAccountEmail = identity
    ? (identity as StackdriverIdentity).serviceAccountEmail
    : "Couldn't find a matching service account email!";

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleTierChange = (option: SelectOption<Tier>) => setTier(option.value);
  const handleRegionChange = (option: SelectOption<string>) => setRegion(option.value);
  const handleGcpProjectIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setGcpProjectId(value);

    if (gcpProjectIdValidationError) {
      setGcpProjectIdValidationError(validateGcpProjectId(value));
    }
  };

  const handleGcpProjectIdBlur = () => {
    setGcpProjectIdValidationError(validateGcpProjectId(gcpProjectId));
  };

  const handleNextStep = () => {
    setStep(1);
  };

  const handleCreate = () => {
    const payload: CreateLogForwardingPayload = {
      destination: {
        type: DestinationType.STACKDRIVER,
        gcpProjectId,
      },
      logType: LogType.SECURITY,
      name,
      region,
      tier,
      tenantId: tenant.id,
    };
    onCreate(payload);
  };

  const isValidPayload =
    !!gcpProjectId && !!region && !!name.trim() && !gcpProjectIdValidationError;

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>New log forwarding process</Dialog.Header>
      {step === -1 && <DisclaimerDialogStep onAccept={() => setStep(0)} onDecline={onClose} />}
      {step === 0 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            <TextInput
              label="Name"
              value={name}
              onChange={handleNameChange}
              onBlur={trimName}
              placeholder="Process name"
              fluid
              data-testid="log-forwarding-name-input"
            />
            <div>
              <p className="tw-mb-2">Logs to forward:</p>
              <Radio checked readOnly label="Security logs" />
            </div>
            {hasReachedProcessLimit(region, tier) && (
              <Banner
                icon
                type="warning"
                description="The selected product and region already has a log forwarding process defined"
              />
            )}
            <FormSelect
              label="Product"
              options={tierOptions}
              value={tier}
              onChange={handleTierChange}
              data-testid="log-forwarding-product-select"
            />
            <FormSelect
              label="Region"
              onChange={handleRegionChange}
              options={regionOptions}
              value={region}
              formatOptionLabel={formatOptionLabel}
              helpText="All future instances' logs in the selected region will be forwarded as part of this process."
              data-testid="log-forwarding-region-select"
            />
            <TextInput
              label="Target GCP Project ID"
              value={gcpProjectId}
              onChange={handleGcpProjectIdChange}
              onBlur={handleGcpProjectIdBlur}
              placeholder="your-project-id"
              helpText='Your project ID will be listed next to the project name in parentheses as "Project ID: your-project-id"'
              fluid
              data-testid="log-forwarding-gcp-project-id-input"
              errorText={gcpProjectIdValidationError}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!isValidPayload || hasReachedProcessLimit(region, tier)}
              color="primary"
              onClick={handleNextStep}
              data-testid="log-forwarding-next-step"
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 1 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            {error && <Alert type="danger">{String(error)}</Alert>}
            <p>
              Please grant our service account <strong>log writer role</strong> in your GCP project.
              Our service account is:
            </p>
            <SingleLineCodeBlock
              code={serviceAccountEmail}
              copyToClipboard
              data-testid="log-forwarding-identity"
            />
            <p>Follow these steps:</p>
            <ol className="tw-list-decimal tw-pl-6 tw-my-6">
              <li>Go to the Google Cloud Console and select your project.</li>
              <li>
                Click on the &quot;Navigation menu&quot; button (☰) on the top-left corner of the
                console and select &quot;IAM & Admin&quot; &gt; &quot;IAM&quot;.
              </li>
              <li>
                Click on the &quot;+ Grant Access&quot; button at the top of the page to add a new
                principal to your project&apos;s IAM policy.
              </li>
              <li>
                In the &quot;New principals&quot; field, enter the email address of the service
                account you want to grant the &quot;Logs Writer&quot; role to. In this case, it
                should be <strong>{serviceAccountEmail}</strong>.
              </li>
              <li>
                In the &quot;Role&quot; field, start typing &quot;Logs Writer&quot; and select the
                &quot;Logs Writer&quot; role from the dropdown that appears.
              </li>
              <li>
                Click on the &quot;Save&quot; button to grant the role to the service account.
              </li>
            </ol>

            <Alert type="info">
              This process can take up to five minutes. You can check the status on this page.
            </Alert>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={() => setStep(0)}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              loading={loading}
              data-testid="log-forwarding-create"
            >
              Create process
            </Button>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};
