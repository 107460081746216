import ApiClient from 'remote/api-client';
import { LogFile } from 'types/log';

const listLogs = dbid => ApiClient.get(`/databases/${dbid}/logs`).promise();

const requestLog = (dbid: string, start: string, end: string, files: LogFile[]) =>
  ApiClient.post(`/databases/${dbid}/logs`)
    .issue({
      start,
      end,
      files,
    })
    .ignoreAllErrors()
    .promise();

const getDownloadLink = (dbid: string, logId: string) =>
  ApiClient.get(`/databases/${dbid}/logs/${logId}`).promise();

export default { listLogs, requestLog, getDownloadLink };
