import React, { useState } from 'react';
import { FormInput, Button, Form } from 'foundation';
import Actions from 'actions';

const LookupOrganizationForm = ({ ...rest }) => {
  const [organizationId, setOrganizationId] = useState('');

  const handleOrganizationIdChange = ({ target: { value } }) => setOrganizationId(value);

  const handleSubmit = () => {
    Actions.navigate.push({ hash: `#admin/organizations/${organizationId}` });
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">
        This form enables you to find an organization by its UUID, such as{' '}
        <code>&apos;4a03fc7d-5749-4a7f-8bbf-0d71bee08d5e&apos;</code>.
      </p>
      <p className="tw-mb-2">Only exact UUID matches can be found.</p>

      <Form onSubmit={handleSubmit} className="tw-mt-8">
        <FormInput
          fluid
          value={organizationId}
          onChange={handleOrganizationIdChange}
          label="Organization UUID"
          data-testid="lookup-organization-by-id-input"
          helpText="The exact UUID of a organization"
        />
        <Button
          onClick={handleSubmit}
          className="tw-mt-8"
          type="submit"
          color="primary"
          disabled={organizationId === ''}
          data-testid="lookup-organization-by-id-button"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default LookupOrganizationForm;
