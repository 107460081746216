import globals from 'browser/globals';
import location from 'browser/location';

export default () => {
  const query = location.search;
  const hasPunchoutParams =
    query && query.includes && query.includes('user_id=') && query.includes('project_id=');
  if (!hasPunchoutParams) {
    return {};
  }
  const params = new globals.URLSearchParams(query.substring(1));
  return {
    partnerAccount: {
      userId: params.get('user_id'),
      projectId: params.get('project_id'),
    },
  };
};
