import 'static/css/console.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Actions from 'actions';
import ErrorPage from 'pages/error';
import Controller from './controller'; // eslint-disable-line import/no-named-as-default
import { useDataFetching } from './data-fetcher';
import { StoreContext, useSession, store } from 'store';
import { LoadingSpinner, NeedleThemeProvider } from 'foundation';
import NotificationsToast from 'application/notifications-toast';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { buildApiClient } from 'remote/api-client/api-client';
import * as Sentry from '@sentry/react';
import { hrefFromLocation, locationFromUrl } from 'state/navigation';
import { NavigationStore, SessionStore } from 'state';
import { setProductState } from 'actions/product-actions';
import { findProductByValue } from 'types/product';
import { parse } from 'query-string';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDarkTheme } from 'utils/hooks';
import cx from 'classnames';
import logger from 'logger';

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(false);

  const handleError = useCallback((errorArg, componentStack) => {
    setError(true);

    Actions.errors.report('Error while rendering the user interface', errorArg, componentStack);
  }, []);

  if (error) {
    return <ErrorPage />;
  }

  return <Sentry.ErrorBoundary onError={handleError}>{children}</Sentry.ErrorBoundary>;
};

/**
 * The app component handles all site wide subscriptions
 * and initialization.
 */
export const App = () => {
  const [currState, setStoreState] = useState(() => store.getState()); // eslint-disable-line
  const isDarkTheme = useDarkTheme();

  const handleExternalLocationChange = useCallback(() => {
    const existingHref = hrefFromLocation(NavigationStore.state.location);
    if (location.href !== existingHref) {
      const existingSearch = parse(NavigationStore.state.location.search);
      const nextSearch = parse(location.search);
      if (existingSearch.product !== nextSearch.product) {
        const product = findProductByValue(nextSearch.product as string);
        setProductState(product);
      }
      NavigationStore.setLocation(locationFromUrl(location.href));
    }
  }, []);

  const handleInternalLocationChange = useCallback(locationState => {
    const searchProduct = parse(locationState.search).product;
    const storeProduct = SessionStore.state.product;
    if (searchProduct !== storeProduct) {
      const product = findProductByValue(searchProduct as string);
      setProductState(product);
    }
  }, []);

  const defaultErrorHandler = useDefaultErrorHandler(true);

  // Constructor
  useMemo(() => {
    // Init api client
    buildApiClient('', defaultErrorHandler);

    store.subscribe(state => {
      if (state.navigation.location !== currState.navigation.location) {
        handleInternalLocationChange(state.navigation.location);
      }

      setStoreState(state);
    });
  }, []);

  useEffect(() => {
    // On init
    Actions.navigate.watch(handleExternalLocationChange);
    Actions.userTracking.checkMixPanelUser();
    Actions.analytics();

    // On deinit
    return () => {
      Actions.navigate.stopWatching(handleExternalLocationChange);
      store.stopListening();
    };
  }, []);

  return (
    <StoreContext.Provider value={store}>
      <NeedleThemeProvider theme={isDarkTheme ? 'dark' : 'light'}>
        <Content />
      </NeedleThemeProvider>
    </StoreContext.Provider>
  );
};

interface Props {
  client: QueryClient;
}

/**
 * This first component to be rendered. Ensures
 * that all errors are handled by the error boundary
 */
const Root = ({ client }: Props) => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

const InitialAppLoader = () => {
  const isDarkTheme = useDarkTheme();
  const classes = cx(
    'tw-flex tw-justify-center tw-items-center tw-flex-col tw-h-full tw-bg-palette-neutral-bg-weak',
    {
      'ndl-theme-dark': isDarkTheme,
      'ndl-theme-light': !isDarkTheme,
    }
  );
  return (
    <div className={classes}>
      <LoadingSpinner size="large" />
      Loading...
    </div>
  );
};

const withInitialAuthCredCheck = Component => {
  const Func = props => {
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
      Actions.auth.attemptCachedLogin();
      setAuthChecked(true);
    }, []);

    if (!authChecked) return <InitialAppLoader />;

    return <Component {...props} />;
  };

  Func.displayName = Component.displayName || Component.name;
  return Func;
};

const withInitialDataFetch = Component => {
  const Func = props => {
    const { loading } = useDataFetching();

    if (loading) return <InitialAppLoader />;

    return <Component {...props} />;
  };
  Func.displayName = Component.displayName || Component.name;

  return Func;
};

const ControllerWithDataFetch = withInitialDataFetch(Controller);

/**
 * The content component handles initial data fetches and validation.
 * These data checks should eventually move to the router.
 */
const Content = withInitialAuthCredCheck(function Content() {
  const { loggedIn, emailVerificationRequired, accountToLink } = useSession();

  let Component = ControllerWithDataFetch;
  if (!loggedIn) {
    Component = Controller;
    logger.debug('Skipping data fetch due to not logged in');
  } else if (emailVerificationRequired) {
    Component = Controller;
    logger.debug('Skipping data fetch due to email verification required');
  } else if (accountToLink) {
    Component = Controller;
    logger.debug('Skipping data fetch due to account linking needed');
  }

  return (
    <>
      <NotificationsToast />
      <Component />
    </>
  );
});

export default Root;
