import React, { useState, useEffect } from 'react';

import SsoConfigsTable from './sso-config';
import CreateSsoConfigModal from './sso-config-create-modal';

import { Alert, LoadingSpinner, Button, TextLink } from 'components/foundation';
import { useOrganizationSsoConfigsQuery } from 'remote/resources/sso-configs';
import { useOrganizationQuery } from 'remote/resources/organizations';
import { searchOrgTenants, useOrganizationTenantsQuery } from 'remote/resources/tenants';
import ssoConfigPlaceholder from 'components/ui/images/sso_config_placeholder.png';
import { SsoConfigFormAction, EnrichedSsoConfig } from '../types';
import DeleteSsoConfigModal from './sso-config-delete-modal';
import EditSsoConfigModal from './sso-config-edit-modal';
import { Organization } from 'types/organization';
import { OrgLoginMethods } from './sso-config-login-methods';
import { Channel } from 'types/sso-config';
import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import { Tenant } from 'entities/tenant';
import { Tier } from 'entities/database';

export interface FormActionData {
  action: SsoConfigFormAction;
  data: EnrichedSsoConfig;
}

const SsoConfigGuard = ({ component: Component, organizationId }) => {
  const ssoConfigsQuery = useOrganizationSsoConfigsQuery(organizationId);
  const organizationQuery = useOrganizationQuery(organizationId);
  const tenantsQuery = useOrganizationTenantsQuery(organizationId);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ssoConfigs, setSsoConfigs] = useState<EnrichedSsoConfig[]>([]);
  const [isUpdatingSsoConfigs, setIsUpdatingSsoConfigs] = useState(true);

  useEffect(() => {
    const fetchTenantsAndUpdateConfigs = async () => {
      if (
        !ssoConfigsQuery.isLoading &&
        !organizationQuery.isLoading &&
        !tenantsQuery.isLoading &&
        ssoConfigsQuery.data &&
        !ssoConfigsQuery.isError &&
        !tenantsQuery.isError &&
        !organizationQuery.isError &&
        isUpdatingSsoConfigs
      ) {
        setIsLoading(true);
        try {
          const updatedConfigs: EnrichedSsoConfig[] = await Promise.all(
            ssoConfigsQuery.data.map(async config => {
              const tenants = (await searchOrgTenants(organizationId, config.ssoConfigId)) ?? [];
              return {
                ...config,
                linkedTenants: tenants.map(tenant => ({
                  tenantId: tenant.id,
                  tenantName: tenant.name,
                })),
              };
            })
          );
          setSsoConfigs(updatedConfigs);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
          setIsUpdatingSsoConfigs(false);
        }
      }
    };

    fetchTenantsAndUpdateConfigs();
  }, [
    ssoConfigsQuery.data,
    organizationQuery.data,
    tenantsQuery.data,
    organizationId,
    ssoConfigsQuery.isLoading,
    ssoConfigsQuery.isError,
    organizationQuery.isLoading,
    organizationQuery.isError,
    tenantsQuery.isLoading,
    tenantsQuery.isError,
    isUpdatingSsoConfigs,
  ]);

  if (
    isLoading ||
    ssoConfigsQuery.isLoading ||
    organizationQuery.isLoading ||
    tenantsQuery.isLoading
  ) {
    return <LoadingSpinner minHeight={300} />;
  }

  if (error || ssoConfigsQuery.isError || organizationQuery.isError || tenantsQuery.isLoading) {
    return <Alert type="danger" description="Error fetching data. Please reload the page." />;
  }

  return (
    <Component
      organization={organizationQuery.data}
      ssoConfigs={ssoConfigs}
      tenants={tenantsQuery.data}
      refetchOrganization={organizationQuery.refetch}
      refetchSsoConfigs={ssoConfigsQuery.refetch}
      setIsUpdatingSsoConfigs={setIsUpdatingSsoConfigs}
    />
  );
};

interface SsoConfigBaseProps {
  organization: Organization;
  ssoConfigs: EnrichedSsoConfig[];
  tenants: Tenant[];
  refetchOrganization: () => void;
  refetchSsoConfigs: () => void;
  setIsUpdatingSsoConfigs: (x: boolean) => void;
}

export const SsoConfigBase = ({
  organization,
  ssoConfigs,
  tenants,
  refetchOrganization,
  refetchSsoConfigs,
  setIsUpdatingSsoConfigs,
}: SsoConfigBaseProps) => {
  const [formActionData, setFormActionData] = useState<FormActionData>(null);
  const hasFrontChannel = ssoConfigs.some(ssoConfig => ssoConfig.channel === Channel.FRONT);
  const loginWithOrgLink = `${location.origin}?org_id=${organization.id}&autosubmit=true#organization-lookup`;
  const allowedTiers = [Tier.ENTERPRISE, Tier.MTE];
  const IsSSOAllowedForTenant = (tenant: Tenant): boolean => {
    const tenantTiers = Object.keys(tenant.tierConfigs) as Tier[];
    return tenantTiers.some(tier => allowedTiers.includes(tier));
  };
  const isSSOAllowed = tenants.some(tenant => IsSSOAllowedForTenant(tenant));

  return (
    <div className="tw-mt-5 tw-h-full" data-testid="organization-sso-config">
      <div className="tw-justify-between tw-flex-nowrap tw-mb-2">
        <div className="tw-flex tw-justify-between tw-mb-2">
          <h4>Single Sign-Ons</h4>
          {ssoConfigs?.length > 0 && (
            <Button
              style={{ left: '305px' }}
              data-testid="create-new-sso-config-1"
              onClick={() => {
                setFormActionData({ action: SsoConfigFormAction.CREATE, data: null });
              }}
            >
              Create
            </Button>
          )}
        </div>
        {organization?.ssoOrgId ? (
          <KeyPairSection>
            <KeyPairItem label="Organization SSO login" href={loginWithOrgLink}>
              {loginWithOrgLink}
            </KeyPairItem>
          </KeyPairSection>
        ) : null}
        {ssoConfigs?.length > 0 ? (
          <OrgLoginMethods
            organization={organization}
            disabled={ssoConfigs?.length === 0}
            refetchOrganization={() => refetchOrganization()}
          />
        ) : null}
      </div>
      <div className="tw-h-full">
        {ssoConfigs?.length > 0 ? (
          <div>
            {hasFrontChannel && (
              <Alert type="warning" data-testid="estimation-error" className="tw-mt-2 tw-mb-2">
                You have sso configurations that are managed by Aura Support, please reach out{' '}
                <TextLink externalLink href="https://support.neo4j.com/s/">
                  here
                </TextLink>{' '}
                to update them.
              </Alert>
            )}
            <SsoConfigsTable
              ssoConfigs={ssoConfigs}
              setFormActionData={setFormActionData}
              isSSOAllowed={isSSOAllowed}
            />
          </div>
        ) : (
          <div
            className="tw-h-full"
            style={{
              background: `url(${ssoConfigPlaceholder}) no-repeat center center / cover fixed`,
            }}
          >
            <div className="tw-flex tw-justify-center tw-items-end" style={{ height: '83%' }}>
              <Button
                data-testid="create-new-sso-config"
                onClick={() => {
                  setFormActionData({ action: SsoConfigFormAction.CREATE, data: null });
                }}
                disabled={!isSSOAllowed}
              >
                SSO Configuration
              </Button>
            </div>
          </div>
        )}
        {formActionData?.action === SsoConfigFormAction.CREATE && (
          <CreateSsoConfigModal
            open={true}
            closeModal={() => {
              setFormActionData(null);
            }}
            saveAndClose={() => {
              setFormActionData(null);
              refetchSsoConfigs();
              refetchOrganization();
              setIsUpdatingSsoConfigs(true);
            }}
            organizationId={organization.id}
            isSSOAllowed={isSSOAllowed}
          />
        )}
        {formActionData?.action === SsoConfigFormAction.DELETE && (
          <DeleteSsoConfigModal
            open={true}
            closeModal={() => {
              setFormActionData(null);
            }}
            saveAndClose={() => {
              setFormActionData(null);
              refetchSsoConfigs();
              refetchOrganization();
              setIsUpdatingSsoConfigs(true);
            }}
            formAction={formActionData}
          />
        )}
        {formActionData?.action === SsoConfigFormAction.UPDATE && (
          <EditSsoConfigModal
            closeModal={() => {
              setFormActionData(null);
            }}
            saveAndClose={() => {
              setFormActionData(null);
              setIsUpdatingSsoConfigs(true);
              refetchSsoConfigs();
              refetchOrganization();
            }}
            formAction={formActionData}
            isSSOAllowed={isSSOAllowed}
          />
        )}
      </div>
    </div>
  );
};

const WrappedSsoConfigGuard = ({ organizationId }) => {
  return <SsoConfigGuard component={SsoConfigBase} organizationId={organizationId} />;
};

export default WrappedSsoConfigGuard;
