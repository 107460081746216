import {
  Alert,
  Button,
  ConfirmModal,
  createColumnHelper,
  IconButton,
  Label,
  StickyActionsDataGrid,
  useDefaultTable,
} from 'components/foundation';
import { ConfirmAction } from 'components/foundation/confirm-modal';
import Icon from 'components/ui/icons';
import { CloudProvider, Database } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import React, { useMemo, useState } from 'react';
import { useDefaultErrorHandler } from 'remote/error-handler';
import TrafficConfigResources from 'remote/resources/traffic-configs';
import { productFriendlyName } from 'types/product';
import { TrafficConfig, TrafficEnablement, TrafficStatus } from 'types/traffic-configs';
import { getRegionsForAllCsps } from 'utils/tiers-and-regions';
import { TrafficConfigDialog } from './dialog';
import { useTracking } from 'react-tracking';

const columnHelper = createColumnHelper<TrafficConfig>();
const INTERMEDIATE_STATUS = [
  TrafficStatus.CREATING,
  TrafficStatus.UPDATING,
  TrafficStatus.DELETING,
];

const TrafficEnablementLabel = ({
  value,
  status,
  desiredStatus,
}: {
  value: TrafficEnablement;
  status: TrafficStatus;
  desiredStatus: TrafficStatus;
}) => {
  let color: 'default' | 'warning' | 'success' = 'default';
  let displayValue: TrafficEnablement | TrafficStatus = value;
  let fill: React.ComponentProps<typeof Label>['fill'] =
    desiredStatus === status ? 'semi-filled' : 'clean';
  color = desiredStatus === status ? 'success' : 'warning';

  if (INTERMEDIATE_STATUS.includes(status)) {
    color = 'warning';
    displayValue = status;
  } else if (status === TrafficStatus.RUNNING) {
    // Override display value to say enabled as long as
    // status is still running, otherwise we get "Disabled"
    // but status is running so it has the success label
    displayValue = TrafficEnablement.ENABLED;
    fill = 'semi-filled';
  }

  return (
    <Label fill={fill} color={color} withIcon>
      {displayValue === TrafficStatus.DELETING ? 'Disabling' : displayValue}
    </Label>
  );
};

interface StatusComponentProps {
  databases: Database[];
  trafficConfig: TrafficConfig;
  tenant: Tenant;
  onUpdate: (c: TrafficConfig) => void;
  onDelete: (c: TrafficConfig) => void;
}

const StatusComponent = ({
  trafficConfig,
  databases,
  tenant,
  onUpdate,
  onDelete,
}: StatusComponentProps) => {
  const product = getProductFromTier(trafficConfig.tier);
  const productName = productFriendlyName(product);
  const [editOpen, setEditOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tracking = useTracking();
  const defaultErrorHandler = useDefaultErrorHandler();
  const affectedDatabases = databases.filter(
    db => db.Region === trafficConfig.region && db.Tier === trafficConfig.tier
  );

  const handleConfigureClick = () => {
    tracking.trackEvent({
      action: 'network_access_configure',
      properties: { event_label: 'clicked' },
    });
    setEditOpen(true);
  };
  const handleClose = () => {
    onUpdate(trafficConfig);
    setEditOpen(false);
  };

  const handleDelete = () => setConfirmOpen(true);
  const handleConfirmCancel = () => setConfirmOpen(false);

  const handleDeleteConfirm = async () => {
    setLoading(true);

    // This wont actually take effect in the first version,
    // the infra api needs to be updated to support this
    let propertiesToDelete = {};
    if (trafficConfig.cloudProvider === CloudProvider.GCP) {
      propertiesToDelete = { gcpProperties: { projectIds: [] } };
    } else if (trafficConfig.cloudProvider === CloudProvider.AWS) {
      propertiesToDelete = { awsProperties: { endpointIds: [] } };
    } else if (trafficConfig.cloudProvider === CloudProvider.AZURE) {
      propertiesToDelete = { azureProperties: { subscriptionIds: [], endpointIds: [] } };
    }

    try {
      await TrafficConfigResources.update(tenant.id, trafficConfig.tier, trafficConfig.region, {
        privateTraffic: TrafficEnablement.DISABLED,
        publicTraffic: TrafficEnablement.ENABLED,
        ...propertiesToDelete,
      });
      tracking.trackEvent({
        action: 'delete_network_access_configuration',
        properties: { event_label: 'clicked' },
      });
      onDelete(trafficConfig);
      setConfirmOpen(false);
    } catch (e) {
      defaultErrorHandler(e);
    }

    setLoading(false);
  };

  return (
    <div className="tw-flex tw-align-center tw-justify-between tw-gap-2">
      <Button
        onClick={handleConfigureClick}
        fill="outlined"
        iconName="Cog8ToothIconOutline"
        disabled={loading}
      >
        Configure
      </Button>
      <IconButton
        className="tw-ml-1"
        iconName="TrashIconOutline"
        title="Delete network config"
        aria-label="Delete config"
        danger
        onClick={handleDelete}
        disabled={trafficConfig.status.privateTraffic === TrafficStatus.DELETING}
        data-testid="delete-network-config"
      />
      {editOpen && (
        <TrafficConfigDialog
          tenant={tenant}
          trafficConfig={trafficConfig}
          title="Edit network access configuration"
          onClose={handleClose}
          onSuccess={onUpdate}
          databases={databases}
        />
      )}
      <ConfirmModal
        negative
        open={confirmOpen}
        title="Remove network config?"
        onCancel={handleConfirmCancel}
        onConfirm={handleDeleteConfirm}
        confirmText="CONFIRM"
        action={ConfirmAction.DELETE}
        loading={loading}
        content={
          <div>
            <p>Removing this network config will:</p>
            <ul className="tw-list-disc tw-my-3 tw-ml-6">
              <li>
                Enable public traffic for ALL <strong>{productName}</strong> instances in region{' '}
                <strong>{trafficConfig.region}</strong>
              </li>
              <li>
                Disable traffic over the private connection for ALL <strong>{productName}</strong>{' '}
                instances in region <strong>{trafficConfig.region}</strong>
              </li>
              <li>
                Prevent access over the private connection URI (
                <code>{`<dbid>.${trafficConfig.status.dnsDomain}`}</code>)
              </li>
            </ul>
            <Alert
              icon
              type="warning"
              title="Update application connection URI"
              description={
                <>
                  <p>
                    Your applications will lose access to their instance if they are using the
                    private connnection URI (
                    <code>{`<dbid>.${trafficConfig.status.dnsDomain}`}</code>). Enable public
                    traffic and switch to the public connection URI before removing the network
                    config.
                  </p>
                  {affectedDatabases.length > 0 && (
                    <div className="tw-mt-2">
                      The following databases will be affected:
                      <ul className="tw-list-disc tw-mt-1 tw-list-inside tw-ml-4">
                        {affectedDatabases.map(db => (
                          <li key={db.DbId} className="n-label">
                            {db.Name} - {db.DbId}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              }
            />
          </div>
        }
      />
    </div>
  );
};

const TableComponents = { Navigation: null };

export const TrafficConfigsTable = ({
  tenant,
  configs,
  onUpdate,
  onDelete,
  databases,
}: {
  tenant: Tenant;
  configs: TrafficConfig[];
  onUpdate: (c: TrafficConfig) => void;
  onDelete: (c: TrafficConfig) => void;
  databases: Database[];
}) => {
  const regions = useMemo(() => getRegionsForAllCsps(tenant.providerConfigs), [
    tenant.providerConfigs,
  ]);
  const columns = useMemo(
    () => [
      columnHelper.accessor('region', {
        cell: c => {
          const trafficConfig = c.row.original;
          const isProtected =
            trafficConfig.status.privateTraffic === TrafficStatus.RUNNING &&
            trafficConfig.status.publicTraffic === TrafficStatus.DELETED;
          const value = c.getValue();
          const region = regions.find(r => r.name === value);
          return (
            <span className="tw-relative">
              {isProtected && (
                <Icon
                  name="ShieldCheckIconOutline"
                  aria-label="Traffic protected"
                  title="Traffic protected"
                  height="20px"
                  className="tw-absolute tw-text-color-secondary tw-text-color"
                  style={{ left: '-15px' }}
                />
              )}
              <span className="n-subheading-medium tw-whitespace-nowrap tw-ml-2">
                {region.friendly}
              </span>
            </span>
          );
        },
        header: 'Region',
        minSize: 300,
      }),
      columnHelper.accessor('tier', {
        cell: c => {
          const product = getProductFromTier(c.getValue());
          const value = productFriendlyName(product);
          return <span className="n-subheading-medium">{value}</span>;
        },
        header: 'Product',
      }),
      columnHelper.accessor('privateTraffic', {
        cell: c => {
          const value = c.getValue();
          const trafficConfig = c.row.original;
          return (
            <TrafficEnablementLabel
              value={value}
              status={trafficConfig.status.privateTraffic}
              desiredStatus={TrafficStatus.RUNNING}
            />
          );
        },
        header: 'Private traffic',
      }),
      columnHelper.accessor('publicTraffic', {
        cell: c => {
          const value = c.getValue();
          const trafficConfig = c.row.original;
          return (
            <TrafficEnablementLabel
              value={value}
              status={trafficConfig.status.publicTraffic}
              desiredStatus={TrafficStatus.DELETED}
            />
          );
        },
        header: 'Public traffic',
      }),
      columnHelper.display({
        id: 'status',
        cell: (c): React.ReactNode => {
          const trafficConfig = c.row.original;
          return (
            <StatusComponent
              trafficConfig={trafficConfig}
              databases={databases}
              onUpdate={onUpdate}
              onDelete={onDelete}
              tenant={tenant}
            />
          );
        },
        meta: {
          isStickyAction: true,
        },
        size: 240,
      }),
    ],
    [tenant, regions]
  );

  const table = useDefaultTable({
    data: configs,
    columns,
  });

  return (
    <div
      className="tw-p-8 tw-rounded-lg tw-bg-palette-neutral-bg-weak"
      data-testid="console-traffic-config-table"
    >
      <StickyActionsDataGrid tableInstance={table} components={{ TableComponents }} />
    </div>
  );
};
