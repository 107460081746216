import React, { useState } from 'react';
import { FormInput, Button, Form } from 'foundation';
import Actions from 'actions';

export const LookupTenantForm = ({ ...rest }) => {
  const [tenantId, setTenantId] = useState('');

  const handleTenantIdChange = ({ target: { value } }) => setTenantId(value);

  const handleSubmit = () => {
    Actions.navigate.push({ hash: `#admin/projects/${tenantId}/users` });
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">
        This form enables you to find a project by its UUID, such as{' '}
        <code>&apos;4a03fc7d-5749-4a7f-8bbf-0d71bee08d5e&apos;</code>.
      </p>
      <p className="tw-mb-2">Only exact UUID matches can be found.</p>

      <Form onSubmit={handleSubmit} className="tw-mt-8">
        <FormInput
          fluid
          value={tenantId}
          onChange={handleTenantIdChange}
          aria-label="Project id"
          data-testid="lookup-tenant-by-id-input"
          helpText="The exact UUID of a tenant"
        />
        <Button
          onClick={handleSubmit}
          className="tw-mt-8"
          type="submit"
          color="primary"
          disabled={tenantId === ''}
          data-testid="lookup-tenant-by-id-button"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};
