import React, { useState } from 'react';
import { Tabs, Page } from 'foundation';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import SearchOrganizationsForm from './search-organizations-form';
import LookupOrganizationForm from './lookup-organization-form';

enum TabId {
  Name = 'name',
  OrganizationId = 'organization-id',
}

export const LookupOrganizationPage = () => {
  const permission = usePermissions();
  const canReadOrganizations = permission.allow(Action.READ, 'organizations/*');
  const [selectedTabId, setSelectedTabId] = useState(TabId.Name);

  return (
    <Page fullWidth>
      {canReadOrganizations ? (
        <>
          <h5 className="tw-mb-4" data-testid="lookup-tenant-page-header">
            Lookup Organization(s)
          </h5>

          <Tabs<TabId> className="tw-mb-8" value={selectedTabId} onChange={setSelectedTabId}>
            <Tabs.Tab tabId={TabId.Name}>Internal Name</Tabs.Tab>
            <Tabs.Tab tabId={TabId.OrganizationId}>Organization UUID</Tabs.Tab>
          </Tabs>

          <Tabs.TabPanel tabId={TabId.Name} value={selectedTabId} data-testid="search-name-page">
            <SearchOrganizationsForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel
            tabId={TabId.OrganizationId}
            value={selectedTabId}
            data-testid="search-uuid-page"
          >
            <LookupOrganizationForm />
          </Tabs.TabPanel>
        </>
      ) : (
        <h5 data-testid="authorize-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default LookupOrganizationPage;
