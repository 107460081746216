import { format, getHours, getMinutes } from 'date-fns';
import { dateFromUnixTimestamp } from 'utils/date-time';

const formatDateRange = (from: Date, to: Date): string => {
  if (from.getFullYear() !== to.getFullYear()) {
    // January 1st 2020 - February 2nd 2021
    return `${format(from, 'MMMM do y')} - ${format(to, 'MMMM do y')}`;
  }
  if (from.getMonth() !== to.getMonth()) {
    // January 1st - February 2nd, 2020
    return `${format(from, 'MMMM do')} - ${format(to, 'MMMM do, y')}`;
  }
  if (from.getDate() !== to.getDate()) {
    // January 1st - 2nd, 2020
    return `${format(from, 'MMMM do')} - ${format(to, 'do, y')}`;
  }
  // January 1st, 2020
  return `${format(from, 'MMMM do, y')}`;
};

const xAxisFormatter = (timestamp: number | string) => {
  const date = dateFromUnixTimestamp(timestamp);

  if (getHours(date) === 0 && getMinutes(date) <= 5) {
    // Jan 1
    return format(date, 'MMM d');
  }
  // 22:59
  return format(date, 'H:mm');
};

interface Metrics {
  Charts?: {
    TimeSeries?: {
      DataPoints?: { Timestamp: number | string }[];
    }[];
  }[];
}

const buildDateRange = (metrics: Metrics = {}): string => {
  const hasRequiredValues = Boolean(metrics?.Charts?.[0]?.TimeSeries[0]?.DataPoints?.[0]);

  if (hasRequiredValues) {
    const { DataPoints: dataPoints } = metrics.Charts[0].TimeSeries[0];
    const endDate = dateFromUnixTimestamp(dataPoints[dataPoints.length - 1].Timestamp);
    const startDate = dateFromUnixTimestamp(dataPoints[0].Timestamp);
    return formatDateRange(startDate, endDate);
  }

  return '';
};

const tooltipFormatter = (postFixValue, info) => {
  const timestamp = info[0].axisValue;
  let formattedDateTime;
  try {
    const time = dateFromUnixTimestamp(timestamp);
    formattedDateTime = format(time, 'MMM d - H:mm');
  } catch (e) {
    formattedDateTime = `${timestamp}`;
  }
  const a = info.map(({ marker, data }) => `${marker} ${data}${postFixValue}`);
  return `<p>${formattedDateTime}<br/>${a.join('<br />')}</p>`;
};

export { formatDateRange, xAxisFormatter, tooltipFormatter, buildDateRange };
