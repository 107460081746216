import { Database } from 'entities/database';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { validateYup, Validation } from 'utils/validation';
import * as yup from 'yup';
import customEndpointResources from 'remote/resources/custom-endpoints';
import { useDefaultErrorHandler } from 'remote/error-handler';
import logger from 'logger';
import { Dialog, FormSelect, TextInput, Button, Alert } from 'components/foundation';
import { useNotify } from 'state/notifications';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { formatOptionLabel } from '../shared';

const helpText =
  'May only contain lowercase letters (a-z), numbers (0-9) and hyphens (-), must begin with a letter and must not end with a hyphen.';

type CreateCustomEndpointData = {
  name: string;
  dbId: string;
};
const schema = yup.object({
  name: yup
    .string()
    .min(1)
    .max(30)
    .lowercase()
    .label('Custom endpoint')
    .required()
    .matches(/^$|^[a-z]$|^[a-z][a-z0-9-]*[a-z0-9]$/, { message: helpText })
    .strict(),
  dbId: yup
    .string()
    .required()
    .label('Instance'),
});

const validate = (data: CreateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  databases: Database[];
};

export const CreateCustomEndpointDialog = ({ onClose, databases }: Props) => {
  const defaultErrorHandler = useDefaultErrorHandler();
  const notify = useNotify();
  const [data, setData] = useState<CreateCustomEndpointData>({ name: '', dbId: '' });
  const [validationError, setValidationError] = useState<Validation<
    CreateCustomEndpointData
  > | null>(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const linkCustomEndpointAction = useMemo(() => {
    const selectedDatabase = databases.find(db => db.DbId === data.dbId);
    return selectedDatabase?.AvailableActions.link_custom_endpoint;
  }, [databases, data.dbId]);
  const linkCustomEndpointActionMessage =
    !linkCustomEndpointAction || linkCustomEndpointAction.enabled
      ? undefined
      : linkCustomEndpointAction.message;

  const handleSelectDatabase = (dbId?: string) => {
    setValidationError(null);
    setErrorMessage(null);
    setData(prev => ({ name: prev.name, dbId: dbId ?? '' }));
  };
  const handleNameChange = (name: string) => {
    setValidationError(null);
    setData(prev => ({ ...prev, name }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    const validation = validate(data);
    if (validation) {
      setValidationError(validation);
      setLoading(false);
      return;
    }
    setValidationError(null);

    customEndpointResources
      .create(data)
      .then(() => {
        onClose();
      })
      .catch((e: ApiClientRequestError) => {
        logger.error('Failed to create custom endpoint', e);
        if (e.reason === 'custom-endpoint-path-already-exists') {
          notify.error('Something unexpected went wrong. Please try again');
        } else {
          defaultErrorHandler(e);
        }
      })
      .finally(() => setLoading(false));
  };

  const options = databases.map(database => ({
    key: database.DbId,
    label: database.Name,
    value: database.DbId,
    description: database.DbId,
    isDisabled: !database.Capabilities.create_custom_endpoint.enabled,
    disabledReason: !database.Capabilities.create_custom_endpoint.enabled
      ? database.Capabilities.create_custom_endpoint.message
      : undefined,
  }));

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Create custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <TextInput
              label="Custom endpoint"
              onChange={event => handleNameChange(event.target.value)}
              errorText={validationError?.name?.message}
              value={data.name}
              helpText={helpText}
              fluid
              data-testid={validationError?.name ? 'name-input-with-validation-error' : undefined}
            />
          </div>
          <FormSelect
            label="Assign to instance"
            errorText={validationError?.dbId?.message ?? linkCustomEndpointActionMessage}
            value={options.find(item => item.key === data.dbId)}
            options={options}
            onChange={obj => handleSelectDatabase(obj?.value)}
            formatOptionLabel={formatOptionLabel}
            menuPosition="fixed"
            data-testid="select-instance"
          />
          <Alert
            icon
            type="info"
            description="It will take a short moment for the custom endpoint to be ready for use."
          />
          <Alert
            icon
            type="warning"
            description="You will not be able to configure the custom endpoint for the next 3 hours."
          />
          {errorMessage && <Alert type="danger" description={errorMessage} icon />}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={onClose} data-testid="cancel-button">
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            loading={loading}
            disabled={linkCustomEndpointAction && !linkCustomEndpointAction.enabled}
            data-testid="create-button"
          >
            Create
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
