export enum SnapshotProfile {
  SCHEDULED = 'Scheduled',
  RESCUE = 'Rescue',
  AD_HOC = 'AdHoc',
  LOAD = 'Load',
}

export enum SnapshotStatus {
  IN_PROGRESS = 'InProgress',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
  PENDING = 'Pending',
}

export enum SnapshotType {
  SYSTEM_BACKUP = 'SystemBackup',
  CUSTOMER_DATA = 'CustomerData',
}

export type Snapshot = {
  DbId: string;
  SnapshotId: string;
  Timestamp: string;
  Profile: SnapshotProfile;
  Status: SnapshotStatus;
  Type: SnapshotType;
  Exportable: boolean;
};
