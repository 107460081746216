import { Database, Tier } from 'entities/database';
import { TenantType } from 'entities/tenant';
import logger from 'logger';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SessionState } from 'state/session-store';
import { State, useStore } from './store';

const equals = (a, b) => a === b;

export const useSelector = <T = any>(
  selector: (state: State) => T,
  equalityFn: (a, b) => boolean = equals
): T => {
  const store = useStore();
  // This `selected` variable can become out of date. It should
  // ONLY be used for the currentSelectedState initial value,
  // but it needs to be a state variable so we can trigger
  // rerenders
  const [selected, setSelected] = useState<T>(() => selector(store.getState()));

  const prevSubscriber = useRef(null);
  const isMounted = useRef(true);

  // The true current state of the selector.
  // This variable can be updated in two ways.
  // 1. This selectors subscriber is called
  // 2. A parent component is rerendered first
  //    due to its subscriber being earlier in
  //    the stores subscriber list. Therefore
  //    when this component rerenders we get a new
  //    selected state during rerender
  const currentSelectedState = useRef(selected);

  // Create subscriber function that updates useState
  // whenever the selected state changes
  const subscriber = useCallback(
    (newState: State) => {
      // This case should never happen, but
      // we leave it here as a safe guard for
      // any issues we may introduce as the
      // codebase grows
      if (!isMounted.current) {
        logger.error('Subscriber called on unmounted component');
        return;
      }

      const newSelected = selector(newState);

      // We compare newSelected against the currentSelectedState
      // instead of `selected` because currentSelectedState
      // will get updated on rerender, meaning that if a parent
      // component rerenders after a state change and
      // currentSelectedState changes, then currentSelectedState will
      // be accurate and `selected` will be stale (rerenders don't set
      // `selected` to avoid unnecessary rerenders). So basically,
      // `selected` can be out of date, but currentSelectedState
      // will never be out of date so we use currentSelectedState here
      if (!equalityFn(currentSelectedState.current, newSelected)) {
        currentSelectedState.current = newSelected;
        setSelected(newSelected); // Triggers the component rerender
      }
    },
    [selector]
  );

  // Subscribe to the store with the subscriber
  // any time it changes. We subscribe
  // using useMemo because useMemo will run
  // its function as the component renders, not after
  // its done. Parent components need to subscribe
  // before child components, and since parent components
  // are run before child components, useMemo ensures that
  // parents subscriber before children. useEffect does NOT
  // give us this guarantee for some reason
  useMemo(() => {
    if (prevSubscriber.current) {
      store.replace(prevSubscriber.current, subscriber);
    } else {
      store.subscribe(subscriber);
    }

    if (prevSubscriber.current !== subscriber) {
      prevSubscriber.current = subscriber;
    }
  }, [subscriber]);

  // On destroy, unsubscribe instead of replace
  useEffect(
    () => () => {
      isMounted.current = false;
      store.unsubscribe(prevSubscriber.current);
    },
    []
  );

  // Always update currentSelectedState on every rerender so it is
  // up to date and components never render with stale state.
  // If the selector is expensive then the selector itself should
  // take advantage of memoization to avoid expensive operations
  currentSelectedState.current = selector(store.getState());

  return currentSelectedState.current;
};

export const selectSession = (state: State): SessionState => state.session;

export const selectDatabaseState = (state: State) => state.databases;

export const selectSnapshotsState = (state: State) => state.snapshots;

export const selectDatabaseSnapshotsFactory = (dbId: string) => (state: State) => {
  const snapshotState = selectSnapshotsState(state);
  return snapshotState.snapshots[dbId];
};

export const selectDatabaseFactory = (dbId: string) => (state: State): Database | undefined => {
  const dbState = selectDatabaseState(state);
  return dbState.databases.find(db => db.DbId === dbId);
};

export const selectBillingRequiredFactory = (tier?: Tier) => (state: State): boolean => {
  const session = selectSession(state);
  return (
    tier !== Tier.FREE &&
    session.tenant.tenantType === TenantType.PERSONAL &&
    session.tenant.requiresBilling &&
    !session.tenant.hasBilling
  );
};

export const selectNavigationState = (state: State) => state.navigation;
