import React, { useState } from 'react';
import {
  createColumnHelper,
  DataGrid,
  IconButton,
  Label,
  LoadingSpinner,
  useDefaultTable,
} from 'components/foundation';
import { Organization } from 'types/organization';
import {
  getOrganizationRoleOptions,
  OrganizationMember,
  organizationRoleToFriendlyName,
  RoleName,
} from 'types/user';
import OrganizationsResource from 'remote/resources/organizations';
import LoadError from 'components/application/load-error';
import EditUserModal from 'components/pages/user-management/modals/edit-user-modal';

const memberHeaderHelper = createColumnHelper<OrganizationMember>();

const OrganizationMembersGuard = (
  component: React.ComponentType<{
    members: OrganizationMember[];
    organization: Organization;
    onRefetchData?: () => void;
  }>
) => ({ organization }: { organization: Organization }) => {
  const membersQuery = OrganizationsResource.useOrganizationMembersQuery(organization.id); // eslint-disable-line

  if (membersQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  if (membersQuery.isError) {
    return <LoadError />;
  }
  const Component = component;
  return (
    <Component
      members={membersQuery.data}
      organization={organization}
      onRefetchData={membersQuery.refetch}
    />
  );
};

export const OrganizationMembersBase = ({
  organization,
  members,
  onRefetchData,
}: {
  members: OrganizationMember[];
  organization: Organization;
  onRefetchData?: () => void;
}) => {
  const [editedMember, setEditedMember] = useState<OrganizationMember>(null);
  const [editInProgress, setEditInProgress] = useState<boolean>(false);
  const [editErrorMessage, setEditErrorMessage] = useState<string | null>(null);

  const handleMemberRoleChange = async (member: OrganizationMember, newRoleName: RoleName) => {
    setEditInProgress(true);
    setEditErrorMessage(null);

    try {
      await OrganizationsResource.patchMemberRoles(organization.id, member.UserId, [newRoleName]);
      setEditedMember(null);
      onRefetchData?.();
    } catch (ex) {
      if (ex.response.status === 403) {
        setEditErrorMessage('You do not have permission to perform that action.');
      } else {
        setEditErrorMessage(ex.responseMessage);
      }
    } finally {
      setEditInProgress(false);
    }
  };

  const columns = [
    memberHeaderHelper.accessor('OrganizationId', {
      header: 'ID',
      minSize: 100,
    }),
    memberHeaderHelper.accessor('UserId', {
      header: 'UserId',
    }),
    memberHeaderHelper.accessor('Email', {
      header: 'Email',
    }),
    memberHeaderHelper.accessor('Roles', {
      cell: c => {
        // we assume only 1 role per organization member for now
        const role = c.row.original.Roles[0];
        const roleLabel = organizationRoleToFriendlyName(role.Name);
        const member: OrganizationMember = {
          OrganizationId: organization.id,
          UserId: c.row.original.UserId,
          Email: c.row.original.Email,
          Roles: [role],
        };
        return (
          <div className="tw-flex tw-flew-row tw-w-full tw-items-center">
            <Label
              fill="outlined"
              color="info"
              className="console-role-label"
              data-testid={`role-label-${member.UserId}`}
            >
              <div className="tw-truncate">{roleLabel}</div>
            </Label>
            <IconButton
              title="Edit Member"
              aria-label="Edit Member"
              iconName="PencilSquareIconOutline"
              onClick={() => setEditedMember(member)}
              clean
            />
          </div>
        );
      },
    }),
  ];

  const table = useDefaultTable({
    columns: columns,
    data: members,
  });

  const roleOptions = getOrganizationRoleOptions();
  return (
    <div>
      <DataGrid tableInstance={table} />
      {editedMember && (
        <EditUserModal
          open={editedMember !== null}
          user={editedMember}
          onClose={() => {
            setEditedMember(null);
            setEditErrorMessage(null);
            setEditInProgress(false);
          }}
          onEdit={handleMemberRoleChange}
          editInProgress={editInProgress}
          errorMessage={editErrorMessage}
          roleOptions={roleOptions}
        />
      )}
    </div>
  );
};

export const OrganizationMembers = OrganizationMembersGuard(OrganizationMembersBase);
