import { Alert, Breadcrumbs, Capitalization, Page, SideNavigation } from 'components/foundation';
import React from 'react';
import { SessionState } from 'state/session-store';
import { useLocation, useSession } from 'store';
import { UserManagement } from './user-management';
import SsoConfig from './sso-config';
import SummaryPage from './summary';
import { getHomePath } from 'components/utils';
import { useOrganizationQuery } from 'remote/resources/organizations';
import { useCanViewOrganizationMembers, useCanViewOrganizationSummary } from './helpers';
import { OrgPageSuffix, OrgPageSuffixTitles } from './types';
import { OrganizationCapabilityKeys } from 'types/organization';

const PageNotFound = () => {
  return <div>Page does not exist</div>;
};

const pageToRender = (organizationId, capabilities) => {
  const suffix = location.hash.split('/').at(-1);
  switch (suffix) {
    case OrgPageSuffix.USER_MANAGEMENT:
      return <UserManagement organizationId={organizationId} />;
    case OrgPageSuffix.SSO_CONFIG:
      return capabilities?.[OrganizationCapabilityKeys.ORGANIZATION_SSO] ? (
        <SsoConfig organizationId={organizationId} />
      ) : (
        <PageNotFound />
      );
    case OrgPageSuffix.SUMMARY:
      return <SummaryPage organizationId={organizationId} />;
    default:
      return <PageNotFound />;
  }
};

const getBreadcrumbs = (homePath, location, organization) => {
  if (!location || !organization) {
    return [];
  }

  const suffix = location.hash.split('/').at(-1);

  const breadcrumbs = [
    { title: 'Neo4j Aura', href: homePath },
    { title: organization.displayName, href: `#organizations/${organization.id}/${suffix}` },
    {
      title: OrgPageSuffixTitles[suffix],
      href: `#organizations/${organization.id}/${suffix}`,
      capitalization: Capitalization.NONE,
    },
  ];

  return breadcrumbs;
};

interface Props {
  organizationId: string;
}

const OrganizationSettingsPage = ({ organizationId }: Props) => {
  const location = useLocation();
  const session = useSession();
  const homePath = getHomePath(session);
  const organization = useOrganizationQuery(organizationId);

  const Component = pageToRender(organizationId, organization.data?.capabilities);
  return (
    <Page plain fullWidth className="tw-pl-0 tw-pr-0 tw-h-full">
      <div className="tw-flex tw-h-full tw-pl-16 lg:tw-pl-0">
        <SettingsSideNavigation session={session} organizationId={organizationId} />
        <div className="tw-w-full tw-relative tw-h-full" style={{ overflowX: 'clip' }}>
          <Breadcrumbs
            items={getBreadcrumbs(homePath, location, organization.data)}
            className="tw-absolute tw--top-7 tw-left-4"
          />
          {Component}
        </div>
        <div />
      </div>
    </Page>
  );
};

interface SettingsSideNavigationProps {
  session: SessionState;
  organizationId: string;
}

const SettingsSideNavigation = ({ organizationId }: SettingsSideNavigationProps) => {
  const location = useLocation();
  const organization = useOrganizationQuery(organizationId);
  const showSsoConfigSection =
    organization.data?.capabilities?.[OrganizationCapabilityKeys.ORGANIZATION_SSO];
  const displayForAdmins = useCanViewOrganizationMembers(organizationId);

  return (
    <div className="settings-sidenav ndl-side-navigation ndl-left ndl-expanded">
      <nav className="ndl-side-navigation-nav" data-testid="settings-sidenav">
        <SideNavigation.List className="tw-gap-1" data-testid="settings-sidenav-list">
          <p className="tw-text-palette-neutral-text-weaker tw-ml-2 tw-my-3">Settings</p>
          <SideNavigation.Item
            selected={[`#organizations/${organizationId}/summary`].includes(location.hash)}
            href={`#organizations/${organizationId}/summary`}
            data-testid="settings-side-nav-summary"
          >
            Summary
          </SideNavigation.Item>
          {displayForAdmins && (
            <SideNavigation.Item
              selected={[`#organizations/${organizationId}/user-management`].includes(
                location.hash
              )}
              href={`#organizations/${organizationId}/user-management`}
              data-testid="settings-side-nav-user-management"
            >
              User Management
            </SideNavigation.Item>
          )}
          {showSsoConfigSection && displayForAdmins && (
            <SideNavigation.Item
              selected={[`#organizations/${organizationId}/sso-config`].includes(location.hash)}
              href={`#organizations/${organizationId}/sso-config`}
              data-testid="settings-side-nav-sso-config"
            >
              Single Sign-On
            </SideNavigation.Item>
          )}
        </SideNavigation.List>
      </nav>
    </div>
  );
};

const QueryParamsGuard = ({ organizationId }: Props) => {
  // TODO: add guard for single-sign-on page when implemented
  // const canViewSso = allow(Action.READ, `organizations/${organizationId}/sso-configs`);

  if (!useCanViewOrganizationSummary(organizationId)) {
    return (
      <Alert
        type="danger"
        description="You do not have sufficient permissions to access this page."
      />
    );
  }

  return <OrganizationSettingsPage organizationId={organizationId} />;
};

export default QueryParamsGuard;
