import { CloudProvider, Tier } from 'entities/database';

export enum TrafficEnablement {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum TrafficStatus {
  CREATING = 'creating',
  UPDATING = 'updating',
  RUNNING = 'running',
  DELETING = 'deleting',
  DELETED = 'deleted',
}

export enum AwsEndpointConnectionState {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_ACCEPTANCE = 'pendingAcceptance',
  REJECTED = 'rejected',
  // The following connection types
  // are possible, but the infra traffic config
  // api doesn't use them atm
  // AVAILABLE = 'available',
  // DELETING = 'deleting',
  // DELETED = 'deleted',
  // FAILED = 'failed',
  // EXPIRED = 'expired',
}

export interface AwsEndpointConnection {
  endpointId: string;
  state: AwsEndpointConnectionState;
}

export interface GcpStatus {
  serviceAttachmentUrl: string;
}

export interface AwsStatus {
  endpointServiceName: string;
  endpointConnections: AwsEndpointConnection[];
}

export enum AzureEndpointConnectionState {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REMOVED = 'removed',
}

export interface AzureEndpointConnection {
  privateEndpointId: string;
  state: AzureEndpointConnectionState;
}

export interface AzureStatus {
  privateLinkServiceName: string;
  privateEndpointConnections: AzureEndpointConnection[];
}

export interface GcpProperties {
  projectIds: string[];
}

export interface AwsProperties {
  endpointIds: string[];
}

export interface AzureProperties {
  subscriptionIds: string[];
  endpointIds: string[];
}

export interface TrafficConfigStatus {
  publicTraffic: TrafficStatus;
  privateTraffic: TrafficStatus;
  dnsDomain: string;
  gcpStatus?: GcpStatus;
  awsStatus?: AwsStatus;
  azureStatus?: AzureStatus;
}

export interface TrafficConfig {
  cloudProvider: CloudProvider;
  tier: Tier;
  region: string;
  privateTraffic: TrafficEnablement;
  publicTraffic: TrafficEnablement;
  awsProperties?: AwsProperties;
  gcpProperties?: GcpProperties;
  azureProperties?: AzureProperties;
  status: TrafficConfigStatus;
}
