/* eslint-disable max-classes-per-file */
import { SessionStore } from 'state';
import globals from 'browser/globals';
import { ApiClientRequestError, NetworkError } from './api-client-error';
import * as Sentry from '@sentry/browser';
import { Breadcrumb } from '@sentry/types';
import logger from 'logger';

export enum IgnoreErrors {
  ALL = 'all',
  SERVER = 'server',
  NETWORK = 'network',
}

type ErrorHandler = (e: Error) => Promise<unknown>;
type ApiClientRequestFunc = (path: string) => ApiClientRequestBuilderInterface;
export interface ApiClientPublicInterface {
  get: ApiClientRequestFunc;
  post: ApiClientRequestFunc;
  put: ApiClientRequestFunc;
  patch: ApiClientRequestFunc;
  delete: ApiClientRequestFunc;
  options: ApiClientRequestFunc;
}

export interface ApiClientRequestBuilderInterface {
  then: (cb: () => any) => Promise<any>;
  catch: (cb: () => any) => Promise<any>;
  ignore: (codes: number | number[]) => ApiClient;
  ignoreErrors: (ignore?: IgnoreErrors) => ApiClient;
  ignoreServerErrors: (codes?: number | number[]) => ApiClient;
  ignoreNetworkErrors: () => ApiClient;
  ignoreAllErrors: () => ApiClient;
  issue: (body: Record<string, any>) => ApiClient;
  query: (q: Record<string, any>) => ApiClient;
  promise: () => Promise<any>;
}

interface ClientRequestOptions extends Omit<RequestInit, 'headers' | 'body'> {
  headers?: Record<string, string>;
  body?: Record<string, any>;
}

interface ClientRequest extends ClientRequestOptions {
  url: string;
}

type ChainFunc = (request: ClientRequest) => Promise<any>;

type Middleware = (next: ChainFunc) => ChainFunc;

export class ApiClientManager implements ApiClientPublicInterface {
  _hostname;
  _defaultErrorHandler: ErrorHandler;

  constructor(hostname: string, defaultErrorHandler: ErrorHandler) {
    this._hostname = hostname;
    this._defaultErrorHandler = defaultErrorHandler;
  }

  _createClient() {
    return new ApiClient(this._hostname, this._defaultErrorHandler);
  }

  get(path: string) {
    return this._createClient().get(path);
  }

  post(path: string) {
    return this._createClient().post(path);
  }

  put(path: string) {
    return this._createClient().put(path);
  }

  patch(path: string) {
    return this._createClient().patch(path);
  }

  delete(path: string) {
    return this._createClient().delete(path);
  }

  options(path: string) {
    return this._createClient().options(path);
  }
}

let apiClientManager: ApiClientManager;

export const buildApiClient = (hostname, defaultErrorHandler: ErrorHandler) => {
  apiClientManager = new ApiClientManager(hostname, defaultErrorHandler);
};

const getClient = () => {
  if (!apiClientManager) {
    throw new Error('Api client not initialized. Did you call `buildApiClient`?');
  }

  return apiClientManager;
};

export default getClient;

type ApiClientInterface = ApiClientPublicInterface & ApiClientRequestBuilderInterface;

export class ApiClient implements ApiClientInterface {
  _options: ClientRequestOptions;
  _query;
  _ignoredStatuses: Record<number, boolean>;
  _ignoreNetworkErrors: boolean;
  _url;
  _hostname: string;
  _defaultErrorHandler: ErrorHandler;
  _middlewares: Middleware[];

  constructor(hostname: string, defaultErrorHandler: ErrorHandler) {
    this._hostname = hostname;
    this._defaultErrorHandler = defaultErrorHandler;
    this._options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    };
    this._ignoredStatuses = {};
    this._query = {};
    if (SessionStore.state && SessionStore.state.loggedIn) {
      this.bearerAuth(SessionStore.state.oauthBearerToken);
    }

    this._middlewares = [
      makeRequest,
      retryMiddleware,
      errorMiddlewareFactory(
        this._shouldHandleError.bind(this),
        this._defaultErrorHandler.bind(this)
      ),
      sentryErrResponseMiddleware,
    ];
  }

  get(url) {
    this._setup(url, 'GET');
    return this;
  }

  post(url) {
    this._setup(url, 'POST');
    return this;
  }

  put(url) {
    this._setup(url, 'PUT');
    return this;
  }

  patch(url) {
    this._setup(url, 'PATCH');
    return this;
  }

  delete(url) {
    this._setup(url, 'DELETE');
    return this;
  }

  options(url) {
    this._setup(url, 'OPTIONS');
    return this;
  }

  issue(entity) {
    this._options.headers['Content-Type'] = 'application/json';
    this._options.body = entity;
    return this;
  }

  query(parameters) {
    if (parameters) {
      this._query = { ...this._query, ...parameters };
    }
    return this;
  }

  ignoreErrors(ignore?: IgnoreErrors) {
    if (ignore === IgnoreErrors.ALL) {
      this.ignoreAllErrors();
    } else if (ignore === IgnoreErrors.NETWORK) {
      this.ignoreNetworkErrors();
    } else if (ignore === IgnoreErrors.SERVER) {
      this.ignoreServerErrors();
    }

    return this;
  }

  ignoreAllErrors() {
    this.ignoreServerErrors();
    this.ignoreNetworkErrors();

    return this;
  }

  ignoreNetworkErrors() {
    this._ignoreNetworkErrors = true;

    return this;
  }

  ignoreServerErrors(statuses?: number | number[]) {
    if (!statuses) {
      // Never ignore 401 errors
      const excludedErrors = [401];
      for (let i = 300; i < 600; i++) {
        if (!excludedErrors.includes(i)) {
          this._ignoredStatuses[i] = true;
        }
      }
    } else if (typeof statuses === 'number') {
      this._ignoredStatuses[statuses] = true;
    } else if (Array.isArray(statuses)) {
      statuses.forEach(status => (this._ignoredStatuses[status] = true));
    }

    return this;
  }

  // Deprecated in favor of ignoreServerErrors
  ignore(statuses: number | number[]) {
    this.ignoreServerErrors(statuses);

    return this;
  }

  bearerAuth(token) {
    this._options.headers.Authorization = `Bearer ${token}`;
    return this;
  }

  // Executes the middleware chain. The first item in the chain should
  // be the function that makes the request.
  async _execute() {
    let currentNext = null;
    for (const middleware of this._middlewares) {
      currentNext = middleware(currentNext);
    }

    const r = await currentNext(this._buildRequestObject());
    return r;
  }

  promise(): Promise<any> {
    return this._execute();
  }

  // DEPRECATED - please use .promise().then()
  then(onFulfilled, onRejected?: (reason: any) => any) {
    return this.promise().then(onFulfilled, onRejected);
  }

  // DEPRECATED - please use .promise().catch()
  catch(onRejected) {
    return this.promise().catch(onRejected);
  }

  _buildRequestObject(): ClientRequest {
    return {
      url: this._composeUrl(),
      ...this._options,
    };
  }

  _shouldHandleError(e: Error): boolean {
    if (e instanceof NetworkError && !this._ignoreNetworkErrors) {
      return true;
    } else if (e instanceof ApiClientRequestError) {
      const status = e?.response?.status;

      return this._ignoredStatuses[status] !== true;
    }

    // Unknown errors we handle by default
    return true;
  }

  _composeUrl() {
    const composedUrl = this._hostname + this._url;

    // an imperfect approximation
    const keyValuePairs = Object.keys(this._query).map(name => `${name}=${this._query[name]}`);

    if (keyValuePairs.length === 0) {
      return composedUrl;
    }

    return `${composedUrl}?${keyValuePairs.join('&')}`;
  }

  _setup(url, method) {
    const [path, ...params] = url.split('?');

    if (params.length > 0) {
      this._extractQueryParams(params.join('&'));
    }
    this._url = path;
    this._options.method = method;
  }

  _extractQueryParams(queryString) {
    const tokens = queryString.split('&');
    tokens.forEach(t => {
      const keyValue = t.split('=');
      this.query({ [keyValue[0]]: keyValue[1] });
    });
  }
}

const errorMiddlewareFactory = (
  shouldHandleError: (e: Error) => boolean,
  defaultErrorHandler: ErrorHandler
): Middleware => next => async request => {
  try {
    const response = await next(request);
    return response;
    // Decide if the errors should be handled automatically or not
  } catch (e) {
    if (e instanceof NetworkError && shouldHandleError(e)) {
      await defaultErrorHandler(e);
    } else if (e instanceof ApiClientRequestError && shouldHandleError(e)) {
      await defaultErrorHandler(e);
    }

    // Always rethrow if not handled by default
    throw e;
  }
};

// Exported for testing
export const RETRY_COUNT = 2;

const delay = (attempt: number): number => {
  if (process.env.NODE_ENV === 'test') {
    return 1;
  }

  return (2 ** attempt - 1) * 1000;
};

const retry = async (
  request: ClientRequest,
  next: ChainFunc,
  resolve: (value: any) => void,
  reject: (reason?: any) => void,
  attempt: number
) => {
  next(request)
    .then(resolve)
    .catch(e => {
      // Retry on network errors or 500's on GET/OPTION requests as GET/OPTION requests should always be idempotent
      if (
        e instanceof NetworkError ||
        (['GET', 'OPTIONS'].includes(request.method) && e?.response?.status === 500)
      ) {
        if (attempt >= RETRY_COUNT + 1) {
          reject(e);
          return;
        }

        setTimeout(() => retry(request, next, resolve, reject, attempt + 1), delay(attempt));
      } else {
        reject(e);
      }
    });
};

const retryMiddleware: Middleware = next => request => {
  return new Promise((resolve, reject) => {
    retry(request, next, resolve, reject, 1);
  });
};

const sentryErrResponseMiddleware: Middleware = (next: ChainFunc) => async (
  request: ClientRequest
) => {
  try {
    return await next(request);
  } catch (err) {
    if (err instanceof ApiClientRequestError) {
      const responseErr = err as ApiClientRequestError;
      const detail = responseErr.reason ? { errorReason: responseErr.reason } : {};

      trackResponseInSentry(request, responseErr.response, detail);
    }
    throw err;
  }
};

const makeRequest: Middleware = () => async request => {
  const { url, ...rest } = request;

  let options: RequestInit;
  if ('body' in rest) {
    options = { ...rest, body: JSON.stringify(rest.body) };
  } else {
    options = rest as Omit<ClientRequestOptions, 'body'>;
  }

  let response: Response;

  try {
    response = await globals.fetch(url, options);
  } catch (e) {
    // Fetch only throws errors when the network is down or misconfigured
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
    throw new NetworkError(e.message);
  }

  // Response.ok indicates 200-299. If not ok, attempt to deserialize and throw error
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (!response.ok) {
    let body;
    try {
      body = await response.json();
    } catch (e) {
      // Unable to deserialize response. Throw error with no reason or message
      throw new ApiClientRequestError(response);
    }
    throw new ApiClientRequestError(
      response,
      body?.Reason,
      body?.Message,
      body?.Extra,
      body?.Errors
    );
  }

  trackResponseInSentry(request, response);

  // Options methods do not have a json body
  if (request.method === 'OPTIONS') {
    return response;
  }

  // 204 responses do not have a json body
  if (response.status === 204) {
    return response;
  }

  const body = await response.json();

  return body;
};

const trackResponseInSentry = (request: ClientRequest, response: Response, extra: Object = {}) => {
  try {
    const crumb: Breadcrumb = {
      type: 'http',
      category: 'fetch',
      data: {
        ...extra,
        method: request.method,
        url: request.url,
        status_code: response.status,
      },
    };
    Sentry.addBreadcrumb(crumb);
  } catch (err) {
    logger.warn(err);
  }
};
