import React, { useState } from 'react';
import {
  Alert,
  Button,
  CodeBlock,
  Dialog,
  FormSelect,
  Radio,
  SelectOption,
  SingleLineCodeBlock,
  TextInput,
} from 'components/foundation';
import { Tier } from 'entities/database';
import {
  DestinationType,
  CreateLogForwardingPayload,
  LogType,
  CloudwatchIdentity,
  CloudwatchDestination,
  LogForwardingIdentity,
} from 'remote/resources/log-forwarding';
import Icon from 'components/ui/icons';
import globals from 'browser/globals';
import { regionNameWithInstanceCount } from './helpers';
import { getRegionsForTier, getTiersForAllCsps } from 'utils/tiers-and-regions';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { productFriendlyName } from 'types/product';
import { useDatabaseState } from 'store/index';
import { Banner } from '@neo4j-ndl/react';
import { DisclaimerDialogStep } from 'pages/log-forwarding/disclaimer-dialog-step';

interface Props {
  tenant: Tenant;
  onCreate: (payload: CreateLogForwardingPayload) => any;
  onClose: () => any;
  open: boolean;
  loading: boolean;
  error: Error | null;
  logForwardingIdentities: LogForwardingIdentity[];
  hasReachedProcessLimit: (region: String, tier: String) => boolean;
}

export const CreateCloudwatchConfigModal = ({
  tenant,
  onCreate,
  onClose,
  open,
  loading,
  error,
  logForwardingIdentities,
  hasReachedProcessLimit,
}: Props) => {
  const [step, setStep] = useState(-1);
  const [name, setName] = useState('');
  const [region, setRegion] = useState<string>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [logType, setLogType] = useState<LogType>(LogType.SECURITY);
  /* eslint-enable */
  const [hasCopied, setHasCopied] = useState(false);
  const [destination, setDestination] = useState<CloudwatchDestination>({
    type: DestinationType.CLOUDWATCH,
    region: '',
    logGroupName: 'neo4j',
    logRetentionDays: 14,
    logArn: '',
    logStreamName: logType,
  });
  const { databases } = useDatabaseState();

  const availableTiers = getTiersForAllCsps(tenant.providerConfigs);
  const [tier, setTier] = useState<Tier>(availableTiers[0]);

  let regionOptions = [];

  const tierOptions = availableTiers.map(tier => {
    const product = getProductFromTier(tier);
    return {
      key: tier,
      value: tier,
      label: productFriendlyName(product),
    };
  });

  const availableRegions = getRegionsForTier(tenant.providerConfigs, tier);
  regionOptions = availableRegions.map(r => {
    const dbsInRegion = databases.filter(db => db.Region === r.name && db.Tier === tier);
    return {
      key: r.name,
      label: regionNameWithInstanceCount(r.friendly, dbsInRegion),
      value: r.name,
      databases: dbsInRegion,
    };
  });

  const identity = logForwardingIdentities.find(id => id.region === region && id.tier === tier);
  const logArn = identity ? (identity as CloudwatchIdentity).logArn : 'Nothing found';
  const accountId = identity ? (identity as CloudwatchIdentity).accountId : 'Nothing found';

  const formatOptionLabel = ({ label, value, databases }, { context }) => {
    if (context === 'value') {
      return <div>{label}</div>;
    } else if (context === 'menu') {
      return (
        <div data-testid={`region-${value}`}>
          <p>{label}</p>
          <p className="tw-pl-4">{databases.map(db => db.Name).join(', ')}</p>
        </div>
      );
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName(value => value.trim());
  const handleTierChange = (option: SelectOption<Tier>) => setTier(option.value);
  const handleRegionChange = (option: SelectOption<string>) => {
    setRegion(option.value);
    setDestination(prev => ({ ...prev, region: option.value }));
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const onCopy = () => {
    globals.window.navigator.clipboard.writeText(jsonText);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  const jsonText = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "logs:CreateLogGroup",
                "logs:CreateLogStream",
                "logs:PutLogEvents",
                "logs:DescribeLogStreams"
            ],
            "Resource": [
                "arn:aws:logs:*:*:*"
            ]
        }
    ]
}`;

  const handleCreate = () => {
    const payload: CreateLogForwardingPayload = {
      destination,
      logType,
      name,
      region,
      tier,
      tenantId: tenant.id,
    };
    onCreate(payload);
  };

  const isValidInfo = !!region && !!name.trim();

  const isValidLogArn = !!destination.logArn;

  const isValidDestination =
    !!destination.logGroupName &&
    destination.logRetentionDays &&
    !!destination.logStreamName &&
    !!destination.region;

  const isValidPayload = isValidInfo && isValidLogArn && isValidDestination;

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>New log forwarding process</Dialog.Header>
      {step === -1 && <DisclaimerDialogStep onAccept={() => setStep(0)} onDecline={onClose} />}
      {step === 0 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            <TextInput
              label="Name"
              value={name}
              onChange={handleNameChange}
              onBlur={trimName}
              placeholder="Process name"
              fluid
              data-testid="log-forwarding-name-input"
            />
            <div>
              <p className="tw-mb-2">Logs to forward:</p>
              <Radio checked={logType === LogType.SECURITY} readOnly label="Security logs" />
            </div>
            {hasReachedProcessLimit(region, tier) && (
              <Banner
                icon
                type="warning"
                description="The selected product and region already has a log forwarding process defined"
              />
            )}
            <FormSelect
              label="Product"
              options={tierOptions}
              value={tier}
              onChange={handleTierChange}
              data-testid="log-forwarding-product-select"
            />
            <FormSelect
              label="Region"
              onChange={handleRegionChange}
              options={regionOptions}
              value={region}
              formatOptionLabel={formatOptionLabel}
              helpText="All future instances' logs in the selected region will be forwarded as part of this process."
              data-testid="log-forwarding-region-select"
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!isValidInfo || hasReachedProcessLimit(region, tier)}
              color="primary"
              onClick={handleNextStep}
              data-testid="log-forwarding-next-step"
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 1 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            {error && <Alert type="danger">{String(error)}</Alert>}
            <ol className="tw-list-decimal tw-pl-6 tw-my-6 tw-space-y-6">
              <li>
                Access the AWS Console:
                <ul className="tw-list-disc tw-list-inside">
                  <li>Log in to the AWS Management Console.</li>
                  <li>Navigate to IAM (Identity and Access Management).</li>
                  <li>Navigate to Policies.</li>
                  <li>Click &quot;Create Policy&quot;.</li>
                  <li>Select the &quot;JSON&quot; tab, and copy and paste the following policy:</li>
                  <div className="tw-my-6">
                    <CodeBlock
                      actions={[
                        {
                          'aria-label': 'copy',
                          title: 'copy',
                          children: (
                            <Icon
                              data-testid="copy-json-clipboard"
                              title={hasCopied ? 'Copied to clipboard' : 'Copy'}
                              name={
                                hasCopied
                                  ? 'ClipboardDocumentCheckIconOutline'
                                  : 'ClipboardIconOutline'
                              }
                            />
                          ),
                          onClick: onCopy,
                        },
                      ]}
                      code={jsonText}
                      language="json"
                    />
                  </div>
                  <li>Click &quot;Next.&quot;</li>
                  <li>Choose a name for the policy and click &quot;Create.&quot;</li>
                </ul>
              </li>
            </ol>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={() => setStep(step - 1)}>
              Previous
            </Button>
            <Button color="primary" onClick={handleNextStep} data-testid="log-forwarding-next-step">
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 2 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            {error && <Alert type="danger">{String(error)}</Alert>}
            <ol className="tw-list-decimal tw-pl-6 tw-my-6 tw-space-y-6" start={2}>
              <li>
                Navigate to Roles and create a Role:
                <ul className="tw-list-disc tw-list-inside">
                  <li>Choose &quot;AWS account&quot; as the trusted entity.</li>
                  <li>
                    Select &quot;Another AWS account&quot; and enter the Neo4j account ID below
                  </li>
                </ul>
              </li>
              <SingleLineCodeBlock code={accountId} copyToClipboard />
              <ul className="tw-list-disc tw-list-inside">
                <li>Click &quot;Next.&quot;</li>
                <li>Search for and select the policy you created in the previous step.</li>
                <li>Name the role.</li>
                <li>Choose &quot;Create role.&quot;</li>
                <li>Open the role you just created from the list of available roles.</li>
                <li>
                  Select the &quot;Trust relationships&quot; tab, then &quot;Edit trust
                  policy.&quot;
                </li>
                <li>Replace the existing role ARN with the Neo4j AWS Role ARN below:</li>
              </ul>
              <SingleLineCodeBlock code={logArn} copyToClipboard />
              <ul className="tw-list-disc tw-list-inside">
                <li>Click &quot;Update policy.&quot;</li>
              </ul>

              <li>
                Copy the Role ARN:
                <ul className="tw-list-disc tw-list-inside">
                  <li>Once the trust entity has been updated, click on the new role.</li>
                  <li>From that page, copy the generated ARN.</li>
                  <li>Paste the ARN in the specified field below:</li>
                </ul>
              </li>
              <TextInput
                label="Role ARN"
                value={destination.logArn}
                onChange={event =>
                  setDestination(prev => ({ ...prev, logArn: event.target.value }))
                }
                data-testid="log-forwarding-aws-role-arn-input"
                fluid
              />
            </ol>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={() => setStep(step - 1)}>
              Previous
            </Button>
            <Button
              disabled={!isValidLogArn}
              color="primary"
              onClick={handleNextStep}
              data-testid="log-forwarding-next-step"
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 3 && (
        <>
          <Dialog.Content className="tw-space-y-6">
            {error && <Alert type="danger">{String(error)}</Alert>}
            <TextInput
              label="Group name"
              value={destination.logGroupName}
              onChange={event =>
                setDestination(prev => ({ ...prev, logGroupName: event.target.value }))
              }
              data-testid="log-forwarding-aws-group-name-input"
              fluid
            />
            <TextInput
              label="Stream name"
              value={destination.logStreamName}
              onChange={event =>
                setDestination(prev => ({ ...prev, logStreamName: event.target.value }))
              }
              data-testid="log-forwarding-aws-stream-name-input"
              fluid
            />
            <TextInput
              label="Retention days"
              value={destination.logRetentionDays}
              onChange={event =>
                setDestination(prev => ({
                  ...prev,
                  logRetentionDays: event.target.value ? parseInt(event.target.value) : 1,
                }))
              }
              helpText='The number of days until AWS marks a log as “expired"'
              type="number"
              min={1}
              data-testid="log-forwarding-aws-retention-days-input"
              fluid
            />
            <TextInput
              label="AWS Region"
              value={destination.region}
              onChange={event => setDestination(prev => ({ ...prev, region: event.target.value }))}
              helpText="Your AWS Region (can be different from your Neo4j region)."
              data-testid="log-forwarding-aws-region-input"
              fluid
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={() => setStep(step - 1)}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              loading={loading}
              data-testid="log-forwarding-create"
              disabled={!isValidPayload}
            >
              Create process
            </Button>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};
