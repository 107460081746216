import React, { useMemo, useState } from 'react';
import {
  Button,
  createColumnHelper,
  LoadingSpinner,
  StickyActionsDataGrid,
  useDefaultTable,
} from 'components/foundation';
import { Organization } from 'types/organization';
import { BillingAccount } from 'types/billing-account';
import { useNotify } from 'state/notifications';
import CreateBillingAccountModal from './create-billing-account-modal';
import billingAccountsResource from 'remote/resources/billing-accounts';
import LoadError from 'components/application/load-error';
import { PlanType } from 'entities/tenant';

const OrganizationBillingAccountsGuard = (
  component: React.ComponentType<{
    organization: Organization;
    billingAccounts: BillingAccount[];
    onRefreshBillingAccounts: () => void;
  }>
) => ({ organization }: { organization: Organization }) => {
  const Component = component;
  const orgBillingAccountsQuery = billingAccountsResource.useGetOrgBillingAccountsQuery(
    organization.id
  );
  if (orgBillingAccountsQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }
  if (orgBillingAccountsQuery.isError) {
    return <LoadError />;
  }
  return (
    <Component
      organization={organization}
      billingAccounts={orgBillingAccountsQuery.data}
      onRefreshBillingAccounts={orgBillingAccountsQuery.refetch}
    />
  );
};

const billingAccountsHeaderHelper = createColumnHelper<BillingAccount>();

export const OrganizationBillingAccountsBase = ({
  organization,
  billingAccounts,
  onRefreshBillingAccounts,
}: {
  organization: Organization;
  billingAccounts: BillingAccount[];
  onRefreshBillingAccounts: () => void;
}) => {
  const [createOpen, setCreateOpen] = useState(false);
  const notify = useNotify();
  const columns = useMemo(
    () => [
      billingAccountsHeaderHelper.accessor('id', {
        header: 'Account ID',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('name', {
        header: 'Name',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('billingModel', {
        header: 'Billing Model',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('billingProcessor', {
        header: 'Billing Processor',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('paymentMethod', {
        header: 'Payment Method',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('pricingPlan', {
        header: 'Pricing Plan',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('projectIds', {
        header: 'Project IDs',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('status', {
        header: 'Status',
        minSize: 100,
      }),
    ],
    []
  );
  const toggleBillingAccountModal = () => setCreateOpen(!createOpen);
  const handleCreateSuccess = (billingAccount: BillingAccount) => {
    onRefreshBillingAccounts();
    notify.success(`Billing Account ${billingAccount.id} successfully created!`);
    setCreateOpen(false);
  };
  const table = useDefaultTable({
    columns: columns,
    data: billingAccounts,
  });
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <h5>Billing Accounts</h5>
        {organization.planType === PlanType.ENTERPRISE && (
          <div className="tw-justify-end tw-gap-2 tw-flex">
            <Button
              onClick={toggleBillingAccountModal}
              iconName="PlusIconOutline"
              fill="outlined"
              data-testid="create-org-billing-account-button"
            >
              Create
            </Button>
          </div>
        )}
      </div>

      <StickyActionsDataGrid tableInstance={table} />
      <CreateBillingAccountModal
        organizationId={organization.id}
        open={createOpen}
        closeModal={toggleBillingAccountModal}
        saveAndClose={handleCreateSuccess}
      />
    </div>
  );
};

export const OrganizationBillingAccounts = OrganizationBillingAccountsGuard(
  OrganizationBillingAccountsBase
);
