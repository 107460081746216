import { RTCellInfo, RTRowInfo } from './shared';

export enum LogStatus {
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  EXPIRING_SOON = 'ExpiringSoon',
}

export enum LogFile {
  QUERY = 'query.log',
  SECURITY = 'security.log',
}

export interface Log {
  logId: string;
  dbid: string;
  status: LogStatus;
  start: string;
  end: string;
  requestedAt: string;
  files: LogFile[];
  type: string;
  timePeriod: string;
  expiresIn: Duration;
  downloadLog: boolean;
}

export interface RemoteLog {
  log_id: string;
  dbid: string;
  start: string;
  end: string;
  status: LogStatus;
  files: LogFile[];
  requested_at: string;
}

export interface LogRowInfo extends RTRowInfo {
  original: Log;
}
export interface LogCellInfo extends RTCellInfo {
  row: LogRowInfo;
}
