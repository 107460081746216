import React, { useState, useEffect } from 'react';
import { Button, Dialog, FormSelect, LoadingSpinner } from 'foundation';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { InternalTenant } from 'entities/tenant';
import billingAccountsResource from 'remote/resources/billing-accounts';

interface LinkBillingAccountFormData {
  billingAccountId?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  tenant: InternalTenant;
  billingAccountId?: string;
}

export const LinkBillingAccountModal = ({
  open,
  onClose,
  onSuccess,
  tenant,
  billingAccountId,
}: Props) => {
  const [data, setData] = useState<LinkBillingAccountFormData>({
    billingAccountId: billingAccountId,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setData({ billingAccountId: billingAccountId });
    }
  }, [billingAccountId, open]);

  const defaultErrorHandler = useDefaultErrorHandler();

  const orgBillingAccountQuery = billingAccountsResource.useGetOrgBillingAccountsQuery(
    tenant.organizationId
  );
  const handleClose = () => {
    setData({ billingAccountId: billingAccountId });
    onClose();
  };

  const handleBillingAccountChange = ({ value }) => setData({ billingAccountId: value });

  const handleSubmit = () => {
    setLoading(true);

    billingAccountsResource
      .linkToNamespace(tenant.id, data.billingAccountId)
      .then(() => onSuccess())
      .catch(e => defaultErrorHandler(e))
      .finally(() => setLoading(false));
  };
  const options = orgBillingAccountQuery.data?.map(({ id, name }) => ({
    key: id,
    value: id,
    label: `${id}: ${name}`,
  }));

  const chosenOption = options?.find(option => option.value === data.billingAccountId);

  if (orgBillingAccountQuery.isLoading) {
    return <LoadingSpinner />;
  }
  if (orgBillingAccountQuery.isError) {
    return null;
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Header>Link Billing Account</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-2">
        <FormSelect
          label="Billing Account Id"
          value={chosenOption}
          options={options}
          onChange={handleBillingAccountChange}
          data-testid="select-billing-account"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} loading={loading} data-testid="submit-pricing-plan">
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
