import localStorage from 'browser/localStorage';
import MarketplaceResource, { OrderParameters } from 'remote/resources/marketplace';
export interface MarketplaceRegistrationDetails {
  customerId: string;
  productId: string;
  cloudProvider: string;
}

export const marketplaceRegistrationLocalStorageKey = (email: string) =>
  `aura-console-marketplace-registration-${email}`;

const setMarketplaceRegistrationLocalStorage = (
  customerId: string,
  productId: string,
  cloudProvider: string,
  email: string
) => {
  const marketplaceRegistrationDetails = { customerId, productId, cloudProvider };
  localStorage.setItem(
    marketplaceRegistrationLocalStorageKey(email),
    JSON.stringify(marketplaceRegistrationDetails)
  );
  window.dispatchEvent(new Event('storage'));
};

const getMarketplaceRegistrationLocalStorage = (
  email: string
): MarketplaceRegistrationDetails | null => {
  const registrationString = localStorage.getItem(marketplaceRegistrationLocalStorageKey(email));
  if (registrationString) {
    return JSON.parse(registrationString);
  }
  return null;
};

const removeMarketplaceRegistrationLocalStorage = (email: string): void => {
  localStorage.removeItem(marketplaceRegistrationLocalStorageKey(email));
  window.dispatchEvent(new Event('storage'));
};

const registerOrder = (data: OrderParameters) => {
  return MarketplaceResource.register(data);
};

export default {
  getMarketplaceRegistrationLocalStorage,
  setMarketplaceRegistrationLocalStorage,
  removeMarketplaceRegistrationLocalStorage,
  registerOrder,
};
