import { useQuery, UseQueryResult } from 'react-query';
import ApiClient from 'remote/api-client';
import { BillingAccount } from 'types/billing-account';

const create = (organizationId: string, name: string): Promise<BillingAccount> => {
  return ApiClient.post(`/organizations/${organizationId}/billing-accounts`)
    .issue({
      Name: name,
      OrganizationId: organizationId,
    })
    .promise();
};

const getAccountsForOrganization = (organizationId: string): Promise<BillingAccount[]> => {
  return ApiClient.get(`/organizations/${organizationId}/billing-accounts`)
    .promise()
    .then(res => res.map(transformBillingAccountResponse));
};

const getAccountForNamespace = (namespaceId: string): Promise<BillingAccount> => {
  return ApiClient.get(`/namespaces/${namespaceId}/billing-account`)
    .ignoreServerErrors()
    .promise()
    .then(res => transformBillingAccountResponse(res));
};

const linkToNamespace = (namespaceId: string, billingAccountId: string): Promise<void> => {
  return ApiClient.post(`/namespaces/${namespaceId}/billing-account`)
    .issue({
      BillingAccountId: billingAccountId,
    })
    .promise();
};

export const transformBillingAccountResponse = (data: Record<string, any>): BillingAccount => {
  return {
    id: data.billing_account_id,
    name: data.name,
    billingModel: data.billing_model,
    billingProcessor: data.billing_processor,
    organizationId: data.organization_id,
    paymentMethod: data.payment_method,
    pricingPlan: data.pricing_plan,
    projectIds: data.project_ids,
    reason: data.reason,
    status: data.status,
  };
};

const useGetOrgBillingAccountsQuery = (
  organizationId: string
): UseQueryResult<BillingAccount[]> => {
  const query = async () => {
    const billingAccounts = await getAccountsForOrganization(organizationId);
    return billingAccounts;
  };
  return useQuery(`billingaccounts-${organizationId}`, query, {
    enabled: !!organizationId,
    refetchInterval: 10000,
  });
};

export default {
  create,
  getAccountForNamespace,
  getAccountsForOrganization,
  linkToNamespace,
  useGetOrgBillingAccountsQuery,
};
