import React from 'react';
import { CopyTextToClipBoardButton, IconButton } from 'components/foundation';
import { isString } from 'utils/strings';

export const KeyPairItem = ({
  label,
  children,
  isCopyable = true,
  href = undefined,
  copyText = undefined,
}) => {
  let textToCopy = copyText;
  let enableCopy = isCopyable;

  if (isCopyable && !textToCopy) {
    if (isString(children)) {
      textToCopy = children;
    } else {
      enableCopy = false;
    }
  }

  return (
    <tr>
      <td className="tw-pr-4 tw-text-color-secondary tw-font-thin">{label}</td>
      <td>
        {href ? <a href={href}>{children}</a> : children}
        {enableCopy && (
          <CopyTextToClipBoardButton
            text={textToCopy}
            className="tw-ml-1"
            iconButtonProps={{
              size: 'small',
              style: { width: '18px', height: '18px', borderRadius: '4px' },
            }}
          />
        )}
        {href && (
          <IconButton
            size="small"
            style={{ width: '18px', height: '18px', borderRadius: '4px' }}
            className={enableCopy ? 'tw-ml-1' : ''}
            as="a"
            href={href}
            iconName="ArrowTopRightOnSquareIconOutline"
            title="Open"
            target="_blank"
            clean
          />
        )}
      </td>
    </tr>
  );
};

export const KeyPairSection = ({ children }) => {
  return (
    <table>
      <tbody>{children}</tbody>
    </table>
  );
};
