import React from 'react';
import AlgorithmCategoryPicker from './algorithm-category-picker';
import * as yup from 'yup';
import { Validation, validateYup } from 'utils/validation';
import { FormInput } from 'foundation/form-element';
import './form-size-estimator.css';
import { Tier } from 'entities/database';

// Neo4j itself is not be able to support more than (2^35)-1 nodes
const safeMax = 2 ** 35 - 1;

const schema = yup.object({
  nodeCount: yup
    .number()
    .min(1)
    .max(safeMax)
    .when('unsure', {
      is: true,
      then: yup.number().optional(),
      otherwise: yup.number().required(),
    })
    .label('Number of nodes'),
  relationshipCount: yup
    .number()
    .min(1)
    .max(safeMax)
    .when('unsure', {
      is: true,
      then: yup.number().optional(),
      otherwise: yup.number().required(),
    })
    .label('Number of relationships'),
  unsure: yup.boolean(),
  categories: yup.array().when('unsure', {
    is: true,
    then: yup.array().max(0),
    otherwise: yup
      .array()
      .required('Must select at least 1 algorithm')
      .min(1, 'Must select at least 1 algorithm'),
  }),
});

export interface Data {
  nodeCount?: number;
  relationshipCount?: number;
  categories?: string[];
  unsure: boolean;
}

export const validate = (data: Data, { onlyRequired }: { onlyRequired?: boolean } = {}) =>
  validateYup(schema, data, onlyRequired || false);

export const defaults = (nodeCount?: number, relationshipCount?: number): Data => ({
  categories: undefined,
  unsure: false,
  nodeCount,
  relationshipCount,
});

const stringToNumber = (value: string) => parseInt(value.replace(/,/g, ''), 10);
const numberToString = (value: number) => value.toLocaleString('en');

interface DatabaseSizeEstimatorProps {
  data: Data;
  onChange: (data: Data) => any;
  validation?: Validation<Data>;
  tier?: Tier;
}

const DatabaseSizeEstimator = ({
  tier,
  data,
  validation,
  onChange,
}: DatabaseSizeEstimatorProps) => {
  const handleNodeCountChange = ({ target: { value } }) => {
    const nodeCount = stringToNumber(value);
    onChange({ ...data, nodeCount: !isNaN(nodeCount) ? nodeCount : undefined });
  };
  const handleRelationshipCountChange = ({ target: { value } }) => {
    const relationshipCount = stringToNumber(value);
    onChange({
      ...data,
      relationshipCount: !isNaN(relationshipCount) ? relationshipCount : undefined,
    });
  };
  const handleAlgorithmChange = (categories, unsure) => {
    if (categories.length === 0) {
      onChange({ ...data, categories: undefined, unsure });
    } else {
      onChange({ ...data, categories, unsure });
    }
  };

  return (
    <div>
      {tier !== Tier.GDS && <h5 className="tw-my-4">How big is your graph?</h5>}
      <div className="form-size-estimator instance-details tw-mb-6">
        <div className="nodes">
          <FormInput
            fluid
            label="Number of nodes"
            value={data.nodeCount ? numberToString(data.nodeCount) : ''}
            data-testid="nodes-input"
            errorText={validation?.nodeCount?.message}
            onChange={handleNodeCountChange}
          />
        </div>
        <div className="relationships">
          <FormInput
            fluid
            label="Number of relationships"
            value={data.relationshipCount ? numberToString(data.relationshipCount) : ''}
            data-testid="relationships-input"
            errorText={validation?.relationshipCount?.message}
            onChange={handleRelationshipCountChange}
          />
        </div>
      </div>
      {tier !== Tier.GDS && <h5>Which algorithms are you going to use?</h5>}
      <div className="tw-mt-4 tw-mb-6">
        <AlgorithmCategoryPicker
          categories={data.categories || []}
          unsure={data.unsure}
          errorMessage={validation?.categories?.message}
          onChange={handleAlgorithmChange}
        />
      </div>
    </div>
  );
};

export default DatabaseSizeEstimator;
