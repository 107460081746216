import DatabasesResource from 'remote/resources/databases';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { DatabasesStore } from 'state';
import logger from 'logger';
import Actions from 'actions';
import errorMessages, { getErrorMessage } from 'remote/error-handler/error-messages';
import { Tier } from 'entities/database';

const destroyDatabase = (dbid: string) =>
  DatabasesResource.destroy(dbid)
    .then(updatedDatabase => {
      logger.debug(`Database ${dbid} successfully scheduled for destruction`);

      if (DatabasesStore.findById(dbid).Tier === Tier.FREE) {
        Actions.namespaces
          .refresh()
          .catch(error => logger.error('Failed to refresh current tenant:', error));
      }

      DatabasesStore.upsert(dbid, updatedDatabase);
    })
    .catch(error => {
      logger.debug('Failed to destroy DB due to error: ', error);
      if (error instanceof ApiClientRequestError) {
        return { error: getErrorMessage(error.response.status, error.reason) };
      }
      return { error: errorMessages.unavailable };
    });

export default destroyDatabase;
