import React, { useState } from 'react';
import PropTypes from 'prop-types';
import globals from 'browser/globals';
import { IconButton, TipSide, Tip } from 'foundation';

interface IconButtonProps
  extends Omit<React.ComponentProps<typeof IconButton>, 'aria-label' | 'iconName'> {
  'aria-label'?: string;
}
interface CopyTextToClipBoardButtonProps extends React.ComponentProps<'span'> {
  text: string;
  iconButtonProps?: IconButtonProps;
  allowedPlacements?: TipSide[];
}

export const CopyTextToClipBoardButton = ({
  text,
  iconButtonProps = {},
  allowedPlacements = ['right', 'left'],
  ...rest
}: CopyTextToClipBoardButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const onOpen = () => {
    globals.window.navigator.clipboard.writeText(text);
    setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const onClickToCopy = e => {
    if (iconButtonProps && iconButtonProps.onClick) {
      iconButtonProps.onClick(e);
    }
    onOpen();
  };

  return (
    <>
      <Tip isOpen={open} allowedPlacements={allowedPlacements}>
        <Tip.Trigger>
          <span {...rest}>
            <IconButton
              title="Copy to clipboard"
              iconName="ClipboardIconOutline"
              aria-label="Copy text to clipboard"
              clean
              {...iconButtonProps}
              onClick={onClickToCopy}
            />
          </span>
        </Tip.Trigger>
        <Tip.Content isPortaled={false}>Copied to clipboard</Tip.Content>
      </Tip>
    </>
  );
};

CopyTextToClipBoardButton.propTypes = {
  text: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};
