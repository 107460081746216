import React, { SyntheticEvent, useCallback, useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { Database, Tier, isPausable } from 'entities/database';
import PauseDatabaseModal from './modal';
import { DatabasesStore } from 'state';
import DatabasesResource from 'remote/resources/databases';

import logger from 'logger';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';

export interface PauseDatabaseChildProps {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  loading: boolean;
  allowPause: boolean;
}
export interface PauseDatabaseProps {
  database: Database;
  children: (args: PauseDatabaseChildProps) => JSX.Element;
}

export const MODAL_TIERS = [Tier.PROFESSIONAL, Tier.GDS, Tier.MTE, Tier.ENTERPRISE, Tier.AURA_DSE];

const PauseDatabase = ({ database, children }: PauseDatabaseProps) => {
  const session = useSession();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const { trackEvent } = useTracking();
  const { allow } = usePermissions();
  const allowPause = allow(
    Action.PAUSE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );

  const getShouldShowModal = () => MODAL_TIERS.includes(database.Tier);

  const pauseDatabase = async () => {
    trackEvent({ action: 'pause_db', properties: { dbid: database.DbId } });
    setLoading(true);

    try {
      const updatedDatabase = await DatabasesResource.pause(database.DbId);

      logger.debug(`Database ${database.DbId} successfully scheduled for pausing`);

      DatabasesStore.upsert(database.DbId, updatedDatabase);
    } catch (error) {
      logger.debug('Failed to pause DB due to error:', error);
      setError(error.message);
    }
  };

  const onPauseClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (getShouldShowModal()) {
      setShouldShowModal(!shouldShowModal);
      return;
    }

    pauseDatabase();
  };

  const handleModalConfirmClick = useCallback(() => {
    pauseDatabase();
    setShouldShowModal(false);
  }, [database.DbId]);

  const handleModalCancelClick = useCallback(() => {
    setLoading(false);
    setError(null);
    setShouldShowModal(false);
  }, []);

  return (
    <>
      {children({
        onClick: onPauseClick,
        disabled: !isPausable(database) || loading || !allowPause,
        loading,
        allowPause,
      })}
      {shouldShowModal && (
        <PauseDatabaseModal
          open={shouldShowModal}
          loading={loading}
          onConfirmClick={handleModalConfirmClick}
          onCancelClick={handleModalCancelClick}
          error={error}
          errorHeader="Unable to pause database"
          database={database}
        />
      )}
    </>
  );
};

export default track()(PauseDatabase);
