import React, { useState } from 'react';
import { isCloneable, Database, Tier } from 'entities/database';

import { Product } from 'types/product';
import CloneToExistingDatabaseModal from './to-existing/modal';
import CloneToNewDatabaseModal from './to-new/modal';
import './clone-database.css';
import { SessionState } from 'state/session-store';
import { PlanType } from 'entities/tenant';
import { NEO4J_MANAGED_KEY_LABEL } from 'types/encryption-keys';

export enum CloneMode {
  NEW = 'new',
  EXISTING = 'existing',
  UPGRADE = 'upgrade',
}
interface CloneState {
  cloneToTier?: Tier;
  mode?: CloneMode;
}

interface CloneDatabaseProps {
  database: Database;
  session: SessionState;
}

interface CloneDatabaseModalProps {
  open: boolean;
  mode: CloneMode;
  database: Database;
  cloneToTier: Tier;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const buildCloneOptions = (session: SessionState, tier: Tier) => {
  const { planType } = session;
  const dsOption = {
    product: Product.AURA_DS,
    tier: planType === PlanType.ENTERPRISE ? Tier.AURA_DSE : Tier.GDS,
  };
  const dbOption = {
    product: Product.AURA_DB,
    tier: planType === PlanType.ENTERPRISE ? Tier.ENTERPRISE : Tier.PROFESSIONAL,
  };
  const mteOption = {
    product: Product.AURA_DB,
    tier: Tier.MTE,
  };
  return tier === Tier.MTE ? [mteOption] : [dbOption, dsOption];
};

const CloneDatabaseModal = ({
  open,
  mode,
  database,
  cloneToTier,
  _onClose,
  onClose,
}: CloneDatabaseModalProps) => {
  // We return null here instead of setting `open` on the modal
  // as no cloneToTier will break the modal even when not showing
  if (!open) return null;

  if ([CloneMode.NEW, CloneMode.UPGRADE].includes(mode)) {
    return (
      <CloneToNewDatabaseModal
        open
        onClose={_onClose(onClose)}
        database={database}
        tier={cloneToTier}
        isUpgrade={mode === CloneMode.UPGRADE}
      />
    );
  }

  return (
    <CloneToExistingDatabaseModal
      open
      onClose={_onClose(onClose)}
      sourceDatabase={database}
      targetTier={cloneToTier}
    />
  );
};

export const useCloneModal = ({ database, session }: CloneDatabaseProps) => {
  const [{ cloneToTier, mode }, setState] = useState<CloneState>({});

  const options = buildCloneOptions(session, database.Tier);

  const handleTierSelect = (tier: Tier, cloneMode: CloneMode) =>
    setState({ cloneToTier: tier, mode: cloneMode });

  const handleClose = onClose => () => {
    setState({});
    if (onClose) onClose();
  };

  const open = !!cloneToTier;

  const modalProps = {
    open,
    mode,
    database,
    cloneToTier,
    _onClose: handleClose,
  };

  return {
    onClick: handleTierSelect,
    options,
    disabled: !isCloneable(database),
    Modal: CloneDatabaseModal,
    modalProps,
  };
};

const getEncryptionKeyName = (keys = [], key: string) =>
  keys.find(k => k.encryptionKeyRef === key)?.name || NEO4J_MANAGED_KEY_LABEL;

export const CloneDatabaseChangeCmekWarning = ({ keys, encryptionKey }) => (
  <>
    <p>
      You are about to clone to an instance that is encrypted with{' '}
      <span className="h6">{getEncryptionKeyName(keys, encryptionKey)}</span>.
    </p>
    <p>
      <span className="h6">Warning</span>: Source instance and destination instance use different
      encryption keys.
    </p>
  </>
);
