import React, { useState } from 'react';
import { Alert, Dialog, Button } from 'foundation';
import { Invite } from 'types/invite';
import InviteActions from 'actions/invite-actions';

interface Props {
  invite: Invite;
  open: boolean;
  onClose: () => any;
  onSubmit: () => any;
}

export const RevokeInviteModal = ({ invite, open, onClose, onSubmit }: Props) => {
  const [error, setError] = useState<Error>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await InviteActions.revokeInvite(invite.InviteId);
      setSuccess(true);
      onSubmit();
    } catch (err) {
      switch (err.reason) {
        default:
          setError(err);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header data-testid="delete-invite-header">Are you sure?</Dialog.Header>
      <Dialog.Content data-testid="delete-invite-content">
        <Dialog.Description>This will delete the invite.</Dialog.Description>
        {error && (
          <Alert type="danger" className="tw-mt-2">
            {String(error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="delete-invite-success" className="tw-mt-2">
            Invite deleted.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          data-testid="delete-invite-cancel"
          onClick={onClose}
          color="neutral"
          fill="outlined"
        >
          Cancel
        </Button>
        <Button
          data-testid="delete-invite-button"
          color="danger"
          onClick={handleSubmit}
          disabled={loading || success}
        >
          Delete invite
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
