import { Database } from 'entities/database';
import Actions from 'actions';

export const seconds = s => s * 1000;

type Pair<T> = [T, T];
type DatabaseComparison = Pair<Database | undefined>;

export const compareDatabaseStates = (
  oldState: Database[],
  newState: Database[]
): DatabaseComparison[] => {
  const oldStateByDbId = new Map(oldState.map(db => [db.DbId, db]));
  const newStateByDbId = new Map(newState.map(db => [db.DbId, db]));
  const allDbIds = new Set([...oldStateByDbId.keys(), ...newStateByDbId.keys()]);
  const comparisons: DatabaseComparison[] = [];

  for (const dbid of allDbIds) {
    comparisons.push([oldStateByDbId.get(dbid), newStateByDbId.get(dbid)]);
  }

  return comparisons;
};

export const stripQueryParam = (queryParams: URLSearchParams, paramToStrip: string) => {
  if (paramToStrip && queryParams.has(paramToStrip)) {
    queryParams.delete(paramToStrip);

    Actions.navigate.replace({ search: queryParams });
  }
};

export const stringToBool = (str: string): boolean => {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};
