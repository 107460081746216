import React from 'react';
import ConfirmModal, { ConfirmAction } from 'components/foundation/confirm-modal';
import { LogForwarding } from 'remote/resources/log-forwarding';

interface ModalProps {
  logForwarding: LogForwarding;
  open: boolean;
  loading: boolean;
  error?: Error | null;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  region: string;
}

export const ConfirmDeleteModal = ({
  logForwarding,
  open,
  loading,
  error,
  onClose,
  onConfirm,
  region,
}: ModalProps) => {
  // Orchestra _should_ exist but let's be defensive to not crash the application
  const regionName = region || logForwarding.region;

  return (
    <ConfirmModal
      title={`Delete log forwarding process ${logForwarding.name}`}
      content={`This will stop forwarding ${logForwarding.logType} logs to ${regionName}.`}
      confirmText={logForwarding.name}
      action={ConfirmAction.DELETE}
      buttonTextLoading="Deleting"
      negative
      open={open}
      error={error ? String(error) : null}
      loading={loading}
      onCancel={onClose}
      onConfirm={onConfirm}
    />
  );
};
