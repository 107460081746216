import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import logger from 'logger';
import { debounce } from './javascript';
import { THEME_KEY, Theme, themeState } from './themes';
import { tokens } from '@neo4j-ndl/base';

/**
 * A local storage hook
 * from https://usehooks.com/useLocalStorage/
 */
export const useLocalStorage = (key: string, initialValue: any): [any, (value: any) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.log(error);
    }
  };

  return [storedValue, setValue];
};

/**
 * A hook to provide functionality similar to the old "componentDidUpdate"
 * lifecycle method. Does nothing significant on the first render, and calls
 * the callback on all subsequent renders.
 * @param callback the function to call when the dependencies receive updates
 * @param dependencies the dependencies to watch for changes
 */
export const useUpdateEffect = (callback: Function, dependencies: Array<any>): void => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      callback();
    }
  }, dependencies);
};

/**
 * A hook to provide a setInterval-like API that is automatically stopped when the component
 * unmounts. The callback runs in the next available event loop (i.e. setTimeout(callback, 0)), and
 * then at every interval thereafter until stopped.
 * @param callback the function to call during each poll
 * @param delay the desired interval between each callback execution
 */
export const useImmediateInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Save a reference to the current callback when it changes
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setTimeout(() => {
      savedCallback.current();
    }, 0);

    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    // Allow a falsy (but non-numerical) delay value to "pause" the polling
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
};

/**
 * A hook to provide a setInterval-like API that is automatically stopped when the component
 * unmounts. The callback is first called after `delay` ms, the same as setInterval
 * @param callback the function to call during each poll
 * @param delay the desired interval between each callback execution
 */
export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Save a reference to the current callback when it changes
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Allow a falsy (but non-numerical) delay value to "pause" the polling
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
};

export const useMediaQuery = (breakpoint: number) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 10);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return breakpoint <= width;
};

export const useDarkTheme = () => {
  const theme = JSON.parse(localStorage.getItem(THEME_KEY));
  const initTheme = themeState(theme, window.matchMedia('(prefers-color-scheme: dark)').matches);

  const [darkTheme, setDarkTheme] = useState(initTheme);
  const mqListener = e => {
    const preference = JSON.parse(localStorage.getItem(THEME_KEY));
    const value = e.matches ? Theme.DARK : Theme.LIGHT;
    if (!preference || preference === Theme.SYSTEM_PREFERENCE) setDarkTheme(value === Theme.DARK);
  };

  const localStorageListener = useCallback(() => {
    const newValue = JSON.parse(localStorage.getItem(THEME_KEY));
    const defaultValue = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const state = themeState(newValue, defaultValue);
    setDarkTheme(state);
  }, []);

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);
    window.addEventListener('storage', localStorageListener);
    return () => {
      darkThemeMq.removeEventListener('change', mqListener);
      window.removeEventListener('storage', localStorageListener);
    };
  }, []);

  return darkTheme;
};

export const useThemePalette = () => {
  const isDarkTheme = useDarkTheme();
  return tokens.theme[isDarkTheme ? 'dark' : 'light'].palette;
};
