import { RoleName } from './user';

export enum InviteStatus {
  PENDING = 'pending',
  REGISTERED = 'registered',
  EXPIRED = 'expired',
  RESCINDED = 'rescinded',
  REVOKED = 'revoked',
  DECLINED = 'declined',
}
export interface Invite {
  InviteId: string; // uuid
  Status: InviteStatus;
  NamespaceId: string;
  Roles: RoleName[];

  InvitedBy: string; // user id
  InvitedAt: string; // ISO timestamp
  ExpiresAt: string; // ISO timestamp

  Email?: string;
  UserId?: string;
  SubjectId?: string;

  AcceptedAt?: string; // ISO timestamp
  RevokedAt?: string; // ISO timestamp
  DeclinedAt?: string; // ISO timestamp
}

export interface InviteeTenantDetails {
  tenant_id: string;
  friendly_name: string;
}
export interface InviteDetails {
  InviteId: string;
  NamespaceId: string;
  FriendlyName: string;
  Roles: RoleName[];
  Email: string;
  InvitedBy: string;
  PlanType: string;
  UserHasRunningInstances: boolean;
  InviteeTenantsWithAffectedInstances: InviteeTenantDetails[];
}
