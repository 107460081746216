import React from 'react';

import { Alert, Button, Dialog, FormSelect, TextInput } from 'foundation';
import { Invite } from 'types/invite';
import { getTenantRoleOptions, isTenantRole } from 'types/user';
import { useSession } from 'store';

interface Props {
  open: boolean;
  invite: Invite;
  revokeInProgress: boolean;
  onClose: () => void;
  onRevoke: (invite: Invite) => void;
  errorMessage?: string;
}

const InviteRevokeModal = ({
  open,
  invite,
  revokeInProgress,
  onClose,
  onRevoke,
  errorMessage = null,
}: Props) => {
  const session = useSession();
  const currentRole = invite.Roles.find(r => isTenantRole(r));
  const roleOptions = getTenantRoleOptions(session.tenant);

  return (
    <Dialog size="small" open={open} onClose={onClose}>
      <Dialog.Header>Revoke Invite</Dialog.Header>
      {errorMessage && <Alert type="danger" description={errorMessage} className="tw-mb-6" />}
      <Dialog.Content>
        <TextInput fluid disabled readOnly label="Email" value={invite.Email} className="tw-my-6" />
        <FormSelect
          label="Role"
          searchable={false}
          value={currentRole}
          options={roleOptions}
          disabled={true}
          onChange={() => {}}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" loading={revokeInProgress} onClick={() => onRevoke(invite)}>
          Revoke
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default InviteRevokeModal;
