/* global window, document */

import 'regenerator-runtime/runtime'; // eslint-disable-line
import location from 'browser/location';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

interface WindowAnalytics {
  track: Function;
  identify: Function;
  alias: Function;
  load: Function;
  page: Function;
  user: Function;
}

export type GlobalWindow = Window &
  typeof globalThis & { analytics: WindowAnalytics; gtag: Function };

// This helps to avoid exporting undefined objects, which helps with Jest mocking
const fakeFn = () => {
  throw new Error('Using undefined function');
};

const getUrlSearchParams = () => {
  // FIXME: Delete this
  if (!window.URLSearchParams) {
    throw new Error('Using undefined function');
  }

  return new window.URLSearchParams(location.search);
};

const xmlHttpRequest: typeof window.XMLHttpRequest = window.XMLHttpRequest || (fakeFn as any);
const urlSearchParams: typeof window.URLSearchParams = window.URLSearchParams || (fakeFn as any);
const url: typeof window.URL = window.URL || (fakeFn as any);
const fetch: typeof window.fetch = window.fetch.bind(window) || fetchPolyfill || fakeFn;
const decodeURI: typeof window.decodeURI = window.decodeURI || fakeFn;

export default {
  window: window as GlobalWindow,
  document,
  fetch,
  URL: url,
  URLSearchParams: urlSearchParams,
  XMLHttpRequest: xmlHttpRequest,
  decodeURI,
  getUrlSearchParams,
};
