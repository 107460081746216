import React, { useEffect, useState } from 'react';
import { ToastNotification } from './types';
import classnames from 'classnames';
import { NotificationLevel } from 'state/notifications';
import { NotificationsStore } from 'state';
import { Alert } from 'foundation/alert';
import { BannerType } from '@neo4j-ndl/react';

const levelToAlertType: Record<NotificationLevel, BannerType> = {
  [NotificationLevel.ERROR]: 'danger',
  [NotificationLevel.WARNING]: 'warning',
  [NotificationLevel.SUCCESS]: 'success',
  [NotificationLevel.INFO]: 'info',
};

const levelToDefaultTitle: Record<NotificationLevel, string> = {
  [NotificationLevel.ERROR]: 'Error',
  [NotificationLevel.WARNING]: 'Warning',
  [NotificationLevel.SUCCESS]: 'Success',
  [NotificationLevel.INFO]: 'Information',
};

export const Toast = ({ notification }: { notification: ToastNotification }) => {
  const [show, setShow] = useState(false);
  const { expiresAfter, message, level, title, mounted } = notification;

  const alertType = levelToAlertType[level];
  const titleWithDefault = title || levelToDefaultTitle[level];

  const classes = classnames('toast', { show: show && mounted });

  const handleClose = () => {
    if (!mounted || !NotificationsStore.has(notification.id)) {
      return;
    }

    if (notification.onClose) {
      notification.onClose();
    }

    NotificationsStore.remove(notification.id);
  };

  useEffect(() => setShow(true), []);

  useEffect(() => {
    if (expiresAfter) {
      setTimeout(handleClose, expiresAfter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAfter]);

  const actionsWithClose = notification.actions?.map(action => ({
    label: action.label,
    onClick: () => {
      action.onClick();
      handleClose();
    },
  }));

  return (
    <Alert
      title={titleWithDefault}
      description={message}
      type={alertType}
      className={classes}
      onClose={handleClose}
      actions={actionsWithClose}
      floating
      closeable
      icon
    />
  );
};
