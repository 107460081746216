import { Region, Tier } from 'entities/database';
import { ProviderConfigs, ProviderConfigTier } from 'entities/tenant';

export const getRegionsForTier = (providerConfigs: ProviderConfigs, tier: Tier): Region[] => {
  return Object.values(providerConfigs).flatMap(providerConfig => {
    return providerConfig.tiers[tier].regions;
  });
};

export const getTiersForAllCsps = (providerConfigs: ProviderConfigs): Tier[] => {
  const availableTiersSet = new Set<Tier>();
  Object.values(providerConfigs).forEach(({ tiers }) => {
    Object.keys(tiers).forEach((tier: Tier) => {
      availableTiersSet.add(tier);
    });
  });
  return Array.from(availableTiersSet);
};

export const getRegionsForAllCsps = (providerConfigs: ProviderConfigs): Region[] => {
  const availableRegionsSet = new Set<Region>();
  Object.values(providerConfigs).forEach(({ tiers }) => {
    Object.values(tiers).forEach((tierConfig: ProviderConfigTier) => {
      tierConfig.regions.forEach(region => {
        availableRegionsSet.add(region);
      });
    });
  });
  return Array.from(availableRegionsSet);
};
