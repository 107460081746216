import React from 'react';
import { CloudProvider } from 'entities/database';
import { CmekForm } from './cmek-form';
import { Tenant } from 'entities/tenant';
import { EncryptionKey } from 'types/encryption-keys';
import { AwsCmekPermissionsInstructions } from './permissions/aws';
import { GcpCmekPermissionsInstructions } from './permissions/gcp';
import {
  AzureAddCustomerKeyInstructions,
  AzureCreatePrincipalInstructions,
} from './permissions/azure';

type CmekDialogProps = {
  tenant: Tenant;
  step: number;
  encryptionKey: EncryptionKey;
  updateStep: (step: number) => void;
  onClose: () => void;
  onSuccess: (encryptionKey: EncryptionKey) => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
};

const getContentByCloudProvider = (
  tenant: Tenant,
  step: number,
  encryptionKey: EncryptionKey,
  updateStep: (step: number) => void,
  onClose: () => void,
  onSuccess: (encryptionKey: EncryptionKey) => void,
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void
) => {
  const cloudProviders = Object.keys(tenant.providerConfigs);
  const cloudProvider = cloudProviders[0];
  switch (cloudProvider) {
    case CloudProvider.AWS:
      switch (step) {
        case 0:
          return (
            <CmekForm
              step={step}
              tenant={tenant}
              cloudProviders={cloudProviders}
              onClose={onClose}
              onSuccess={onSuccess}
              updateStep={updateStep}
              updateEncryptionKey={updateEncryptionKey}
            />
          );
        case 1:
          return <AwsCmekPermissionsInstructions encryptionKey={encryptionKey} onClose={onClose} />;
        default:
          return null;
      }
    case CloudProvider.GCP:
      switch (step) {
        case 0:
          return (
            <CmekForm
              step={step}
              tenant={tenant}
              cloudProviders={cloudProviders}
              onClose={onClose}
              onSuccess={onSuccess}
              updateStep={updateStep}
              updateEncryptionKey={updateEncryptionKey}
            />
          );
        case 1:
          return <GcpCmekPermissionsInstructions encryptionKey={encryptionKey} onClose={onClose} />;
        default:
          return null;
      }
    case CloudProvider.AZURE:
      switch (step) {
        case 0:
          return (
            <AzureAddCustomerKeyInstructions
              step={step}
              updateStep={updateStep}
              onClose={onClose}
            />
          );
        case 1:
          return (
            <CmekForm
              step={step}
              tenant={tenant}
              cloudProviders={cloudProviders}
              onClose={onClose}
              onSuccess={onSuccess}
              updateStep={updateStep}
              updateEncryptionKey={updateEncryptionKey}
            />
          );
        case 2:
          return (
            <AzureCreatePrincipalInstructions encryptionKey={encryptionKey} onClose={onClose} />
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export const CmekDialog = ({
  tenant,
  step,
  encryptionKey,
  updateStep,
  onClose,
  onSuccess,
  updateEncryptionKey,
}: CmekDialogProps) => {
  return getContentByCloudProvider(
    tenant,
    step,
    encryptionKey,
    updateStep,
    onClose,
    onSuccess,
    updateEncryptionKey
  );
};
