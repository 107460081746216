import React from 'react';
import { Button, Dialog } from '@neo4j-ndl/react';
import { CopyInput } from 'components/application/copy-input';
import { EncryptionKey } from 'types/encryption-keys';

export interface GcpCmekPermissionsInstructionsProps {
  encryptionKey: EncryptionKey;
  onClose: () => void;
}

export const GcpCmekPermissionsInstructions = ({
  encryptionKey,
  onClose,
}: GcpCmekPermissionsInstructionsProps) => {
  const serviceAccounts = encryptionKey.gcpProperties?.permissions.serviceAccounts ?? [];

  return (
    <Dialog
      open={true}
      modalProps={{
        'data-testid': 'get-cmek-encryption-key-dialog',
      }}
      onClose={onClose}
    >
      <Dialog.Header>Customer Managed Key Permissions</Dialog.Header>
      <Dialog.Content className="tw-space-y-6">
        <div>
          <p className="tw-mb-4">
            Within GCP Key Management, grant the built-in{' '}
            <code>&quot;Cloud KMS CryptoKey Encrypter/Decrypter&quot;</code> and{' '}
            <code>&quot;Cloud KMS Viewer&quot;</code> roles to the following service accounts:
          </p>
          {serviceAccounts.map((serviceAccount: string, index: number) => {
            return (
              <CopyInput
                wrapperClassName="tw-mb-4"
                value={`${serviceAccount}`}
                label=""
                id={`sa-${index}`}
                key={`sa-${index}`}
                readOnly
              />
            );
          })}
        </div>
      </Dialog.Content>
      <Dialog.Actions className="tw-justify-between">
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Close
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
