import React, { useEffect, useState } from 'react';

import { Alert, Button, Dialog, FormSelect, SelectOption, TextInput } from 'foundation';
import { RoleName, RoleOption, Member } from 'types/user';

interface Props {
  open: boolean;
  user: Member;
  editInProgress: boolean;
  onClose: () => void;
  onEdit: (user: Member, newRoleName: RoleName) => void;
  errorMessage?: string;
  roleOptions: RoleOption[];
}

const EditUserModal = ({
  open,
  user,
  editInProgress,
  onClose,
  onEdit,
  errorMessage = null,
  roleOptions,
}: Props) => {
  const currentRoleName = user.Roles[0].Name;

  const [selectedRoleName, setSelectedRoleName] = useState(currentRoleName);
  const [saveEnabled, setSaveEnabled] = useState(false);
  useEffect(() => {
    setSaveEnabled(currentRoleName !== selectedRoleName && !editInProgress);
  }, [selectedRoleName, editInProgress]);

  return (
    <Dialog
      size="small"
      open={open}
      onClose={onClose}
      modalProps={{ 'data-testid': 'edit-user-modal' }}
    >
      <Dialog.Header>Edit User Role</Dialog.Header>
      <Dialog.Content>
        {errorMessage && <Alert type="danger" description={errorMessage} className="tw-mb-6" />}
        <TextInput fluid disabled readOnly label="Email" value={user.Email} className="tw-my-6" />
        <FormSelect
          label="Role"
          searchable={false}
          value={selectedRoleName}
          options={roleOptions}
          onChange={(role: SelectOption) => {
            setSelectedRoleName(role.value as RoleName);
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!saveEnabled}
          loading={editInProgress}
          data-testid="save-role-edit"
          onClick={() => onEdit(user, selectedRoleName)}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditUserModal;
