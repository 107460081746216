import userTrackingActions from 'actions/user-tracking-actions';

export const trackAction = data => {
  if (!data || Object.keys(data).length === 0) {
    return;
  }

  let path = data.page || '';

  if (data.view) {
    path += `${path ? '/' : ''}${data.view}`;
  }

  const action = data.action ? data.action.toUpperCase() : `NAVIGATE_TO_${path.toUpperCase()}`;

  userTrackingActions.trackEvent(action, {
    application: 'WEBAPP',
    page: data.page,
    view: data.view,
    action: data.action,
    event: data.event,
    ...data.properties,
  });
};
