import globals from 'browser/globals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import RootPage from 'pages/root';
import logger from 'logger';
import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/types';
import { store } from 'store';
import { omit } from 'utils/javascript';
import { SessionState } from 'state/session-store';
import { QueryClient } from 'react-query';

const sentryEnvironment = process.env.ENVIRONMENT || 'localhost';
const isSentryAlwaysEnabledEnvironment = ['prestaging', 'staging', 'production'].includes(
  sentryEnvironment
);
const sentryEnabled = process.env.FORCE_SENTRY_ENABLED || isSentryAlwaysEnabledEnvironment;

const stripPIInfo = (session?: SessionState): Partial<SessionState> => {
  if (!session) {
    return session;
  }

  return omit(session, ['oauthBearerToken', 'name', 'email', 'picture']);
};

Sentry.init({
  dsn: sentryEnabled
    ? 'https://9c13877e30e14d5292c1ef92c57eb4f1@o110884.ingest.sentry.io/6557993'
    : null,
  environment: sentryEnvironment,
  release: process.env.SENTRY_RELEASE, // Replaced during webpack build
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, // Send 100% of error events to sentry
  ignoreErrors: [
    // Ignore errors due to network issues (ie NetworkError thrown by fetch)
    'Failed to fetch',
    'Content Security Policy',
  ],
  beforeSend: event => {
    const state = store.getState();
    const userId = state?.session?.userId;
    return {
      ...event,
      user: { id: userId },
      contexts: { ...(event.contexts || {}), session: stripPIInfo(state?.session) },
    };
  },
  beforeBreadcrumb(breadcrumb: Breadcrumb, hint: Sentry.BreadcrumbHint) {
    if (breadcrumb.type === 'http') {
      const url = breadcrumb.data?.url;
      // Exclude anything except for relative URLS such as '/databases/deadbeef'
      // In order to filter out 3rd party requests such as segment/canny/mixpanel
      if (!url?.match(/^\//)) {
        return null;
      }
    }

    try {
      // Try to report ui elements innerText
      if (breadcrumb.category.startsWith('ui')) {
        const clickElements = ['button', 'a'];
        let target = hint.event.target;

        // Sometimes the event is for a child of the button, ie
        // we get the svg path element click event for clicking
        // on the icon in an icon button. Theres no good
        // attributes so we try to find the parent a or button
        // element if one exists
        if (!clickElements.includes(target.tagName.toLowerCase())) {
          const closestLink = target.closest('a');
          const closestButton = target.closest('button');

          if (closestLink || closestButton) {
            target = closestLink ?? closestButton;
          }
        }

        let message = `${breadcrumb.message}`;

        const testid = target.dataset.testid;
        const testidMessage = testid ? `[data-testid="${testid}"]` : '';

        // Only get innerText for button/link elements. We don't want the body
        // of a <p> element for example
        const tagName = target.tagName.toLowerCase();
        const innerText = clickElements.includes(tagName) ? target.innerText : undefined;
        const innerTextMessage = innerText ? ` innerText="${innerText}"` : '';

        const title = target.title;
        const titleMessage = title ? `[title="${title}"]` : '';

        const ariaLabel = target.ariaLabel;
        const ariaLabelMessage = ariaLabel ? `[aria-label="${ariaLabel}"]` : '';

        const additionalMessage = `${testidMessage}${titleMessage}${ariaLabelMessage}${innerTextMessage}`;

        const messageToAppend = additionalMessage ? ` CTX:${additionalMessage}` : '';

        message = `${message}${messageToAppend}`;

        breadcrumb.message = message;
      }
    } catch (e) {
      // pass
    }

    return breadcrumb;
  },
});

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

logger.log(`Environment mode: ${process.env.NODE_ENV}`);

createRoot(globals.document.getElementById('reactContent')).render(<RootPage client={client} />);
