import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Invite, InviteStatus } from 'types/invite';
import { EditInviteModal } from './edit-invite-modal';
import { RevokeInviteModal } from './revoke-invite-modal';
import {
  StickyActionsDataGrid,
  Menu,
  MenuItem,
  IconButton,
  createColumnHelper,
  useDefaultTable,
} from 'foundation';
import { Tenant } from 'entities/tenant';

interface Props {
  invites: Invite[];
  onUpdate: () => any;
  tenant: Tenant;
}

interface Headers {
  InviteId: string;
  Status: string;
  Actions: React.ReactNode;
}

const helper = createColumnHelper<Headers>();

export const InvitesTable = ({ invites, onUpdate, tenant }: Props) => {
  const columns = useMemo(
    () => [
      helper.accessor('InviteId', {
        minSize: 400,
      }),
      helper.accessor('Status', {
        minSize: 350,
      }),
      helper.accessor('Actions', {
        header: null,
        id: 'actions',
        size: 90,
        cell: cell => cell.getValue(),
        enableSorting: false,
        enableResizing: false,
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    []
  );

  const data: Headers[] = useMemo(() => {
    return invites.map(invite => ({
      InviteId: invite.Email ?? invite.SubjectId ?? invite.InviteId,
      Status: invite.Status,
      Actions: <InviteActions invite={invite} onUpdate={onUpdate} tenant={tenant} />,
    }));
  }, [invites]);

  const table = useDefaultTable({
    columns,
    data,
    enableHiding: true,
  });

  return <StickyActionsDataGrid data-testid="users-table" tableInstance={table} />;
};

interface InviteActionsProps {
  invite: Invite;
  onUpdate: () => any;
  tenant: Tenant;
}

const EDITABLE_STATUSES = [InviteStatus.EXPIRED, InviteStatus.RESCINDED, InviteStatus.PENDING];
const REVOKEABLE_STATUSES = [InviteStatus.PENDING];

const InviteActions = ({ invite, onUpdate, tenant }: InviteActionsProps) => {
  const [showRevokeInvite, setshowRevokeInvite] = useState(false);
  const [showEditInvite, setShowEditInvite] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleEditInviteClick = () => setShowEditInvite(true);
  const handleEditInviteClose = () => {
    setShowEditInvite(false);
    setAnchorEl(false);
  };
  const handleEditInviteSuccess = () => {
    setShowEditInvite(false);
    onUpdate();
  };
  const handleRevokeInviteClose = () => {
    setshowRevokeInvite(false);
    setAnchorEl(false);
  };
  const handleRevokeInviteSuccess = () => {
    setshowRevokeInvite(false);
    onUpdate();
  };

  return (
    <>
      {EDITABLE_STATUSES.includes(invite.Status) && (
        <>
          <IconButton
            iconName="EllipsisHorizontalIconOutline"
            onClick={handleOpen}
            title="Additional actions"
            aria-label="Additional invite actions"
            clean
          />
          <Menu
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            data-testid="invite-actions"
          >
            <Menu.Items>
              {REVOKEABLE_STATUSES.includes(invite.Status) && (
                <MenuItem
                  name="Revoke invite"
                  title="Revoke invite"
                  data-testid="revoke-invite"
                  onClick={() => setshowRevokeInvite(true)}
                  style={{ display: 'flex' }}
                  nested={false}
                />
              )}
              <MenuItem
                data-testid="edit-invite"
                onClick={handleEditInviteClick}
                name="Edit invite"
                title="Edit invite"
              />
            </Menu.Items>
          </Menu>
        </>
      )}
      <RevokeInviteModal
        invite={invite}
        open={showRevokeInvite}
        onClose={handleRevokeInviteClose}
        onSubmit={handleRevokeInviteSuccess}
      />
      <EditInviteModal
        open={showEditInvite}
        onClose={handleEditInviteClose}
        onSuccess={handleEditInviteSuccess}
        invite={invite}
        tenant={tenant}
      />
    </>
  );
};
