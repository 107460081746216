import React, { useState } from 'react';
import UsersResource from 'remote/resources/users';
import { Button, Alert, Dialog } from 'foundation';
import { User } from 'types/user';

interface Props {
  user: User;
  open: boolean;
  onClose: () => any;
}

export const ResetUserAuthenticationModal = ({ user, open, onClose }: Props) => {
  const [error, setError] = useState<Error>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      await UsersResource.resetAuthenticationMethod(user.UserId);
      setSuccess(true);
    } catch (err) {
      switch (err.reason) {
        case 'subject-id-not-found':
          setError(
            Error("User's authentication method has already been cleared or cannot be found")
          );
          break;
        default:
          setError(err);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header data-testid="reset-user-auth-header">Are you sure?</Dialog.Header>
      <Dialog.Content data-testid="reset-user-auth-content">
        <Dialog.Description>
          This will reset the user&apos;s authentication method.
        </Dialog.Description>
        {error && (
          <Alert type="danger" className="tw-mt-2">
            {String(error)}
          </Alert>
        )}
        {success && (
          <Alert type="success" data-testid="reset-user-auth-success" className="tw-mt-2">
            User authentication method successfully reset.
          </Alert>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          data-testid="reset-user-auth-cancel"
          onClick={onClose}
          fill="outlined"
          color="neutral"
        >
          Cancel
        </Button>
        <Button
          data-testid="reset-user-auth-button"
          color="primary"
          onClick={handleSubmit}
          disabled={loading || success}
        >
          Reset user authentication method
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
