import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import OrganizationsResource from 'remote/resources/organizations';
import { OrganizationsTable } from './organizations-table';
import { useNotify } from 'state/notifications';
import { Organization } from 'types/organization';

const SearchOrganizationsForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const notify = useNotify();

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleSearchSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setOrganizations([]);
    setError(null);

    try {
      const searchResult = await OrganizationsResource.search(query.trim());
      setOrganizations(searchResult.organizations);
      if (searchResult.hasHitLimit) {
        notify.warning(
          'Only the first 100 search results are displayed. Update the search string to refine the search results.'
        );
      }
    } catch (err) {
      setError(String(err));
    }
    setLoading(false);
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">
        This form enables you to search for an organization by internal name.
      </p>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          label="Internal Organization name"
          data-testid="lookup-organizations-by-query-input"
          name="name_prefix"
          autoComplete="name_prefix"
          helpText="Search for an organization by Internal Name prefix"
        />
        <Button
          className="tw-mt-8"
          type="submit"
          color="primary"
          loading={loading}
          disabled={!query}
          data-testid="lookup-organizations-by-query-button"
        >
          Search
        </Button>

        {error && (
          <Alert type="danger" data-testid="lookup-organizations-error-message" className="tw-mt-8">
            {error}
          </Alert>
        )}
      </Form>

      {organizations.length > 0 && (
        <OrganizationsTable
          organizations={organizations}
          data-testid="search-organizations-result-table"
        />
      )}
    </div>
  );
};

export default SearchOrganizationsForm;
