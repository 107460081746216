import { TokenResult } from '@stripe/stripe-js';
import ApiClient from 'remote/api-client';
import { Customer, CustomerCardResponse, CustomerResponse } from 'types/customer';

const getCustomer = (tenantId: string): Promise<CustomerResponse> =>
  ApiClient.get(`/tenants/${tenantId}/billing`).promise();

const createCustomer = (
  tenantId: string,
  token: TokenResult,
  isTrialExtension: boolean
): Promise<CustomerResponse> =>
  ApiClient.post(`/tenants/${tenantId}/billing`)
    .ignore(422)
    .issue({ ...token, is_trial_extension: isTrialExtension })
    .promise();

const updateCustomer = (
  tenantId: string,
  { data, email }: { data?: Customer; email?: string }
): Promise<CustomerResponse> =>
  ApiClient.patch(`/tenants/${tenantId}/billing`)
    .ignore(422)
    .issue({ data, email })
    .promise();

const addCard = (tenantId: string, token: TokenResult): Promise<CustomerCardResponse> =>
  ApiClient.post(`/tenants/${tenantId}/billing/card`)
    .ignore(422)
    .issue(token)
    .promise();

const getCard = (tenantId: string): Promise<CustomerCardResponse> =>
  ApiClient.get(`/tenants/${tenantId}/billing/card`).promise();

export default {
  createCustomer,
  getCustomer,
  updateCustomer,
  addCard,
  getCard,
};
