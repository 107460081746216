import { NotificationsStore } from 'state';
import { NotificationAction, NotificationLevel } from 'state/notifications';

interface Props {
  message: string;
  level: NotificationLevel;
  title?: string;
  expiresAfter?: number;
  actions?: NotificationAction[];
}

const show = ({ message, level, title = null, expiresAfter = 6000 }: Props) => {
  NotificationsStore.add({ message, level, title, expiresAfter });
};

export default { show };
