import SnapshotsResource from 'remote/resources/snapshots';
import { saveAs } from 'file-saver';
import logger from 'logger';

const downloadSnapshot = (dbId: string, snapshotId: string, fileName: string) =>
  SnapshotsResource.getUrl(dbId, snapshotId)
    .then(url => {
      saveAs(url, fileName);
    })
    .catch(error => {
      logger.debug('Failed to download due to error:', error);
    });

export default downloadSnapshot;
