import {
  Alert,
  Button,
  ConfirmModal,
  createColumnHelper,
  Dialog,
  IconButton,
  Label,
  StickyActionsDataGrid,
  useDefaultTable,
} from 'components/foundation';
import RemoteDatabases from 'remote/resources/databases';
import { CloudProvider, Database } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import React, { useMemo, useState } from 'react';
import { EncryptionKey, EncryptionKeyStatus } from 'types/encryption-keys';
import { productFriendlyName } from 'types/product';
import { useNotify } from 'state/notifications';
import { ConfirmAction } from 'components/foundation/confirm-modal';
import EncryptionKeyResource from 'remote/resources/encryption-keys';

import './cmek.css';
import { getEncryptionKeyId } from 'entities/encryption-keys';
import { capitalizeCloudProvider } from 'utils/capitalize-cloud-provider';
import { CmekDialog } from './instructions';
import { getRegionsForAllCsps } from 'utils/tiers-and-regions';
import { parseISO, format } from 'date-fns';
import { useTracking } from 'react-tracking';

const columnHelper = createColumnHelper<EncryptionKey>();

const EncryptionKeyStatusLabel = ({ value }: { value: EncryptionKeyStatus }) => {
  let color: 'default' | 'danger' | 'success';
  let fill: React.ComponentProps<typeof Label>['fill'] = 'clean';

  if (value === EncryptionKeyStatus.READY) {
    color = 'success';
  } else if (value === EncryptionKeyStatus.PENDING) {
    color = 'default';
  } else {
    // Case DELETED or ERROR
    color = 'danger';
  }

  return (
    <Label fill={fill} color={color} withIcon>
      {value}
    </Label>
  );
};

const CmekTable = ({
  tenant,
  cmeks,
  updateStep,
  updateEncryptionKey,
  handleClose,
}: {
  tenant: Tenant;
  cmeks: EncryptionKey[];
  updateStep: (step: number) => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
  handleClose: () => void;
}) => {
  const regions = useMemo(() => getRegionsForAllCsps(tenant.providerConfigs), [
    tenant.providerConfigs,
  ]);
  const cloudProvider = Object.keys(tenant.providerConfigs)[0];

  const keyIdAccessorMap = {
    gcp: 'gcpProperties.keyId',
    aws: 'awsProperties.kmsKeyArn',
    azure: 'azureProperties.keyId',
  };
  const keyIdAccessor = keyIdAccessorMap[cloudProvider];

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: c => {
          const name = c.getValue();
          return <span className="n-subheading-medium">{name}</span>;
        },
        header: 'Name',
      }),
      columnHelper.accessor('region', {
        cell: c => {
          const value = c.getValue();
          const region = regions.find(r => r.name === value);
          return <span className="n-subheading-medium">{region.friendly}</span>;
        },
        header: 'Region',
        minSize: 200,
      }),
      columnHelper.accessor('tier', {
        cell: c => {
          const product = getProductFromTier(c.getValue());
          const value = productFriendlyName(product);
          return <span className="n-subheading-medium">{value}</span>;
        },
        header: 'Product',
      }),
      columnHelper.accessor(keyIdAccessor, {
        cell: c => {
          const keyId = c.getValue();
          return <span className="n-subheading-medium">{keyId}</span>;
        },
        header:
          cloudProvider === CloudProvider.AWS
            ? 'Key ARN'
            : cloudProvider === CloudProvider.AZURE
            ? 'Key Identifier'
            : 'Key Resource Name',
        minSize: 400,
      }),
      columnHelper.accessor('created', {
        cell: c => {
          return (
            <span className="n-subheading-medium">
              {format(parseISO(c.getValue()), 'do MMM yyyy')}
            </span>
          );
        },
        header: 'Added',
      }),
      columnHelper.accessor('status', {
        cell: c => {
          const status = c.getValue();
          return <EncryptionKeyStatusLabel value={status} />;
        },
        header: 'Status',
      }),
      columnHelper.display({
        id: 'actions',
        cell: c => {
          const key = c.row.original;
          return (
            <span className="tw-flex tw-gap-3 tw-justify-end tw-w-full">
              <GetKeyPermissionsComponent
                encryptionKey={key}
                tenant={tenant}
                cloudProvider={cloudProvider}
                updateStep={updateStep}
                updateEncryptionKey={updateEncryptionKey}
                handleClose={handleClose}
              />
              <DeleteKeyComponent encryptionKey={key} tenant={tenant} />
            </span>
          );
        },
        meta: {
          isStickyAction: true,
        },
        size: 120,
      }),
    ],
    [tenant, regions]
  );

  const table = useDefaultTable({
    data: cmeks,
    columns,
  });

  return (
    <div
      data-testid="cmek-table"
      className="cmek-table tw-p-8 tw-rounded-lg tw-bg-palette-neutral-bg-weak"
    >
      <StickyActionsDataGrid tableInstance={table} />
    </div>
  );
};

interface GetKeyPermissionsComponentProps {
  encryptionKey: EncryptionKey;
  tenant: Tenant;
  cloudProvider: string;
  updateStep: (step: number) => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
  handleClose: () => void;
}

const GetKeyPermissionsComponent = ({
  encryptionKey,
  tenant,
  cloudProvider,
  updateStep: setStep,
  updateEncryptionKey,
  handleClose,
}: GetKeyPermissionsComponentProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  return (
    <div>
      <IconButton
        className="console-cmek-get-button"
        iconName="PencilIconOutline"
        title="Get encryption key"
        aria-label="Get encryption key"
        clean
        data-testid="get-cmek-button"
        onClick={handleOpenDialog}
      />
      {dialogOpen && (
        <CmekDialog
          tenant={tenant}
          step={cloudProvider === CloudProvider.AZURE ? 2 : 1}
          encryptionKey={encryptionKey}
          updateStep={setStep}
          updateEncryptionKey={updateEncryptionKey}
          onClose={() => {
            setDialogOpen(false);
            handleClose();
          }}
          onSuccess={null}
        />
      )}
    </div>
  );
};

interface DeleteKeyComponentProps {
  tenant: Tenant;
  encryptionKey: EncryptionKey;
}

const DeleteKeyComponent = ({ tenant, encryptionKey }: DeleteKeyComponentProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [linkedInstances, setLinkedInstances] = useState<Database[]>([]);
  const [deleteError, setDeleteError] = useState();

  const handleOpenModal = () => {
    setLoading(true);
    // Check if key is currently used and open corresponding modal
    RemoteDatabases.list(tenant.id)
      .then(dbs => {
        const linkedInstances = dbs.filter(
          db => db.EncryptionKey?.encryptionKeyRef === encryptionKey.encryptionKeyRef
        );
        setLinkedInstances(linkedInstances);

        const keyIsUsed = linkedInstances.length > 0;

        if (keyIsUsed) {
          setWarningDialogOpen(true);
        } else {
          setDeleteModalOpen(true);
        }
      })
      .catch(() => {
        notify.error(
          'An unexpected error occurred. Try again later. If the problem persists, contact support.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleCloseWarningModal = () => setWarningDialogOpen(false);

  const tracking = useTracking();

  const handleDeleteKey = () => {
    setLoading(true);
    EncryptionKeyResource.deleteKey(tenant.id, encryptionKey.encryptionKeyRef)
      .then(() => {
        handleCloseDeleteModal();
        notify.success(`Encryption Key "${encryptionKey.name}" successfully deleted.`);
        tracking.trackEvent({
          action: 'delete_customer_managed_key',
        });
      })
      .catch(err => {
        if (err.response.status === 422) {
          setDeleteError(err);
        } else {
          notify.error(
            `There was an error deleting the encryption key "${encryptionKey.name}". Try again later. If the problem persists, contact support.`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <IconButton
        className="console-cmek-delete-button"
        iconName="TrashIconOutline"
        title="Delete encryption key"
        aria-label="Delete cmek"
        danger
        onClick={handleOpenModal}
        disabled={loading}
        data-testid={`delete-cmek-button-${encryptionKey.encryptionKeyRef}`}
      />
      {/* Dialog to inform that Key is in use */}
      <Dialog
        open={warningDialogOpen}
        modalProps={{
          'data-testid': 'delete-cmek-warning-dialog',
        }}
        onClose={handleCloseWarningModal}
      >
        <Dialog.Header>Cannot Delete Active Key</Dialog.Header>
        <Dialog.Content>
          <p>
            The Customer Managed Key <b>{encryptionKey.name}</b> is currently being used by the
            following instance
            {`${linkedInstances.length > 1 ? 's' : ''}`}:
          </p>
          <ul className="tw-list-disc tw-ml-10 tw-mt-2">
            {linkedInstances.map((instance, index) => {
              return (
                <li
                  className="tw-list-item"
                  key={index}
                >{`${instance.Name} (${instance.DbId})`}</li>
              );
            })}
          </ul>
          <br></br>
          <p>To delete the key from Aura, ensure it is not linked to any active instance.</p>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={handleCloseWarningModal}>Close</Button>
        </Dialog.Actions>
      </Dialog>
      {/* Modal for Deleting the Key */}
      <ConfirmModal
        negative
        open={deleteModalOpen}
        title="Delete Customer Managed Key?"
        onCancel={handleCloseDeleteModal}
        onConfirm={handleDeleteKey}
        confirmText="CONFIRM"
        action={ConfirmAction.DELETE}
        loading={loading}
        content={
          <div>
            <p>
              Are you sure you want to delete the Customer Managed Key{' '}
              <strong>{encryptionKey.name}</strong> with{' '}
              {encryptionKey.cloudProvider === CloudProvider.AWS
                ? 'Key ARN'
                : encryptionKey.cloudProvider === CloudProvider.AZURE
                ? 'Key Identifier'
                : 'Key Resource Name'}{' '}
              <strong>{getEncryptionKeyId(encryptionKey)} </strong>?
            </p>
            <br />
            <p>
              Deleting it will remove the key from Aura. If the key still exists in your{' '}
              {capitalizeCloudProvider(encryptionKey.cloudProvider)} account, it will remain there.
            </p>
            {deleteError && (
              <Alert
                className="tw-mt-4"
                description={
                  'Could not delete Key. Ensure it is not linked to any active instances.'
                }
                type="danger"
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export { CmekTable };
