import React, { useState } from 'react';
import { Alert, Button, ConfirmModal, Dialog, FormInput, Tip } from 'foundation';
import { ErrorReasons } from 'remote/error-handler/error-reason-codes';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';
import { InternalTenant } from 'entities/tenant';
import { ConfirmAction } from 'components/foundation/confirm-modal';
import globals from 'browser/globals';
import TenantName from 'components/application/tenant-name';

const reasonSchema = yup.object({
  reason: yup
    .string()
    .label('Reason')
    .min(10)
    .required(),
});

const validateReason = data => {
  return validateYup(reasonSchema, data, false);
};

interface ModalProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  suspensionReason?: string;
  setSuspensionReason?: (str) => any;
  tenant: InternalTenant;
}

enum TearDownStep {
  InputSuspensionReason = 'input-suspension-reason',
  Confirm = 'confirm',
}

const getErrorMessage = (err?: Error | null): string | undefined => {
  if (!err) {
    return undefined;
  }

  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case ErrorReasons.ONGOING_DATABASE_OPERATION:
      return 'Some of the projects databases are still ongoing database operations (Creating, Deleting, Resuming, etc.); retry suspending the project in a while.';
    default:
      return `Error: ${err.reason || 'unknown'}: ${err.message}`;
  }
};

export const TearDownTenantModal = ({
  open,
  loading,
  error,
  onClose,
  onConfirm,
  suspensionReason,
  setSuspensionReason,
  tenant,
}: ModalProps) => {
  const [validationError, setValidationError] = useState(null);
  const submitDisabled =
    suspensionReason === null || suspensionReason === '' || validationError !== null;
  const [step, setStep] = useState<TearDownStep>(
    error ? TearDownStep.Confirm : TearDownStep.InputSuspensionReason
  );
  const [tipOpen, setTipOpen] = useState<boolean>(false);

  const handleSuspensionReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(null);
    const error = validateReason({ reason: e.target.value });
    if (error) {
      setValidationError(error);
    }

    setSuspensionReason(e.target.value);
  };

  const handleContinue = () => {
    setStep(TearDownStep.Confirm);
  };

  const handleCancel = e => {
    setStep(TearDownStep.InputSuspensionReason);
    setTipOpen(false);
    onClose(e);
  };

  return (
    <>
      <Dialog
        open={open && step === TearDownStep.InputSuspensionReason}
        onClose={handleCancel}
        modalProps={{ 'data-testid': 'tear-down-tenant-modal' }}
      >
        <Dialog.Header>Tear Down Project</Dialog.Header>
        <Dialog.Content>
          <p>Tearing down the project will:</p>
          <ul className="tw-list-disc tw-list-inside">
            <li>Revoke access for all members.</li>
            <li>Revoke all pending invites.</li>
            <li>Suspend the project.</li>
          </ul>
          <p className="tw-my-2">Please enter a reason for suspending the project below.</p>
          <FormInput
            fluid
            label="Reason"
            className="tw-my-4"
            value={suspensionReason}
            onChange={e => {
              handleSuspensionReasonChange(e);
            }}
            helpText="The suspension reason will be displayed in the project details page"
            errorText={validationError?.reason?.message}
            data-testid="suspension-reason-input"
          />
          <Alert
            type="info"
            description="This action will also trigger suspend on all running databases for this project."
            className="tw-mt-4"
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            color="neutral"
            fill="outlined"
            onClick={onClose}
            data-testid="tear-down-tenant-modal-cancel"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleContinue}
            loading={loading}
            data-testid="tear-down-tenant-modal-continue"
            disabled={submitDisabled}
          >
            Continue
          </Button>
        </Dialog.Actions>
      </Dialog>
      <ConfirmModal
        open={open && step === TearDownStep.Confirm}
        onConfirm={onConfirm}
        onCancel={handleCancel}
        title={
          <div className="tw-truncate">
            Are you sure you want to tear down <br />
            <Tip isOpen={tipOpen} type="toggletip" allowedPlacements={['right', 'left']}>
              <Tip.Trigger className="tw-cursor-text">
                <i
                  onClick={() => {
                    globals.window.navigator.clipboard.writeText(tenant.friendlyName);
                    if (!tipOpen) {
                      setTipOpen(true);
                    }
                  }}
                  aria-aria-label="Copy text"
                >
                  <TenantName name={tenant.friendlyName} tenantId={tenant.id} />
                </i>
              </Tip.Trigger>
              <Tip.Content isPortaled={false}>Copied to clipboard</Tip.Content>
            </Tip>{' '}
            ?
          </div>
        }
        content={
          <div>
            <p className="tw-mb-2">
              If you want to undo a teardown, you will have to reverse each step manually.
            </p>
          </div>
        }
        negative
        action={ConfirmAction.TEAR_DOWN}
        buttonTextLoading="Tearing down"
        confirmText={tenant.friendlyName}
        loading={loading}
        error={getErrorMessage(error)}
        errorHeader={'Something went wrong'}
      ></ConfirmModal>
    </>
  );
};
