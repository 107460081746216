import ApiClient from 'remote/api-client';
import { useQuery, UseQueryResult } from 'react-query';
import {
  AwsProperties,
  AzureProperties,
  GcpProperties,
  TrafficConfig,
  TrafficEnablement,
} from 'types/traffic-configs';
import { Tier } from 'entities/database';

interface UpdateData {
  privateTraffic?: TrafficEnablement;
  publicTraffic?: TrafficEnablement;
  gcpProperties?: GcpProperties;
  awsProperties?: AwsProperties;
  azureProperties?: AzureProperties;
}

const mapTrafficConfig = c => {
  const result: TrafficConfig = {
    cloudProvider: c.CloudProvider,
    tier: c.Tier,
    region: c.Region,
    privateTraffic: c.PrivateTraffic,
    publicTraffic: c.PublicTraffic,
    gcpProperties: c.GcpProperties ? { projectIds: c.GcpProperties.ProjectIds } : null,
    awsProperties: c.AwsProperties ? { endpointIds: c.AwsProperties.EndpointIds } : null,
    azureProperties: c.AzureProperties
      ? {
          endpointIds: c.AzureProperties.EndpointIds,
          subscriptionIds: c.AzureProperties.SubscriptionIds,
        }
      : null,
    status: {
      publicTraffic: c.Status.PublicTraffic,
      privateTraffic: c.Status.PrivateTraffic,
      dnsDomain: c.Status.DnsDomain,
      gcpStatus: c.Status.GcpStatus
        ? { serviceAttachmentUrl: c.Status.GcpStatus.ServiceAttachmentUrl }
        : null,
      awsStatus: c.Status.AwsStatus
        ? {
            endpointServiceName: c.Status.AwsStatus.EndpointServiceName,
            endpointConnections: c.Status.AwsStatus.EndpointConnections.map(conn => ({
              endpointId: conn.EndpointId,
              state: conn.State,
            })),
          }
        : null,
      azureStatus: c.Status.AzureStatus
        ? {
            privateLinkServiceName: c.Status.AzureStatus.PrivateLinkServiceName,
            privateEndpointConnections: c.Status.AzureStatus.PrivateEndpointConnections.map(
              conn => ({
                privateEndpointId: conn.PrivateEndpointId,
                state: conn.State,
              })
            ),
          }
        : null,
    },
  };

  return result;
};

export const update = async (
  tenantId: string,
  tier: Tier,
  region: string,
  data: UpdateData
): Promise<TrafficConfig> => {
  return ApiClient.patch(`/tenants/${tenantId}/traffic-configs/tiers/${tier}/regions/${region}`)
    .issue({
      PrivateTraffic: data.privateTraffic,
      PublicTraffic: data.publicTraffic,
      GcpProperties: data.gcpProperties ? { ProjectIds: data.gcpProperties.projectIds } : undefined,
      AwsProperties: data.awsProperties
        ? { EndpointIds: data.awsProperties.endpointIds }
        : undefined,
      AzureProperties: data.azureProperties
        ? {
            SubscriptionIds: data.azureProperties.subscriptionIds,
            EndpointIds: data.azureProperties.endpointIds,
          }
        : undefined,
    })
    .promise()
    .then(mapTrafficConfig);
};

export const list = async (tenantId: string): Promise<TrafficConfig[]> => {
  const trafficConfigs = await ApiClient.get(`/tenants/${tenantId}/traffic-configs`).promise();

  return trafficConfigs.map(mapTrafficConfig);
};

export const useTrafficConfigs = (tenantId: string): UseQueryResult<TrafficConfig[]> => {
  const query = async () => {
    const trafficConfigs = await list(tenantId);
    return trafficConfigs;
  };

  return useQuery(`traffic-configs-${tenantId}`, query, {
    enabled: !!tenantId,
    refetchInterval: 5000,
  });
};

export default { list, useTrafficConfigs, update };
