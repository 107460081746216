import React from 'react';
import { Page } from 'components/foundation';
import LookupSsoConfig from './lookup-sso-config-page';
import { Action } from 'types/user';
import { usePermissions } from 'store';
export { SsoConfigDetailsPage } from './details';

const LookupSsoConfigPage = () => {
  const permission = usePermissions();
  const canManageSsoConfigs = permission.allow(Action.READ, 'sso-configs/*');

  return (
    <Page fullWidth>
      {canManageSsoConfigs ? (
        <>
          <div className="tw-flex tw-justify-between tw-flex-nowrap tw-mb-2">
            <h5 className="tw-mb-4" data-testid="">
              SSO Configs
            </h5>
          </div>

          <LookupSsoConfig />
        </>
      ) : (
        <h5 data-testid="manage-sso-configs-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default LookupSsoConfigPage;
