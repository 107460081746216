import track from 'react-tracking';

import router from 'pages/routes';
import { default as Layout } from 'application/main-layout';
import { trackAction } from './action-tracker';
import { selectNavigationState, useLocation, useSelector } from 'store';
import React, { useEffect } from 'react';
import globals from 'browser/globals';
import userTracking from 'actions/user-tracking-actions';
import { redact } from 'state/navigation';

export const Controller = () => {
  const location = useLocation();

  // Track location changes
  useEffect(() => {
    const redactedHash = redact(location.hash);
    globals.window.scrollTo(0, 0);
    userTracking.trackPath(`${location.pathname}${redactedHash}`);
  }, [location]);

  const currentPage = useSelector(state => selectNavigationState(state).currentPage);

  return router(currentPage, MainLayout);
};

Controller.displayName = 'Controller';

const MainLayout = ({ children }) => {
  return <Layout>{children}</Layout>;
};

export default track(
  {},
  {
    dispatch: trackAction,
  }
)(Controller);
