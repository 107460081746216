import globals from 'browser/globals';

const env = process.env.NODE_ENV;

const writeKey =
  env === 'production' ? 'UbJKEfylYCxjrGGT3sCoyROOOcFBYsCU' : 'Pl6iKcJBJ1DWYIkDtVgeevatkVyJ0DsI';

const loadAnalytics = () => {
  if (globals.window.analytics) {
    globals.window.analytics.load(writeKey);
    globals.window.analytics.page();
  }
};

export default loadAnalytics;
