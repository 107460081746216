import React, { useState } from 'react';
import { CodeBlock, Dialog, Tip } from 'components/foundation';
import Icon from 'components/ui/icons';
import globals from 'browser/globals';

const MetricsIntegrationConfigModal = ({
  open,
  onClose,
  prometheusConfig,
}: {
  open: boolean;
  onClose: () => void;
  prometheusConfig: string;
}) => {
  const [toolTipOpen, setToolTipOpen] = useState<boolean>(false);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{ 'data-testid': 'link-prometheus-config-modal' }}
    >
      <Dialog.Header>Prometheus job configuration</Dialog.Header>
      <CodeBlock
        code={prometheusConfig}
        language="yaml"
        theme="prism"
        actions={[
          {
            'aria-label': 'copy',
            title: 'copy',
            children: (
              <Tip isOpen={toolTipOpen}>
                <Tip.Trigger hasButtonWrapper={true} style={{ display: 'flex' }}>
                  <Icon title="Copy to clipboard" name="ClipboardIconOutline" />
                </Tip.Trigger>
                <Tip.Content isPortaled={false} style={{ width: '150px' }}>
                  Copied to Clipboard
                </Tip.Content>
              </Tip>
            ),
            onClick: () => {
              globals.window.navigator.clipboard.writeText(prometheusConfig);
              setToolTipOpen(true);

              setTimeout(() => {
                setToolTipOpen(false);
              }, 1000);
            },
          },
        ]}
      />
    </Dialog>
  );
};

export default MetricsIntegrationConfigModal;
