export const THEME_KEY = 'theme-enabled';
export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM_PREFERENCE = 'system_preference',
}
export const themeState = (theme: Theme, defaultTheme: boolean) => {
  switch (theme) {
    case Theme.DARK:
      return true;
    case Theme.LIGHT:
      return false;
    case Theme.SYSTEM_PREFERENCE:
    default:
      return defaultTheme;
  }
};
