import { parse as _parse, format as _format } from 'date-fns';

export const isValid = (value: Date, dateFormat) => {
  try {
    format(value, dateFormat);
    return true;
  } catch (e) {
    if (e instanceof RangeError) {
      return false;
    }

    throw e;
  }
};

export const getErrorText = (value: Date, dateFormat, defaultErrorMessage) => {
  if (!value) {
    return defaultErrorMessage;
  }

  const valid = isValid(value, dateFormat);

  if (!valid) {
    return 'Invalid date';
  }

  return defaultErrorMessage;
};

export const elementIsOrHasAncestor = (element: HTMLElement, otherElement: HTMLElement) => {
  let current = element;

  while (current) {
    if (current === otherElement) {
      return true;
    }
    current = current.parentElement;
  }

  return false;
};

export const format = (d: Date, dateFormat: string) => _format(d, dateFormat);

export const parse = (d: string, dateFormat: string) => _parse(d, dateFormat, new Date());
