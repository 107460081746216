import DatabasesResource from 'remote/resources/databases';
import { DatabaseSizeMap } from 'entities/database';

const getAvailableSizes = async (): Promise<DatabaseSizeMap> => {
  const response = await DatabasesResource.getAvailableSizes();

  const sizes: DatabaseSizeMap = response.data;

  return sizes;
};

export default getAvailableSizes;
