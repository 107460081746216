import React, { useMemo, useState, useEffect } from 'react';
import track, { useTracking } from 'react-tracking';
import Actions from 'actions';
import CloneToNewDatabaseForm, {
  defaults as createDefaults,
  validate as validateCreate,
} from 'application/clone-db/to-new/form-wrapper';
import {
  defaults as stripeDefaults,
  withStripeElements,
  useStripeSubmit,
  StripeBaseForm,
} from 'application/billing-form';
import {
  Database,
  filterSizeMap,
  findDatabaseSize,
  getDefaultName,
  gibibytesStringToInt,
  Tier,
} from 'entities/database';
import { useNeedsBilling, useSession } from 'store';
import { Alert, Button, Dialog, Form } from 'foundation';
import { scrollToFirstError } from 'components/utils';
import {
  CloneToNewDatabaseFormData,
  CloneToNewDatabaseFormOptions,
} from 'application/clone-db/to-new/form-data';
import { setProduct } from 'actions/product-actions';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { BillingMethod, getProductFromTier, tierDisplayName } from 'entities/tenant';
import { getAvailableVersions, getDefaultVersion } from 'utils/neo4j-versions';
import { CDCCloneDBWarning } from 'components/application/edit-cdc-enrichment-mode/cdc-warnings';
import { getHasCDCEnrichmentMode } from 'components/application/edit-cdc-enrichment-mode/helpers';
import { NEO4J_MANAGED_KEY } from 'types/encryption-keys';

type Props = {
  database: Database;
  tier: Tier;
  snapshotId?: string;
  open: boolean;
  onClose: () => void;
  isUpgrade?: boolean;
};

enum Step {
  EnterDatabaseDetails = 1,
  EnterCreditCardDetails,
}

const disableFreeReason = (fromSnapshot: boolean) => {
  if (fromSnapshot) {
    return 'Cannot create a free database from a snapshot';
  }

  return 'Cannot clone to a free database';
};

const isDataScienceTier = (tier: Tier) => [Tier.GDS, Tier.AURA_DSE].includes(tier);

// exported for testing
export const CloneToNewDatabaseModal = ({
  open,
  onClose,
  database,
  snapshotId,
  tier,
  isUpgrade = false,
  ...rest
}: Props) => {
  const session = useSession();
  const defaultErrorHandler = useDefaultErrorHandler();

  const fromSnapshot = !!snapshotId;
  const availableSizes = useMemo(() => {
    const minMemory = gibibytesStringToInt(database.DesiredSettings?.Memory || '1GB');
    return filterSizeMap(
      session.databaseSizes,
      size =>
        gibibytesStringToInt(size.memory) >= minMemory &&
        !size.deprecated &&
        size.cloud_providers.includes(database.CloudProvider) &&
        !size.is_trial
    );
  }, [database.DesiredSettings?.Memory, tier]);
  const availableVersions = useMemo(() => {
    return getAvailableVersions(session.providerConfigs, database.CloudProvider, tier);
  }, [tier]);

  const formOptions: CloneToNewDatabaseFormOptions = {
    database,
    planType: session.planType,
    product: session.product,
    tenant: session.tenant,
    targetTier: tier,
    neo4jVersions: availableVersions.filter(v => v >= database.DesiredSettings.Version),
    databaseSizes: availableSizes,
    disableFreeDatabaseCreationReason: disableFreeReason(fromSnapshot),
    providerConfigs: { [database.CloudProvider]: session.providerConfigs[database.CloudProvider] },
    isUpgrade,
    isCloned: true,
  };

  // Tier display name for DB being cloned or "upgraded"
  const dbTierName = database.TierDisplayName;
  // Tier display name for DB being "upgraded" if applicable
  const targetTierName = tierDisplayName(session.tenant, formOptions.targetTier);

  const setDefaults = () => {
    const sourceTier = database.Tier;
    const targetTier = tier;

    // If we're cloning into a DS tier from a non-DS tier, we're displaying the size estimator.
    // In this case, we do not want to set an initial size.
    if (isDataScienceTier(targetTier) && !isDataScienceTier(sourceTier)) {
      return createDefaults(formOptions);
    }

    const availableSizesForTier = availableSizes[targetTier];
    const desiredMemory = database.DesiredSettings?.Memory;
    const match = desiredMemory && findDatabaseSize(availableSizesForTier, desiredMemory);
    const startingSize = match || availableSizesForTier[0];

    return {
      ...createDefaults(formOptions),
      size: startingSize,
      ...(isUpgrade ? { name: getDefaultName(targetTierName, 'Instance') } : {}),
      cloudProvider: database.CloudProvider,
      region: database.Region,
      version: getDefaultVersion(session.providerConfigs, database.CloudProvider, targetTier),
    };
  };

  useEffect(() => {
    setData(setDefaults());
  }, [tier]);

  const [data, setData] = useState<CloneToNewDatabaseFormData>(() => setDefaults());
  const [stripeData, setStripeData] = useState(stripeDefaults());
  const [isServiceAddress, setIsServiceAddress] = useState(true);
  const [stripeError, setStripeError] = useState(null);
  const [validation, setValidation] = useState(null);
  const [step, setStep] = useState(Step.EnterDatabaseDetails);
  const [loading, setLoading] = useState(false);
  const stripeSubmit = useStripeSubmit();
  const tracking = useTracking();
  const elementsRef = React.useRef(null);

  const isCreditCardStepRequired = useNeedsBilling(data.tier);

  const handleDatabaseDetailsSubmit = ev => {
    ev.preventDefault();
    const errors = validateCreate(data, formOptions);
    setValidation(errors);

    if (errors) {
      scrollToFirstError();
      return;
    }

    if (isCreditCardStepRequired) {
      setStep(Step.EnterCreditCardDetails);
    } else {
      createDatabase();
      const product = getProductFromTier(tier);
      setProduct(product, { redirect: true });
    }
  };

  const handleStripeSubmit = ev => {
    ev.preventDefault();
    setLoading(true);
    stripeSubmit(stripeData, isServiceAddress)
      .then(() => {
        elementsRef.current.clear();
        createDatabase();
      })
      .catch(err => setStripeError(err));
  };

  const handleChange = (newData: CloneToNewDatabaseFormData) => {
    setData(newData);

    if (validation) {
      setValidation(validateCreate(newData, formOptions));
    }
  };
  const handleStripeChange = (newStripeData: typeof stripeData) => setStripeData(newStripeData);
  const handleBillingCancel = () => setStep(Step.EnterDatabaseDetails);

  const createDatabase = () => {
    tracking.trackEvent({
      action: isUpgrade ? 'upgrade_db' : 'clone_db',
      properties: {
        size: data.size,
        region: data.region,
        version: data.version,
        sourceTier: database.Tier,
        targetTier: tier,
        isCloneToNew: true,
      },
    });

    setLoading(true);

    Actions.databases
      .createDatabase({
        Name: data.name,
        Memory: data.size.memory,
        Region: data.region,
        Version: data.version,
        Tier: data.tier,
        Namespace: session.currentTenant,
        SourceSnapshot: {
          DbId: database.DbId,
          ...(snapshotId ? { SnapshotId: snapshotId } : {}),
        },
        CloudProvider: data.cloudProvider,
        ...(session.tenant?.capabilities?.cmek
          ? {
              Confirmed: data.confirmed,
              EncryptionKeyRef:
                data.encryptionKeyRef === NEO4J_MANAGED_KEY ? null : data.encryptionKeyRef,
            }
          : {}),
      })
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false);
        return defaultErrorHandler(error);
      });
  };
  const missingRequiredField = useMemo(() => {
    const errors = validateCreate(data, formOptions);
    if (errors?.confirmed || (data.name?.length ?? 0) > 30) {
      return true;
    }
    if (validation) {
      return true;
    }
    return false;
  }, [data, formOptions, validation]);

  const isPrepaidTenant = session.tenant.billingMethod === BillingMethod.PREPAID;
  const isPrepaidOnlyOptionSelected = useMemo(() => !!data?.size?.prepaid_only, [data]);

  const disableCloneWithoutCMK = useMemo(() => {
    return (
      [Tier.AURA_DSE, Tier.ENTERPRISE].includes(tier) &&
      database.EncryptionKey &&
      (data.encryptionKeyRef === NEO4J_MANAGED_KEY || !data.encryptionKeyRef)
    );
  }, [tier, database, data]);

  const disableClone = useMemo(() => {
    if (missingRequiredField) return true;
    if (isPrepaidOnlyOptionSelected && !isPrepaidTenant) return true;
    if (disableCloneWithoutCMK) return true;
    return false;
  }, [missingRequiredField, isPrepaidOnlyOptionSelected, isPrepaidTenant, disableCloneWithoutCMK]);

  const getHeader = () =>
    isUpgrade
      ? `Upgrade to ${targetTierName}`
      : fromSnapshot
      ? 'Create instance from snapshot'
      : 'Clone instance';

  const getButtonText = () => {
    if (isCreditCardStepRequired && step === Step.EnterDatabaseDetails) {
      return 'Next';
    }
    return isUpgrade ? 'Upgrade' : fromSnapshot ? 'Create' : 'Clone';
  };

  const cloneMessage = isUpgrade
    ? `Upgrading clones your ${dbTierName} instance data to a new ${targetTierName} instance with a new DBID and password. You can still keep your ${dbTierName} instance afterwards if you wish.`
    : database.Tier === Tier.GDS
    ? 'Create an exact copy of this instance without affecting this instance.'
    : 'Cloning your instance enables you to safely test without affecting your production instance.';

  const hasCDCEnrichmentMode = getHasCDCEnrichmentMode(database);

  const stepConfig = {
    [Step.EnterDatabaseDetails]: {
      header: getHeader(),
      cancelText: 'Cancel',
      buttonText: getButtonText(),
      handleCancel: onClose,
      handleSubmit: handleDatabaseDetailsSubmit,
      form: (
        <>
          <Dialog.Description className="tw-mb-6">{cloneMessage}</Dialog.Description>
          {hasCDCEnrichmentMode && <CDCCloneDBWarning />}
          <Form onSubmit={handleDatabaseDetailsSubmit} data-testid="clone-db-form">
            <CloneToNewDatabaseForm
              onChange={handleChange}
              options={formOptions}
              validation={validation}
              data={data}
            />
          </Form>
        </>
      ),
    },
    [Step.EnterCreditCardDetails]: {
      header: getHeader(),
      cancelText: 'Back',
      buttonText: getButtonText(),
      handleCancel: handleBillingCancel,
      handleSubmit: handleStripeSubmit,
      form: (
        <Form onSubmit={handleStripeSubmit}>
          <StripeBaseForm
            isServiceAddress={isServiceAddress}
            onIsServiceAddressChange={newValue => setIsServiceAddress(newValue)}
            data={stripeData}
            onChange={handleStripeChange}
            stripeElementsRef={ref => (elementsRef.current = ref)}
            formTitle="Add payment method"
          />
          {stripeError && (
            <Alert
              type="danger"
              closeable
              onClose={() => setStripeError(null)}
              title="Form error"
              description={stripeError}
              className="tw-mt-2"
            />
          )}
        </Form>
      ),
    },
  };

  const currentStep = stepConfig[step];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{ 'data-testid': 'clone-database-modal' }}
      {...rest}
      size="large"
    >
      <Dialog.Header>{currentStep.header}</Dialog.Header>
      <Dialog.Content className="tw-text-palette-neutral-text-default">
        {currentStep.form}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={currentStep.handleCancel}
          data-testid="clone-cancel-button"
          fill="outlined"
          color="neutral"
        >
          {currentStep.cancelText}
        </Button>
        <Button
          disabled={disableClone || loading}
          loading={loading}
          onClick={currentStep.handleSubmit}
          data-testid="clone-button"
        >
          {currentStep.buttonText}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default track()(withStripeElements(CloneToNewDatabaseModal));
