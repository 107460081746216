import { Tenant } from 'entities/tenant';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { useDatabaseQuery } from 'remote/resources/databases';
import customEndpointResources, { useListByNamespace } from 'remote/resources/custom-endpoints';
import { formatOptionLabel } from 'components/pages/custom-endpoints/shared';
import { validateYup, Validation } from 'utils/validation';
import logger from 'logger';
import * as yup from 'yup';
import { customEndpointURI } from 'entities/custom-endpoints';
import { Database } from 'entities/database';
import {
  Alert,
  Button,
  Dialog,
  LoadingSpinner,
  TextInput,
  Tip,
  FormSelect,
} from 'components/foundation';
import { useDefaultErrorHandler } from 'remote/error-handler';

type UpdateCustomEndpointData = {
  endpointId: string;
};
const schema = yup.object({
  endpointId: yup
    .string()
    .required()
    .label('Custom endpoint'),
});

const validate = (data: UpdateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  database: Database;
  tenant: Tenant;
};

export const ManageCustomEndpointModal = ({ onClose, database, tenant }: Props) => {
  const { data: databases = [] } = useDatabaseQuery(tenant.id);
  const [validationError, setValidationError] = useState<Validation<
    UpdateCustomEndpointData
  > | null>(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { data: endpoints = [], isLoading } = useListByNamespace(tenant.id);
  const [data, setData] = useState<UpdateCustomEndpointData>({
    endpointId: endpoints.find(endpoint => endpoint.dbId === database.DbId)?.id ?? '',
  });
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();

  const handleSelectEndpoint = (endpointId?: string) => {
    setValidationError(null);
    setData(prev => ({ ...prev, endpointId: endpointId ?? '' }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    const validation = validate(data);
    if (validation) {
      setValidationError(validation);
      setLoading(false);
      return;
    }
    setValidationError(null);

    customEndpointResources
      .update(database.DbId, data.endpointId)
      .then(() => {
        onClose();
      })
      .catch((e: ApiClientRequestError) => {
        logger.error('Failed to update custom endpoint', e);
        if (e.reason === 'custom-endpoint-was-recently-updated') {
          setErrorMessage(
            `Custom endpoint ${
              selectedEndpoint ? customEndpointURI(selectedEndpoint) : data.endpointId
            } cannot be transferred right now. There has to be a minimum of 3 hours between consecutive updates. Please try again later.`
          );
        } else {
          defaultErrorHandler(e);
        }
      })
      .finally(() => setLoading(false));
  };

  const options = endpoints.map(item => ({
    key: item.id,
    label: customEndpointURI(item),
    value: item.id,
    isDisabled: !item.isTransferable && !(item.isRevertible && item.sourceDbId === database.DbId),
    disabledReason: `Custom endpoint '${item.name}' was created or updated too recently.`,
  }));
  const selectedOption = options.find(item => item.key === data.endpointId);
  const selectedEndpoint = endpoints.find(endpoint => endpoint.id === data.endpointId);

  const databaseForEndpoint = useMemo(() => {
    const endpoint = endpoints.find(ep => ep.id === data.endpointId);
    const endpointDatabase = databases.find(inst => inst.DbId === endpoint?.dbId);
    return endpointDatabase;
  }, [databases, data, endpoints]);

  const isRevert =
    selectedEndpoint &&
    selectedEndpoint.isRevertible &&
    selectedEndpoint.sourceDbId === database.DbId;

  return (
    <Dialog open onClose={onClose}>
      <Dialog.Header>Manage custom endpoint</Dialog.Header>
      {isLoading ? (
        <LoadingSpinner className="tw-m-auto" />
      ) : (
        <form onSubmit={handleSubmit}>
          <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-col tw-gap-4">
              <TextInput label="Assigned instance" value={database.Name} readOnly fluid />
              <FormSelect
                label="Custom endpoint"
                errorText={validationError?.endpointId?.message}
                value={selectedOption}
                options={options}
                formatOptionLabel={formatOptionLabel}
                menuPosition="fixed"
                onChange={obj => handleSelectEndpoint(obj?.value)}
                data-testid="select-custom-endpoint"
              />
            </div>
            <Alert
              icon
              type="info"
              description="It will take a short moment for the custom endpoint to be ready for use again."
            />
            <Alert
              icon
              type="warning"
              description="You will not be able to configure the custom endpoint for the next 3 hours."
            />
            {databaseForEndpoint &&
              database.DbId !== databaseForEndpoint.DbId &&
              (selectedEndpoint?.isTransferable || isRevert) && (
                <Alert
                  type="warning"
                  description={
                    <>
                      <b>{selectedOption?.label}</b> is currently assigned to the instance{' '}
                      <b>{databaseForEndpoint.Name}</b>. The custom endpoint will be transferred to
                      the instance <b>{database.Name}</b> by clicking confirm.
                      {!isRevert && ' You will be able to undo the transfer.'}
                    </>
                  }
                  icon
                  data-testid="already-assigned-callout"
                />
              )}
            {errorMessage && <Alert type="danger" description={errorMessage} icon />}
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={onClose} data-testid="cancel-button">
              Cancel
            </Button>
            <Tip
              isDisabled={database.DbId !== endpoints.find(ep => ep.id === data.endpointId)?.dbId}
            >
              <Tip.Trigger>
                <Button
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={
                    selectedEndpoint &&
                    (database.DbId === selectedEndpoint.dbId ||
                      !(selectedEndpoint.isTransferable || isRevert))
                  }
                  data-testid="confirm-button"
                >
                  Confirm
                </Button>
              </Tip.Trigger>
              <Tip.Content isPortaled={false} style={{ width: 200, position: 'fixed' }}>
                <b>{database.Name}</b> is already assigned to this endpoint.
              </Tip.Content>
            </Tip>
          </Dialog.Actions>
        </form>
      )}
    </Dialog>
  );
};
