import React from 'react';
import ReactEchartWrapper from './react-echart-wrapper';

import Icon from 'ui/icons';
import { formatDateRange } from './helpers';
import { chartBuilder } from './options';
import { dateFromUnixTimestamp } from 'utils/date-time';
import { Dimmer, LoadingSpinner, Tip } from 'foundation';
import { sub } from 'date-fns';

export type TimeWindowValue = '6h' | '12h' | '24h' | '3d' | '7d' | '30d';

export interface TimeWindow {
  value: TimeWindowValue;
  label: string;
}

const chartHeight = 200;

const assertUnreachable = (x: never): never => {
  throw new Error(`Invalid timeWindow value: ${x}`);
};

const timesFromWindow = (timeWindow: TimeWindowValue) => {
  const now = dateFromUnixTimestamp(Date.now() / 1000);

  if (timeWindow === '6h') {
    return [sub(now, { hours: 6 }), now];
  }

  if (timeWindow === '12h') {
    return [sub(now, { hours: 12 }), now];
  }

  if (timeWindow === '24h') {
    return [sub(now, { hours: 24 }), now];
  }

  if (timeWindow === '3d') {
    return [sub(now, { days: 3 }), now];
  }

  if (timeWindow === '7d') {
    return [sub(now, { days: 7 }), now];
  }

  if (timeWindow === '30d') {
    return [sub(now, { days: 30 }), now];
  }

  // This makes TS yell if we forget a timeWindow
  assertUnreachable(timeWindow);
};

const DateRange = ({ timeWindow, metrics }) => {
  const ts = metrics.Charts[0].TimeSeries[0];

  let [startTime, endTime] = timesFromWindow(timeWindow);

  if (ts.length > 0) {
    endTime = dateFromUnixTimestamp(ts.DataPoints[ts.DataPoints.length - 1].Timestamp);
    startTime = dateFromUnixTimestamp(ts.DataPoints[0].Timestamp);
  }

  return <p>{formatDateRange(startTime, endTime)}</p>;
};

const DatabaseMetricChart = ({ loading, options }) => (
  <Dimmer.Dimmable className="tw-flex tw-flex-grow tw-flex-col tw-justify-center">
    {loading && (
      <Dimmer>
        <LoadingSpinner expand size="medium" />
      </Dimmer>
    )}
    <ReactEchartWrapper options={options} style={{ height: chartHeight }} />
  </Dimmer.Dimmable>
);

const DatabaseMetricCharts = ({ timeWindow, metrics, loading = false }) => {
  if (metrics?.Charts?.length) {
    return (
      <>
        <DateRange timeWindow={timeWindow} metrics={metrics} />
        <div
          className="tw-grid tw-gap-6"
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(570px, 1fr))' }}
        >
          {metrics.Charts.map((chart, i) => {
            const options = chartBuilder({ chart });
            return (
              <div
                key={`chart-${i}`}
                data-testid="chart"
                className="tw-my-4 tw-p-8 tw-rounded-2xl tw-shadow-raised console-border-color tw-relative tw-bg-palette-neutral-bg-weak tw-min-w-[570px] tw-h-[350px]"
              >
                <div className="tw-flex tw-flex-col tw-h-full">
                  <Tip>
                    <Tip.Trigger>
                      <span className="tw-ml-auto tw-inline tw-absolute tw-top-4 tw-right-4">
                        <Icon
                          name="InformationCircleIconSolid"
                          aria-label="Display chart information"
                          type="solid"
                          className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1 tw-text-palette-neutral-text-weak"
                        />
                      </span>
                    </Tip.Trigger>
                    <Tip.Content className="tw-max-w-sm">
                      <div>
                        <h6 className="tw-font-normal tw-mb-4">{options.DescriptionHeader}</h6>
                        <p className="tw-font-light">{options.Description}</p>
                      </div>
                    </Tip.Content>
                  </Tip>

                  <h5 className="tw-mb-4">{options.ChartTitle}</h5>
                  <DatabaseMetricChart loading={loading} options={options} />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="tw-flex tw-justify-center tw-items-center" style={{ minHeight: chartHeight }}>
      {loading ? (
        <LoadingSpinner size="large" />
      ) : (
        <p className="n-body-large">
          Sorry, there is no data to display for the selected time period.
        </p>
      )}
    </div>
  );
};

export default DatabaseMetricCharts;
