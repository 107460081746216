import { CustomEndpoint, CustomEndpointStatus } from 'types/custom-endpoints';

export const customEndpointURI = (customEndpoint: CustomEndpoint) =>
  `neo4j+s://${customEndpoint.name}-${customEndpoint.suffix}.endpoints.neo4j.io`;

const STATUS_TO_FRIENDLY = {
  [CustomEndpointStatus.UNKNOWN]: 'UNKNOWN', // Fail-safe, unlikely to be displayed
  [CustomEndpointStatus.DISCONNECTED]: 'DISCONNECTED',
  [CustomEndpointStatus.ACTIVE]: 'ACTIVE',
  [CustomEndpointStatus.DELETED]: 'DELETED', // Also unlikely to be displayed
  [CustomEndpointStatus.ERROR]: 'ERROR',
  [CustomEndpointStatus.SUBMITTED]: 'LOADING',
};

export const friendlyCustomEndpointStatus = (status: CustomEndpointStatus) =>
  STATUS_TO_FRIENDLY[status];
