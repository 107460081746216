import ApiClient from 'remote/api-client';

interface TenantFeatureToggleOverride {
  FeatureToggle: string;
  NewValue: boolean;
  OverrideId: string;
  NamespaceId: string;
  UserId?: null | undefined;
  NamespaceInternalName: null | string;
}

interface UserFeatureToggleOverride {
  FeatureToggle: string;
  NewValue: boolean;
  OverrideId: string;
  NamespaceId?: null | undefined;
  UserId: string;
  NamespaceInternalName: null | undefined;
}

export type FeatureToggleOverride = TenantFeatureToggleOverride | UserFeatureToggleOverride;

const listByTenantId = (tenantId: string): Promise<FeatureToggleOverride[]> =>
  ApiClient.get(`/feature-toggles/overrides?NamespaceId=${encodeURIComponent(tenantId)}`).promise();

const listByFeatureToggle = (featureToggle: string): Promise<FeatureToggleOverride[]> =>
  ApiClient.get(
    `/feature-toggles/overrides?FeatureToggle=${encodeURIComponent(featureToggle)}`
  ).promise();

interface CreateFeatureToggleOverridePayload {
  FeatureToggle: string;
  Value: boolean;
  NamespaceId?: string;
  UserId?: string;
}

const create = async (
  payload: CreateFeatureToggleOverridePayload
): Promise<FeatureToggleOverride> =>
  ApiClient.put('/feature-toggles/overrides')
    .issue(payload)
    .promise();

const deleteOverride = (overrideId: string) =>
  ApiClient.delete(`/feature-toggles/overrides/${overrideId}`).promise();

export default {
  listByTenantId,
  listByFeatureToggle,
  create,
  delete: deleteOverride,
};
