import { CurrentPage, NavigationLocation, NavigationState } from './types';
import globalLocation from 'browser/location';
import BaseStore from '../base-store';
import logger from 'logger';
import * as Sentry from '@sentry/browser';
import { parse, stringify } from 'query-string';
import { omit } from 'utils/javascript';

class NavigationStore extends BaseStore<NavigationState> {
  constructor() {
    super('NavigationStore');
  }

  initialState(): NavigationState {
    const location = locationFromUrl(globalLocation.href);
    return {
      location,
      currentPage: locationToCurrentPage(location),
    };
  }

  updateLocation(locationParts: Partial<NavigationLocation>) {
    const newLocation = { ...this.state.location, ...locationParts };
    this.setLocation(newLocation);
  }

  setLocation(location: NavigationLocation) {
    trackNavigation(location);

    this._updateState({ ...this.state, location, currentPage: locationToCurrentPage(location) });
  }

  setCurrentPage(currentPage: CurrentPage) {
    this._updateState({ ...this.state, currentPage });
  }

  _redactFilter(stateForLogging) {
    return {
      ...stateForLogging,
      currentPage: this._redactPageFields(
        stateForLogging.currentPage,
        'ref',
        'root',
        'name',
        'hierarchy'
      ),
    };
  }

  _redactPageFields(currentPage, ...fieldNames) {
    const result = { ...currentPage };

    fieldNames.forEach(field => {
      const isArrayField = Array.isArray(result[field]);
      const isStringField = result[field] && !isArrayField;

      if (isStringField) {
        result[field] = this._redactAccessTokens(result[field]);
      }
      if (isArrayField) {
        result[field] = result[field].map(this._redactAccessTokens);
      }
    });

    return result;
  }

  _redactAccessTokens(str) {
    return str.replace(
      /(access.token)=([^&]+)/,
      (entireMatch, key, value) => `${key}=${value.replace(/./g, '*')}`
    );
  }
}

export const locationToCurrentPage = (location: NavigationLocation): CurrentPage => {
  const redactedHash = redact(location.hash);
  logger.debug('Hash change:', redactedHash);

  const page = location.hash.replace(/^#(.*?)\/?$/, '$1');

  const targetPage = page || 'databases';

  logger.debug('Navigating to page:', redact(targetPage));

  const hierarchy = targetPage.split('/');
  const adminView = hierarchy[0] === 'admin';

  const normalizedHierarchy = [...hierarchy];

  if (adminView) {
    // Remove the admin prefix for now
    normalizedHierarchy.shift();
  }

  const [root, instanceId, subView] = normalizedHierarchy;

  let name = root;

  if (subView) {
    name += `-${subView}`;
  } else if (instanceId) {
    name += '-instance';
  }

  return {
    ref: targetPage,
    name,
    hierarchy,
    root,
    instanceId,
    subView,
  };
};

export const redact = str =>
  str.replace(
    /(access.token)=([^&]+)/,
    (entireMatch, key, value) => `${key}=${value.replace(/./g, '*')}`
  );

// Takes an href (ie url with origin) or just the path + search + hash
export const locationFromUrl = (urlValue: string): NavigationLocation => {
  const url = new URL(urlValue, globalLocation.origin);
  return {
    origin: url.origin,
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
  };
};

export const hrefFromLocation = (location: NavigationLocation): string => {
  return `${location.origin}${location.pathname}${location.search}${location.hash}`;
};

export const redactedLocation = (location: NavigationLocation): NavigationLocation => {
  const searchObj = parse(location.search);
  const redactedSearchObj = omit(searchObj, ['access_token', 'access-token', 'code']);
  const search = stringify(redactedSearchObj);
  return { ...location, search: search ? `?${search}` : '' };
};

const trackNavigation = (location: NavigationLocation) => {
  const safeLocation = redactedLocation(location);
  Sentry.addBreadcrumb({
    category: 'navigation',
    message: 'New Location ' + hrefFromLocation(safeLocation),
    data: safeLocation,
    level: 'info',
  });
};

export const InstantiatedNavigationStore = new NavigationStore();

export default NavigationStore;
